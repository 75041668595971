import React from 'react';

const StarRating = ({ rating }) => {
  const getStarClass = (index) => {
    if (rating >= index + 1) {
      return 'full';
    }
    if (rating > index && rating < index + 1) {
      return 'half';
    }
    return '';
  };

  return (
    <div className="star-rating">
      {Array.from({ length: 5 }, (_, index) => (
        <div key={index} className={`star ${getStarClass(index)}`} />
      ))}
    </div>
  );
};

export default StarRating;
