import React, { useState } from 'react';
import VITR from '../assets/img/vitrine/VITRINE ZONE PERMIS.jpg';


const InfosPresAE = () => {

    return (
        <div className='lay-presAE'>
            <div className='widthMaxcont'>
                <p className='nuAGR'>Ecole de conduite agréée sous le numéro E 17 006 0006 0</p>
                <div className='theGpartAE'>
                    <div className='leftAE'>
                        <h2 className='hPrAE'>Apprenez à conduire avec les meilleurs à <b>Antibes</b> ! </h2>
                        <p className='text-pres'>Que ce soit pour la voiture, la moto ou le bateau, notre école de conduite vous propose des formations complètes et adaptées à vos besoins. 
                        <br /><br />
                            Grâce à nos horaires très flexibles et notre réactivité exemplaire, nous vous garantissons une expérience d'apprentissage optimale et sur mesure. 
                            <br /><br />
                            Rejoignez-nous dès aujourd'hui et prenez la route ou le large en toute confiance avec zone permis !</p>
                        {/* <section>
                            <p>Sont nos critères qui font notre réputation ! </p>
                        </section> */}
                    </div>
                    <div className='rightAE'>
                        <div className='theVitZP'>
                            <img
                                className='forDvitrine'
                                src={VITR}
                            />
                        </div>
                    </div>
                </div>       
            </div>
        </div>
    );


};

export default InfosPresAE;