import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import ContactBan from '../components/ContactBan';
import Footer from '../components/Footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LesFormules from '../components/LesFormules';
import { IoCaretBackCircleOutline } from "react-icons/io5";
import ConditionsAge from '../components/ConditionsAge';
import LaFormuleCard from '../components/LaFormuleCard';

import vehicules from '../dataJSON/vehicules';
import PJ from '../dataJSON/PJ';
import formations from '../dataJSON/formations';
import infosDEScond from '../dataJSON/infosDEScond';

import SvmiMANU from '../icon/svgiMANU';
import SvmiAUTO from '../icon/iAUTO';
import SvmiAAC from '../icon/iAAC';
import SvmiAFTWIN from '../icon/iAFTWIN';
import SvmiPCX from '../icon/iPCX';
import SvmiCB125F from '../icon/iCB125F';
import SvmiCBH from '../icon/iCBH';
import SvmiVESPA from '../icon/iVESPA';
import SvmiVOITURETTE from '../icon/iVOITURETTE';
import SvmiETG from '../icon/iLIVREvoitureETG';
import SvmiETM from '../icon/iLIVREmotoETM';

const tradCatTypeC = {
  "ETG": SvmiETG,
  "ETM": SvmiETM,
  "ACCOMPAGNEE": SvmiAAC,
  "SUPERVISEE": SvmiAAC,
  "B AUTO": SvmiAUTO,
  "B MANU": SvmiMANU,
  "A1": SvmiCB125F,
  "A2": SvmiCBH,
  "A": SvmiAFTWIN,
  "F125": SvmiPCX,
  "AM cyclo": SvmiVESPA,
  "AM quadri": SvmiVOITURETTE
};

function FormationsPage() {

  const { FormuleS } = useParams();
  const theFormaatSp = FormuleS ? getIdFormuleS(FormuleS) : '';

  const theForm = theFormaatSp ? infosDEScond.find(info => info.formuleSt === theFormaatSp) : '';
  const vehicle = theForm ? vehicules.find(vehi => vehi.categories === theForm.categories) : '';
  const laFormule = theForm ? formations.find(form => form.categorie === theForm.categories) : '';
  const [lesFormulesAuCasPlusieurs, setLesFormulesAuCasPlusieurs] = useState(theForm ? formations.filter(form => form.categorie === theForm.categories) : '');

  const parts = FormuleS ? FormuleS.split('&') : '';
  const OPactuel = FormuleS ? parts[1] : '';
  const modeAutoouManu = FormuleS ? parts[0] : '';
  const tradAouM = {
    "AUTOMATIQUE": "auto",
    "MANUELLE": "manu",
  };

  const tabTrad = {
    "CLASSIQUE": "",
    "ACCOMPAGNEE": "-AAC",
    "SUPERVISEE": "-CS"
  };

  const IconComponent = theForm ? (tradCatTypeC[theForm.categories] || null) : '';
  const IconComponentAACouCS = OPactuel ? (tradCatTypeC[OPactuel] || null) : '';
  const [age, setAge] = useState(theForm ? theForm.ageMini : '');



  const filteredPJ = PJ.filter(pj => {
    const categorieMatche = pj.categories.includes(`${theForm ? theForm.categories : ''}`);

    const ageDeConditionValide =
      (age >= parseInt(pj.conditionAgeMini)) && (age < parseInt(pj.conditionAgeMax));

    return categorieMatche && ageDeConditionValide;
  });
  const [code, setCode] = useState(false);

  const formulesRefs = useRef([]);
  const [maxHeight, setMaxHeight] = useState(0);

  const updateMaxHeight = () => {
    const heights = formulesRefs.current.map(ref => ref?.offsetHeight || 0);
    const maxDivHeight = Math.max(...heights);
    setMaxHeight(maxDivHeight);
  };
  useEffect(() => {
    setAge(theForm ? theForm.ageMini : '');
    if (theFormaatSp.endsWith("-code")) {
      setCode(true);
    } else {
      setCode(false);
    }
    if (FormuleS) {
      setLesFormulesAuCasPlusieurs(theForm ? formations.filter(form => form.categorie === theForm.categories) : '');
    }
  }, [theForm, theFormaatSp]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (lesFormulesAuCasPlusieurs) {
        updateMaxHeight();
      }
    }, 0);

    window.addEventListener('resize', updateMaxHeight);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', updateMaxHeight);
    };
  }, [lesFormulesAuCasPlusieurs]);

  const navigate = useNavigate();


  const toggleTheFormaatSp = () => {
    if (FormuleS.endsWith("-code")) {
      navigate(`/nos-formules/${FormuleS.replace("AVEC-", "SANS-").replace("-code", "")}`);
      setCode(false);
    } else {
      navigate(`/nos-formules/${FormuleS.replace("SANS-", "AVEC-") + "-code"}`);
      setCode(true);
    }
  };

  const [an, setAn] = useState(false);

  const handleClick = (index) => {
    const clickedFormule = lesFormulesAuCasPlusieurs[index];
    const remainingFormules = lesFormulesAuCasPlusieurs.filter((_, i) => i !== index);
    setLesFormulesAuCasPlusieurs([...remainingFormules, clickedFormule]);
    setAn(true);
    setTimeout(() => {
      setAn(false);
    }, 1000);
  };

  const toggleDirectOptionConduite = (OPbtn) => {
    navigate(`/nos-formules/${FormuleS.replace(OPactuel, OPbtn).replace(theFormaatSp, `B-${tradAouM[modeAutoouManu]}${tabTrad[OPbtn]}${code ? '-code' : ''}`)}`);

  };


  function getIdFormuleS(str) {
    const parts = str.split('=');
    return parts.length > 1 ? parts[1] : '';
  }
  function rplSpa(str) {
    return str.replace(/-/g, ' ');
  }
  function rplSpaTR(str) {
    return str.replace(/&/g, ' - ');
  }

  return (
    <React.Fragment>

      <Header />

      <div id="ForMbg" className='GpageMPORM'>
        {FormuleS &&
          <div className='lay-entetepresFormation'>
            <div className='widthMaxcont'>
              <div className='theCOMPFORTIT'>
                <Link to="/nos-formules" className='thebtnTretF'>
                  <IoCaretBackCircleOutline color={'#476c78'} size={45} />
                  <h3 className='titFormuu'>{theForm ? theForm.titre.toUpperCase() : 'RETOUR'}</h3>
                </Link>
                <div className='justIcon'>
                  {IconComponent && <IconComponent size={150} className='theicSVI' color={"rgb(38 106 167 / 54%)"} />}
                  {IconComponentAACouCS && <IconComponentAACouCS size={150} className='theicSVI' color={"rgb(38 106 167 / 54%)"} />}
                </div>

              </div>

            </div>
          </div>
        }
        <div className='lay-formations'>
          <div className='widthMaxcont'>
            {!FormuleS ?
              <div className='contForm'>
                <LesFormules />
              </div>
              :
              <React.Fragment>
                {theForm ?
                  <div className='contLaFcat'>
                    <div className="seleOptionCat">
                      {laFormule && laFormule.categorie.includes("B") &&
                        <div className='optionCAT'>
                          <h4 className='titOP'>CONDUITE :</h4>
                          <ul>
                            <li>
                              <button
                                onClick={() => {
                                  toggleDirectOptionConduite("CLASSIQUE");
                                }}
                                className={`btnCon ${(!laFormule.categorie.includes("AAC") && !laFormule.categorie.includes("CS")) ? 'selB' : ''}`}>
                                CLASSIQUE
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => {
                                  toggleDirectOptionConduite("ACCOMPAGNEE");
                                }}
                                className={`btnCon ${laFormule.categorie.includes("AAC") ? 'selB' : ''}`}>
                                ACCOMPAGNEE
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => {
                                  toggleDirectOptionConduite("SUPERVISEE");
                                }}
                                className={`btnCon ${laFormule.categorie.includes("CS") ? 'selB' : ''}`}>
                                SUPERVISEE
                              </button>
                            </li>
                          </ul>
                        </div>
                      }

                      {/* <p>{code ? 'true' : 'false'}</p> */}

                      {theForm && theForm.typeC &&
                        <div className='optionCODE'>
                          <h4 className='titOP'>FORFAIT CODE :</h4>
                          <ul>
                            <li>
                              <button
                                onClick={() => {
                                  toggleTheFormaatSp();
                                }}
                                className={`btnC ${theForm.typeC === "AVEC FORFAIT CODE" ? 'selB' : ''}`}>
                                AVEC
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => {
                                  toggleTheFormaatSp();
                                }}
                                className={`btnC ${theForm.typeC === "SANS FORFAIT CODE" ? 'selB' : ''}`}>
                                SANS
                              </button>
                            </li>
                          </ul>
                        </div>
                      }
                    </div>
                    <div className='contDFormation'>

                      <div className='partG'>
                        <div className='desriptionFormation'>
                          <h2 className='titpForm'>{theForm && (theForm.categories === 'ETG' || theForm.categories === 'ETM') ? 'Description :' : 'Permet la conduite de :'}</h2>
                          <p>{theForm.description}</p>
                        </div>
                        <div className='conditionsFormation'>
                          <h2 className='titpForm'>Conditions d'accès :</h2>
                          <ul className='ulCA'>
                            {theForm.conditions.map((zbr, index) =>
                              <li key={index}>
                                {zbr}
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className='pjfournirFormation'>
                          <h2 className='titpForm'>Pièces à fournir :</h2>

                          <ConditionsAge
                            age={age}
                            setAge={setAge}
                            ageMini={theForm ? theForm.ageMini : ''}
                            trancheAgepossible={theForm ? theForm.trancheAgepossible : ''}
                            categorie={theFormaatSp} />

                          <ul className='ulPF'>
                            {filteredPJ.map((pj, index) =>
                              <li key={index}>
                                {pj.namePJ}
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>

                      <div className='partD'>
                        {(lesFormulesAuCasPlusieurs && lesFormulesAuCasPlusieurs.length > 1) ?

                          <div className='listeDeFormules' style={{ minHeight: `${maxHeight + 140}px` }}>
                            {lesFormulesAuCasPlusieurs.map((formule, index) => (
                              <div
                                key={index}
                                ref={el => formulesRefs.current[index] = el}
                                className={`fomruleDansF cursp ${(an && index===2) ? 'anim animate__fadeInDownBig' : ''}${(an && index!==2) ? `op${index}` : ''}`}
                                onClick={() => handleClick(index)}
                                style={{
                                  transform: `translate(${-20 * index}px, ${65 * index}px)`,
                                }}>
                                <LaFormuleCard
                                  laFormule={formule}
                                  code={code}
                                  AUTOouMANUouMOTO={modeAutoouManu}
                                />
                              </div>
                            ))}
                          </div>
                          :
                          <React.Fragment>
                            {laFormule &&
                              <div className='fomruleDansF'>
                                <LaFormuleCard
                                  laFormule={laFormule}
                                  code={code}
                                  AUTOouMANUouMOTO={modeAutoouManu}
                                />
                              </div>
                            }
                          </React.Fragment>
                        }

                      </div>




                    </div>
                  </div>
                  :
                  <div>
                    <p>ERREUR SUR LA PAGE</p>
                  </div>
                }
              </React.Fragment>
            }


          </div>
        </div>
      </div>

      <ContactBan />
      <Footer />

    </React.Fragment >
  );
}

export default FormationsPage;