import React from "react";
const iCBH = ({ size = 24, color = "#0056b3" }) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size} height={size}
		viewBox="0 0 300 178"
		fill={color}
		stroke="none"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round">
		<g>
			<path d="M81.4,100.9c1.3,0,2,0.4,1.3,1.9c-0.2,0.4,0,0.9,0.1,1.8c1.9-0.8,3.6-1.5,5.4-2.3c0.7,0.8,1.4,1.9,2.2,2.8
		c0.3,0.3,0.7,0.7,1.1,0.8c3.3,0.1,6.5,0.2,9.8,0.3c0.5,0,1.1-0.1,1.8-0.2c-0.4-2.4-0.7-4.7-1.1-7c0.5-0.1,0.9-0.3,1.4-0.4
		c0-0.1,0.1-0.2,0.1-0.3c-2.1-1-4.2-1.9-6.5-2.9c-1.4,1.1-2.8,2.2-4.3,3.4c-1.4,1.1-2.7,2.3-4.3,3.4c0.2-0.9,0.7-1.8-0.5-2.4
		c0.4-0.1,1-0.3,1.2-0.2c1,0.8,1.7,0.2,2.4-0.4c1.9-1.6,3.7-3.2,5.8-4.6c0.9-0.6,2.1-0.5,3.2-0.8c0.8-0.2,1.5-0.5,2.3-0.8
		c0.1-0.5,0.1-1.1,0.2-1.6c-0.4-1.1-1.2-1.5-2.3-1.3c-0.4,0.1-0.9,0.2-1.3,0c-2.2-1.2-4.4-2.4-6.4-3.5c-0.6,0.3-1.5,1.1-2.1,0.9
		c-0.7-0.2-1.2-1.3-1.4-2.1c-0.3-1-0.2-2.1-0.3-3.1c-4.2-2.5-8.3-5.2-12.7-7.2c-2.8-1.3-5-3-7.4-4.8c-1.3-1-2.7-1.7-4.1-2.5
		c-1.1-0.6-2.3-0.8-3.3-1.4c-3.8-2.6-7.9-2.5-12.2-2.2c-5.8,0.4-11.6,0.6-17,0.9c-0.9,1-1.4,2.1-2.3,2.6c-3.5,1.7-6.6,3.9-9.1,6.9
		c-0.5,0.6-1.1,1-1.7,1.5c-1.3,1.2-2.7,2.5-4,3.7c-0.3,0.3-0.5,0.7-0.8,1.1c-4,6.3-8,12.5-12,18.8c-0.2,0.3-0.5,0.5-0.8,0.7
		c-0.1-0.1-0.3-0.1-0.4-0.2c1.2-3,2.5-6,3.7-9.1c-0.7-0.9-1.2-0.5-1.7,0.3c-0.6,1-1.2,1.9-1.9,2.9C1.4,94,1.2,93.9,1,93.8
		c0.2-0.5,0.3-1,0.5-1.4c2.9-5.8,5.9-11.5,8.8-17.3c1-1.9,2.5-1.5,3.8-1.4c-0.2-1-0.6-1.9-0.6-2.9c-0.1-1-0.5-2.3,1-3.2
		c-1-0.3-2.1-0.6-3.2-0.7c-2.6-0.3-2.9-0.7-2.2-3.5c0.6,0,1.3,0,2,0c1,0,2.1,0.2,2.7-1c0-0.1,0.2-0.2,0.3-0.2
		c2.4-0.1,4.7-0.3,7.1-0.4c0.6,0,1.3,0.3,1.9,0.2c5.2-0.9,10.4-1.9,15.7-2.9c0-0.1,0-0.3,0-0.4c-1.1-0.1-2.3-0.3-3.4-0.4
		c-1.4,0-2-0.8-2.1-2c-0.1-1.1-0.5-2.2-0.3-3.3c0.3-2.1-0.9-3.3-2.3-4.4c-1.9-1.4-3.9-2.7-5.8-4c-1-0.6-1.6-1.3-1.5-2.5
		c1.3,0.8,2.6,1.5,3.8,2.4c2.3,1.7,4.7,3.1,8.1,2.7c-4.3-1.8-7.3-5.5-12.1-6c0.1,0.4,0.1,0.7,0.2,0.9c-0.7-0.4-1.4-0.8-2.4-1.3
		c0.6-0.4,0.9-0.7,1.2-0.7c4.1,0.5,8.2,1,12.4,1.5c0,0.2,0,0.3-0.1,0.5c-2.2-0.3-4.5-0.5-6.9-0.8c6.1,3.3,12.5,5.4,18.9,7.4
		c0.1-0.2,0.1-0.3,0.2-0.5c-3.7-1.3-7.5-2.5-11.2-3.8c0-0.1,0.1-0.3,0.1-0.4c2.2,0.5,4.3,0.9,6.7,1.4c-1.4-1-2.6-1.9-3.8-2.9
		c0.1-0.1,0.1-0.1,0.2-0.2c0.6,0.1,1.1,0.3,1.7,0.4c0-0.1,0.1-0.2,0.1-0.3c-1.1-0.6-2.3-1.3-3.4-1.9c0.1-0.2,0.1-0.4,0.2-0.6
		c1.6,0.1,3.1,0.2,4.9,0.3c-0.2,0.2-0.3,0.3-0.6,0.6c2,0.2,4,0.3,5.9,0.5c3.4,0.4,6.8,0.7,10.2,1.2c2.9,0.4,5.8,0.9,8.7,1.6
		c1.2,0.3,2.3,1.1,3.4,1.4c1.1,0.2,2.4,0.1,4,0.1c-0.5-1.5-0.8-2.6-1.2-3.8C70,42,67.5,42.2,65,42.3c3.9-0.5,7.9-0.5,11.3-2.8
		c2.5,2,4,8.7,2.6,11c0.2,1,0.5,1.9,0.7,2.9c0.2,0.9,0.2,1.8,0.2,2.5c1.9,0.7,3.7,1.3,5.5,1.9c-0.9-0.2-1.9-0.4-2.9-0.6
		c0.8,1.4,3.1,2.7,4.3,2.6c-0.4-0.6-0.9-1.3-1.4-2c4,1.3,8,2.6,12,4c2.8,1,5.7,0.7,8.6,0.8c3.6,0.1,7.1,0.6,10.7,0.6
		c2.5,0,5.1-0.5,7.7-0.8c1-0.1,1.9,0,3,0c0.1-1,0.3-2.2,0.5-3.4c0.6-4.1,3.4-6.7,6.6-8.8c4.2-2.9,8.6-5.5,12.9-8.2
		c0.8-0.5,1.7-0.7,2.5-1.1c-0.1,0.2-0.2,0.4-0.4,0.5c-3,2-6.1,3.9-9.1,5.9c-0.2,0.1-0.4,0.1-0.6,0.2c0.1,0.1,0.2,0.2,0.3,0.3
		c2.1-0.8,4.2-1.8,6.4-2.4c1.6-0.5,3.4-0.9,5-0.6c3.2,0.6,6.4,1.5,9.5,2.6c6.8,2.5,13.6,5.3,20.5,8c-0.6,1-1.2,2-1.8,3.1
		c-0.6,1-1.1,2.1-1.7,3.2c0.5,0,0.7-0.2,0.9-0.4c2.5-3.5,5.4-6.2,10.2-5c0.1,0,0.1,0,0.2,0.1c-1,0.1-1.9,0-2.8,0.2
		c-0.9,0.2-2,0.3-2.6,0.9c-2.1,2-4.1,4.3-6.1,6.4c-0.4,0.4-1,0.7-1.7,1.1c0.6,0.3,1.1,0.7,1.7,0.8c5.1,1.1,10.2,2.1,15.3,3.2
		c1.5,0.3,2.7-0.4,3.6-1.4c1.1,0.3,2.1,0.7,3.1,1c0.6-0.4,1-0.8,1.6-1.2c-2.1-0.5-4-0.9-5.9-1.3c0.2-0.4,0.4-0.5,0.5-0.4
		c2.1,1,4,0.8,6.2-0.3c2.2-1.2,4.6-1.8,7.1-2.8c-0.3-0.4-0.6-0.6-1-1.1c1.4,0.5,2.6,0.9,3.8,1.4c0.8-0.7,1.4-1.4,2.1-2
		c-2.6,0.9-4.8-0.2-6.9-1.2c0.4-0.1,0.9-0.1,1.2,0.1c1.7,0.8,3.4,0.4,5.2,0.5c3.1,0.2,5.9-1.1,9-1.4c-0.7-0.5-1.4-0.6-2.2-0.7
		c-1.7-0.4-3.4-0.7-5.1-1.2c-0.5-0.1-1-0.4-1.5-0.6c-1.9-0.6-3.7-1.2-5.6-1.8c-3-1-6-2-8.8-3c0.4-0.2,1-0.5,2.1-0.9
		c-1.9-2.1-3.6-4-5.3-5.9c0-0.2,0.1-0.5,0.1-0.8c-0.2-2.3-1.9-2.9-3.7-3.3c-7.5-1.8-14.9-3.7-22.4-5.4c-2.6-0.6-5.2-0.5-7.8,0.5
		c-3,1.2-6.1,2.5-9.1,3.7c-1.2,0.5-2.4,1-3.6,1.5c0.1-0.1,0.1-0.4,0.2-0.4c4.5-2,9-4.3,13.7-6c1.9-0.7,4.2-0.4,6.3-0.1
		c7.9,1.2,15.6,3.3,23.2,5.7c3.7,1.2,5.7,4,7.8,6.7c0.9,1.2,2,2.3,3.1,3.6c0.7-2.8,2.5-0.8,3.7-1.5c-1.4-1.5-3.3-3-1.9-5.6
		c0.5-0.2,1.2-0.6,1.9-0.9c-0.2-0.9-0.2-1.8-0.5-2.5c-0.5-1.1-1.2-2.1-2.8-2c-1.7,0.1-3.4-0.1-5.1-0.5c-1,0.2-2,0.4-3,0.7
		c0-0.2,0.1-0.3,0.1-0.5c-1.5-0.5-3-1.1-4.9-1.7c0.2-0.7,0.5-1.6,0.9-2.7c-2,0.3-3.7,0.7-5.3,0.8c-1.2,0.1-2-0.6-2.3-1.7
		c-0.3-1.1,0.1-1.9,1.2-2.1c1-0.2,2-0.2,3-0.3c0.6-0.1,1.3-0.2,1.9-0.2c0.5-2.3,1.9-3.2,4.2-3c0.9,0.1,1.9-0.4,2.8-0.7
		c2.2-0.2,4.5-0.4,6.8-0.6c0-1.3-0.3-2.4,0.1-3.2c0.8-2.2,0.3-4.3-0.2-6.4c-0.2-0.7-0.9-1.2-1.6-2c-1.2,1.1-2.2,2-3.1,2.8
		c-0.6-1.2-1.4-1.6-2.5-0.9c1.4,1.7,2.9,3.4,4.6,5.5c0.1,1,0.3,2.4,0.5,4c-1.5,0.3-3.1,0.6-4.6,0.9c0.7-0.5,1.4-1,2.1-1.5
		c0.9-1.6,0.9-3.4-0.2-5.1c-0.9-1.4-2.1-1.7-3.5-0.7c-1.4,1-2.8,1.6-4.5,0.8l0,0c1.2,0.3,1.6-0.5,2-1.4c1.3-3.1,2.2-6.3,2.1-9.7
		c0.1,1.3,0.2,2.6,0.2,3.8c0.2,0,0.3,0,0.5,0c0.7-3.3,1.4-6.5,2-9.8c0.1,0.1,0.2,0.1,0.3,0.2c0.6-0.4,1.2-0.7,1.7-1.1
		c0.9-0.7,1.7-0.6,2.2,0.3c1.4,2.3,2.8,4.7,4.1,7.1c0.3,0.5,0,1.5-0.4,2c-0.9,1.2-1.4,2.4-0.7,3.9c0.3,0.8,0.5,1.7,0.7,2.5
		c0.3,1.6,0.7,3.3,0.9,4.9c0.1,0.7,0,1.4,0,1.8c3.4-0.1,7.1-0.3,10.9-0.2c0.9,0,2.1,1,2.7,1.8c1.9,2.9,2.3,6.1,1.4,9.5
		c-0.1,0.4-0.1,0.8-0.2,1.1c0.6,0.6,1.2,1.1,1.8,1.6c0.6,0,1.3-0.2,1.8,0.1c1.2,0.7,1.8,0.4,2.5-0.7c1.2-2.1,2.6-4,3.8-5.9
		c1.3,0.1,2.4,0.2,3.5,0.4c0.6,0.1,1.2,0.6,1.5,1.1c1.9,3.3,3.7,6.7,5.5,10.1c0.6,1.2,1.1,2.5,1.6,3.8c0.2,0.6,0.2,1.3,0.2,1.9
		c-0.1,0.1-0.3,0.2-0.4,0.3c1.1,0.9,2.2,1.8,3.3,2.7c-0.6,0.7-1,1.1-1.4,1.5c-0.5-0.1-1-0.3-1.6-0.4c0.1-0.3,0.2-0.6,0.3-1.1
		c-0.3,0.2-0.6,0.4-1,0.6c-0.1-0.4-0.1-0.7-0.2-1.1c-0.5,0.2-1,0.4-1.7,0.8c2.1,3.8,3.2,7.9,6.4,10.9c-1.1-0.3-2.1-0.6-3.2-1
		c-0.1,0.2-0.2,0.3-0.3,0.5c0.2,0.2,0.5,0.5,0.7,0.7c1.1,0.6,2.2,1.1,3.3,1.7c0.4,0.2,0.8,0.6,1.2,0.9c0.1-0.1,0.1-0.2,0.2-0.3
		c-0.7-0.8-1.3-1.7-2-2.5c1.9,1.8,3.8,3.5,5.9,5.4c-1.1,1.4-1.9,2.6-2.8,3.7c-0.2,0.3-0.8,0.4-1.2,0.4c-3,0.1-5.9,0.2-8.9,0.4
		c-3,0.2-5.4-1-7.7-2.7c-1.3-0.9-2.4-2-3.6-3c-0.2,0.1-0.3,0.3-0.5,0.4c1.4,2.2,2.8,4.4,4.3,6.8c0.5-0.1,1.2-0.2,1.9-0.3
		c2.6-0.1,4,1.5,3.3,4c-0.5,1.6-0.5,3.5-2.4,4.4c0.7,1.4,1.3,2.7,1.9,4.1c0.8,1.6,1.9,2.2,3.7,2c3.6-0.5,7.2-1.1,10.9-1.6
		c1.5-0.2,3-0.2,4.6-0.2c-2.9,0.6-5.7,1.2-8.6,1.8c0,0.2-0.1,0.4-0.1,0.7c0.6,0.3,1.3,0.8,1.9,0.7c7-1.4,13.6,0.4,20.3,1.9
		c0.1,0,0.2,0,0.3,0c0.3-0.3,0.6-0.7,1-1.1c2,1.1,3.9,2.3,6.3,3.6c-1.5,0.3-2.7,0.5-4.2,0.7c3.4,1.6,13.5,7,19.8,19.2
		c1.9,3.7,6.3,13.4,4.5,26c-0.4,2.9-2,12.7-9.7,21.6c-5.8,6.7-11.9,9.9-14.1,11c-1.3,0.6-3.4,1.6-6.1,2.4
		c-13.6,4.2-25.7-0.1-27.1-0.6c-3.2-1.2-9.5-3.9-15.5-9.9c-1.4-1.4-5.5-5.7-8.6-12.6c-4.9-10.9-5-22-0.9-33.5
		c-1.2,0.8-2,1.5-2.9,2.1c-0.1-0.1-0.2-0.1-0.4-0.2c0.1-0.6,0.2-1.2,0.4-1.8c2.7-7.9,6.5-15.1,12.6-21c0.4-0.4,0.4-1.4,0.6-2.1
		c0.3-1.1,0.5-2.3,0.9-3.4c0.1-0.3,0.7-0.4,1.1-0.6c0.1-0.4,0-1,0.2-1.3c1.8-2,1.1-3.9-0.1-5.9c-0.8-1.3-1.4-2.8-2.3-4.1
		c-0.1,2.2-1.9,2.8-3.2,3.8c-1.3,1-2.8,1.6-4.5,1.1c-1.1,2.2-2.1,4.4-3.2,6.6c-2,3.9-4,7.8-5.9,11.8c-0.6,1.2-1.4,1.8-2.8,1.8
		c-1.1,0-2.1,0.7-3.2,1c0.4,1.1,0.4,1.1-1.2,2.9c-0.2-0.2-0.4-0.4-0.8-0.7c0,0.4-0.1,0.6,0,0.8c0.9,1.9,1.1,2,2.1,1.8
		c-0.4-0.2-0.6-0.3-1-0.6c0.8-0.5,1.5-1,2.3-1.4c1.7,2.4,1.4,5.1,0.9,7.7c-0.2,1-0.7,1.1-2.5,0.9c1,9.8,0,19.1-6.6,27.1
		c1-2,1.9-4,3-5.9c2.1-3.6,2.7-7.6,2.5-11.7c-0.2-3.9-0.2-7.9-0.6-11.8c0,0.3-0.1,0.6-0.1,0.9c0,3,0,6-0.2,9c-0.2,2.4-0.6,4.9-1,7.3
		c-0.2,1.1-0.7,2.1-2,2.1c-1.6,4.6-2.6,9.5-7.3,12.5c2.1-5.1,4.1-9.9,6-14.8c0.6-1.5,0.7-3.1,1.1-4.7c0.5-2.4,1-4.8,1.4-7.3
		c0.4-2.4,0.9-4.9,1.2-7.3c0.1-0.9-0.1-1.8-0.1-2.2c-1.6,6.4-3.2,13-4.9,19.7c-0.1,0.4-0.5,0.7-0.7,1.1c-0.1-0.1-0.3-0.2-0.4-0.3
		c0-0.7,0.1-1.4,0.1-2.2c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0.8-0.6,1.6-0.8,2.4c-1.1,3.2-2.1,6.3-3.4,9.4c-0.5,1.3-1.6,2.4-2.1,3.7
		c-1,2.5-3,3.8-5.5,4.4c-0.4,0.1-0.7,0.6-1.2,1.1c1,0.1,1.6,0.2,2.3,0.3c0,0.2,0,0.3,0,0.5c-2.4,0.5-4.8,1.1-7.3,1.3
		c-1.6,0.1-3.1,0.2-4.6,1c-2.7,1.4-5.3,2-8.5,1.7c-4.2-0.4-8.5,0.1-12.7,0.1c-3.3,0-6.7,0.3-10,0c-1.7-0.2-3.4-1.1-4.9-2
		c-1.8-1.1-3.6-1.3-5.7-1.1c-1.7,0.2-3.5,0.4-5-0.8c-0.3-0.2-0.8-0.2-1.3-0.2c-2.8-0.3-5.5-0.5-8.6-0.8c0.4-0.9,0.7-1.5,0.9-2.1
		c0.2,0.4,0.3,1,0.5,1.1c2.1,0.6,4.2,0.7,6.3,0.2c-2-2-4.6-1.2-6.9-1.5c0.3-0.6,0.5-1.2,0.8-1.8c-0.3-0.3-0.4-0.5-0.6-0.5
		c-5.7-1.8-10.7-5.2-16.1-7.7c-3.3-1.5-5.9-3.7-8.7-5.8c-1.3-1-2.6-1.9-3.9-2.8c-0.3-0.2-0.5-0.5-0.6-0.8c-0.9-2.2-1.7-4.3-2.5-6.5
		c-0.2-0.5-0.7-0.9-1.1-1.3c-1.2,0.6-2.2,1.1-3.2,1.6c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.9-0.2,1.7-0.3,2.6c0.5,0.5,1.1,1,1.9,1.7
		c-1.4,1.7-2.7,3.4-4.1,5.1c0.1-0.4,0.1-0.7,0.2-1.1c-0.5-0.3-1.1-0.7-1.8-1.1c-0.8,1.2-1.6,2.4-2.4,3.6c0.7,0.5,1.2,0.8,1.6,1.2
		c0.3-0.2,0.6-0.4,0.9-0.5c0,0.3-0.1,0.6-0.1,1.1c1.7,0.1,3.4,0.3,5.1,0.4c-3.5,0.4-7.1-0.7-10.8,0.7c2.5,1.8,4.8,3.6,7.1,5.2
		c0.3,0.2,0.8,0.2,1.2,0.1c0.7-0.1,1.4-0.3,2.4-0.6c0.2-1.3,0.5-2.9,0.7-4.8c0.4,0.6,0.7,1,1,1.4c0.2-0.1,0.5-0.3,0.7-0.4
		c-0.4-0.4-0.8-0.7-1.5-1.3c3-0.1,3.2-2.3,3.7-3.5c1.4,1.3,3,2.7,4.6,4.2c-1.4-0.7-2.8-1.5-4.3-2.3c-0.4,0.8-0.6,1.2-0.9,1.9
		c3.2,2.3,6.2,4.5,9.6,6.9c0.5,0.2,1.4,0.5,2.4,0.9c-1,3.9-3.5,11.4-10,18.5c-6.7,7.5-14.4,10.9-19.3,12.5c-4,1.3-16,4.6-29.7-0.5
		c-3.2-1.2-16.3-6.5-23.9-20.9c-1.4-2.7-5.9-12.8-4.8-26.1c1.4-17.7,13.7-31.1,26.6-36.2c-0.4,1.1-0.7,2-1.2,3.4
		c2.4,0.4,4.6,0.7,6.9,1.1c0-0.1,0-0.1,0.1-0.2c-1.9-0.6-3.9-1.3-6.1-2c0.8-1.8,1.6-3.5,2.3-5.3c6.3,2.9,12.8,5.1,17.6,10.1
		c-3.4-4.8-8.4-7.2-13.8-8.9c0-0.1,0-0.3,0-0.4c1.3-0.3,2.7-0.7,4-0.9c10.4-1.7,20.4-0.2,29.7,4.8c1.1,0.6,2,1.8,2.9,2.8
		c1.3,1.4,2.4,3,3.6,4.4C82,100.2,81.7,100.6,81.4,100.9c-1.3-0.6-2.2-0.1-2.9,1.1c1,0.4,1.8,0.7,2.6,1c-0.2,0.5-0.4,1.1-0.6,1.8
		C82.2,104,82.4,102.5,81.4,100.9z M197.9,84.2c-0.1-1.1-0.6-1.7-1.4-1.9c-0.1,0.7-0.1,1.3-0.2,2.3c0.5,0,0.9-0.1,1.4-0.1
		c-0.1,0.9-0.2,1.7-0.3,2.6c1,0.4,1.6,0.7,2.3,1c0.6-1.7,0-2.9-1.1-3.9C198.6,84,198.2,84.2,197.9,84.2z M286.8,144.5
		c0.4-0.4,1-0.6,1.2-1.1c1.8-6,1.7-12.1,0.1-18.2c-0.1-0.3-0.3-0.6-0.6-0.9c-0.1,0.2-0.2,0.3-0.2,0.3c0.1,0.3,0.1,0.5,0.2,0.8
		c1.7,5.8,1.8,11.7,0,17.5C287.4,143.5,287,144,286.8,144.5c-0.7,1.9-1.3,3.8-2,5.7c0.1,0.1,0.2,0.1,0.4,0.2
		c0.7-1.6,1.4-3.2,2.1-4.8C287.3,145.3,286.9,144.8,286.8,144.5z M48.4,102.6c0.4-0.3,0.9-0.6,1.3-1c0-0.2-0.1-0.3-0.1-0.5
		c-0.8,0.1-1.6,0.3-2.4,0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0.8-0.7,1.7-1.3,2.5-2c-0.1-0.1-0.2-0.3-0.3-0.4c-1.4,0.3-2,1.5-3.1,2.6
		C47.1,101.9,47.8,102.2,48.4,102.6c0.7,0.4,1.4,0.8,2,1.2c0.1-0.1,0.2-0.2,0.3-0.4c-0.1,0.4-0.2,0.9-0.4,1.4
		c0.5-0.1,0.8-0.1,0.9-0.1c1.7,1.6,3.4,3.1,5,4.6c0.5-0.6,0.9-1,1.4-1.5c-1.1-0.4-2.1-0.8-3.1-1.2c0.2-1.5-0.9-1.4-2.1-1.3
		c0.1-0.5,0.1-0.9,0.2-1.3c0.5,0.1,0.9,0.2,1.7,0.3c-0.4-0.7-0.7-1.1-1-1.5c-0.6,0.4-1.2,0.7-1.8,1.1c-0.1-0.1-0.1-0.1-0.2-0.2
		c0.7-1,1.4-1.9,2.2-2.9c-0.1-0.1-0.3-0.2-0.4-0.3c-0.7,0.7-1.4,1.3-2,1.8C50.2,102.4,49.3,102.5,48.4,102.6z M153,120
		c-0.5,0.8-1,1.5-1.5,2.3c0.1,0,0.1,0.1,0.2,0.1c0.3-0.2,0.5-0.4,0.8-0.6c0.2,1.6,1.6,1.4,2.6,1.6c1.4,0.2,3,0.3,4-1.2
		c0.2,0.2,0.4,0.3,0.6,0.5c0.1-0.1,0.1-0.1,0.2-0.2c-0.2-0.4-0.5-0.8-0.7-1.2c0.5,0.3,0.9,0.5,1.3,0.7c2.4-1.8,3.4-3.6,3.4-6.4
		c-0.7,0.1-1.5,0.2-2.2,0.3c0-0.1,0-0.2,0-0.3c0.7-0.2,1.5-0.5,2.2-0.7c0.1-0.2,0.3-0.4,0.4-0.6c-0.7-0.4-1.4-0.7-2.2-1.2
		c-0.3,0.1-0.8,0.2-1.3,0.3c-0.7-0.8-1.3-1.6-2-2.3c-0.4-0.1-0.9-0.1-1.4-0.2c-1.8-0.4-3.5-0.1-4.9,1.2c-0.7,0.7-1.1,1.7-1.6,2.6
		c-0.6,1-0.9,1.1-1.8,0.5c0.5-0.3,0.9-0.6,1.6-1.1c-0.9-0.4-1.4-0.6-2-0.8c-1.6,2.9-0.4,6.6,2.5,8.4
		C151.7,121.2,152.3,120.6,153,120z M38,127.4c-0.7-0.3-1.4-0.5-2.1-0.9c-2-1.4-4-2.9-6-4.4c-1.3-0.9-2.6-0.7-2.8,0.8
		c-0.6,3.1-1.1,6.2-1.4,9.4c-0.1,1.6,1,2.3,2.5,1.8c3.2-1.1,6.4-2.2,9.6-3.4c0.5-0.2,0.9-1,1.3-1.5c-0.3,0.8-0.6,1.6-0.9,2.4
		c-3.5,1.1-7,2.2-10.4,3.5c-2.2,0.8-1.8,2.5-0.8,5c0.5,1.2,1.6,1.2,3.4,0.4c0.5-0.2,1.2-0.3,1.8-0.4c0.1,0.2,0.1,0.5,0.2,0.7
		c-0.6,0.3-1.2,0.6-1.7,0.9c-1,0.6-2.3,1-2,2.6c0.6,2.9,2.6,3.8,5.3,2.7c2.7-1.2,5.3-2.4,8-3.5c-0.1,0-0.3,0-0.4-0.1
		c0.4,0.1,0.7,0.1,1.5,0.2c-0.8,0.5-1.4,0.8-1.9,1.1c-2.5,1.1-5,2.2-7.5,3.4c-1.3,0.6-1.6,1.6-0.7,2.6c2.2,2.5,4.8,4.7,7.8,6.2
		c1.2,0.6,2,0.2,2.4-1.1c0.2-0.6,0.3-1.2,0.4-1.8c0.5-3.1,1.1-6.1,1.7-9.3c0.7,0.8,1.2,1.5,1.7,2.1c-0.1,0.3-0.2,0.6-0.3,1
		c-0.4,0-0.7-0.1-1.2-0.1c-0.5,2.3-0.9,4.5-1.3,6.7c-0.1,0.6-0.2,1.2-0.3,1.8c-0.1,1.4,0.5,2.6,1.9,2.8c2.8,0.5,5.6,1,8.4,1.3
		c2,0.2,2.8-0.6,3-2.6c0.4-3.3,0.7-6.7,1-10c0-0.4,0.3-0.9,0.5-1.3c0.1,0,0.3,0.1,0.4,0.1c0,0.2,0,0.5,0,0.7
		c-0.4,3.4-0.8,6.8-1.1,10.2c-0.2,2,0.6,2.9,2.5,2.3c2.9-0.9,5.8-2.1,8.5-3.3c1.4-0.6,1.4-1.8,0.3-2.9c-0.8-0.8-1.6-1.5-2.5-2.2
		c-1.6-1.4-3.2-2.8-4.9-4.2c0.1-0.1,0.2-0.3,0.3-0.4c0.8,0.5,1.6,1,2.4,1.6c1.8,1.5,3.4,3.1,5.2,4.6c1.4,1.1,2.5,1.2,3.7,0.2
		c2.5-2.1,4.4-4.7,5.8-7.7c0.6-1.2-0.2-2.3-1.5-2.2c-1.2,0.1-2.4,0.3-3.6,0.4c-2,0.3-4,0.7-6,1.1c-0.7,0.1-1.4,0.4-2.4,0.7
		c0.2-0.9,0.2-1.4,0.3-1.8c3.6-0.6,7-1.2,10.8-1.9c-2.8-1.9-5.2-3.7-7.9-5.6c0.2,3.6-0.5,5-2,5.5c-0.6-0.6-1-1-1.4-1.4
		c0.2-0.7,0.6-1.8,1.1-3c-2-0.1-3.6-0.5-4.8,1.6c-1.3,2.3-3.7,3.6-6.3,4.1c-0.6,0.1-1.2,0-2.2-0.1c1.4-2.3,4.1-1.5,5.4-3.4
		c-0.4-0.4-0.8-0.8-1.2-1.4c-2.2,1.5-4.4,1.6-6.6,0.9c-0.3,0.4-0.5,0.7-1,1.3c0.1-0.6,0.1-0.8,0.1-1c-0.6,0-1.1,0.1-1.9,0.1
		c1.1,0.8,2,1.5,2.6,1.9c0.9,0.1,1.6,0.2,2.3,0.2c-1.2,0.4-2.4,0.9-3.5,0.7c-3.5-0.5-7.5-7.3-5.3-9.4c0.4,0.7,0.8,1.4,1.3,2.3
		c-0.2-1.1-0.3-1.9-0.5-2.8c-0.4,0-0.7,0-0.9,0c0-0.7,0-1.4,0-2c0-0.8-0.1-1.7,0.2-2.3c1.3-2.6,3.4-4.4,6.3-5.1
		c1.2-0.3,2.3-0.5,2.9,1.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.8-0.2-1.5-0.4-2.4c0.4-0.6,0.9-1.4,1.3-2c-3-3-5.7-6.4-10.8-4.5
		c1.6,1,2.6,3,4.9,2.9c-0.7,1.6-0.9,1.7-2.2,1.4c-0.4-0.6-0.5-1.1-0.8-1.4c-1-1-2.1-1.9-3.2-2.8c-0.4-0.3-0.9-0.5-1.3-0.5
		c-3.1,0-6.1,0-9.5,0c-0.1,0.1-0.4,0.5-0.7,1c-0.5,0.7-0.9,1.3-1.4,2.1c-1.2,2-0.9,3.1,0.9,4.5C33.1,123.5,35.5,125.5,38,127.4z
		 M241.4,131c-1.3,0.7-2.7,1.5-4.3,2.4c0.3,0.4,0.7,0.9,1,1.3c-1.2,2-1.3,2.8-0.3,3.7c-0.2,0.9-0.4,1.8-0.6,2.7c-0.1,0-0.3,0-0.4,0
		c0-1.3,0.6-2.8-0.8-3.9c-1.9,0.8-3.7,1.6-5.6,2.4c-1.1,0.5-1.6,1.2-1.1,2.3c1.4,2.9,2.7,5.8,4.1,8.7c0.5,1.1,1.4,1.2,2.5,0.7
		c1.4-0.8,2.9-1.6,4.4-2.3c0.4-0.2,0.8-0.2,1.3-0.2c-1.8,1.9-4.4,2.3-6.1,4.2c2.1,2.7,4.7,4.6,7.6,6.1c4.1,2.2,4.1,2.1,4.6-2.4
		c0.1-0.6,0.3-1.1,0.4-1.7c0.2,0,0.4,0.1,0.6,0.1c-0.2,1.5-0.3,3-0.6,4.4c-0.2,1.1,0.2,1.7,1.2,2c3.3,0.8,6.7,1.2,10.1,0.8
		c1.1-0.2,1.5-0.7,1.5-1.7c0-1.2,0.1-2.4,0.2-3.6c0-0.3,0.3-0.6,0.4-1c0.2,0,0.3,0.1,0.5,0.1c0,1.8,0,3.6,0,5.7c4.9-1,9-3,12.7-6.2
		c-1.5-1.2-2.7-2.3-3.9-3.3c0.1-0.2,0.2-0.3,0.4-0.5c1.4,1,2.9,2,4.2,2.9c2.9-2,6.3-6.9,7.3-10.6c-0.7-0.5-1.5-1.1-2.3-1.6
		c-1.7-1.1-1.7-1.1-1.6-2c0.5,0.3,0.9,0.6,1.4,0.9c0.5,0.3,0.9,0.6,1.4,0.9c1.4,0.8,1.8,0.7,2.2-0.9c1.1-3.7,1.4-7.6,0.8-11.4
		c0-0.3-0.2-0.6-0.4-1.1c-1.7,0.7-3.4,1.4-5,2c-0.1-0.2-0.2-0.4-0.3-0.6c0.8-0.4,1.6-0.8,2.5-1.2c3-1.3,3.1-1.3,2-4.5
		c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4-0.8-1-1.3-1.9-1c-0.7,0.2-1.3,0.5-1.9,0.8c-0.8,0.4-1.2,0.2-1.4-0.7c4.1-2.8,4.2-3.6,0.7-6.9
		c-1.6,0.8-3.3,1.6-5,2.5c-0.1-0.2-0.2-0.3-0.3-0.5c1.5-1,3-1.9,4.7-3c-3.2-3.9-6.9-6.3-11.6-8.1c-0.4,2.6-0.7,4.9-1.1,7.2
		c-0.2,0-0.3,0-0.5,0c-0.1-0.5-0.2-0.9-0.2-1.4c0.1-1.5,0.5-3.1,0.5-4.6c0-0.6-0.4-1.6-0.9-1.8c-1.3-0.5-2.7-0.7-4.3-1
		c0,0.9,0,1.5,0,2.1c0,3-0.1,5.9-0.1,8.9c0,2.1-1.4,3.3-3.1,4.5c-0.6-1.2-1.1-2.1-1.6-3.1c-0.2,0.1-0.4,0.2-0.6,0.3
		c1.3,2.8,2.6,5.6,4,8.8c1.2-1,2.1-1.9,3.1-2.8c0.8,0.4,1.8,0.8,2.8,1.3c-0.3,0.6-0.5,1-0.8,1.4c-1.6,2.8-0.1,5.9,3.1,6.4
		c0.9,0.1,1.8,0.3,2.7,0.4c-0.1,1-0.1,1.8-0.1,2.7c-2.3,0.3-4.8-0.6-6.5,1.9c-1.7,2.5-0.1,4.5,0.5,6.7c-0.9,0.4-1.6,0.7-2.4,1
		c-1.5-1.4-1.6-4-4.3-4.4c-2.6-0.4-3.9,1.3-5.4,3c-0.8-1-1.5-1.8-2.2-2.5c2.4-3.1,1.7-5.2-2.6-7.5c0-0.5,0-1.1,0-1.7
		c-1.4-0.4-2.7-0.7-4.4-1.1c0.3,0.9,0.5,1.3,0.6,1.5c-0.8-0.1-1.5-0.2-2.5-0.4c0.6-0.3,1-0.6,1.4-0.9
		C241.6,131.6,241.5,131.3,241.4,131c1,0.2,2.1,0.5,3.4,0.8c0.2-1.1,0.3-1.9,0.4-2.5c-1.4,0-2.5,0-3.8,0
		C241.4,129.8,241.4,130.4,241.4,131z M97.8,77.1c-7.6-8.2-16.3-15.3-23-24.8c1.5,0.7,2.6,1.3,4.1,2c-0.2-1.3-0.4-2.2-0.5-2.9
		c-11.8-2.2-23.3-4.4-34.8-6.5c0.5,0.6,1.2,1,1.8,1.3c4.9,1.6,9.8,3.1,14.7,4.7c0.5,0.2,0.9,0.5,1.3,0.8c0.6,0.6,0.9,1.5,1.6,1.9
		c2.8,1.7,5.6,3.2,8.4,4.9c1,0.6,1.8,1.5,2.7,2.2c-0.1,0.2-0.2,0.3-0.2,0.5c-2.1,0.2-4.1,0.4-6.2,0.6c-0.1,0.2-0.1,0.3-0.2,0.5
		c1,0.9,1.9,2.2,3.1,2.8C79.6,69.1,88.7,73.1,97.8,77.1z M119.5,121.8c0.5-0.3,1.1-1,1.7-1.1c1.3-0.1,2.7,0.2,4.1,0.1
		c0.7,0,1.8-0.2,2.1-0.7c0.3-0.5,0.3-1.7-0.1-2.2c-0.4-0.5-1.4-0.7-2.2-0.6c-2,0.2-4,0.7-6,1c-1.1,0.1-2.6,0.3-2.9-1.1
		c-0.4-1.8-1.8-2.3-3.2-2.9c-2-0.8-4-1.6-6-2.4c-0.5-0.2-1.1-0.4-1.9-0.6c0,2.2,0.6,3.8,1.8,5.2c1.6,1.9,3.3,3.7,5,5.4
		c0.6,0.6,1.4,1,2.2,1.2c2,0.4,2.3,0.8,2.1,2.9c-0.1,0.7,0.1,1.8,0.5,2.2c1.1,1,2.5,1.8,3.8,2.7c-0.1,0.1-0.3,0.1-0.4,0.2
		c0.3,0.9,0.6,1.9,1,3.1c-0.1,0.3-0.2,1-0.4,1.7c-1.1,0.3-2,0.7-0.8,2.2c0.7-0.9,1.3-1.7,1.8-2.3c1.4,1.9,3.6,2.5,5.5,3.3
		c0.4-0.9,0.8-1.6,1.3-2.7c-0.2-0.8-0.6-2-1.1-3.5c0.9,0.2,1.5,0.4,2.1,0.6c1.1,0.4,2.2-0.1,2.6-1.1c0.4-1.2-0.3-2.3-1.6-2.5
		c-0.8-0.1-1.6-0.1-2.3-0.3c-0.9-0.3-1.8-0.6-2.4-1.2C123.8,126.4,121.9,124.3,119.5,121.8z M143.5,64.4c2.2-1.2,4-1.9,6.1-1.5
		c8.3,1.5,16.5,3.1,24.8,4.6c0,0.2,0.1,0.3,0.1,0.5c-4.9,2.6-9.7,5.2-14.7,7.8c-1,0.5-2.2,0.7-3.3,0.8c-3,0.4-6,0.5-9,1.1
		c-4.5,0.8-8.9,1.9-13.3,2.9c-1.6,0.4-2,0.9-1.7,2.5c0.3,1.5-1.5,3.7-3,3.7c-1.3-0.1-2.6-0.2-3.7-0.4c1.3,1.1,2.2,1.3,5.5,1.3
		c0.4-0.5,0.8-1.1,1.2-1.7c1.2-1.8,2.7-3.1,5.1-3.5c6.6-1,13.1-2.2,19.6-3.4c1.2-0.2,2.5-0.5,3.6-1.2c4.4-2.7,8.7-5.5,13-8.4
		c0.9-0.6,1.7-1.4,1.4-2.7c-0.2-0.1-0.3-0.2-0.5-0.2c-7.9-1.5-15.7-3.1-23.6-4.5c-5.1-0.9-9.4,1.6-13.7,3.6
		c-0.2,0.1-0.4,0.3-0.5,0.5c1.5-0.8,2.6,0.2,3.8,0.8c1.8,0.9,3.7,1.7,5.6,2.6c0.1-0.1,0.2-0.2,0.2-0.3c-0.6-1.5-1.2-3.1-1.9-4.7
		C144.4,64.5,144.1,64.4,143.5,64.4z M143.4,110.7c-1.6,3.6-1.3,7.2,0.5,10.6c0.4,0.9,0.6,1.9,1,2.8c0.3,0.6,0.7,1.2,1.2,1.5
		c1,0.6,1.3,1.3,1,2.3c-0.1,0.2-0.2,0.6-0.1,0.8c0.9,1.9,1.9,3.8,2.8,5.5c2.6-0.1,2.7,0,3.2,2.1c2,0.7,4.1,0.7,6.3,0.3
		c0.5-0.1,1-0.1,1.4,0c2.6,0.4,5.3,1.4,6.8-1.9c0-0.1,0.2-0.1,0.2-0.2c1.4-1.4,2.9-2.8,4.4-4.3c-3.7-1.4-4.9-4.6-5.9-7.8
		c-5,5.4-10.4,7-15.7,4.3C145.5,124.2,141.8,119.3,143.4,110.7z M186.7,136.4c-0.1-0.4-0.2-0.8-0.3-1.5c-0.1,0.7-0.2,1.1-0.3,1.5
		c-1.8,0.2-3.5,0.3-5.4,0.5c1.1,0.6,1.8,0.9,2.6,1.3c-2.1,1.1-4,2-5.8,2.9c0.1,1,0.1,1.7,0.2,2.5c1.4,0.8,2.8,1.7,4.3,2.5
		c0.4,0.2,1.1,0.2,1.5,0c3.2-1,5.3-3.1,6.6-6.2c2.9-7.4,5.3-14.8,6.8-22.6c0.2-1.1,0.4-2.3,0.6-3.4c-3.6,3.9-4.6,9-6.9,13.2
		c0.4,0.9,0.8,1.5,0.9,2.1c0.1,1.1,0.2,2.2,0.1,3.3c-0.3,5-0.2,4.2-4.6,4.1C186.9,136.5,186.8,136.4,186.7,136.4z M117.2,80.2
		c11.4-1.3,22.7-1.5,33.6-3.5c0.4-1.3-0.3-1.9-1.2-2.3c-2.5-1.1-5-2.2-7.5-3.2c-0.7,0.4-1.3,0.8-2,1.2c0-0.4,0-0.7-0.1-1.1
		c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,2.4-2.1,2.4-3.6,2.8c-6.8,1.6-13.6,3.1-20.4,4.7c-0.3,0.1-0.7,0.2-1.4,0.4c1.5,1,2.8,1.8,4,2.6
		c0.1-0.1,0.1-0.2,0.2-0.3C118.3,81,117.9,80.7,117.2,80.2z M219.7,36.8c0.2-0.1,0.5-0.2,0.7-0.3c0.3-2.5,0.6-5.1,0.7-7.6
		c0.1-1.8-0.7-2.7-2.5-2.6c-2.5,0.1-5,0.6-7.5,0.9c0,1,0,1.8,0,2.5c0,0-0.1,0.1-0.2,0.2c-0.3-0.1-0.7-0.2-1.1-0.3
		c0.1,0.3,0.2,0.5,0.3,0.7c-0.2,0-0.4,0-0.6,0.1c-0.9,0.5-1.7,1-2.6,1.5c0.4,0.7,0.9,1.4,1.3,2.1c0.2,0.3,0.3,0.6,0.5,1
		c0.6-0.3,1-0.5,1.4-0.7c0.2,0.7,0.3,1.2,0.6,2.2c1-1.6,1.7-2.7,2.5-3.9C215.6,33.1,218.2,34,219.7,36.8z M121.3,113.9
		c0.3-0.1,0.8-0.2,1.3-0.3c0-1,0-1.9,0-2.8c0.1,0.1,0.2,0.1,0.3,0.2c0.5-0.6,0.9-1.2,1.2-1.6c-0.6-0.9-0.9-1.4-1.3-1.9
		c0.4-0.3,0.8-0.5,1.3-0.9c-2.7-2.2-5.7-0.9-8.9-1.6c0,2.8,0,5.2,0,7.5C117.1,113.4,119.5,112.3,121.3,113.9z M232.7,126.4
		c-2.3-1.9-2.9-1.7-3.5,1.1c-0.1,0.4-0.2,0.7-0.2,1.1c-0.6,3.4-0.8,6.7,0,10.3c2.5-0.3,4.4-1.7,6.5-2.5c0-1.1,0-2.1,0-3.1
		c-1.4-0.5-3.1-0.8-3.2-2.6C232,129.4,232.4,127.9,232.7,126.4z M56.6,132.5c-0.2-0.5-0.4-1-0.8-1.5c-0.4-0.5-0.9-1.4-1.3-1.4
		c-1.8,0.1-3.6,0.4-5.6,0.7c0.3,2.3,0.6,4.3,0.9,6.5c1.9-0.3,3.6-0.5,5.3-0.7c0.9-0.2,1.4-0.7,1.2-1.8
		C56.3,133.8,56.5,133.2,56.6,132.5z M46,62.5c-2-1.2-3.9-2.3-6-2.1c-3.2,0.4-6.4,1.2-9.5,1.8c-0.3,0.1-0.5,0.3-0.7,0.5
		C31.5,64.4,44,64.3,46,62.5z M236.9,113.8c-2.5,2.4-4.3,4.9-5.6,7.7c-1,2.1-0.8,2.6,1.2,3.4c0.9-1.3,1.9-2.5,2.7-3.8
		c0.8-1.3,1.5-2.7,2.2-4.1c0.5,0.3,0.9,0.5,1.3,0.8c0.2-0.1,0.3-0.2,0.5-0.3C238.5,116.3,237.8,115.2,236.9,113.8z M101.8,136.7
		c4.8,3.6,10,6.3,11.9,6.2c-0.7-1-1.6-2-2.2-3.1C109.5,136.3,104.9,135.7,101.8,136.7z M141.5,141.5c0.9,0.5,1.7,1,2.6,1.5
		c0.4-1.3,0.5-2.7,1.3-3.5c1.2-1.4,1.2-2.9,0.9-4.6c-0.8-0.1-1.4-0.2-2.3-0.3c0.9,1.2,1.9,2.3,0.9,3.7c0.3-1.3-0.4-2.2-1.5-2.3
		c-0.6-0.1-1.6,0.4-1.9,0.9c-0.9,1.5,0.5,2.1,1.5,2.9c-1.4,0.5-2.8,0.5-3.8,2.2C140.1,141.7,140.9,141.6,141.5,141.5z M202.5,5.6
		c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0.3,0.1,0.6,0.2,1c-0.4-0.2-0.7-0.3-1-0.4c-1.1,1.8-3,3.2-2.7,6.1c2-0.6,3.8-1.2,5.4-1.7
		c0.1-0.9,0.2-1.4,0.3-2.1c0.7,0.1,1.3,0.3,2.2,0.5C205.1,7.8,203.8,6.7,202.5,5.6z M155.4,153c1.9,0.2,3.6,0.5,5.2,0.5
		c1.6,0,3.2,0,4.6-0.7c0-0.2,0-0.5-0.1-0.7c-7.9-0.2-15.9-0.4-24-0.6c0,0.6,0,1,0,1.4c1.7-0.9,4.3-0.8,6-0.1
		c1.9,0.8,3.9,0.7,5.6-0.3C154.1,151.8,154.8,151.8,155.4,153z M245.1,92.2c2.9,1.1,5.6,2.1,8.3,3c0.6-0.4,1.2-0.8,1.9-1.4
		c-3.3-1.6-6.4-3.1-9.8-4.8C245.3,90.3,245.2,91.2,245.1,92.2z M186,125.7c0.2,0.1,0.3,0.1,0.5,0.2c0.8-2.1,1.4-4.2,2.3-6.3
		c0.9-2.1,2.1-4.1,3.2-6.2c-0.2-0.1-0.3-0.3-0.5-0.4c-1.6,1.2-3.3,2.5-4.7,3.5C187,119.9,187.5,122.9,186,125.7z M119.8,135.1
		c-0.5-0.3-0.9-0.9-1.3-0.9c-0.9-0.1-1.9,0-2.7,0.4c-0.9,0.4-0.7,1.2,0,1.9c0.1,0.2,0.1,0.5,0.1,0.8c0,2.4,0.1,2.5,2.8,3
		c0.1-0.8,0.4-1.6,0.2-2.2c-0.3-1-0.4-1.8,0.7-2.3C119.7,135.7,119.7,135.3,119.8,135.1z M230.5,76.1c-0.1,0.1-0.3,0.1-0.4,0.2
		c0.7,4.7,3.1,8.9,5,13.3c0.6-0.3,1-0.6,1.4-0.8C234.5,84.4,232.5,80.3,230.5,76.1z M175.4,119.3c1.4,2.1,1.9,4,0.7,4.8
		c-1.1,0.7-2.7,1.3-3.9,1c-2.3-0.6-2.3-2.6-1.5-4.7c-1.6,2.6-1.7,3.3-0.4,4.7c1.2,1.3,3.2,1.7,4.8,1.1c1.7-0.7,2.9-2.5,2.7-4.2
		C177.7,120.6,177.3,119.4,175.4,119.3z M44.9,98.5c3.3,0.6,4-2,5.6-3.8c-0.6-0.5-1.1-0.8-1.6-1.2C47,94.8,45,95.7,44.9,98.5z
		 M117.9,143.3c-2.1-2.8-4.9-5.2-2.8-9.3c-0.9-0.1-1.6-0.1-1.8-0.1c-0.7,1.1-1.2,2-1.8,2.8C114,138.5,115,141.8,117.9,143.3z
		 M211.2,26.4c3.2-0.3,6.2-0.6,9.5-0.9c-0.9-0.5-1.8-1.2-2.7-1.2c-1.8-0.1-3.7,0.1-5.5,0.3C211.6,24.7,210.9,25.3,211.2,26.4z
		 M24.5,144.8c-2.4-6.5-2.8-13.1-1.6-19.9C20.7,131.8,21.5,138.4,24.5,144.8z M97.6,119.9c1.9-0.9,3.7-1.7,5.7-2.6
		c-0.2-0.3-0.4-0.9-0.7-0.9c-2,0-4,0.2-6.1,0.3C97,118,97.3,118.9,97.6,119.9z M168.3,96.7c0.8-0.1,1.6-0.3,2.4-0.4
		c0.1-1,0.2-1.8,0.2-2.5c-1-0.5-2-1-2.9-1.4c-0.5,0.6-0.9,1.2-1.2,1.7c0,0.7,0,1.5,0,2.2c0.1,0.1,0.2,0.1,0.3,0.2
		c0.1-0.3,0.2-0.7,0.4-1.2C167.9,96,168.1,96.3,168.3,96.7z M44.2,110.4c0.1-0.2,0.1-0.3,0.2-0.5c-1.6-0.9-3.3-1.7-5.3-2.8
		c-0.2,1.1-0.6,2-0.4,2.6c0.3,1,1.2,1.2,2.3,1C42,110.5,43.1,110.5,44.2,110.4z M77.2,107.5c-0.2,0.2-0.5,0.4-0.8,0.6
		c1.7,0,2.4-0.6,2.4-1.9c-0.1-1.2-1.1-2.1-2.3-2c-1.2,0.1-1.8,0.9-1.6,2.5c0.2-0.2,0.4-0.3,0.4-0.3
		C75.9,106.8,76.6,107.1,77.2,107.5z M173.4,120c-1.1,0-2.2,1.1-2.1,2.1c0.1,1,1.3,2,2.3,2c1.1,0,2-1,2-2
		C175.6,120.9,174.6,119.9,173.4,120z M124.9,142.2c0-0.2,0-0.3,0-0.5c-1.8-0.9-3.5-1.7-5.3-2.6c-0.1,0.1-0.1,0.2-0.2,0.2
		c0.6,0.9,1.2,1.9,1.9,3C122.4,142.3,123.6,142.3,124.9,142.2z M154.5,107.7c1.1,0,2.4,0,4.1,0c-0.9-0.5-1.4-0.7-1.9-1
		c0.4-1.9-0.4-2.9-1.9-2.5C154.7,105.4,154.6,106.5,154.5,107.7z M79.7,121.2c-1.7-2.6-5.7-5.1-6.9-4.2
		C75.4,117.7,76.5,120.7,79.7,121.2z M40.9,107.5c0.6-1.2,1.2-2.3,1.9-3.6c-0.7,0.1-1.1,0.1-1.6,0.2c0-0.4,0-0.7-0.1-1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.6,1-1.2,2-1.9,3.1C39.7,106.6,40.3,107.1,40.9,107.5z M149.8,105.6c-0.6,0.5-1.2,1-1.8,1.6
		c0.8,0.9,1.5,1.7,2.3,2.5c0.5-0.3,0.8-0.6,1.2-0.8C150.8,107.8,150.3,106.8,149.8,105.6z M234,76.3c0.6,1.3,1.1,2.3,1.7,3.6
		c0.7-1.5,1.3-2.6,1.8-3.8c-0.1-0.1-0.2-0.2-0.3-0.4C236.2,75.9,235.2,76.1,234,76.3z M140.2,62.5c-2.3,0-4.9,0-7.3,0
		C135.2,64.5,136.6,64.5,140.2,62.5z M228,72.4c0.2-0.1,0.4-0.2,0.6-0.2c-1.8-3.9-3.7-7.8-5.5-11.6c-0.1,0.1-0.3,0.1-0.4,0.2
		C224.4,64.6,226.2,68.5,228,72.4z M99.8,114.7c-0.8-0.8-1.3-1.4-1.9-2.1c-0.8,0.3-1.7,0.5-2.6,0.9c0.3,0.6,0.5,1,0.7,1.5
		C97.2,114.9,98.4,114.8,99.8,114.7z M198.2,104.5c0.2,0.1,0.3,0.2,0.5,0.2c1.6-3.2,3.2-6.4,4.7-9.6c-0.1-0.1-0.3-0.1-0.4-0.2
		C201.3,98.1,199.8,101.3,198.2,104.5z M214.1,44.2c0.8,1.6,1.5,2.9,2.3,4.2c0.7,1.3,1.4,2.5,2.2,3.8
		C217.5,48.5,215.2,44.4,214.1,44.2z M150.7,103.5c0.4,1.7,0.7,3.3,1.1,5.3c1.4-1.8-0.1-3,0-4.3c0.3-0.2,0.6-0.5,1-0.8
		C152.1,103.6,151.5,103.6,150.7,103.5z M115.4,64.6c0-0.2,0-0.4,0-0.6c-2.2-0.1-4.3-0.3-6.5-0.4c0,0.2,0,0.4,0,0.6
		C111.1,64.3,113.3,64.5,115.4,64.6z M147,123.2c-0.9-2.3-2-4.9-3-7.2C143.6,117.9,145,121.4,147,123.2z M248,131.4
		c0.2,0.1,0.3,0.2,0.5,0.3c0.6-0.5,1.1-0.9,1.7-1.4c-0.4-0.8-0.8-1.4-1.1-2c-0.2,0-0.4,0-0.6,0C248.4,129.4,248.2,130.4,248,131.4z
		 M228.4,42.9c1.2-1.9,1-3.2-0.5-4.2c-0.2,0.3-0.4,0.7-0.7,1C228.3,40.3,228.3,40.3,228.4,42.9z M239.8,113.4
		c0.7-0.5,1.2-0.9,1.8-1.3c-0.3-0.5-0.5-0.9-0.8-1.4c-0.7,0.6-1.2,1-1.9,1.6C239.2,112.7,239.5,113,239.8,113.4z M240.5,114.1
		c1.1,1.6,1.4,1.6,2.6,0.1c-0.4-0.4-0.8-0.7-1.4-1.3C241.2,113.4,240.8,113.8,240.5,114.1z M246.2,123.7c-0.5,0.6-0.8,1-1.2,1.4
		c1.2,1.3,1.4,1.2,2.4-0.3C247.1,124.5,246.7,124.2,246.2,123.7z M202.7,84.7c-1.3,1.4-2.2,2.9-1.6,4.9
		C201.6,88,202.2,86.4,202.7,84.7z M214.2,35c-0.6,1.9,0.7,2.2,1.2,3c0.1,0.2,0.7,0,1,0c0.1-0.2,0.2-0.4,0.3-0.6
		C215.9,36.7,215.2,35.9,214.2,35z M16.4,63.6c-0.3,0.2-1,0.5-1,0.7c0,1-0.8,2.3,0.6,3.1C16.1,66.2,16.2,65.1,16.4,63.6z
		 M28.3,151.7c0.1-0.1,0.2-0.2,0.4-0.3c-0.8-1.3-1.7-2.5-2.5-3.8c-0.2,0.1-0.3,0.2-0.5,0.3C26.5,149.2,27.4,150.4,28.3,151.7z
		 M182.4,83c-0.1-0.1-0.2-0.2-0.4-0.3c-0.6,0.3-1.3,1-1.9,1c-0.7,0-1.3-0.7-2.2-1.2c0.6,1.3,1.4,2.1,2.7,1.9
		C181.3,84.3,181.8,83.5,182.4,83z M280.2,156.8c-0.1-0.1-0.2-0.3-0.4-0.4c-1.3,1.2-2.6,2.3-3.9,3.5c0.1,0.1,0.2,0.2,0.3,0.3
		C277.5,159.1,278.8,158,280.2,156.8z M192.7,103.8c-0.2-0.2-0.4-0.4-0.6-0.5c-1,0.8-1.6,2-2.2,3.1c0.1,0.1,0.3,0.2,0.4,0.3
		C191.1,105.8,191.9,104.8,192.7,103.8z M168,110.6c0.2,1.9,0.4,3.7,0.5,5.6C169.4,115.2,169.2,113.3,168,110.6z M242.3,51.2
		c0.7-2-1-1.9-1.8-2.5C240.4,50,241.4,50.4,242.3,51.2z M260.4,135.2c-0.6-0.4-1-0.9-1.4-0.9c-0.3,0-0.9,0.7-0.8,0.9
		c0.1,0.4,0.6,0.9,1,1C259.4,136.2,259.8,135.7,260.4,135.2z M63.2,108.1c-0.2,0.2-0.3,0.5-0.5,0.7c0.8,0.5,1.6,1,2.4,1.6
		c0.1-0.2,0.3-0.4,0.4-0.6C64.8,109.2,64,108.6,63.2,108.1z M34.5,111.5c1.3-0.2,2.4-0.4,3.8-0.7C36.5,109.7,35.8,109.9,34.5,111.5z
		 M191.8,87.4c0.5,1.4,1.1,2.1,2.3,2.1c1,0,1.8-0.6,1.9-1.8C194.3,89.3,194,89.3,191.8,87.4z M181.6,123.2c-0.3,0.8-0.5,1.4-0.7,2
		c0.1,0.1,0.3,0.2,0.4,0.3c0.5-0.3,1-0.6,1.5-1C182.4,124.1,182.1,123.8,181.6,123.2z M184.7,126.1c-0.9-0.1-1.6-0.2-2.8-0.4
		C183.8,127.7,183.8,127.7,184.7,126.1z M193.8,100.9c-0.2,0.2-0.4,0.3-0.7,0.5c0.6,0.8,1.2,1.6,1.8,2.4c0.2-0.1,0.4-0.3,0.5-0.4
		C194.9,102.6,194.3,101.8,193.8,100.9z M177,126.9c0,0.1,0.1,0.1,0.1,0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.1-0.1-0.2-0.2
		C177.1,126.7,177,126.8,177,126.9z"/>
			<path d="M88.9,95.6c-0.7,0.5-1.3,0.9-2,1.5c-1.9-2-3.6-3.9-5.4-5.8c-0.4-0.4-1-0.8-1.5-1c-1.5-0.5-2-1.6-1.4-3.7
		C82,89.6,85.3,92.5,88.9,95.6z"/>
			<path d="M93.1,87.6c0.1,2.1,0.2,3.9,0.3,6c-0.1,0-0.5,0.3-1,0.6C91,92.9,91.4,89.1,93.1,87.6z" />
			<path d="M191.9,19.4c-1,0-1.2-0.7-1.1-1.5c0.4-2.6,0.7-5.2,1.2-7.7c0.4-1.7,1.7-2.8,3.5-3.2c-1.6,0.7-2.4,2.1-2.8,3.7
		c-0.5,2.2-1,4.5-1.4,6.7C191.3,18.1,191.7,18.8,191.9,19.4L191.9,19.4z"/>
			<path d="M103,91.2c-0.1,0.5-0.1,1.1-0.2,1.6c-0.3,0.1-0.7,0.4-1,0.4c-0.6-0.1-1.4-0.3-1.8-0.6c-0.2-0.2-0.2-1.2,0-1.4
		c0.6-0.4,1.3-0.6,2-0.7C102.3,90.4,102.7,90.9,103,91.2z"/>
			<path d="M107.7,143.1c2.3,1.6,5.1,1.5,6.5,3.7c-0.6,0.8-1.1,1.5-1.8,2.5C113.7,144.9,109.1,145.6,107.7,143.1z" />
			<path d="M248.2,58.5c0.2,0.5,0.5,0.9,0.7,1.3c-2,0.4-2.5,0-3.3-2.4c0.7,0.1,1.4,0.2,2,0.3c0,0.1,0,0.2,0,0.2
		c-0.4,0.1-0.7,0.2-1.1,0.3c0,0.1,0,0.2,0,0.3C247.1,58.5,247.6,58.5,248.2,58.5z"/>
			<path d="M82.6,97.1c0.6,0.1,1.1,0.3,1.9,0.4c-0.5,0.8-0.9,1.4-1.4,2.3C82.9,98.7,82.8,98,82.6,97.1z" />
			<path d="M263.5,122.4c0.2-1.4,0.5-2.9,0.7-4.3c0.1-0.1,0.2-0.2,0.4-0.3c0.4,0.6,1.4,1.4,1.3,1.9c-0.3,1.1-1.1,2-1.7,3
		C263.9,122.5,263.7,122.4,263.5,122.4z"/>
			<path d="M244.8,146.8c1.3-0.9,2.6-1.7,4-2.6C248.5,147.3,246.6,148.5,244.8,146.8z" />
			<path d="M261.5,152.4c0.1-1.7,0.2-3.1,0.2-4.6C263.9,149.5,263.8,151,261.5,152.4z" />
			<path d="M274.4,134.3c-0.8-0.2-1.6-0.4-2.4-0.6c0-0.1,0-0.2-0.1-0.3c1.2-0.5,2.4-1,3.6-1.5c0.1,0.1,0.2,0.2,0.3,0.2
		C275.4,132.8,275,133.5,274.4,134.3z"/>
			<path d="M213.4,57.8c-10.3-1-20.7-2-31-3.1c-0.1-0.1-0.1-0.2-0.2-0.3c4.4-1.5,8.8-3,13.2-4.6c-0.4-1.4-1.5-0.7-2.2-0.8
		c0.3-2-0.3-3.1-2.4-3.9c-5.4-1.9-10.8-3.8-16.1-5.6c-0.4-0.1-0.7-0.3-1-0.7c3.4,0.9,6.7,1.8,10.1,2.8c2.9,0.8,5.8,1.8,8.7,2.6
		c3.2,0.9,5.2,3.1,7,6.1c-3.5,1.1-7,2.1-10.7,3.3c8.4,1.4,16.5,2.7,24.7,4C213.4,57.6,213.4,57.7,213.4,57.8z"/>
			<path d="M41.1,143.1c-0.8-1.1-1.8-2-2.3-3.2c-0.8-1.9-1.4-4-2-6.1c-0.1-0.3,0.3-0.9,0.5-1.3c0.3-0.4,0.6-0.7,1-1.1
		c0.2,0.2,0.4,0.5,0.6,0.7c-0.2,0-0.3,0-0.5,0.1c0,0.6,0.1,1.2,0.1,1.7c0.1,1.4,2.4,2.6,0.5,4.1c0.5,0.8,0.8,1.4,1.3,2.2
		c0.4-0.2,0.9-0.3,1.4-0.5C41.5,141,40,141.8,41.1,143.1z"/>
			<path d="M126.9,136.9c-1,0.1-1.5,0.1-2.2,0.2c-0.2-0.9-0.4-1.8-0.6-2.8c0.7-0.5,1.3-1,2.2-1.6C126.5,134.2,126.6,135.5,126.9,136.9
		z"/>
			<path d="M118.5,121.7c-0.4-0.4-0.8-0.8-1.1-1.2c0-0.1,0.4-0.7,0.6-0.7c0.4-0.1,1.2,0.1,1.3,0.3c0.1,0.4-0.1,1-0.2,1.5
		C118.9,121.6,118.7,121.6,118.5,121.7z"/>
			<path d="M116.2,127.4c0.6-0.7,0.9-1.1,1.4-1.7c0.5,0.5,0.9,0.9,1.1,1.4c0.1,0.2-0.4,0.8-0.5,0.8C117.6,127.9,117,127.6,116.2,127.4
		z"/>
			<path d="M150.3,129.1c0.4-0.3,0.8-0.7,1.2-0.9c2.3,0.1,4.6,0.2,6.9,0.3c-2.2,0.8-4.4,0.4-6.9,0c0.8,1,1.4,1.7,2,2.4
		c1.2,0,2.3,0,3.5,0c0.6-0.6,1.4-1.3,2.1-2c0.2,0.1,0.3,0.3,0.5,0.4c-0.4,0.4-0.8,0.9-1.2,1.3c-0.1,0.2-0.2,0.4-0.2,0.6
		c-1.9,0.1-3.9,0.1-5.8,0.2C151.6,130.6,150.9,129.9,150.3,129.1z"/>
			<path d="M157.5,132.9c-0.2,0.6-0.5,1.2-0.5,1.8c0,1.2-0.7,1.7-1.7,1.7c-1.1,0.1-1.9-0.4-2-1.6c-0.1-0.7-0.1-1.4-0.1-2.1
		c1.4-0.7,2.7-1,3.9,0.2C157.2,132.9,157.4,132.9,157.5,132.9L157.5,132.9z M154.8,133.9c0.8,0,1.2,0,1.8,0
		c-0.3-0.5-0.6-0.9-0.9-1.3c-0.5,0.1-1.1,0.2-1.6,0.4c0,0.5-0.1,1-0.1,1.8c0.6,0,1.3,0.1,1.9,0.1c0-0.2,0-0.3,0-0.5
		C155.6,134.2,155.4,134.1,154.8,133.9z"/>
			<path d="M158.1,131.2c0.1-0.2,0.2-0.4,0.2-0.6c-0.1,0.5-0.1,1-0.2,1.9c1.8-0.4,3.5-0.8,5.2-1.2c1.7-0.4,3.5-0.8,5.3-0.9
		c-2.6,0.8-5.1,1.5-7.7,2.3l0.1,0c-1.2,0-2.3,0.1-3.5,0.1l0.1,0.1C157.7,132.3,157.9,131.8,158.1,131.2z"/>
			<path d="M148.2,127.9c0.6,1.4,1.2,2.9,1.9,4.3c0.2,0.4,1,0.8,1.4,0.7c0.4-0.1,0.6-0.9,0.8-1.4c0.3,0.4,0.6,0.8,1,1.1
		c-0.3,0.3-0.7,0.5-1,0.8c-0.8,0.6-1.8,0.6-2.3-0.3c-0.8-1.3-1.5-2.8-2.2-4.2C147.6,128.7,148,128.3,148.2,127.9z"/>
			<path d="M166,135.2c-1,0.9-6.1,1.1-7.6,0C161,135.2,163.5,135.2,166,135.2z" />
			<path d="M161,132.8c2-0.4,4-0.8,6-1.1c0,0.1,0,0.2,0.1,0.2c-2.7,0.7-5.3,1.4-8,2.1c0-0.1,0-0.2-0.1-0.3
		C159.7,133.4,160.4,133.1,161,132.8L161,132.8z"/>
			<path d="M158.6,120.5c-1.9-0.2-3.8-0.3-5.6-0.5c-0.7-1.2-2-2.2-1.9-4.1c0.4,0.5,0.7,0.7,1,1.1c-0.3-3.4,1-5.5,3.6-5.6
		c2.4-0.1,4.1,1.5,4,4c-0.4-0.1-0.7-0.2-1.1-0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0.8,0.4,1.6,0.7,2.4,1.1c-0.4,1.6-1.1,3-3,3.2
		C158.1,120.1,158.3,120.3,158.6,120.5z M153.2,118.6c3.3,1.6,5.6,0.9,6.2-1.3C157.5,117.7,155.5,118.1,153.2,118.6z"/>
			<path d="M174.8,121.4c-1-0.2-2-0.5-2.3,1c-0.2-0.1-0.3-0.2-0.5-0.3c0.1-0.5,0.1-1.1,0.4-1.4C173.3,120.1,174.1,120.3,174.8,121.4z"
			/>
		</g>
	</svg>);
export default iCBH;