import React from 'react';
import Footer from '../../components/Footer';
import ContactBan from '../../components/ContactBan';
import Header from '../../components/Header';

function ObligationsAE() {
    return (
        <React.Fragment>

            <Header />

            <div className='GpageMPORM'>
                <div className="widthMaxcont">                        
                    <h1 className='TCDGF'>Obligations de l'école de conduite</h1>

                    <div className='theMPORMw'>
                        <h2>1. Livret d'apprentissage</h2>
                        <p>L' élève doit être détenteur d'un livret d'apprentissage établi dans les conditions prévues par la réglementation en vigueur. L' école de conduite fournit un livret d'apprentissage. Il permet à l' élève de connaitre le programme de sa formation et de suivre sa progression. L' école de conduite établit au nom de l' élève une fiche de suivi de formation. Cette fiche sera conservée pendant trois ans dans les archives de l' école de conduite. Lorsque l' élève change d' établissement pendant la formation, une copie de la fiche est transmise à l'établissement dans lequel l'élève poursuit sa formation.</p>

                        <h2>2. Qualité de la formation</h2>
                        <p><b>Programme :</b> L' école de conduite s' engage à délivrer à l'élève une formation conforme au programme de formation défini par l' autorité administrative qui en contrôle l' application. L' ensemble des compétences à acquérir au cours de sa formation est énumérée dans le livret d'apprentissage qui lui a été transmis.</p>
                        <p><b>Moyens :</b> L' école de conduite s' engage à mettre en œuvre toutes les compétences et moyens nécessaires pour que l' élève atteigne le niveau de performance requis. Les cours théoriques, les cours pratiques et les examens blancs seront exclusivement conduits par des personnes titulaires d' une autorisation d' enseigner en cours de validité correspondant à la catégorie enseignée.</p>
                        <p><b>Déroulement :</b> Dans le cadre du présent contrat, l' école de conduite fournit à l' élève une formation tant théorique que pratique. Le nombre d' heures minimum que l' école de conduite, suite à l' évaluation initiale, estime nécessaire à une bonne formation est communiqué à l' élève. La répartition entre les heures de formation théorique dispensées dans les locaux de l' école de conduite et les heures de formation pratique au cours desquelles l' élève est amené à conduire, est précisé à l' élève.
                            Le calendrier prévisionnel des séances de formation est établi par l' école de conduite en concertation avec l' élève et lui est communiqué. L' école de conduite tient l' élève informé de la progression de sa formation.</p>
                        <p><b>Exemple pour une leçon de conduite permis B d' une heure :</b></p>
                        <ul>
                            <li>
                                <p>5 mn de définition des objectifs en se référant au livret d' apprentissage,</p>
                            </li>
                            <li>
                                <p>45 à 50 mn de conduite effective pour travailler les compétences définies et évaluer les apprentissages,</p>
                            </li>
                            <li>
                                <p>5 à 10 mn de bilan et commentaires pédagogiques incluant les annotations sur le livret d' apprentissage.</p>
                            </li>
                        </ul>


                        <h2>Présentation aux examens</h2>
                        <p>
                            L' école de conduite s' engage à présenter l' élève aux épreuves du permis de conduire, sous réserve qu'il ait atteint le niveau requis (les quatre étapes de synthèse devraient être validées) et dans la limite des places d' examen attribuées par l' administration à l' école de conduite.
                            <br /><br />
                            En cas de non-respect par l' élève des prescriptions pédagogiques de l' école de conduite ou du calendrier de formation, l' école de conduite se réserve la possibilité de surseoir à sa présentation aux épreuves du permis de conduire.
                            <br /><br />
                            Le responsable de l' école de conduite en informera l' élève par écrit et de façon motivée.
                            <br /><br />

                            L' élève pourra contester cette décision par écrit et de manière motivée.
                            <br /><br />
                            Après entretien avec le gérant de l' école de conduite, l' élève pourra, à son libre choix, se présenter à l'examen.
                            <br /><br />
                            En cas d' échec aux examens et après accord sur les besoins de formation supplémentaire, l' école de conduite s' engage à représenter l' élève dans les meilleurs délais, dans la limite des places d'examen qui lui seront attribuées par l' administration.
                            <br /><br />
                            Les frais afférents aux présentations supplémentaires seront à la charge de l' élève et seront facturés au tarif en vigueur à la date de ces nouvelles présentations.
                            <br /><br />
                            <b>Pour tout examen théorique ou pratique, l’ élève sera accompagné par un moniteur diplômé.</b>
                        </p>
                    </div>
                </div>
            </div>

            <ContactBan />
            <Footer />

        </React.Fragment>
    );
}

export default ObligationsAE;