const vehicules = [
    {
      categories: "B MANU",
      typeVehicules: [
        "Peugeot 208",
        "VW Golf 7",
        "Seat Arona"
      ]
    },
    {
      categories: "B AUTO",
      typeVehicules: [
        "Fiat 500X"
      ]
    },
    {
      categories: "F125",
      typeVehicules: [
        "Honda PCX"
      ]
    },
    {
      categories: "A1",
      typeVehicules: [
        "Honda CB125F"
      ]
    },
    {
      categories: "A2",
      typeVehicules: [
        "Honda CB500F"
      ]
    },
    {
      categories: "A",
      typeVehicules: [
        "Honda CB650",
        "Honda XADV"
      ]
    },
    {
      categories: "AM cyclo",
      typeVehicules: [
        "Peugeot Kisbee"
      ]
    },
    {
      categories: "AM quadri",
      typeVehicules: [
        "Citroën AMI"
      ]
    },
    {
      categories: "ETM",
      typeVehicules: [
        "en salle"
      ]
    },
    {
      categories: "ETG",
      typeVehicules: [
        "en salle"
      ]
    }
  ];
  export default vehicules;