import React from "react";
const iPCX = ({size=24, color="#0056b3"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
width={size} height={size} 
viewBox="0 0 300 178" 
fill={color} 
stroke="none"
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round">
<g>
	<path d="M20,64.2c-1.7-1.5-3.5-2.8-5-4.4c-1.9-2.2-3.6-4.7-5.7-7.5c2.6-0.9,4.5-1.5,6.8-2.2c-2.4-1.3-4.4-2.3-6.4-3.5
		c-0.8-0.5-1.2-1.4-1.8-2.2c0.9-0.4,1.7-1.1,2.6-1.1c24.5-0.2,48.9-0.4,73.2,3c3.5,0.5,6.9,2.1,10.1,3.7c9.8,5.1,20.3,6.7,31.2,6.4
		c4.5-0.1,8.9-0.5,13.3-0.6c3.3-0.1,6.6,0.5,8.8,2.9c5.5,5.9,11.1,11.7,16.1,18.1c3.3,4.1,3.1,6.5,1.4,11.2c2.6,1.6,5.5,1.8,8.3,1.3
		c2.6-0.5,5.4-1.2,7.8-2.4c6.7-3.4,13.3-7,19.9-10.8c1.6-0.9,3-2.3,4.2-3.8c2.5-3.2,2.2-6.4-0.4-9.7c-4.5-5.7-9-11.4-13.3-17.2
		c-1.3-1.8-2.1-4-2.8-6.2c-0.8-2.4-0.8-4.8,1.6-6.7c0.5-0.4,0.9-1.6,0.6-2c-0.4-0.6-1.3-1-2-1.2c-1.6-0.3-3.3-0.2-4.8-0.7
		c-2-0.7-3.8-1.7-6.1-0.3c-0.7,0.4-3.1-0.4-3.6-1.3c-0.6-1-0.1-2.8,0.3-4.1c0.5-1.5,1.7-2.7,2.2-4.1c1.2-3.3,3.7-5.4,8.8-7
		c2.2,2.2,4.5,2,8.7-0.5c3-1.9,4.8-1.1,5.6,2.5c0.2,1,0.1,2,0.4,3c0.3,1,0.8,1.9,1.3,2.8c-0.6,0.1-1.4-0.1-1.7,0.2
		c-2.8,2.9-5.6,6-8.5,9.2c5.9,0.9,8.3-5.3,13.5-5.5c0-0.3,0-0.5-0.1-0.8c-0.9-0.8-1.8-1.7-2.7-2.5c4.8,4.9,10.4,2.2,15.5,1.1
		c4.1-0.9,4.6-3.3,2.6-7.1c-1.3-2.5-2.1-5.2-2.9-7.9c-0.2-0.8,0.4-1.8,0.6-2.7c0.8,0.3,1.8,0.4,2.4,0.9c4.8,4.1,9.7,8.2,14.3,12.6
		c9.2,8.9,18.2,18,27.2,27.1c1.6,1.6,2.7,3.5,4.6,5.9c-1.4-0.3-1.9-0.4-2.5-0.5c2.4,3.4,4.8,6.9,7.2,10.3c-0.6-0.6-1.2-1.1-1.7-1.7
		c-2.1-3.3-4.8-5.9-8.3-7.7c-9.2-4.7-18.4-9.5-27.6-14.2c-0.4-0.2-0.9-0.2-2-0.5c5.7,5,11,9.5,16.1,14.2c4.4,4.1,8.9,8.4,12.8,13
		c1.7,2,1.8,5.5,3.6,7.5c1.8,2.1,4.8,3.1,7.1,4.8c1.9,1.4,3.4,3.1,5.5,5c0.4-5.6-1.1-10.3-3.2-14.8c-0.9-1.8-1.6-3.7-2.5-5.6
		c4.4,6,6.6,12.8,6.4,20.3c0,0.8-1.1,1.9-2,2.4c-4.8,2.4-9.7,4.5-14.6,6.8c-0.9,0.4-1.6,1-2.4,1.7c14.9,0,25.3,8,34.6,18.1
		c-0.1,0.2-0.2,0.5-0.2,0.7c-1.3-0.1-2.6-0.1-4.4-0.2c10.1,13.1,12.8,27,6.2,42.1c-4.5,10.2-12.3,17-22.7,20.9
		c-21.2,7.9-47.4-5.7-52.1-31.2c-1.6,3.6-3.3,7.2-4.6,10.9c-0.8,2.2-2,3-4.2,3c-26.6,0.2-53.1,0.4-79.7,0.7c-0.6,0-1.5,0.2-1.9-0.2
		c-2.4-2.5-4.9-1-7.2-0.1c-8.4,3.2-16.8,2.3-25.3,0.9c-2.4-0.4-3.9,0-5.5,2.2C81.1,173,62,178.6,46.2,172.2
		c-17.5-7.1-27.4-24-24.7-42c0.5-3.1,0.4-5.3-2.6-7.3c-2.1-1.4-3.4-3.9-4.8-5.6c-1.6,1.5-3.6,3.4-5.6,5.2c-1.9,1.7-3.8,3.4-5.8,5.1
		c-0.3-0.2-0.5-0.4-0.8-0.6c0.7-1.4,1.3-2.9,2-4.3c4.6-9.3,9.3-18.5,13.9-27.8c1.1-2.3,1.8-4.9,2.6-7.4c1.3-4.3,3.6-7.7,7.4-10.1
		c2.2-1.4,2.3-2.8,0.3-4.7c-2.4-2.2-4.9-4.4-7.3-6.6c-0.5-0.5-0.7-1.2-1-1.8c2,2.2,4.1,2.7,6.4,0.5c-2-6.7-1.8-7,6.2-9.2
		c-5.9-1.6-10.8-4.6-16.6-4.4c-0.1,0.3-0.2,0.6-0.3,0.9c3.5,1.5,7,3,10.5,4.5c0,0.3,0,0.6,0.1,0.9c-3.3,0.8-6.6,1.5-10.1,2.4
		C17.4,61.2,18.7,62.7,20,64.2z M273.2,134.1c0.8-0.5,2.3-1.1,2.2-1.4c-0.4-2-1.2-3.9-1.8-5.8c-0.3,0.1-0.6,0.1-0.9,0.2
		c-0.7,1.3-1.7,2.4-2,3.8c-0.2,1,0.3,2.2,0.7,3.3C271.5,134.3,272.6,134.1,273.2,134.1C273.2,134,273.2,134.1,273.2,134.1z
		 M19.9,112.2c-0.5,2.4-1.1,4.9-1.5,7.3c-0.1,0.7,0.3,1.4,0.6,2.9c1.2-5,2.2-9.2,3.3-13.6C21.4,110.1,20.6,111.2,19.9,112.2
		c1.3-2.7,2.7-5.4,4.2-8.3c-2.3-0.4-4,0.7-4.9,2.4c-1.5,2.9-2.9,5.9-3.8,9c-0.4,1.3,0.6,2.9,1,4.4c0.3-0.1,0.5-0.1,0.8-0.2
		C18.1,117.1,19,114.7,19.9,112.2z M255.2,119c1.3-0.4,2.7-0.9,4.3-1.4c-0.5-1.7-0.9-3.5-1.6-5.1c-0.2-0.6-1.3-1.3-1.8-1.2
		c-2.6,0.5-5.1,1.2-7.7,1.9c1.7,6,2.2,6.4,5.8,5.9c-0.5,0.4-0.9,0.8-1.6,1.3c2.4,2,4.6,3.8,7,5.7C261,119.6,260.1,118.2,255.2,119z
		 M45.7,145.3c-1-0.9-2-1.1-2.9,0.2c-1,1.4-3.1,3.5-2.7,4.2c2,3.6,5,6.2,9,7.8c0.9-6.2,0.9-6.2-2.8-10.7
		C46.1,146.4,45.9,145.8,45.7,145.3c1.2,1.1,2.6,2.3,4.2,3.8c0.3-1.7,0.2-3,0.7-4.1c0.9-2-1-2.4-1.8-3.3c-0.3-0.3-1.9,0.1-2.2,0.6
		C46,143.2,46,144.3,45.7,145.3z M269.5,149.3c-0.1-0.6-0.1-1.6-0.4-1.7c-2.2-0.9-4.6-2.3-6.6,0.6c1.5,4.2,1.6,4.3,5.2,2
		C268.3,149.8,269,149.5,269.5,149.3c-1.5,1.1-3.1,2.4-4.8,3.8c1,1.3,2,2.8,3.2,4c0.4,0.4,1.4,0.9,1.8,0.7c3.7-1.6,6.7-4.1,9.1-7.5
		C272.1,148.1,272.1,148.1,269.5,149.3z M275.1,141.9c0.1-1.3,0.2-2.5,0.4-4.1c-1.3,0.5-2.2,0.7-3,1.1c-1.4,0.8-2.6,1.9-3.9,2.8
		c1.3,1.1,2.7,2.1,4.1,3.2C273.8,143.6,274.5,142.7,275.1,141.9c-0.2,0.9-0.4,2-0.8,3.3c1.8,0.4,3.5,0.9,5.1,1.2
		c0.6,0.1,1.6-0.2,1.8-0.7c1.8-3.6,2.6-7.4,2.1-11.5c-5.8,2.2-5.8,2.2-7.9,7.3C275.4,141.6,275.3,141.7,275.1,141.9z M25.6,102.8
		c2.4,1.1,4.6,1.9,6.5,3.1c1.4,0.8,2.2,0.3,3.4-0.4c4.5-2.5,9.2-4.7,13.6-7.3c1.4-0.8,3-2.2,3.3-3.5c0.8-3.9,1-8,1.4-12.3
		c-2.3-0.4-4.3-0.9-6.3-1.2c-3.4-0.4-6.3,0.1-8.1,3.5c-0.9,1.6-2.1,2.9-3.2,4.4C32.6,93.6,29.2,98.1,25.6,102.8z M93.3,108.6
		c0.3-0.1,0.6-0.3,0.9-0.4c-0.1-1.4,0.1-3.1-0.5-4.3c-1-1.7-2.4-3.6-4.2-4.5c-7.7-4.3-15.5-8.2-23.4-12.3c-0.3-0.2-0.8-0.1-1.5-0.2
		c-0.3,2.8-0.6,5.5-1,8.5C75.3,96.4,85.3,100.3,93.3,108.6z M75.8,123.5c0.8,1.3,1.9,2.6,2.7,4.1c0.7,1.3,1.5,1.7,2.8,1.2
		c1-0.4,2.2-0.6,3-1.2c1.9-1.4,3.6-1.2,5.6-0.3c0.6,0.3,1.7,0.4,2.2,0c1.6-1.1,3.1-2.4,4.9-3.8c-2-2.1-3.7-4-5.6-5.8
		c-0.5-0.5-1.8-0.9-2.3-0.6C84.7,119.1,80.4,121.3,75.8,123.5z M98,105.6c3,4.3,6,8.6,9,12.9c2.9,4,5.7,4.3,9.9,1
		C111.3,113.9,106.4,107.6,98,105.6z M262.1,110.7c-0.6,7.2,4,9.3,8.9,11.4c0.6-1.3,1.2-2.6,1.8-3.9c0.8-1.7,0.5-3-1.3-3.7
		C268.4,113.2,265.3,112,262.1,110.7z M235.7,145.4c6.6-3,6.2-7.9,5.5-13.3c-1.8,0.2-3.6,0.3-5.3,0.7c-0.6,0.2-1.5,1.2-1.5,1.8
		C234.7,138.1,235.2,141.6,235.7,145.4z M276.9,119c-4.5,4.7-2.5,9.2,0,14c1.6-0.8,3.3-1.5,4.7-2.4c0.5-0.3,0.9-1.4,0.8-2
		C281.4,124.9,279.6,121.6,276.9,119z M65.5,159.8c-1.9-6.4-6.6-7.1-12.1-7.1c-0.1,1.7-0.4,3.4-0.3,5c0.1,0.7,1,1.9,1.6,2
		C58.3,159.8,61.9,159.8,65.5,159.8z M252.2,152.6c-0.1,1.8-0.3,3.5-0.2,5.2c0,0.6,0.5,1.6,1,1.7c3.8,1.1,7.7,1.1,11.4,0.1
		C262.5,153.5,257.9,152.6,252.2,152.6z M242.9,144.3c-1.3,1.4-2.6,2.7-3.7,4.2c-0.3,0.4-0.3,1.3,0,1.7c2.1,3.4,5.2,5.7,8.5,7.4
		C250.4,151.5,247,148,242.9,144.3z M242.4,118c-0.4-0.1-0.8-0.1-1.1-0.2c-2.1,3.5-4.2,6.9-6.3,10.4c0.2,0.2,0.4,0.4,0.6,0.7
		c2.1-0.2,4.5,0.1,6.3-0.9c1.4-0.7,3-2.9,3-4.5C244.9,121.7,243.3,119.9,242.4,118z M73.3,120.5c0.3-1.7,1.3-4.2,0.6-4.9
		c-3-2.7-6.8-4.1-11.9-4.4c1.3,2.5,1.8,4.8,3.3,5.8C67.4,118.6,70.2,119.2,73.3,120.5z M37,145.3c2-1.7,3.7-2.8,5-4.3
		c0.6-0.7,0.7-2.8,0.2-3.2c-1.9-1.5-4.1-2.7-6.2-4c-0.3,0.2-0.6,0.4-0.8,0.5C35.7,138.1,36.3,141.7,37,145.3z M66.2,152.2
		c-0.2,0.3-0.4,0.7-0.6,1c1.5,1.6,3.1,4.4,4.5,4.4c2.2,0,4.3-2.2,6.5-3.4c-0.1-0.3-0.2-0.6-0.4-0.9
		C72.9,153.3,69.7,151.1,66.2,152.2z M250.3,140.2c-0.2-0.1-0.5-0.3-0.7-0.4c-1.5,0.9-3.1,1.8-4.5,2.8c-0.4,0.3-0.7,1.6-0.4,2
		c1.1,1.5,2.4,2.8,4.1,4.7C249.3,145.8,249.8,143,250.3,140.2z M241.8,138.8c5.5-2.8,7.2-4.5,6.7-6.1c-0.6,0.8-1.1,1.5-1.9,2.8
		c0-1.4,0-2.2,0-3.4c-1.5,0.2-3.8,0-4,0.5C242,134.6,242.1,136.7,241.8,138.8z M268.4,127.2c0.4-2,1.5-3.9,0.9-4.6
		c-1.2-1.4-3.2-2.1-5-3.3c-0.2,5.1,0.7,8.6,2.2,8.8c-0.3-0.8-0.6-1.6-0.9-2.4c0.2-0.1,0.3-0.2,0.5-0.3
		C266.6,125.9,267.1,126.3,268.4,127.2z M58.3,147.9c-1.5-0.7-2.4-1.1-3.6-1.6c-0.1,1.6-1,4.3-0.2,4.9c1.4,1.2,3.8,1.1,5.8,1.6
		c0.1-0.3,0.2-0.5,0.4-0.8C59.7,150.5,58.8,148.9,58.3,147.9z M254.3,146.4c-0.4,0.2-0.8,0.3-1.2,0.5c0,1.5-0.6,4,0.1,4.4
		c1.7,1,3.9,1.1,6,1.6c0.2-0.4,0.4-0.8,0.6-1.1C258,150,256.1,148.2,254.3,146.4z M53.4,95.7c0,0.4-0.1,0.7-0.1,1.1
		c2.1,0.2,4.1,0.4,6.2,0.6c0-0.4,0.1-0.8,0.1-1.1C57.5,95.6,55.5,94.9,53.4,95.7z M60.6,88c0-0.3,0.1-0.5,0.1-0.8
		c-2.4-1.4-4.9-1.6-6.9,0.1C56.4,87.5,58.5,87.7,60.6,88z M57.7,114.1c0.1-0.5,0.1-1,0.2-1.5c-2.1-0.2-4.2-0.4-6.3-0.6
		c0,0.4-0.1,0.9-0.1,1.3C53.5,113.6,55.6,113.8,57.7,114.1z M52.9,100.1c-0.1,0.5-0.1,0.9-0.2,1.4c2.1,0.3,4.2,0.5,6.3,0.8
		c0.1-0.5,0.1-0.9,0.2-1.4C57.1,100.6,55,100.3,52.9,100.1z M53.2,97.8c-0.1,0.5-0.1,0.9-0.2,1.4c2.1,0.2,4.1,0.4,6.2,0.6
		c0-0.4,0.1-0.9,0.1-1.3C57.3,98.2,55.3,98,53.2,97.8z M59.7,95.1c0.1-0.5,0.1-1,0.2-1.4c-2.1-0.2-4.2-0.4-6.3-0.7
		c0,0.4-0.1,0.9-0.1,1.3C55.5,94.6,57.6,94.9,59.7,95.1z M54.2,88.2c-0.1,0.5-0.1,0.9-0.2,1.4c2.1,0.2,4.2,0.4,6.2,0.7
		c0-0.5,0.1-0.9,0.1-1.4C58.3,88.6,56.3,88.4,54.2,88.2z M52.4,104.9c0,0.2-0.1,0.5-0.1,0.7c2,1.4,5.3,1.5,6.5-0.2
		C56.5,105.3,54.4,105.1,52.4,104.9z M60,92.7c0-0.5,0.1-0.9,0.1-1.4c-2.1-0.2-4.1-0.5-6.2-0.7c0,0.5-0.1,1-0.1,1.4
		C55.9,92.3,58,92.5,60,92.7z M51.3,114.3c-0.1,0.5-0.1,0.9-0.2,1.4c2.1,0.2,4.2,0.4,6.3,0.6c0-0.4,0.1-0.9,0.1-1.3
		C55.5,114.8,53.4,114.5,51.3,114.3z M52.2,107.2c0,0.5-0.1,1-0.1,1.4c2,0.2,4.1,0.4,6.1,0.6c0-0.4,0.1-0.9,0.1-1.3
		C56.2,107.6,54.2,107.4,52.2,107.2z M52,109.7c-0.1,0.5-0.1,0.9-0.2,1.4c2,0.2,4,0.4,6,0.6c0-0.4,0.1-0.9,0.1-1.3
		C56,110.1,54,109.9,52,109.7z M58.6,104.5c0-0.4,0.1-0.9,0.1-1.3c-2-0.2-4-0.4-5.9-0.5c0,0.4-0.1,0.9-0.1,1.3
		C54.6,104.1,56.6,104.3,58.6,104.5z M60.1,85.6c0.1-0.4,0.1-0.8,0.2-1.1c-1.8-0.3-3.7-0.6-5.5-1c-0.1,0.4-0.1,0.9-0.2,1.3
		C56.4,85.1,58.3,85.3,60.1,85.6z"/>
	<path d="M255.3,119.7c1.2,0.3,2.5,0.6,3.7,1c-0.2,0.5-0.4,1.5-0.4,1.5c-1.2-0.2-2.4-0.5-3.7-0.8C255,120.8,255.2,120.3,255.3,119.7
		z"/>
	<path d="M273.2,134c-2.2-1.5-1.9-3.2-0.1-5.1C274.6,130.7,274.3,132.4,273.2,134C273.2,134.1,273.2,134,273.2,134z"/>
</g>

</svg>);
export default iPCX;