import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

const HorairesBureau = [
    {
        "day": "Lundi",
        "times": "10:00 - 12:00 | 14:00 - 19:00",
    },
    {
        "day": "Mardi",
        "times": "10:00 - 12:00 | 14:00 - 19:00",
    },
    {
        "day": "Mercredi",
        "times": "10:00 - 12:00 | 14:00 - 19:00",
    },
    {
        "day": "Jeudi",
        "times": "10:00 - 12:00 | 14:00 - 19:00",
    },
    {
        "day": "Vendredi",
        "times": "10:00 - 12:00 | 15:00 - 19:00",
    },
    {
        "day": "Samedi",
        "times": "10:00 - 12:00 | 14:00 - 19:00",
    },
    {
        "day": "Dimanche",
        "times": "Fermé",
    }
];

const Horaires = () => {

    const [transform, setTransform] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        const { clientX, clientY } = e;
        const element = e.target.getBoundingClientRect();
        const elementCenterX = element.left + element.width / 2;
        const elementCenterY = element.top + element.height / 2;

        const distanceX = clientX - elementCenterX;
        const distanceY = clientY - elementCenterY;

        const maxTranslate = 50; // Maximum translation in pixels

        const translateX = Math.min(maxTranslate, Math.max(-maxTranslate, distanceX / 10));
        const translateY = Math.min(maxTranslate, Math.max(-maxTranslate, distanceY / 10));

        setTransform({ x: translateX, y: translateY });
    };

    const handleMouseLeave = () => {
        setTransform({ x: 0, y: 0 });
    };

    const { ref, inView } = useInView({
        triggerOnce: false, // L'animation s'active et se désactive en fonction de la visibilité
        threshold: 0.1, // Le pourcentage de visibilité avant de déclencher l'animation
    });
    return (
        <div className='lay-horaires'>
            <div className='widthMaxcont'>
                <div ref={ref} className='theContHor'>

                    <div
                        ref={ref}
                        className='allDaysSem'>
                        <h2 style={{ background: 'white' }}>Horaires d'ouverture bureau</h2>
                        {HorairesBureau.map((day, index) =>
                            <div
                                key={index}
                                style={{
                                    background: day.times === "Fermé" ? 'rgb(0, 60, 77)' : 'white',
                                    color: day.times === "Fermé" ? 'white' : 'black'
                                }}
                                ref={ref}
                                className={`Day ${inView ? (index % 2 === 0 ? 'anim animate__fadeInLeft' : 'anim animate__fadeInRight') : ''}`}>
                                <span>{day.day}</span>
                                <span className='times'>{day.times}</span>
                            </div>
                        )}
                    </div>
                    <div
                        ref={ref}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                        style={{ transform: `translate(${transform.x}px, ${transform.y}px)` }}
                        className={`infDimaC magnetic-div ${inView ? 'anim animate__jackInTheBox' : ''}`}>
                        <span className='ocdpld1'>
                            COURS DE CONDUITE
                        </span>
                        <span className='ocdpld2'>
                            de 7h à 22h
                        </span>
                        <span className='ocdpld3'>
                            POSSIBLE LE DIMANCHE
                        </span>

                    </div>

                </div>
            </div>
        </div>
    );


};

export default Horaires;