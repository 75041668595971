import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactBan from '../components/ContactBan';

function NotFoundPage() {
  return (
    <React.Fragment>
      
      <Header />

      <div className='GpageMPORM'>
        <div>
          <h2>404 - Page Not Found</h2>
          <p>The page you are looking for does not exist.</p>
        </div>
      </div>

      <ContactBan />
      <Footer />

    </React.Fragment>
  );
}

export default NotFoundPage;