const descriptionBAUTO = "Véhicules automobiles avec boîte de vitesse automatique ayant un poids total autorisé en charge (PTAC) qui n'excède pas 3,5 tonnes, affectés au transport de personnes ou de marchandises, conçus et construits pour le transport de huit passagers au maximum non compris le conducteur ainsi que les véhicules qui peuvent être assimilés aux véhicules précédents et dont la liste est fixée par arrêté du ministre chargé de la sécurité routière.";
const descriptionBMANU = "Véhicules automobiles ayant un poids total autorisé en charge (PTAC) qui n'excède pas 3,5 tonnes, affectés au transport de personnes ou de marchandises, conçus et construits pour le transport de huit passagers au maximum non compris le conducteur ainsi que les véhicules qui peuvent être assimilés aux véhicules précédents et dont la liste est fixée par arrêté du ministre chargé de la sécurité routière.";

const infosDEScond = [
  {
    titre: "Epreuve théorique générale",
    formuleSt: "code",
    categories: "ETG",
    description: "L'épreuve théorique générale, ou code, est obligatoire pour pouvoir se présenter à l'épreuve pratique de categorie B.",
    ageMini: 15,
    trancheAgepossible: [15, 18, 21, 25],
    conditions: [
      "à partir de 15 ans dans le cadre de l'apprentissage anticipé de la conduite",
      "à partir de 16 ans dans le cadre de l'apprentissage classique"
    ]
  },
  {
    titre: "Epreuve théorique moto",
    formuleSt: "code-moto",
    categories: "ETM",
    description: "L'épreuve théorique moto, ou code, est obligatoire pour pouvoir se présenter à l'épreuve pratique de categorie A1 / A2.",
    ageMini: 16,
    trancheAgepossible: [16, 18, 21, 25],
    conditions: [
      "16 ans minimum au passage de l'examen",
      
    ]
  },
  {
    titre: "PERMIS B boîte manuelle + code",
    formuleSt: "B-manu-code",
    categories: "B MANU",
    typeC: "AVEC FORFAIT CODE",
    description: descriptionBMANU,
    ageMini: 17,
    trancheAgepossible: [17, 18, 21, 25],
    conditions: [
      "17 ans minimum à la date d'inscripton",
    ]
  },
  {
    titre: "PERMIS B - boîte manuelle",
    formuleSt: "B-manu",
    categories: "B MANU",
    typeC: "SANS FORFAIT CODE",
    description: descriptionBMANU,
    ageMini: 17,
    trancheAgepossible: [17, 18, 21, 25],
    conditions: [
      "17 ans minimum à la date d'inscription",      
    ]
  },
  {
    titre: "PERMIS B boîte manuelle AAC + code",
    formuleSt: "B-manu-AAC-code",
    categories: "B MANU AAC",
    typeC: "AVEC FORFAIT CODE",
    description: descriptionBMANU,
    ageMini: 15,
    trancheAgepossible: [15, 18, 21, 25],
    conditions: [
      "15 ans minimum à la date de l'inscription",
    ]
  },
  {
    titre: "PERMIS B - boîte manuelle AAC",
    formuleSt: "B-manu-AAC",
    categories: "B MANU AAC",
    typeC: "SANS FORFAIT CODE",
    description: descriptionBMANU,
    ageMini: 15,
    trancheAgepossible: [15, 17, 18, 21, 25],
    conditions: [
      "15 ans minimum à la date de l'inscription",
    ]
  },
  {
    titre: "PERMIS B boîte manuelle CS + code",
    formuleSt: "B-manu-CS-code",
    categories: "B MANU CS",
    typeC: "AVEC FORFAIT CODE",
    description: descriptionBMANU,
    ageMini: 18,
    trancheAgepossible: [18, 21, 25],
    conditions: [
      "18 ans minimum à la date d'inscription",
    ]
  },
  {
    titre: "PERMIS B - boîte manuelle CS",
    formuleSt: "B-manu-CS",
    categories: "B MANU CS",
    typeC: "SANS FORFAIT CODE",
    description: descriptionBMANU,
    ageMini: 18,
    trancheAgepossible: [18, 21, 25],
    conditions: [
      "18 ans minimum à la date d'inscription",
    ]
  },
  {
    titre: "PERMIS B boîte automatique + code",
    formuleSt: "B-auto-code",
    categories: "B AUTO",
    typeC: "AVEC FORFAIT CODE",
    description: descriptionBAUTO,
    ageMini: 17,
    trancheAgepossible: [17, 18, 21, 25],
    conditions: [
      "17 ans minimum à la date d'inscription",
    ]
  },
  {
    titre: "PERMIS B - boîte automatique",
    formuleSt: "B-auto",
    categories: "B AUTO",
    typeC: "SANS FORFAIT CODE",
    description: descriptionBAUTO,
    ageMini: 17,
    trancheAgepossible: [17, 18, 21, 25],
    conditions: [
      "17 ans minimum à la date d'inscription",      
    ]
  },
  {
    titre: "PERMIS B boîte automatique AAC + code",
    formuleSt: "B-auto-AAC-code",
    categories: "B AUTO AAC",
    typeC: "AVEC FORFAIT CODE",
    description: descriptionBAUTO,
    ageMini: 15,
    trancheAgepossible: [15, 17, 18, 21, 25],
    conditions: [
      "15 ans minimum à la date d'inscription",

    ]
  },
  {
    titre: "PERMIS B - boîte automatique AAC",
    formuleSt: "B-auto-AAC",
    categories: "B AUTO AAC",
    typeC: "SANS FORFAIT CODE",
    description: descriptionBAUTO,
    ageMini: 15,
    trancheAgepossible: [15, 17, 18, 21, 25],
    conditions: [
      "15 ans minimum à la date d'inscription",
      
    ]
  },
  {
    titre: "PERMIS B boîte automatique CS + code",
    formuleSt: "B-auto-CS-code",
    categories: "B AUTO CS",
    typeC: "AVEC FORFAIT CODE",
    description: descriptionBAUTO,
    ageMini: 18,
    trancheAgepossible: [18, 21, 25],
    conditions: [
      "18 ans minimum à la date d'inscription",
    ]
  },
  {
    titre: "PERMIS B - boîte automatique CS",
    formuleSt: "B-auto-CS",
    categories: "B AUTO CS",
    typeC: "SANS FORFAIT CODE",
    description: descriptionBAUTO,
    ageMini: 18,
    trancheAgepossible: [18, 21, 25],
    conditions: [
      "18 ans minimum à la date d'inscription",
      
    ]
  },
  {
    titre: "Formation 125",
    formuleSt: "F125",
    categories: "F125",
    description: "Scooter ou moto d'une cylindrée maximale de 125 cm³, d'une puissance n'excédant pas 11 kilowatts et dont le rapport puissance / poids ne dépasse pas 0,1 kilowatt par kilogramme.",
    ageMini: 18,
    trancheAgepossible: [0],
    conditions: [
      "Plus de 2ans de permis B",
    ]
  },
  {
    titre: "PERMIS A1",
    formuleSt: "A1",
    categories: "A1",
    typeC: "SANS FORFAIT CODE",
    description: "Moto avec ou sans side-car d'une cylindrée maximale de 125 cm ³, d'une puissance n'excédant pas 11 kilowatts et dont le rapport puissance / poids ne dépasse pas 0,1 kilowatt par kilogramme. Tricycles à moteur d'une puissance maximale de 15 kilowatts.",
    ageMini: 16,
    trancheAgepossible: [16, 18, 21, 25],
    conditions: [
      "16 ans minimum à la date de l'inscription",
    ]
  },
  {
    titre: "PERMIS A2",
    formuleSt: "A2-code",
    categories: "A2",
    typeC: "AVEC FORFAIT CODE",
    description: "Moto avec ou sans side-car d'une puissance n'excédant pas 35 kilowatts et dont le rapport puissance / poids n'excède pas 0,2 kilowatt par kilogramme. La puissance ne peut résulter du bridage d'un véhicule développant plus de 70 kW.",
    ageMini: 18,
    trancheAgepossible: [18,21, 25],
    conditions: [
      "18 ans minimum à la date de l'inscription",
    ]
  },
  {
    titre: "PERMIS A1",
    formuleSt: "A1-code",
    categories: "A1",
    typeC: "AVEC FORFAIT CODE",
    description: "Moto avec ou sans side-car d'une cylindrée maximale de 125 cm ³, d'une puissance n'excédant pas 11 kilowatts et dont le rapport puissance / poids ne dépasse pas 0,1 kilowatt par kilogramme. Tricycles à moteur d'une puissance maximale de 15 kilowatts.",
    ageMini: 16,
    trancheAgepossible: [16, 18,21, 25],
    conditions: [
      "16 ans minimum à la date de l'inscription",
    ]
  },
  {
    titre: "PERMIS A2",
    formuleSt: "A2",
    categories: "A2",
    typeC: "SANS FORFAIT CODE",
    description: "Moto avec ou sans side-car d'une puissance n'excédant pas 35 kilowatts et dont le rapport puissance / poids n'excède pas 0,2 kilowatt par kilogramme. La puissance ne peut résulter du bridage d'un véhicule développant plus de 70 kW.",
    ageMini: 18,
    trancheAgepossible: [18, 21, 25],
    conditions: [
      "18 ans minimum à la date de l'inscription",
    ]
  },
  {
    titre: "Passerelle A2 en A",
    formuleSt: "A",
    categories: "A",
    description: "Moto de plus 70 kW. Tricycles à moteur d'une puissance supérieure à 15 kilowatts.",
    ageMini: 20,
    trancheAgepossible: [0],
    conditions: [
      "Plus de 2ans de permis A2",
    ]
  },
  {
    titre: "PERMIS AM cyclomoteur",
    formuleSt: "s50",
    categories: "AM cyclo",
    description: "2 ou 3 roues, de moins de 50 cm3 de cylindrée (ou moins de 4 kW) avec une vitesse maximale de 45 km/h.",
    ageMini: 14,
    trancheAgepossible: [14, 18, 21, 25],
    conditions: [
      "14 ans minimum à la date de l'inscription",
    ]
  },
  {
    titre: "PERMIS AM quadricycle léger",
    formuleSt: "q50",
    categories: "AM quadri",
    description: `Voiturettes, leur description légale est "véhicules à moteur à 4 roues à habitacle fermé". La charge utile est de 250 kg maximum. La vitesse maximale est 45 km/h. Le moteur a une cylindrée de 50 cm3 maximum (ou moins de 6 kW).`,
    ageMini: 14,
    trancheAgepossible: [14, 18, 21, 25],
    conditions: [
      "14 ans minimum à la date de l'inscription",
    ]
  }
];
// source https://www.legifrance.gouv.fr/codes/section_lc/LEGITEXT000006074228/LEGISCTA000032465124


export default infosDEScond;