import React from "react";
const iVESPA = ({size=24, color="#0056b3"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
width={size} height={size} 
viewBox="0 0 300 208" 
fill={color} 
stroke="none"
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round">
<g>
	<path d="M72.5,182.9c-2.7,4.7-6.8,7.2-11.9,8.2c-0.7,0.1-2-0.9-2.3-1.7c-0.7-2.1-1.4-4.3-1.4-6.5c0-2.5-1.1-3.7-3.4-4.1
		c-1-0.2-1.9-0.6-3.5-1.1c-0.9,3.3-1.9,6.4-2.6,9.6c-0.7,2.7-1.4,3.3-3.5,1.6c-2.8-2.2-5.3-4.9-7.6-7.5c-1.2-1.4-0.7-2.8,1-3.6
		c1.7-0.9,3.4-1.8,5.2-2.7c-3.2-3.6-6.8-3.5-10.7-0.8c0.6,12.7,15.6,23,28.9,19.2c5.1-1.4,9.3-4.1,12.6-8.2c0.6-0.7,1.6-1.3,2.4-1.4
		c4.5-0.1,9.1-0.1,14.2-0.1c-5.1,10.8-13.1,17.5-24.2,20.7c-13.8,4-29.5-1.2-38.6-12.9c-5.6-7.1-8.2-15.3-7.8-24.3
		c0.3-9.1,3.9-16.8,9.9-23.8c-1.5-0.6-2.6-1.1-4-1.7c-0.8,1.5-1.5,2.9-2.3,4.2c-1.2,2-2.1,4.5-5,4.7c-0.2,0-0.4,0.1-0.5,0.3
		c-1.6,3-4.5,4.1-7.3,5.6c-1.4,0.7-2.3,2.6-3.8,3.4c-1.4,0.8-3.6,1.7-4.6,1.1c-1.6-1-0.9-2.9,0.3-4.3c0.2-0.3,0.7-0.5,0.7-0.8
		c1.3-8.6,7.5-14.8,10.7-22.6c0.6-1.5,2.2-2.6,3.6-4.2c-0.7-0.6-1.6-1.4-2.6-2.2c-1.9-1.6-2-3.3-0.3-5c4-4.1,7.2-8.6,10-13.7
		c1.9-3.5,6-3.8,9.6-4c4.4-0.2,9.7-3.8,10.2-8.1c0.3-2.4-0.2-5.2-1.2-7.5c-1.3-3.1-0.9-4.8,2.6-5.4c2.3-0.4,3.4-1,3.9-3.5
		c0.5-2.6,2.9-3.9,5.5-3.9c10.6,0.1,21.3,0,31.9,0.7c4.7,0.3,9.2,2.7,13.9,3.6c10.9,2.1,21.8,1.2,32.7-0.2c2.8-0.4,5.7-0.7,8.6-0.8
		c10.9-0.6,20,4.9,24.3,15c1.3,3.1,2.2,6.5,2.4,9.9c0.4,6.5-3.9,11.1-10.4,11.9c-1.2,3.3-2.5,6.7-3.6,10.1
		c-3.1,9.7,0.8,17.4,10.6,20.4c8.8,2.6,17.9,2.9,27,1.8c4.6-0.6,7.9-3,9.6-7.4c1-2.5,2.1-5,2.9-7.5c0.3-1,0.2-2.2-0.1-3.2
		c-1.8-5-2-10.1-1-15.3c2.3-12.4-0.8-24.1-5.1-35.5c-1.4-3.7-2.4-7.6-4.1-11.1c-1.9-4.1-4.4-7.9-6.7-11.8c-0.2-0.3-0.6-0.4-0.9-0.5
		c-0.3-0.1-0.8,0.1-1.1-0.1c-2.6-1.4-5.5-2.5-7.7-4.4c-1.7-1.4-0.7-3.5,0.9-4.8c0.8-0.7,1.9-1,3.1-1.6c0-0.5,0.2-1.2,0.1-1.8
		c-0.2-2.4,0.7-3.9,3.2-4.2c0.2,0,0.4-0.1,0.5-0.2c9.5-4.1,9.5-5.4,3-14c-0.4,0.5-0.8,1-1.3,1.4c-1.8,1.8-3.3,1.5-4.6-0.8
		c-3.6-6.1-4-12.6-1.9-19.3c0.8-2.4,1.5-5.2,4.5-5.3c2.9-0.1,3.7,2.7,4.6,4.9c1.5,3.7,1.8,7.6,1.9,11.6c0.1,4.3,1.9,8.5,3.5,13.1
		c2.1-4.6,5.9-3.8,9.4-3.7c1.7,0,3.4,0.2,5,0c4.7-0.6,8.1,1.6,10.7,5.2c3.9,5.4,5.5,11.4,4.7,18.1c-0.3,2.3-1.3,3.5-3.7,3.6
		c-1.7,0.1-3.5,0.4-5.2,0.7c5.2,0.3,10.2,2.2,12.3,6.5c3.7,7.7,10.6,12.4,15.2,19.1c2.8,4.1,5.6,8.3,8.4,12.4
		c0.4,0.5,0.6,1.4,1.1,1.6c2.7,0.9,2.8,3.7,3.8,5.6c1.3,2.4,1.9,5.1,2.9,7.6c0.8,2-0.2,4-2.7,4.6c-1.5,0.4-3,0.4-4.5,1.1
		c1.8-0.1,3.5-0.3,5.3-0.4c3.4-0.2,6.8-0.6,10.2-0.4c5.9,0.4,10.6,2.9,13.3,8.5c0.8,1.7,2.4,2.9,3.7,4.4c2.7,3.2,5.7,6.1,8,9.5
		c2.5,3.6,0.8,6.6-3.6,6.9c-1.7,0.1-3.5,0-6,0c8.3,6.6,13.4,14.6,14.7,24.7c1.3,10.2-1.3,19.3-7.7,27.3
		c-9.6,11.9-26.5,16.4-40.4,10.9c-17-6.8-24.2-20.3-22.4-42.1c-1.9-0.1-3.9-0.4-5.8-0.2c-0.9,0.1-1.8,1-2.5,1.7
		c-3.3,3.4-6.4,7.2-10,10.4c-5.9,5.2-13.5,5.9-20.9,6.1c-19.2,0.4-38.4,0.5-57.6,0.8c-2.1,0-3.9-0.9-5.8-1.7c-5.2-2.1-10.7-5-16.5-1
		c-5.3,3.7-11.4,4.6-17.7,4.5C85,182.8,78.7,182.9,72.5,182.9z M209.4,63.2c-3.2,1.6-6.4,3.3-9.7,4.7c-1.9,0.9-2.3,1.9-1.5,3.9
		c2.1,5.2,3.9,10.4,5.9,15.6c0.2,0.6,0.9,1.4,1.4,1.4c4.7-0.2,5.9,3,6.8,6.6c1.8,6.9,1.8,14,0.9,21c-0.7,5-0.4,6.9-7,5
		c-0.1,0-0.3,0.2-0.4,0.3c0.1,9.4,7.9,20.4,16,22c2.4-4.6,4.6-9.4,7.2-14c6.3-11.2,5.2-22-1.4-32.6c-4-6.4-8-12.9-12.2-19.2
		c-3.9-5.9-4.5-11.6-0.2-17.5c0.1-0.1-0.1-0.3-0.2-0.5c-1.9,1.2-3.8,2.3-5.7,3.5L209.4,63.2z M36.2,131.5c0,0.3,0,0.6,0.1,1
		c8.4,1.6,16.8,3.7,25.3,4.6c19.1,2.1,38.2,3.6,57.3,5.2c7.2,0.6,7.6,0.3,10.1-6.6c0.8-2.2,1.2-4.7,1.4-7.1
		c0.7-13.9-10.7-24.4-24.3-28.9c-11.5-3.8-23.6-3.2-35.6-2.3c-5.7,0.4-11.4,2-17.1,0.1c-0.3-0.1-0.8,0.1-1.1,0.2
		c-4.8,2.9-9.6,5.8-14.9,8.9c2.9,2.3,1.7,4.1,0.4,5.7c-1,1.3-2.2,2.5-3.2,3.8c-3.2,3.9-6.3,7.9-9.7,12.1c1.3,0.5,2,0.8,2.9,1.1
		c0-1.1-0.2-2.1,0-2.9c1.4-3.9,6.9-7.3,10.8-6.8c0.8,0.1,1.6,0.7,2.4,1.1c-0.4,0.8-0.7,1.7-1.3,2.3c-2.5,2.5-5,4.9-7.7,7.6
		c0.6,0.1,0.9,0.3,1,0.2c0.5-0.4,1-0.8,1.5-1.2c6.2-5,12.2-10.3,18.7-14.8c8.2-5.6,17.4-9.4,27.4-10.9c0.8-0.1,1.8,0.6,2.7,0.9
		c-0.6,0.5-1.2,1.4-1.9,1.5c-9.1,1.3-17.2,5-25,9.5C48.8,120.2,42.2,125.4,36.2,131.5z M128.5,168.2c2.7,3.7,5,7,7.4,10.1
		c0.3,0.4,1.3,0.5,2,0.5c18.4-0.3,36.7-0.6,55-1.1c7.6-0.2,14-3.1,19.3-8.6c7.8-8.1,13.3-17.8,18.1-27.8
		c5.2-10.9,10.6-21.7,19.9-29.8c2.4-2.1,2.5-4,0.9-6.3c-6.7-9.8-13.2-19.7-20.1-29.3c-3.9-5.5-8.3-10.6-12.4-15.8
		c-5.1,5.6-5.1,10.9-1.2,16.7c4.8,7.3,9.5,14.6,13.7,22.2c4.3,7.7,6.4,15.9,3,24.5c-6,14.8-13.5,28.9-23.4,41.5
		c-3.6,4.6-8.7,7.2-14.4,7.4c-9.1,0.3-18.2,0.2-27.2-0.4C156,171,142.8,169.5,128.5,168.2z M126.8,165.3c9.3,0.9,18.4,2,27.5,2.7
		c11.6,0.9,23.3,1.9,34.9,2.3c6.2,0.2,12.5-0.5,17.5-5c5.2-4.7,8.6-10.8,12.2-16.9c-5.2,0.5-6.3-0.1-8.6-4.8
		c-1.2-2.4-2.3-4.9-3.6-7.7c-1.5,3.5-3.3,6.4-3.8,9.5c-0.8,5.4-4.1,7.8-8.8,9.1c-7.8,2.2-15.8,1.9-23.7,0.7c-4.5-0.7-9-2.1-13.2-4
		c-3.9-1.8-4-3.2-1.7-6.9c0.1-0.1-0.1-0.3-0.2-0.5c-3.6,0.4-7.2,1.3-10.7,1.1c-6.3-0.4-10.6,2.1-13.9,7.1c-0.4,0.6-0.9,1.2-1.4,1.7
		C126.7,157.1,126,160.9,126.8,165.3z M223.1,159.4c3.3,1,5.3,0,7.2-2c3.7-3.9,7.4-7.7,11.3-11.5c4.2-4,9.3-6.6,15.2-6.7
		c10-0.2,20,0,30-0.1c1.7,0,3.5-0.6,5.2-1c-0.7-1.5-1.2-3.3-2.2-4.6c-8.4-11.1-20.1-16.2-33.5-18.1c-4.8-0.7-8.7,0.7-11.3,5.1
		C237.7,133.5,230.5,146.4,223.1,159.4z M271.4,193.7c10.9-3.1,17.8-15.4,15.1-26.4c-3-12.3-14.6-20.2-26.8-17.9
		c-12.3,2.4-19.9,13.1-18.9,24.5c1.1,11.5,10.7,21.3,19.2,20.8c-0.4-0.7-1.5-1.7-1.3-2.4c1.1-4.4,2.4-8.7,3.8-13
		c0.2-0.7,1.3-1.4,2-1.5c0.4-0.1,1.1,1,1.5,1.7c2.1,3.3,3.9,6.7,6.3,9.8C273.9,191.4,274.2,192.7,271.4,193.7z M122.8,145.2
		c-0.8-0.1-1.1-0.2-1.5-0.2c-19.4-1.7-38.7-3.1-58.1-5.1c-13.1-1.4-25.9-4.2-38.3-8.7c-2.2-0.8-3.6-0.1-4.6,1.7
		c-1.1,2-0.6,3.3,1.5,4.4c6.5,3.7,13.6,6,20.9,7.1c22.3,3.4,44.7,6.5,67,9.7c0.4,0.1,0.8,0.1,1.1,0
		C115.3,152,119.3,149.3,122.8,145.2z M119.3,103.7c13.7,10.9,17.6,23.5,9.2,39.1c8.5,0.1,16.7,0.4,24.2-1.4
		c-0.6-3.5-1.7-6.7-1.4-9.8c0.4-3.9,1.9-7.7,3-11.5c0.4-1.4,0.9-2.7,1.3-3.9C143.4,112,131.7,107.9,119.3,103.7z M185.1,52.1
		c-0.1,0.4-0.2,0.8-0.3,1.2c4.7-1.1,5.4,2.5,6.9,5.1c1.1,1.9,2.1,3.7,3.1,5.6c1,1.8,2.2,2.5,4.3,1.3c2.8-1.6,5.7-3.1,8.7-4.4
		c1.4-0.6,1.6-1.4,1.4-2.8c-0.5-3,0.5-4.6,3.4-5.5c2-0.6,4.1-0.9,6.7-1.5c-2-5.4-3.8-10.6-5.8-15.8c-2.4-5.9-2.5-5.9-9-6
		c-0.7,0-1.6-0.2-2.2,0c-1.3,0.6-2.7,1.2-3.8,2.1c-0.9,0.7-1.3,2-2,3.1c2.3,1.4,2.1,3.5,0.9,5.1c-2,2.7-4.3,0.4-6.1-0.3
		c-1.5,0.6-2.7,1.2-4,1.7c-2.4,0.9-2.4,0.9-2.2,2.6c5.3-2.3,10.5-1.5,15.4,1.1c2.3,1.2,2.3,3.3,0,4.5c-4.2,2-8.5,3.8-13.3,2.6
		C186.5,51.7,185.8,52,185.1,52.1z M92.5,172.9c-3.9-1.9-7.2-3.6-10.6-5.1c-6.5-3.1-13-6.2-19.6-9.1c-5.6-2.5-11.4-2.8-16.6,1.1
		c-1.7,1.3-3.7,3.3-4,5.2c-0.2,1.1,2.5,3.1,4.3,4c10.2,4.8,21,7.2,32.1,8.1C83.1,177.4,87.7,175.4,92.5,172.9z M113.8,156.4
		c2.2,1.9,4.7,4.1,6.8,6.1c1.2-0.6,2.2-1.1,3.3-1.8c0.1-6.3,5.1-10.3,8.7-15.3c-2.4,0-5.1-0.4-6.8,0.7
		C121.7,149.1,118,152.8,113.8,156.4z M116.5,162.3c-0.4,1.4-0.7,2.4-1,3.3c-1.4,3.8-2.6,4.9-6.6,4.3c-4.2-0.7-8.2-1.9-12.3-3.1
		c-2.1-0.6-3.1-2.2-3.5-4.5c-0.4-2.8-1.3-5.6-2-8.4c-0.3,0.1-0.6,0.1-0.9,0.2c0.7,2.9,1.4,5.9,2.3,9.5c-7.8-5.6-14.8-11.4-24.2-12.2
		c9.8,2.8,17.8,8.5,25.5,14.8c1.5,1.2,3.3,2.3,5.1,2.6c7.2,1.3,14.5,2.3,21.8,3.4c0.1-3-7-3.9-1.4-7.6
		C118.3,163.8,117.5,163.2,116.5,162.3z M58.9,150.1c-6.3-1.8-12.5-3.1-18.3,1.9c-4.5,3.9-7.7,8.4-8.4,14.6
		C38.1,156.2,46.6,150,58.9,150.1z M38,180.5c2.4,2.3,4.5,4.3,6.7,6.5c1.1-3.8,2.1-6.8,3.1-10.2C44.1,176.7,41.5,178.6,38,180.5z
		 M263.9,181.5c-1,3.7-1.9,7-3.1,11.1c3.5-0.4,6.3-0.8,9.6-1.1c-1.5-2-2.6-3.4-3.5-4.9C266,185.1,265.1,183.6,263.9,181.5z
		 M122.2,167.8c1.6,6.6,6.1,11.2,10.2,10.5c-1.9-2.9-4-5.6-5.7-8.5C125.6,167.7,124.2,167.3,122.2,167.8z M59.1,180
		c0.6,3.5,1.1,6,1.7,9.2c3-1.8,5.4-3.3,7.9-4.7c0-0.3-0.1-0.6-0.1-0.9C65.6,182.5,62.8,181.4,59.1,180z M33.3,106.7
		c-0.2,0.1-0.4,0.3-0.7,0.4c1,3-0.4,5.2-2,7.4c-2.4,3.3-4.7,6.7-7,10.1c0.1,0.1,0.3,0.2,0.4,0.3c4.1-5,8.2-10,12.3-15.1
		C35.3,108.7,34.3,107.7,33.3,106.7z M11.6,152.3c0.3,0.2,0.6,0.3,0.9,0.5c1.7-3.5,3.5-7,5.2-10.4c-0.2-0.1-0.5-0.2-0.7-0.4
		C14.5,145,12.2,148.2,11.6,152.3z M217.7,145.3c-0.9-0.7-1.8-1.3-2.6-2c-0.9-0.7-1.7-1.3-3-2.3C214,146,214.8,146.7,217.7,145.3z"
		/>
	<path d="M63,126.9c10.1,1.3,20.1,2.5,30.2,3.8c-2,1.8-28.5-1.5-30.3-2.9C62.8,127.5,62.9,127.2,63,126.9z"/>
	<path d="M91,135c-10-1.2-20.1-2.5-30.1-3.7c0.1-0.5,0.1-0.9,0.2-1.4c10.1,0.7,20.1,2.3,30.2,3.6C91.2,133.9,91.1,134.5,91,135z"/>
	<path d="M65.6,124.6c9.6,0.9,19.2,2.3,28.8,3.5c-0.1,0.5-0.1,0.9-0.2,1.4c-9.7-0.7-19.2-2.2-28.9-3.4
		C65.5,125.5,65.6,125,65.6,124.6z"/>
	<path d="M88.6,137.6c-9.8-1.2-19.6-2.4-29.3-3.5c0.1-0.5,0.1-0.9,0.2-1.4c9.8,0.7,19.6,2,29.4,3.4
		C88.7,136.6,88.7,137.1,88.6,137.6z"/>
	<path d="M94.6,126.9c-8.7-1.1-17.4-2.2-26-3.3c0.1-0.5,0.1-0.9,0.2-1.4c8.7,0.7,17.4,1.9,26.1,3.2
		C94.7,125.9,94.7,126.4,94.6,126.9z"/>
	<path d="M72.2,121.1c2.1-1.8,20.5,0.7,21.8,2.9C86.8,123.1,79.5,122.1,72.2,121.1z"/>
	<path d="M242.3,106.1c-3-0.2-5-1.5-6.5-4c-2.6-4.5-5.5-8.8-8.2-13.2c-0.5-0.8-0.4-1.8-0.6-2.7c1-0.1,2.2-0.7,3-0.3
		c2,1,4.2,2,5.6,3.7c3.2,3.8,6,8,8.8,12.1c0.6,0.8,1,2.2,0.7,3C244.6,105.4,243.2,105.6,242.3,106.1z"/>
	<path d="M144.6,176.8c-2.6-0.7-4.2-1.6-3.6-3.6c0.3-1,2.3-2.3,3.1-2.1c1.2,0.3,2.5,1.8,2.7,3C146.9,175,145.2,176.1,144.6,176.8z"
		/>
	<path d="M139,155.5c2.6,0,5.2-0.1,7.8,0c2.8,0.1,4.2,1.7,4.4,4.4c0.1,2.1-0.9,3.6-3,3.4c-5.6-0.6-11.1-1.5-16.7-2.3
		c-0.6-0.1-1.4-0.4-1.7-0.8c-0.7-0.9-1.6-2-1.5-2.9c0.1-0.6,1.6-1.6,2.5-1.6C133.4,155.5,136.2,155.6,139,155.5
		C139,155.6,139,155.5,139,155.5z"/>
	<path d="M215,150.8c-0.1,0.7-0.1,1.4-0.4,2c-5,9.6-12.5,15.5-23.7,15.5c-8.7,0-17.4-0.4-26.1-0.7c-0.8,0-1.5-0.5-2.3-0.7
		c0-0.4,0.1-0.8,0.1-1.2c1.4,0,2.9,0,4.3,0c6.8,0.3,13.7,0.9,20.5,0.8c4.2-0.1,8.5-0.7,12.6-1.9c5.7-1.7,9.1-6.4,12.1-11.3
		c0.6-1,1.4-1.9,2.1-2.9C214.5,150.5,214.8,150.7,215,150.8z"/>
	<path d="M209.3,63.3c2.4-0.3,3.2,1.3,2.1,2.8c-4.5,5.8-2.6,11.6-0.6,17.6c0.9,2.7-0.2,4.6-2.7,4.4c-1.3-0.1-3.2-1.3-3.5-2.3
		c-1.2-4.1-2.2-8.4-2.6-12.7c-0.2-1.5,1-3.5,2.1-4.8c1.5-1.9,3.6-3.4,5.4-5C209.4,63.2,209.3,63.3,209.3,63.3z"/>
	<path d="M216.9,117.7c3.8,0.2,7.4,0.4,11.6,0.6c-0.8,2.1-1.4,4.1-2.3,5.8c-2.3,4.6-5,5.1-9,1.9c-1.8-1.4-1.9-3-1.2-4.9
		C216.3,120,216.5,119,216.9,117.7z"/>
	<path d="M217.3,116.3c0-2.5,0-4.6,0-6.9c3.6,0,6.9-0.1,10.3,0.1c0.4,0,1.1,0.9,1.2,1.5c0.9,5.1,0.2,5.9-5,5.7
		C221.7,116.5,219.6,116.4,217.3,116.3z"/>
	<path d="M216.4,100.9c3.2,0,5.8-0.1,8.5,0.1c0.6,0,1.6,0.6,1.8,1.2c0.7,1.8,1.2,3.6,1.9,5.8c-3.9,0-7.5,0-11.3,0
		C217,105.5,216.7,103.4,216.4,100.9z"/>
	<path d="M216.1,99.1c-0.3-1.6-0.7-3.1-1-4.6c-0.5-2.5,0.3-3.4,2.9-3.3c3,0.1,4.6,1.9,5.8,4.4c0.5,1.1,1,2.1,1.8,3.5
		C222.3,99.1,219.4,99.1,216.1,99.1z"/>
	<path d="M286.5,172.8c-0.9,3.1-1.7,6.2-2.7,9.2c-0.6,1.8-1.9,2.4-3.5,1.3c-3.6-2.4-7.1-4.9-10.6-7.5c-0.6-0.5-1.1-1.7-0.9-2.4
		c0.2-0.6,1.4-0.9,2.2-1.1c3.6-0.9,7.2-1.5,10.7-2.6C285.5,168.6,286.1,168.9,286.5,172.8z M281.8,181.5c0.7-3.5,1.3-6.2,1.9-9.3
		c-3.9,1.1-7.2,2-11.1,3C275.6,177.3,278.3,179.2,281.8,181.5z"/>
	<path d="M269.2,149.9c3.4,2.1,6.8,4.2,10.1,6.5c0.5,0.3,0.5,2.4,0,2.8c-3.4,2.9-7,5.6-10.7,8.3c-0.6,0.4-1.8,0.3-2.5-0.1
		c-0.4-0.2-0.3-1.4-0.2-2.2c0.2-4.1,0.5-8.3,0.7-12.4c0.1-1.6,0.4-2.5,2.2-2.3C268.9,150.4,269,150.1,269.2,149.9z M269.2,164.7
		c2.9-2.3,5.4-4.4,8.6-7c-3.1-1.7-5.6-3.1-8.6-4.7C269.2,157.1,269.2,160.5,269.2,164.7z"/>
	<path d="M261.8,166.4c-0.9,2-1.6,2.5-3.3,1.9c-4-1.4-8-2.7-12.1-3.8c-2.2-0.6-2.8-1.6-1.5-3.3c2.3-2.9,4.8-5.5,7.4-8.1
		c0.5-0.5,2.6-0.4,2.8,0C257.4,157.6,259.5,162,261.8,166.4z M247,161.8c4,1.1,7.3,2,11.3,3.1c-1.7-3.5-3.2-6.5-4.9-10.1
		C251,157.4,249.2,159.4,247,161.8z"/>
	<path d="M240.9,175.6c1.2-1,1.8-2.1,2.5-2.1c4.6-0.2,9.2-0.2,13.8-0.2c0.6,0,1.7,0.8,1.8,1.4c0.1,0.5-0.6,1.4-1.1,1.9
		c-2.5,3.1-5.2,6-7.6,9.1c-1.7,2.2-2.6,2.5-4.1,0.1C244.2,182.7,242.7,179.2,240.9,175.6z M255.1,175.1c-4,0.2-7.2,0.4-11.3,0.6
		c1.5,3.2,2.6,5.8,3.9,8.6C250.3,181.1,252.4,178.5,255.1,175.1z"/>
	<path d="M263.9,168.4c2.2,0.1,3.5,1.3,3.7,3.4c0.2,2-1.6,3.8-3.6,3.8c-2,0-3.8-1.7-3.7-3.7C260.5,169.7,261.8,168.6,263.9,168.4z"
		/>
	<path d="M272.4,169.9c-1.1,0.8-1.8,1.5-2.4,1.5c-0.6-0.1-1.2-1-1.4-1.6c-0.1-0.3,0.9-1.3,1.4-1.3
		C270.6,168.5,271.2,169.1,272.4,169.9z"/>
	<path d="M257.6,168.3c0.7,1.1,1.5,1.8,1.4,2.3c-0.1,0.6-1,1-1.5,1.6c-0.5-0.5-1.3-1.1-1.4-1.6C256.1,170,256.8,169.4,257.6,168.3z"
		/>
	<path d="M262.7,177.6c-1,0.8-1.6,1.6-2.1,1.6c-0.6,0-1.4-0.9-1.5-1.5c-0.1-0.5,0.8-1.4,1.4-1.5C261,176.2,261.7,176.9,262.7,177.6z
		"/>
	<path d="M265.8,165.7c-1,0.7-1.6,1.5-2.2,1.4c-0.6-0.1-1.5-0.9-1.5-1.4c0-0.6,0.9-1.4,1.4-1.5C264.1,164.1,264.8,164.9,265.8,165.7
		z"/>
	<path d="M106.5,152.9c-0.6-1-1.2-1.8-1.1-1.9c1.3-1.2,2.7-2.3,4.2-3.2c0.1-0.1,0.8,0.7,1.2,1.1
		C109.4,150.1,108.1,151.4,106.5,152.9z"/>
	<path d="M69.5,171.4c-5.6-1.7-11.1-3.5-16.7-5.2C55.2,164.8,68.5,168.9,69.5,171.4z"/>
	<path d="M55.3,162.3c5.6,1.8,11.3,3.6,16.9,5.3c-0.1,0.4-0.3,0.9-0.4,1.3c-5.8-1.3-11.3-3.3-16.9-5.2
		C55.1,163.3,55.2,162.8,55.3,162.3z"/>
	<path d="M57.8,160.2c5.5,1.7,10.9,3.5,17.1,5.4c-2.8,1.2-15.8-2.8-17.3-4.7C57.6,160.7,57.7,160.5,57.8,160.2z"/>
</g>
</svg>);
export default iVESPA;