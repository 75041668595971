import React from 'react';
import Header from '../../components/Header';
import ContactBan from '../../components/ContactBan';
import Footer from '../../components/Footer';



function ReglementInterieur() {
    return (
        <React.Fragment>

            <Header />

            <div className='GpageMPORM'>
                <div className="widthMaxcont">
                    <h1 className='TCDGF'>Réglement intérieur</h1>

                    <div className='theMPORMw'>
                        <p className='titR'>Ce règlement a pour objet de définir les règles relatives à l'hygiène, à la sécurité ainsi qu'à la discipline nécessaire au bon fonctionnement de l'établissement. Il est applicable par l'ensemble des élèves.  </p>

                        <h2><b>Article 1 :</b></h2>
                        <p>L'auto-école <b>ZONE PERMIS</b> applique les règles d'enseignement selon les lois en vigueur, notamment l'arrêté ministériel relatif au référentiel pour l'éducation à une motricité citoyenne (REMC) en vigueur depuis le 01/07/2014.</p>

                        <h2>
                            <b>Article 2 :</b>
                        </h2>
                        <h3>Règles d'hygiène et de comportement</h3>

                        <p>
                            Tous les élèves inscrits dans l' établissement se doivent de respecter les conditions de fonctionnement de l'auto-école <b>ZONE PERMIS</b> sans restriction, à savoir :
                        </p>
                        <ul>
                            <li>respecter le personnel de l'établissement,</li>
                            <li>respecter les autres élèves sans discrimination,</li>
                            <li>avoir une hygiène, une tenue et un comportement correct,</li>

                            <li>ne pas fumer à l'intérieur de l'établissement, ni dans les véhicules,</li>
                            <li>ne pas consommer ou avoir consommé de toute boisson (alcool) ou substances (drogues) ou médicaments pouvant nuire à la conduite d'un véhicule. Il est interdit de diffuser sur les réseaux sociaux tout élément (photo ou texte) concernant l'auto-école <b>ZONE PERMIS</b>, ses formateurs ou les autres élèves.</li>
                        </ul>
                        <p><b>Il est demandé aux élèves de lire les informations mises à leur disposition sur les tableaux d'affichage.</b></p>

                        <h2><b>Article 3 :</b></h2>
                        <h3>Dossier administratif</h3>
                        <p>
                            L'élève s'engage à fournir tous les documents demandés pour l'inscription dans les meilleurs délais.<br />

                            Dans le cas contraire, tout retard de traitement du dossier ne pourra être reproché à l'auto école.<br />

                            Pour tout changement le concernant, l'élève doit en avertir le secrétariat (état civil, adresse, n° de téléphone, mail…)<br />

                            L'élève reste le propriétaire de son dossier. Le dossier sera restitué à l'élève (après le solde de tout compte) par demande écrite uniquement et sans frais supplémentaire.
                        </p>

                        <h2><b>Article 4 :</b></h2>
                        <h3>Organisation des séances de code et utilisation du matériel pédagogique</h3>

                        <p>L'accès à la salle de code n'est autorisé qu'après la constitution du dossier d'inscription et du versement d'un acompte. Le forfait code est dû à l'inscription et, est considéré comme débuté dès l'inscription.<br />

                            Un boitier de réponse est prêté à l'élève au début de chaque séance de code en salle. L'élève doit restituer ce boîtier à la fin de la séance.<br />

                            En cas de casse de celui-ci, l'élève se verra facturer le remplacement du boitier au tarif en vigueur chez le fournisseur au moment de la casse.<br />

                            Aucun élève n'est admis en salle de code après le démarrage de la séance.<br />

                            Aucune sortie n'est autorisée avant la fin de la séance de code.<br />

                            Une séance de code dure environ 1h.<br />
                            La participation des élèves à chacun de ces 5 cours d'une durée d'une heure est <b>OBLIGATOIRE</b>.                                    </p>
                        <ul>
                            <li>Alcool et stupéfiants,</li>
                            <li>Vitesse et réglementation,</li>
                            <li>Comportement,</li>

                            <li>Éco-conduite, éco-mobilité et prise de conscience des risques,</li>
                            <li>Accidents, les usagers vulnérables et partage de l'espace public.</li>
                        </ul>
                        <p>
                            Il est interdit d'utiliser des appareils sonores pendant les séances de code (Enceinte, téléphone…),<br />

                            Il est interdit de filmer les séances de code,<br />

                            Il est interdit de manger ou de boire dans la salle de code,<br />

                            Chaque élève est tenu de respecter le matériel et les locaux.
                        </p>


                        <h2><b>Article 5 :</b></h2>
                        <h3>Organisation des leçons de conduite</h3>

                        <p>
                            <b>L'évaluation de départ :</b></p>
                        <p>
                            Avant la signature du contrat « enseignement pratique » une évaluation de conduite est obligatoire. Cette évaluation a pour but d'estimer le nombre de leçons qui seront nécessaire au candidat pour atteindre le niveau de l'examen.<br />

                            Ce volume n'est pas définitif et dépendra de la motivation et de la régularité du candidat.
                        </p>


                        <p><b>Le livret d'apprentissage :</b></p>
                        <p>
                            A l'issue de l'évaluation, le livret d'apprentissage sera remis au candidat. L'élève devra obligatoirement être muni de ce livret d'apprentissage à chaque leçon de conduite (ainsi que de sa carte d'identité).<br />

                            En cas de perte du livret, l'élève doit avertir le secrétariat et devra s'acquitter des frais de duplicata du livret.
                        </p>

                        <p><b>Dépistage d'alcoolémie :</b></p>
                        <p>Tout élève peut  être soumis, avant sa leçon de conduite, à un dépistage d'alcoolémie réalisé par l'enseignant au moyen un éthylotest. En cas de test positif, ou de refus de se soumettre au dépistage, la leçon sera annulée et facturée à l'élève.</p>

                        <p><b>Réservation des leçons de conduite :</b></p>
                        <p>Les réservations des leçons de conduite se font au secrétariat pendant les horaires d'ouverture. Un planning papier est alors donné à l'élève. Le planning peut être modifié ou des leçons peuvent être annulées par l'auto-école (véhicule- école immobilisé, absence maladie d'un moniteur, planification des examens…). Il est possible de commencer la formation pratique avant l'obtention de l'examen du code. L'ordre de priorité pour la planification des leçons est le suivant :</p>
                        <ul>
                            <li>élève convoqué à l'examen pratique</li>
                            <li>élève ayant obtenu leur code</li>
                            <li>élève n'ayant pas obtenu leur code</li>
                        </ul>


                        <p><b>Annulation des leçons de conduite :</b></p>
                        <p>
                            Les leçons de conduite doivent être annulées au moins 48h à l'avance par téléphone ou mail <br />(06.67.83.96.77 / zonepermis@gmail.com).<br />

                            Aucune leçon ne peut être décommandée par message sur le répondeur.<br />

                            Toute leçon non décommandée au moins 48 heures à l'avance est due.
                        </p>

                        <p><b>Déroulement d'une leçon de conduite :</b></p>
                        <p>Une leçon se décompose comme suivant :</p>
                        <ul>
                            <li>Installation et détermination du ou des objectif(s)</li>
                            <li>Explication théorique </li>
                            <li>Mise en application pratique</li>
                            <li>Bilan et commentaires.</li>
                        </ul>
                        <p>
                            Toute leçon de conduite débute et se termine à l'auto-école.<br />

                            Lorsque un élève demande à ce que sa leçon débute ou finisse ailleurs qu'à l'auto-école, le temps de trajet du moniteur entre l'auto-école et le lieu du rendez-vous sera déduit du temps de conduite de l'élève.
                        </p>

                        <p><b>Retard :</b></p>
                        <p>
                            En cas de retard, sans nouvelle de l'élève, le moniteur ne l'attendra pas au-delà de 20 minutes. De plus, la leçon sera facturée.
                        </p>

                        <p><b>Tenue vestimentaire :</b></p>
                        <p>
                            Permis B : prévoir des chaussures plates (talons hauts, et tongs sont interdits)<br />

                            Permis A et AM : équipement obligatoire homologué : casque, gants, chaussures qui couvrent les chevilles.
                        </p>


                        <p><b>Examen pratique :</b></p>
                        <p>
                            Les places d'examen sont attribuées par la Préfecture à chaque Ecole de Conduite en fonction de ses ETP et de la disponibilité des examinateurs.<br />

                            Le résultat de l'examen sera disponible 48h après sur le site : permisdeconduire.gouv.fr (le numéro NEPH demandé est le numéro du livret d'apprentissage) ou sur notre site (zonepermis@gmail.com). En cas d'échec, le candidat sera placé en liste d'attente, gérée par ordre chronologique.
                        </p>


                        <h2><b>Article 6 :</b></h2>
                        <h3>Sanctions</h3>

                        <p>
                            Tout manquement de l'élève à l'une des dispositions du présent règlement intérieur pourra, en fonction de sa nature et de sa gravité, faire l'objet d'une sanction désignée ci-après :
                        </p>
                        <ul>
                            <li>Avertissement oral,</li>
                            <li>Avertissement écrit,</li>
                            <li>Suspension provisoire,</li>
                            <li>Exclusion définitive.</li>
                        </ul>

                        <p>Le responsable de l'établissement peut décider d'exclure un élève à tout moment de la formation pour l'un des motifs suivant :
                        </p>

                        <ul>
                            <li>Non-paiement,</li>
                            <li>Attitude empêchant la réalisation du travail de formation,</li>
                            <li>Évaluation par le responsable pédagogique de l'inaptitude de l'élève pour la formation concernée,</li>
                            <li>Non-respect du règlement intérieur.</li>
                        </ul>

                        <h2><b>Article 7 :</b></h2>
                        <h3>Consignes de sécurité</h3>

                        <p>
                            Pensez à prendre connaissance des consignes de sécurité auprès de la secrétaire et de nos enseignants.<br />

                            Les consignes d'incendie et notamment un plan de localisation des extincteurs et des issues de secours sont affichés dans les locaux de l'agence.<br />

                            En cas d'alerte, vous devez cesser toute activité et suivre dans le calme les instructions du représentant habilité ou des services de secours.<br />

                            Tout élève témoin d'un début d'incendie doit immédiatement alerter un représentant de l'agence et appeler les secours en composant le 18 à partir d'un téléphone fixe ou le 112 à partir d'un téléphone portable.
                        </p>

                        <p>
                            Quelques règles par ailleurs :
                        </p>
                        <ul>
                            <li>
                                Même si nos moniteurs sont là pour assurer votre sécurité, le respect des consignes de sécurité est primordial lors des heures de conduite (stupéfiant, alcool, usage du téléphone portable).
                            </li>
                            <li>
                                Ne démarrez aucun véhicule d'enseignement tant que votre moniteur ne vous a pas donné la consigne de le faire.
                            </li>
                            <li>
                                Évaluation par le responsable pédagogique de l'inaptitude de l'élève pour la formation concernée,
                            </li>
                            <li>
                                Éteignez votre Smartphone,
                            </li>
                            <li>
                                Emportez vos lunettes de vue, si vous êtes porteurs de verres correcteurs.
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

            <ContactBan />
            <Footer />

        </React.Fragment>
    );
}

export default ReglementInterieur;