import React from "react";
const iLIVREvoitureETG = ({size=24, color="#0056b3"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
width={size} height={size} 
viewBox="0 0 300 210"
fill={color} 
stroke="none"
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round">
<g>
	<path className="st0" d="M124.5,121.2c3.7,0,7.3,0,11.1,0c0,0,0-0.2,0-0.3c-0.1-0.9-0.3-1.9-0.4-2.8c-0.3-2.2-1.5-2.5-3-2.4
		c-1.9,0.1-3.9,0.5-5.8,0.6c-2,0.1-3.2,2.6-2.5,4.5C124,121,124.3,121.2,124.5,121.2z"/>
	<path className="st0" d="M154.1,122.9c-5.1-0.2-10.2-0.4-15.3-0.6c0,0.1,0,0.2,0,0.3c5.1,0.2,10.2,0.4,15.3,0.6
		C154.1,123.1,154.1,123,154.1,122.9z"/>
	<path className="st0" d="M141.2,121c0.1,0.3,0.5,0.6,0.8,0.6c3.3,0.2,6.6,0.3,9.8,0.4c0.3,0,0.6,0,0.7-0.2c0.5-0.7,0.9-1.4,1.4-2.2
		c-0.1-0.1-0.2-0.2-0.4-0.3c-0.5-0.3-1.1-0.6-1.6-0.9c-3.5-1.8-7.2-2.5-11.1-2.7c-1.4-0.1-1.6,0.3-1.1,1.6
		C140.2,118.5,140.7,119.8,141.2,121z"/>
	<path className="st0" d="M117.7,121.8c-3.1,0-6.2,0-9.3,0c0,0.1,0,0.1,0,0.2c3.1,0,6.2,0,9.3,0C117.7,122,117.7,121.9,117.7,121.8z"/>
	<path className="st0" d="M138.2,122.3c-6.6-0.1-13.3-0.3-19.9-0.4c0,0.1,0,0.2,0,0.3c6.6,0.1,13.3,0.3,19.9,0.4
		C138.2,122.5,138.2,122.4,138.2,122.3z"/>
	<path className="st0" d="M82.7,172.2c-0.3-0.7-0.4-1.5-0.6-2.5c-1.6,1.3-1.9,2.7-1.2,4.1c0.7,1.3,2.2,2,3.5,1.6
		c1.5-0.4,2.4-1.8,2.3-3.2c-0.1-1.5-1.1-2.5-2.6-2.6c0.1,0.8,0.4,1.7,0.4,2.5c0,0.4-0.5,0.7-0.7,1.1
		C83.3,172.8,82.8,172.6,82.7,172.2z"/>
	<path className="st0" d="M189.5,128.9c0.8-0.2,1.7-0.5,2.3,0.5c0,0,0.3,0,0.4-0.1c0-0.1,0-0.2,0-0.3c-1.5-1.3-3.4-0.8-5.3-1.1
		C187.8,129,188.3,129.2,189.5,128.9z"/>
	<path className="st0" d="M162.6,123.3c-1.1,0-2.3,0-3.4,0c0,0,0,0,0,0c1.1,0.1,2.3,0.2,3.4,0.2C162.6,123.5,162.6,123.4,162.6,123.3z"
		/>
	<path className="st0" d="M191.7,129.6c-0.9,0-1.7-0.2-2.3,0c-1.2,0.5-2,0.2-2.9-0.6c-0.4-0.4-1-0.7-1.4-1c0,0.1-0.1,0.2-0.1,0.2
		C186.7,130.7,188.9,131.2,191.7,129.6z"/>
	<path className="st0" d="M111.5,142.5c1.6,0.4,3.3,0.4,4.6-1c-0.8,0.1-1.7-0.5-2.2-1.7c-0.2-0.5-0.4-1-0.6-1.5
		c-0.3-0.7-0.1-1.2,0.6-1.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2-0.1-0.6,0.1-0.6c0.7-0.5,1.4-1,2.2-1.4
		c0.4-0.2,0.9-0.4,1.3-0.4c0.3,0,0.8,0.4,0.9,0.7c0.1,0.3-0.1,0.9-0.4,1.1c-1.2,0.9-2.7,0.7-4.1,0.7c0,0,0,0,0,0c0,0,0,0,0,0
		c0.3,0.4,0.6,0.7,0.9,1.1c0.5,0.6,1.1,1.2,1.6,1.8c0.5,0.6,0.5,1.2,0,1.8c3.4-2.3,3.4-7.8-0.1-9.7c0,0,0,0,0,0l0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0.3,0.4,0.6,0.7,0.9,1.2c-1.1,0.8-2.1,1.6-3.2,2.4c-0.1,0-0.1-0.1-0.2-0.1c0.4-1,0.7-2.1,1.2-3.1
		c0.1-0.3,0.8-0.2,1.2-0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.2-0.2c-2.2-1.3-4.4-1.3-6.5,0.1c-2,1.3-3.1,3.7-2.6,6.1
		C107.4,140.3,109,141.9,111.5,142.5z M117.8,139.5c-1.2-0.7-2.2-1.4-3.3-2c0-0.1,0.1-0.2,0.1-0.3c1.2,0.1,2.4,0.2,3.7,0.2
		C118.2,138.2,118,138.8,117.8,139.5z M108,139.4c-0.2-0.4-0.3-0.6-0.4-0.8c-0.4-1.3-0.3-1.4,1-1.4c0.8,0,1.6-0.1,2.5-0.1
		c0,0.1,0.1,0.2,0.1,0.3C110.2,138,109.1,138.7,108,139.4z M110.2,141.6c-0.4-0.3-0.8-0.6-1.2-0.9c0.3-2,2.4-2.3,3.3-3.6
		C113,138.1,111.9,140.4,110.2,141.6z M114.2,142.2c-0.6,0-1.4,0-2.1,0c-0.1,0-0.4-0.4-0.4-0.5c0.3-1.1,0.6-2.1,1.1-3.4
		C113.3,139.7,113.7,140.9,114.2,142.2z M113.7,131.3c0.5,1.4,0.1,2.7-0.4,4.1c0,0.2-0.3,0.3-0.5,0.3c-0.2,0-0.5-0.2-0.5-0.3
		c-0.2-1-0.5-2.1-0.5-3.1C111.8,131.2,112,131.1,113.7,131.3z M110.8,131.5c0.4,1.3,0.7,2.5,1,3.7c-0.1,0-0.1,0.1-0.2,0.1
		c-1-0.8-1.9-1.7-3-2.6C109.5,132.3,110.2,131.9,110.8,131.5z M108.6,134.3c1.2,0.5,2.4,1.2,3.7,2c-1.2,0.9-4.3,0.4-4.9-0.5
		c-0.1-0.1-0.1-0.3-0.1-0.5C107.4,134.5,107.8,133.9,108.6,134.3z"/>
	<path className="st0" d="M166.8,122.8c5.6,0.5,11.2,1.1,16.7,1.6C178.1,123.3,172.5,122.8,166.8,122.8z"/>
	<path className="st0" d="M182.2,134.6c0.1,1.3-0.3,1.8-1.7,2.1c-0.8,0.2-1.7,0.1-2.5,0.1c-0.2,0-0.3-0.2-0.5-0.3
		c0.1-0.1,0.2-0.3,0.3-0.4c0.6-0.4,1.3-0.9,1.9-1.3C180.5,134.3,181.4,134.1,182.2,134.6C182.2,134.6,182.2,134.6,182.2,134.6
		C182.2,134.6,182.3,134.6,182.2,134.6L182.2,134.6c0-0.2-0.1-0.5-0.2-0.7c-1.2-2.4-4.1-3.7-6.8-3.1c-2.7,0.7-4.7,3.2-4.6,6.1
		c0.1,2.8,2.1,5.2,4.8,5.7c1.6,0.3,3.1,0.2,4.4-1c-0.9,0.1-1.9-0.7-2.4-1.9c-0.3-0.7-0.5-1.3-0.8-2.3c-0.2,0.6-0.3,0.9-0.4,1.2
		c-0.5,0.9-1,1.7-1.5,2.5c-0.4,0.6-0.9,0.6-1.5,0.2c-0.7-0.5-0.4-1-0.1-1.4c0.5-0.6,1-1.1,1.6-1.6c0.2-0.2,0.5-0.3,0.7-0.5
		c0.9-0.8,1.7-0.8,2.7,0c0.6,0.5,1.2,0.9,1.6,1.5c0.6,0.7,1.1,1.5,0.1,2.4l0,0C182.2,140.3,183.3,137,182.2,134.6
		C182.2,134.6,182.2,134.6,182.2,134.6z M176.5,138.5c0.1,0,0.1,0,0.2-0.1c0.4,1.1,0.7,2.2,1.1,3.4c-0.7,0.6-1.6,0.6-2.3,0
		C175.8,140.7,176.2,139.6,176.5,138.5z M179.4,131.9c0.5,0.2,1.1,0.6,1.7,0.9c-1.2,0.9-2.2,1.7-3.2,2.5c-0.1-0.1-0.1-0.1-0.2-0.2
		c0.4-1,0.7-2.1,1.1-3.1C178.9,132,179.2,131.9,179.4,131.9z M174.7,131.5c0.3,1.3,0.7,2.5,1.1,4.1c-1.2-1-2.2-1.8-3.3-2.8
		C173.4,132.2,174.1,131.8,174.7,131.5z M171.8,139.4c-0.2-0.4-0.3-0.5-0.3-0.7c-0.5-1.3-0.3-1.5,1-1.5c0.8,0,1.6,0,2.5,0
		c0,0.1,0.1,0.1,0.1,0.2C174,138.1,172.9,138.7,171.8,139.4z M175.4,136.8c-1.3-0.2-2.5-0.4-3.7-0.8c-0.7-0.2-0.5-0.8-0.3-1.2
		c0.1-0.3,0.6-0.6,0.9-0.6c0.6,0.1,1.1,0.4,1.7,0.7c0.6,0.4,1.2,0.8,2,1.3C175.8,136.4,175.5,136.8,175.4,136.8z M176.7,135.7
		c-0.2,0-0.5-0.2-0.6-0.4c-0.2-0.8-0.4-1.5-0.5-2.3c-0.2-1.6,0.2-1.9,2-1.6c0.5,1.5-0.1,2.8-0.4,4.1
		C177.2,135.5,176.9,135.7,176.7,135.7z M181.6,139.5c-1.2-0.8-2.3-1.5-3.3-2.1c0-0.1,0.1-0.2,0.1-0.3c1.2,0.1,2.4,0.1,3.8,0.2
		C182,138.1,181.8,138.7,181.6,139.5z"/>
	<path className="st0" d="M82.7,79.7c-0.3-0.7-0.4-1.5-0.6-2.5c-1.6,1.4-1.9,2.9-1.1,4.2c0.8,1.3,2.3,1.8,3.7,1.3c1.4-0.5,2.2-1.9,2-3.3
		c-0.2-1.5-1.2-2.4-2.6-2.4c0.2,0.8,0.4,1.6,0.4,2.4c0,0.4-0.5,0.8-0.8,1.2C83.3,80.3,82.8,80.1,82.7,79.7z"/>
	<path className="st0" d="M82.7,95.1c-0.3-0.7-0.4-1.5-0.6-2.4c-1.6,1.4-1.9,2.9-1.1,4.2c0.7,1.3,2.3,1.8,3.7,1.3c1.4-0.5,2.2-1.9,2-3.4
		c-0.2-1.4-1.2-2.3-2.6-2.3c0.1,0.8,0.4,1.6,0.4,2.3c0,0.4-0.5,0.8-0.8,1.2C83.3,95.7,82.8,95.5,82.7,95.1z"/>
	<path className="st0" d="M82.7,125.9c-0.3-0.7-0.4-1.5-0.7-2.5c-1.6,1.3-1.9,2.8-1.2,4.1c0.7,1.3,2.3,1.9,3.7,1.5
		c1.5-0.5,2.3-1.9,2.1-3.5c-0.2-1.4-1.2-2.3-2.6-2.3c0.1,0.8,0.4,1.7,0.4,2.5c0,0.4-0.5,0.7-0.7,1.1
		C83.3,126.5,82.9,126.3,82.7,125.9z"/>
	<path className="st0" d="M82.7,110.4c-0.3-0.7-0.4-1.4-0.6-2.3c-1.6,1.3-1.9,2.7-1.2,4.1c0.7,1.3,2.2,2,3.6,1.5
		c1.4-0.4,2.3-1.8,2.1-3.3c-0.2-1.5-1.1-2.4-2.6-2.5c0.1,0.8,0.4,1.6,0.4,2.3c0,0.4-0.5,0.8-0.7,1.2
		C83.3,111.1,82.8,110.8,82.7,110.4z"/>
	<path className="st0" d="M82.7,156.7c-0.3-0.7-0.4-1.4-0.6-2.4c-1.5,1.3-1.9,2.8-1.2,4.1c0.7,1.4,2.2,2,3.6,1.5
		c1.4-0.4,2.3-1.7,2.1-3.2c-0.1-1.5-1.1-2.5-2.6-2.6c0.2,0.8,0.4,1.6,0.4,2.3c0,0.4-0.5,0.8-0.7,1.2
		C83.3,157.4,82.8,157.1,82.7,156.7z"/>
	<path className="st0" d="M82.7,141.4c-0.3-0.7-0.4-1.5-0.6-2.3c-1.5,1-1.9,2.4-1.3,3.8c0.6,1.3,2,2.1,3.4,1.8c1.5-0.3,2.5-1.6,2.5-3.1
		c0-1.5-1-2.6-2.6-2.9c0.2,0.8,0.4,1.7,0.4,2.5c0,0.4-0.5,0.8-0.8,1.2C83.3,142,82.8,141.8,82.7,141.4z"/>
	<path className="st0" d="M76.8,165.8c0,4.8,0,9.2,0,13.2c1.9,1.1,3.5,2.1,5.2,3c0.6,0.3,1.2,0.7,1.8,0.8c2.9,0.3,4.9,2.6,4.7,5.5
		c-0.2,2.8-2.8,4.8-5.6,4.4c-2.8-0.4-4.7-3-4.1-5.7c0.3-1.2,1.1-2.3,1.9-3.8c-1.1-0.5-2.3-1.2-3.8-1.9c0,5.8,0,11.3,0,16.7
		c40.2,0,80.4,0,120.6,0c0-47.7,0-95.3,0-142.9c-5.4,0-10.7,0-16,0l0.6-3.3c0.4,0,0.7,0,0.7-0.1c0.1-0.3,0.5-2.6,0.7-3.7
		c0.2-0.1,0.5-0.2,0.7-0.4l14.1-10.1c1.5-1.1,1.9-3.2,0.8-4.8l-10.1-14.1c-1.1-1.5-3.2-1.9-4.8-0.8L169.9,28
		c-1.5,1.1-1.9,3.2-0.8,4.8l10.1,14.1c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,1.2-0.4,4-0.3,4c0.1,0.1,0.3,0.1,0.6,0.2l-0.6,3.7
		c-8.2,0-16.3,0-24.5,0l1.9-22.4c0.4,0,0.7,0,0.7-0.2c0-0.3,0.2-2,0.3-3.2c6.9-0.4,12.7-5.8,13.3-12.9c0.6-7.8-5.1-14.6-12.9-15.2
		c-7.8-0.6-14.6,5.1-15.2,12.9c-0.6,7.1,4.2,13.3,10.9,14.9c0,1.3-0.1,3.2,0,3.3c0.1,0.1,0.3,0.1,0.6,0.2l-2,22.7
		c-8,0-16.1,0-24.1,0l-2-22c0.4-0.1,0.6-0.1,0.6-0.3c0-0.4-0.3-3.3-0.3-4l4.1-0.4c0.9-0.1,1.7-0.6,2-1.4l5.4-11.8
		c0.4-0.8,0.3-1.7-0.2-2.5l-7.5-10.5c-0.5-0.7-1.4-1.1-2.3-1L115,2.4c-0.9,0.1-1.7,0.6-2,1.4l-5.4,11.8c-0.4,0.8-0.3,1.7,0.2,2.5
		l7.5,10.5c0.5,0.7,1.4,1.1,2.3,1l5.1-0.5c0.1,0.8,0.6,3.9,0.7,4c0.1,0.1,0.3,0,0.6,0l2,21.9c-8.3,0-16.5,0-24.8,0l-1-4.3
		c0.4-0.1,0.6-0.2,0.6-0.4c0-0.3-0.4-1.7-0.6-2.9c1-0.3,1.8-0.6,2.3-0.8c2.6-0.8,2.1-2.5,2.1-2.5s-6.4-30.9-7.3-32.7
		c-0.3-0.6-1.3-0.8-2.6-0.6c-0.4-0.8-1.5-2.5-2.7-2.4c-1.7,0.1-6,1.4-6.5,2.2c-0.3,0.5-0.4,1.8-0.5,2.8c-2.4,1-2,2.2-2,2.2l7.5,32.1
		c0.3,2,3.1,1.7,6,1c0.4,1.2,0.8,2.5,0.9,2.6c0.1,0,0.3,0,0.6,0l0.9,3.9c-7.3,0-14.6,0-21.9,0c-0.4,0.9,0.1,1.1,0.8,1.4
		c1.5,0.6,2.8,1.5,4.2,2.2c0.6,0.3,1.3,0.8,1.9,0.8c2.3,0.3,4.2,1.9,4.6,4.1c0.4,2.4-0.8,4.6-3,5.5c-2.2,0.9-4.6,0.2-6-1.8
		c-1.3-1.9-1.1-4.5,0.5-6.2c0.3-0.3,0.7-0.4,1-0.7c0-0.2-0.1-0.3-0.1-0.5c-1.3-0.6-2.6-1.3-4.1-2.1c0,4.7,0,8.9,0,13.1
		c0,0.3,0.6,0.6,0.9,0.8c1.4,0.8,2.8,1.5,4.2,2.2c0.6,0.3,1.3,0.8,1.9,0.9c2.4,0.3,4.3,1.9,4.6,4.2c0.3,2.4-0.9,4.5-3.1,5.4
		c-2.2,0.9-4.6,0.1-5.9-1.9c-1.3-1.9-1-4.5,0.6-6.2c0.2-0.2,0.6-0.4,0.9-0.6c0-0.2,0-0.3,0-0.5c-1.3-0.6-2.6-1.3-4.1-2.1
		c0,4.7,0,8.9,0,13.1c0,0.3,0.5,0.6,0.9,0.8c1.4,0.8,2.9,1.5,4.3,2.4c0.6,0.5,1.2,0.8,2,0.8c2.7,0.1,4.7,2.4,4.6,5.1
		c-0.1,2.7-2.3,4.7-5.1,4.7c-2.7-0.1-4.7-2.2-4.8-4.8c0-1.8,0.8-3.1,2.4-4.5c-1.5-0.8-2.8-1.4-4.4-2.2c0,4.7,0,8.9,0,13.1
		c0,0.3,0.5,0.7,0.9,0.9c1.4,0.8,2.9,1.4,4.2,2.3c0.7,0.5,1.3,0.9,2.2,0.9c2.6,0.1,4.6,2.4,4.5,5.1c-0.1,2.7-2.3,4.8-5,4.7
		c-2.7-0.1-4.8-2.2-4.8-4.8c0-1.8,0.8-3.2,2.2-4.3c-1.2-1.4-2.5-2.1-4-2c0,4.4,0,8.8,0,12.7c1.9,1.2,3.6,2.1,5.2,3.1
		c0.6,0.3,1.2,0.7,1.8,0.8c2.7,0.2,4.7,2.4,4.7,5.1c-0.1,2.7-2.4,4.9-5.1,4.8c-2.7-0.1-4.8-2.3-4.7-5c0-1.8,0.9-3,2.4-4.3
		c-1.5-0.8-2.8-1.4-4.4-2.2c0,4.8,0,9.1,0,13.4c0,0.2,0.5,0.5,0.9,0.7c1.4,0.8,2.9,1.4,4.2,2.3c0.7,0.5,1.3,0.9,2.2,0.9
		c2.6,0.1,4.5,2.4,4.4,5c-0.1,2.7-2.1,4.8-4.8,4.8c-2.6,0.1-4.8-1.9-5-4.4c-0.2-2,0.6-3.5,2.4-4.9c-1.5-0.8-2.8-1.4-4.3-2.2
		c0,4.9,0,9.3,0,13.2c1.9,1.2,3.6,2.2,5.3,3.1c0.5,0.3,1.1,0.7,1.7,0.7c2.3,0.2,4.2,1.8,4.6,4c0.4,2.4-0.8,4.7-3,5.6
		c-2.2,0.9-4.6,0.1-5.9-1.8c-1.3-1.9-1.1-4.5,0.5-6.2c0.3-0.3,0.5-0.5,1-1c-1.4-0.7-2.7-1.4-4.2-2.1c0,4.8,0,9.3,0,13.3
		c1.8,1,3.5,1.8,5,2.9c0.7,0.5,1.3,0.9,2.2,1c2.3,0.1,4.1,1.9,4.5,4.1c0.3,2.4-0.9,4.5-3.1,5.4c-2.1,0.8-4.5,0.1-5.8-1.8
		c-1.3-1.9-1.1-4.5,0.5-6.2c0.3-0.3,0.7-0.4,1-0.7c0-0.2-0.1-0.3-0.1-0.5C79.6,167.2,78.3,166.6,76.8,165.8z M171.2,32.8
		c-0.9-1.3-0.6-3.2,0.7-4.1l12.2-8.7c1.3-0.9,3.2-0.6,4.1,0.7l8.7,12.2c0.9,1.3,0.6,3.2-0.7,4.1L184,45.6c-1.3,0.9-3.2,0.6-4.1-0.7
		L171.2,32.8z M147.3,23.2c-2.1-2.4-3.2-5.6-2.9-9c0.3-3.4,1.9-6.4,4.3-8.4c2.4-2.1,5.6-3.2,9-2.9c3.4,0.3,6.4,1.9,8.4,4.3
		c2.1,2.4,3.2,5.6,2.9,9c-0.3,3.4-1.9,6.4-4.3,8.4c-2.4,2.1-5.6,3.2-9,2.9C152.3,27.3,149.4,25.7,147.3,23.2z M118,28.5
		c-0.8,0.1-1.6-0.3-2.1-1l-6.9-9.7c-0.5-0.7-0.6-1.5-0.2-2.3l4.9-10.8c0.4-0.8,1-1.3,1.9-1.3l11.9-1.1c0.8-0.1,1.6,0.3,2.1,1
		l6.9,9.7c0.5,0.7,0.6,1.5,0.2,2.3l-4.9,10.8c-0.4,0.8-1,1.3-1.9,1.3L118,28.5z M87,19.6c-0.6-2.4,0.9-4.9,3.4-5.4
		c2.4-0.6,4.9,0.9,5.4,3.4c0.6,2.4-0.9,4.9-3.4,5.4C90.1,23.6,87.6,22.1,87,19.6z M89.6,30.7c-0.6-2.4,0.9-4.9,3.4-5.4
		c2.4-0.6,4.9,0.9,5.4,3.4c0.6,2.4-0.9,4.9-3.4,5.4C92.6,34.6,90.2,33.1,89.6,30.7z M92.2,41.7c-0.6-2.4,0.9-4.9,3.4-5.4
		c2.4-0.6,4.9,0.9,5.4,3.4c0.6,2.4-0.9,4.9-3.4,5.4C95.2,45.7,92.8,44.2,92.2,41.7z M123.3,113.4c-0.7,0.2-1.1,0.3-1.7,0.4
		c-0.1-0.5-0.1-0.9-0.2-1.3C122,112.8,122.5,113,123.3,113.4z M92.3,130.3c0.6-0.5,1.5-2.6,1.3-3.4c0-0.1,0-0.1-0.1-0.2
		c-1.4-1.2-0.6-2.4,0-3.6c0.2-0.5,0.4-1,0.6-1.6c0.5-0.1,1.1-0.1,1.7-0.2c0,0,0,0,0-0.1c-0.4,0-0.8-0.1-1.5-0.1
		c0.4-0.2,0.5-0.3,0.7-0.3c2.6-0.2,5.1-0.4,7.7-0.7c2.2-0.3,4.4-0.6,6.5-1.6c2.5-1.2,5.1-2,8-3.1c-0.8,0.1-1.3,0.1-1.8,0.1
		c0-0.1-0.1-0.2-0.1-0.3c0.8-0.2,1.5-0.6,2.3-0.7c1.6-0.3,3.2-0.4,4.8-0.6c8.3-1.2,16.6-1.5,24.8,0.1c1,0.2,2,0.5,2,0.5
		c2.8,0.8,4.7,1.8,5.3,2.1c0.1,0.1,0.8,0.5,1.7,1c2.6,1.7,3.9,2.5,5.5,3.5c0.3,0.2,0.7,0.2,1.1,0.3c0.7,0.1,1.4,0.3,2.2,0.4
		c0.4,0.1,0.8,0.2,1.2,0.2c6.4,0.3,12.7,0.8,18.9,2.4c1.8,0.5,3.6,1.2,5.3,1.9c2,0.9,2.9,2.5,3.5,4.7c0.5,2,0.3,3.9,0.2,5.8
		c0,0.1,0,0.3,0,0.7c0,0.4,0,1-0.4,1.6c-0.1,0.2-0.4,0.5-0.8,0.8c-3,0-6.1,0-9.1,0c-0.2,0.4-0.6,0.9-1.1,1.5
		c-0.3,0.3-2.4,2.7-5.5,2.9c-2.9,0.2-5.9-1.5-7.6-4.3l-50.1,0.1c-1.5,2.7-4.2,4.3-7.1,4.1c-2.2-0.1-4.6-1.4-5.7-3.4
		c-0.3-0.5-0.5-0.9-0.6-1.2c-0.2-0.1-0.6-0.2-1.1-0.4c-3.8-1.2-7.7-1.1-10.5-1.5c-0.9-0.1-1.4-0.6-2.1-1c-0.2-0.1-0.4-0.3-0.4-0.5
		c-0.3-1.5-0.6-3.1-0.7-4.6C91.4,131.2,91.9,130.6,92.3,130.3z"/>
	<path className="st0" d="M102.6,120.7c0-0.1,0-0.1,0-0.2c-1.6,0.2-3.2,0.3-4.8,0.5c0,0.1,0,0.1,0,0.2C99.4,121,101,120.9,102.6,120.7z"
		/>
	<path className="st0" d="M246.9,54.3c0.8,0.7,1.4,1.3,2,1.9c0.2-0.8,0.5-1.6,0.8-2.6C248.7,53.8,247.9,54,246.9,54.3z"/>
	<path className="st0" d="M244.1,55c-2.9,0.8-5.9,1.6-9,2.4c3.4,3.5,6.6,6.8,10,10.3c1-3.1,1.9-5.9,2.8-8.8
		C248.3,58.1,245.1,54.8,244.1,55z"/>
	<path className="st0" d="M82.7,187.5c-0.3-0.7-0.4-1.5-0.6-2.3c-1.5,1.2-1.9,2.6-1.2,4c0.7,1.3,2.2,2,3.6,1.6c1.4-0.4,2.4-1.8,2.2-3.3
		c-0.1-1.5-1.1-2.5-2.6-2.6c0.1,0.8,0.4,1.6,0.4,2.3c0,0.4-0.4,0.8-0.7,1.2C83.4,188.2,82.8,187.9,82.7,187.5z"/>
	<path className="st0" d="M237.4,68.9c-10.7,10.3-21.4,20.7-32,31c0-0.9,0-1.7,0-2.6c11.3-11,22.6-21.9,33.9-32.8
		c-0.4-0.4-0.7-0.8-1.1-1.1c-0.4,0.3-0.6,0.5-0.9,0.8c-10.7,10.3-21.3,20.6-32,30.9c0-0.9,0-1.7,0-2.6c10.6-10.3,21-20.3,31.5-30.4
		c-1.3-1.2-2.5-2.3-3.8-3.5c0,0-0.1,0.1-0.3,0.2c-9.2,8.9-18.3,17.7-27.5,26.6c0-10.1,0-20.2,0-30.2c-2,0-3.9,0-6,0c0,0.5,0,1,0,1.4
		c0,47.1,0,94.3,0,141.4c0,2-0.1,2.1-2,2.1c-39.6,0-79.1,0-118.7,0c-0.7,0-1.3,0-2,0c0,2.4,0,4.7,0,6.9c42.9,0,85.8,0,128.6,0
		c0-33.3,0-66.5,0-99.8c13.1-12.6,25.9-25,38.9-37.6c-1.3-1.2-2.5-2.4-4.1-3.8C239.3,66.7,238.5,67.9,237.4,68.9z"/>
	<path className="st0" d="M82.6,64.1c-0.3-0.7-0.4-1.4-0.6-2.2c-1.5,1.1-1.9,2.6-1.2,4c0.7,1.3,2.2,2,3.6,1.6c1.4-0.4,2.4-1.8,2.2-3.3
		c-0.1-1.5-1.1-2.5-2.6-2.6c0.1,0.7,0.4,1.5,0.4,2.2c0,0.5-0.4,0.9-0.7,1.4C83.3,64.9,82.8,64.6,82.6,64.1z"/>
	<path className="st0" d="M184,45.2l11.7-8.4c1.3-0.9,1.6-2.7,0.7-4L188,21.2c-0.9-1.3-2.7-1.6-4-0.7l-11.7,8.4c-1.3,0.9-1.6,2.7-0.7,4
		l8.4,11.7C180.9,45.8,182.7,46.1,184,45.2z M178.8,32.8c0.3-3,3.5-2.9,4.3-3c0.8-0.2,0.7-1.5,0.7-1.5l-1.8-0.3l3.9-4.8l2.2,6
		l-1.8-0.3c0,0,0,0.2-0.4,1.9c-0.3,1.6-3.2,1.2-4,1.7c-0.8,0.5-0.4,2,1.5,2.2c1.9,0.2,5,0.6,4.8,2.8c-0.2,2.2-1.4,3.4-3.3,3.1
		c-2.5-0.3-2.1,1.8-2.1,1.8l-2.4-0.3c0.3-3,1.2-3.8,3.5-3.4c0.8,0.1,1.4-0.2,1.5-0.7c0.1-0.8-0.7-1.3-3-1.5
		C181.6,36.5,178.4,35.8,178.8,32.8z"/>
	<path className="st0" d="M168.5,16.2c0.3-3.2-0.8-6.3-2.8-8.6c-2-2.3-4.8-3.9-8-4.1c-6.5-0.5-12.2,4.3-12.7,10.8
		c-0.3,3.2,0.8,6.3,2.8,8.6c2,2.3,4.8,3.9,8,4.1C162.3,27.5,168,22.7,168.5,16.2z M154.5,22.2c0,0-3.6,0.4-3.3-0.3
		c0,0-0.4-11.5,1.8-11.8c2.2-0.3,8.9,0.3,8.9,0.3l0.1-1.3c0.1-0.8,4.2,3.4,4.4,3.7c0,0-4.4,2.2-4.9,2.2c-0.5,0-0.1-1.3-0.1-1.3
		s-6.2-0.5-6.4,0.2C154.9,14.5,154.5,22.2,154.5,22.2z"/>
	<path className="st0" d="M124.6,11.9c-0.5,0-0.7,0.4-0.6,1l0.5,4.9c0.1,0.6,0.3,0.9,0.8,0.8s0.7-0.4,0.6-1l-0.5-4.9
		C125.4,12.1,125.1,11.8,124.6,11.9z"/>
	<path className="st0" d="M130.7,13.3l-0.1-1.1c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.1l-0.7,0.1l0.3,2.7l0.7-0.1
		c0.2,0,0.4-0.1,0.5-0.2C130.6,13.9,130.7,13.6,130.7,13.3z"/>
	<path className="st0" d="M131.4,25.6l4.8-10.4c0.3-0.7,0.3-1.5-0.2-2.2l-6.6-9.3c-0.5-0.7-1.2-1-2-0.9l-11.4,1.1
		c-0.8,0.1-1.5,0.5-1.8,1.3l-4.8,10.4c-0.3,0.7-0.3,1.5,0.2,2.2l6.6,9.3c0.5,0.7,1.2,1,2,0.9l11.4-1.1
		C130.4,26.8,131,26.3,131.4,25.6z M117.3,20c-0.3,0.4-0.8,0.7-1.5,0.7c-0.7,0.1-1.2-0.1-1.6-0.4c-0.4-0.4-0.6-0.9-0.7-1.6l0-0.5
		l1.3-0.1l0.1,0.6c0.1,0.6,0.3,0.8,0.8,0.8c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.1-0.4,0.1-0.7c0-0.4-0.2-0.7-0.4-1
		c-0.2-0.3-0.5-0.6-1-0.9c-0.6-0.5-1.1-0.9-1.3-1.3s-0.4-0.8-0.5-1.4c-0.1-0.7,0.1-1.3,0.4-1.7c0.3-0.4,0.8-0.7,1.5-0.7
		c0.7-0.1,1.2,0.1,1.6,0.4c0.4,0.4,0.6,0.9,0.7,1.6l0,0.4l-1.3,0.1l0-0.5c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.5-0.2
		c-0.5,0-0.7,0.4-0.6,0.9c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.6,0.6,1.1,0.9c0.6,0.5,1.1,0.9,1.3,1.3c0.3,0.4,0.4,0.9,0.5,1.4
		C117.8,19,117.7,19.5,117.3,20z M121.2,20.1l-1.4,0.1l-0.7-7.7l-1.5,0.1l-0.1-1.3l4.3-0.4l0.1,1.3l-1.5,0.1L121.2,20.1z
		 M126.9,19.1c-0.3,0.4-0.8,0.7-1.5,0.7c-0.7,0.1-1.2-0.1-1.6-0.4c-0.4-0.4-0.6-0.9-0.7-1.6l-0.5-4.7c-0.1-0.7,0.1-1.3,0.4-1.7
		c0.3-0.4,0.8-0.7,1.5-0.7c0.7-0.1,1.2,0.1,1.6,0.4c0.4,0.4,0.6,0.9,0.7,1.6l0.5,4.7C127.4,18.1,127.2,18.6,126.9,19.1z M129.8,19.2
		l-1.4,0.1l-0.9-8.9l2.1-0.2c0.7-0.1,1.2,0.1,1.6,0.4c0.4,0.3,0.6,0.9,0.7,1.6l0.1,0.9c0.1,0.7-0.1,1.3-0.4,1.7
		c-0.3,0.4-0.8,0.6-1.5,0.7l-0.7,0.1L129.8,19.2z"/>
</g>


</svg>);
export default iLIVREvoitureETG;