import React from "react";
const iAAC = ({size=24, color="#0056b3"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
width={size} height={size} 
viewBox="0 0 300 259" 
fill={color} 
stroke="none"
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round">
    <path d="M1.5,223.2c0.3-10.1-0.2-20.3,0.9-30.3c2.9-25.9,15-46.5,37.1-60.7c4.7-3,9.9-5.4,14.8-8.2c2.7-1.5,3-3.2,0.7-5.4
		c-1.8-1.7-3.8-3.1-5.8-4.6c-11.4-8.2-20-18.5-23.9-32.2c-3.9-13.7-2.6-27.2,2.1-40.3c7.5-21,22.8-33,44-38
		c15.5-3.7,30.5-2.6,44.4,5.8c17.5,10.7,28.9,25.8,31.6,46.5c2.7,21.3-4.5,39.3-20.2,53.9c-3,2.8-6.5,5.1-9.9,7.6
		c-4.6,3.4-4.5,5.4,0.8,7.7c7.3,3.2,14.3,6.8,20.5,11.8c8.6,6.8,15.3,15.2,20.3,25c1,1.9,2.1,3.7,3.4,5.4c1.4,1.7,3,1.7,4.5,0.1
		c1.2-1.2,2.3-2.5,3.3-3.8c6.3-8.7,14.2-15.4,24.1-19.7c1.5-0.6,2.9-1.5,4.2-2.4c1.7-1.2,2-2.8,0.6-4.4c-1.3-1.5-2.7-2.9-4.3-4.2
		c-11.9-9.3-19.4-21.2-20.6-36.3c-1.8-22,6.8-39.4,25.9-50.5c18.8-11,37.9-10,56.2,1.8c27.7,17.8,33.1,61.6,2.9,84.6
		c-1.5,1.1-2.9,2.4-4.1,3.8c-1.7,1.9-1.5,3.4,0.4,5c1.5,1.2,3.1,2.3,4.8,3.2c12.7,6.9,23.1,16,29.9,28.9c5.2,9.9,7.8,20.5,8,31.6
		c0.3,15.6,0.1,31.2,0.1,46.7c0,3.6-1.1,5.1-3.6,5.3c-2.3,0.2-4-0.8-4.6-3c-0.3-1.2-0.5-2.6-0.5-3.8c-0.1-14.1,0-28.2-0.2-42.3
		c-0.1-13-3.4-25-10.8-35.8c-10-14.5-23.9-23.1-41.1-26.5c-30.9-6.2-62,13.4-71.4,43.5c-2.4,7.8-2.7,16-2.8,24.1
		c-0.2,12.8-0.2,25.6-0.3,38.4c0,3.8-1.4,5.5-4.1,5.5c-2.7,0-4.2-1.7-4.2-5.4c-0.2-14.7-0.4-29.5-0.3-44.2c0-4.4,1-8.8,1.9-13.1
		c1.7-8.1,0.9-15.9-2.7-23.3c-12.2-25.1-32-40.2-59.9-43.3c-25.1-2.7-46.9,4.7-64,23.8c-12.7,14.1-19.2,30.8-19.4,49.8
		c-0.2,15.4,0,30.9-0.1,46.3c0,1.8-0.1,3.6-0.3,5.4c-0.4,2.7-1.8,3.9-4.4,3.9c-2.5,0-3.7-1.1-3.9-3.8c-0.3-3.6-0.2-7.1-0.2-10.7
		c0-6.4,0-12.9,0-19.3C1.6,223.2,1.5,223.2,1.5,223.2z M139.1,63.8C139,34.1,114.8,10.3,85,10.5C55.6,10.7,31.8,35,32,64.7
		c0.2,29.4,25.1,53.2,55.4,52.1C115.9,115.9,139.4,92.8,139.1,63.8z M227,47.9c-25,0-43.9,18.4-43.9,42.8
		c0,24.9,19.2,44.5,43.7,44.4c24,0,43.9-19.8,43.9-43.7C270.8,66.8,251.8,47.9,227,47.9z"/>
	<path d="M178.1,226.4c0.3-4.8,0.3-9,0.9-13.1c1.2-8.4,5.6-15,12.3-20c5.6-4.2,11.9-7,18.3-9.9c10.4-4.7,20.8-3.7,31.2,0.1
		c7.1,2.6,14.1,5.5,21.1,8.4c2.2,0.9,4.4,2.2,6.4,3.7c6.4,4.8,8.6,11.4,7.6,19.2c-0.5,3.5-0.6,6.9-0.8,10.4
		c-0.2,4.7,0.1,9.5-0.5,14.2c-0.3,2.6-1.9,5.2-3.1,7.7c-0.7,1.4-2.1,1.9-3.7,1.4c-1.6-0.5-2.8-1.6-2.5-3.4c0.3-2.4,1.1-4.7,1.4-7
		c0.5-4.8,1.1-9.7,0.9-14.5c-0.1-2.5-1.3-5.3-2.9-7.2c-6.3-7.4-12.9-14.6-19.5-21.7c-4.4-4.7-10.1-7-16.6-6.6
		c-4.5,0.3-9.1,0.4-13.5,1.1c-4.6,0.8-8.4,3.4-11,7.4c-3.5,5.6-6.8,11.3-10.3,16.9c-1.7,2.7-3.4,5.6-5.6,7.9c-3.1,3.3-3.6,7-2.7,11
		c0.7,3.2,1.8,6.3,2.7,9.5c0.3,1,0.5,2,0.5,3c0,1.9-1,3-2.8,3.5c-1.8,0.5-3.3,0-4-1.6c-1.2-3.1-2.3-6.2-2.9-9.4
		C178.3,233.7,178.3,229.7,178.1,226.4z"/>
	<path d="M227.2,125c-9.7-0.3-17.5-4.2-24-10.7c-0.7-0.6-1.3-1.6-1.4-2.5c-0.1-0.9,0.4-2.1,1.1-2.6c0.6-0.5,1.9-0.6,2.6-0.3
		c1.1,0.4,2,1.3,3,2.1c5.5,4.6,11.6,7.9,19,7.7c6.3-0.2,11.6-2.9,16.5-6.7c1.2-0.9,2.3-2.1,3.6-2.9c0.8-0.5,2.3-0.8,2.7-0.4
		c0.8,0.7,1.3,2,1.3,3.1c0,0.9-0.7,2-1.4,2.6C243.7,120.9,236.1,124.8,227.2,125z"/>
</svg>);
export default iAAC;