import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import logoMini from '../assets/logo/LOGO-ZPmini.png';

const Header = () => {
    const location = useLocation();

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const scrollToDiv = () => {
        const element = document.getElementById('zbr');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='lay-header'>
            <header className='widthMaxcont'>
                <Link to="/">
                    <img
                        className='logoSOCIETE'
                        src={logoMini}
                        alt="logo zone permis mini"
                    />

                </Link>

                <div className={`menu-button ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
                <nav className={`thenavH ${menuOpen ? 'open' : ''}`}>
                    <ul>
                        <li>
                            <Link to="/" onClick={toggleMenu}>
                                ACCUEIL
                            </Link>
                        </li>
                        <li>
                            <Link to="/nos-formules" onClick={toggleMenu}>
                                FORMATIONS
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/nos-formules" onClick={toggleMenu}>
                                F.A.Q.
                            </Link>
                        </li> */}
                        <li onClick={() => {
                            scrollToDiv();
                            toggleMenu();
                        }


                        } className='contaLI'>NOUS CONTACTER</li>
                    </ul>
                </nav>
            </header>
        </div>
    );


};

export default Header;