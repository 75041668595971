import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { useInView } from 'react-intersection-observer';


const tabGalCRSL = [
    {
        "img": "https://moto-station.com/wp-content/uploads/2019/11/03/Yamaha-TMAX-560-TECH-2020-29.jpg",
        "description": "<p className='spaceTp'>Passez votre permis sur le T-MAX 560 !</p> <p className='spaceTp'>Inscrivez-vous dès maintenant pour votre formation <b>permis A2 automatique</b> et soyez parmi les premiers à en profiter.</p><p className='spaceTp'>Plongez dans l’aventure de la conduite sur deux roues avec le meilleur équipement possible et préparez-vous à réussir votre permis en toute confiance.</p>",
        "cnt": "TMAX",
        "titlebtn": "NOUVEAU"
    },
    {
        "img": "https://www.vialearnmoto.fr/wp-content/uploads/2023/05/Plateau_viaprolearn-1024x440.png",
        "description": "<p className='spaceTp'>Sa conduite agile vous promettent une expérience de conduite inoubliable.</p><p className='spaceTp'>Que ce soit pour la ville ou la route, la CB500F est prête à vous suivre dans toutes vos aventures.</p>",
        "cnt": "PERMIS MOTO CB500F",
        "titlebtn": "MOTO"
    },
    {
        "img": "https://www.easymonneret.com/media/cache/sylius_shop_product_large_thumbnail/01/79/8f4955cf2f62be50a154e1612288.jpeg",
        "description": "<p className='spaceTp'>Vous avez déjà <b>2 ans</b> de permis B ?</p><p className='spaceTp'>Il est temps de découvrir de nouvelles sensations sur deux roues !</p><p className='spaceTp'>Avec notre formation 125cc, en seulement 7 heures, vous serez prêt à prendre le guidon et à profiter de la liberté de la route.</p>",
        "cnt": "FORMATION 125",
        "titlebtn": "F125"
    },
    {
        "img": "https://media.istockphoto.com/id/1390714417/fr/photo/adolescente-prenant-une-le%C3%A7on-de-conduite-avec-une-instructrice.jpg?s=612x612&w=0&k=20&c=eGBzC3iIHfv_TR6I7aFl05dKBCB7qHso3A-_3YJu5Oc=",
        "description": "<p className='spaceTp'>Nous nous engageons à vous offrir une formation complète et moderne, en mettant l'accent sur la sécurité et la responsabilité au volant.</p><p className='spaceTp'>Restez informés de nos actualités et des nouveautés pour une expérience d'apprentissage toujours plus enrichissante !</p>",
        "cnt": "ACTUALITES",
        "titlebtn": "ACTUALITES"
    },
    {
        "img": "https://www.permis-bateau.com/wp-content/uploads/2016/05/passer-le-permis-hauturier.jpg",
        "description": `<p className='spaceTp'>Le grand moment approche !</> <p className='spaceTp'>Nous sommes ravis de vous annoncer que le permis bateau sera bientôt disponible ! </p> <p className='spaceTp'>Préparez-vous à plonger dans l’aventure maritime et à maîtriser les techniques de navigation avec nous.</p><p className='spaceTp'>Restez connectés pour les premières dates !</p>`,
        "cnt": "PERMIS BATEAU",
        "titlebtn": "PROCHAINEMENT"
    }

];

const CompPresentation = () => {

    const [eleNumber, setEleNumber] = useState(0);
    const [count, setCount] = useState(100);
    // const [maxHeight, setMaxHeight] = useState(0);
    // const containerRef = useRef(null);

    const { ref, inView } = useInView({
        triggerOnce: false, // L'animation s'active et se désactive en fonction de la visibilité
        threshold: 0.1, // Le pourcentage de visibilité avant de déclencher l'animation
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => {
                if (prevCount <= 0) {
                    setEleNumber(prevEleNumber => (prevEleNumber + 1) % tabGalCRSL.length);
                    return 100;
                }
                return prevCount - 1;
            });
        }, 150);

        // const updateHeight = () => {
        //     if (containerRef.current) {
        //         const spans = containerRef.current.querySelectorAll('.desIMG');
        //         const heights = Array.from(spans).map(span => span.getBoundingClientRect().height);
        //         setMaxHeight(Math.max(...heights));
        //     }
        // };

        // // Mettre à jour la hauteur au chargement du composant et lorsque les données changent
        // updateHeight();

        // // Ajouter un écouteur d'événements pour recalculer la hauteur lors du redimensionnement de la fenêtre
        // window.addEventListener('resize', updateHeight);


        // Cleanup the interval on component unmount
        return () => {
            clearInterval(interval);
            // window.removeEventListener('resize', updateHeight);
        };
    }, []);





    return (
        <div className='lay-presentation'>
            <div className='thegalcsrsl widthMaxcont'>
                <div className='cutDCSL'>
                    <div className='dCSL'>
                        {tabGalCRSL.map((elePi, index) =>
                            <div
                                key={index}
                                className={index === eleNumber ? 'widMaxIMG ef' : 'widMaxIMG'}
                                style={{ zIndex: index === eleNumber ? 9 : 1 }}
                            >
                                <img
                                    className='carslIMG'
                                    src={elePi.img}
                                    alt={elePi.cnt}
                                />
                            </div>
                        )}
                    </div>
                    <div className='shadowbottom'></div>
                </div>


                <div className='infroDcrsl'>
                    <h2 className='slog'>Votre réussite, notre priorité !</h2>

                    <div className='infCarslDes'>
                        <div
                            // ref={containerRef}
                            // style={{ height: `${maxHeight}px` }}
                            className='THEdesLOO'>
                            {tabGalCRSL.map((elePi, index) =>
                                <span
                                    style={{ transform: index === eleNumber && 'translateX(0) scale(1)' }}
                                    key={index}
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(elePi.description) }}
                                    className='desIMG'>

                                </span>
                            )}
                        </div>
                        <div ref={ref} className="glisseMEGgal">
                            <nav className='ulind'>
                                <ul>
                                    {tabGalCRSL.map((elePi, index) =>
                                        <React.Fragment key={index}>
                                            <li key={index}>
                                                <button
                                                    onClick={() => {
                                                        setEleNumber(index);
                                                        setCount(100);
                                                    }}
                                                    className={`aGalB ${inView ? 'anim animate__bounce' : ''}`}
                                                >{elePi.titlebtn}</button>

                                                <div className='CONTcharg'>
                                                    <div
                                                        className={index === eleNumber ? 'theBTNCHARG brIT' : 'theBTNCHARG'}
                                                        style={{ width: index === eleNumber ? count + '%' : '0%' }}>

                                                    </div>
                                                </div>

                                            </li>



                                        </React.Fragment>

                                    )}


                                </ul>
                            </nav>
                        </div>
                        {/* <span>{eleNumber}</span>
                        <span>{count}</span> */}


                    </div>

                    {/* <span className='sTxtp'>Prenez la route en toute confiance avec <b>zone permis</b>, chaque leçon vous rapproche de la liberté au volant !</span> */}
                </div>
            </div>
        </div>
    );


};

export default CompPresentation;