import React from "react";
const iLIVREmotoETM = ({size=24, color="#0056b3"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
width={size} height={size} 
viewBox="0 0 300 210"
fill={color} 
stroke="none"
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round">
<g>
	<path d="M184,45.2l11.7-8.4c1.3-0.9,1.6-2.7,0.7-4L188,21.2c-0.9-1.3-2.7-1.6-4-0.7l-11.7,8.4c-1.3,0.9-1.6,2.7-0.7,4l8.4,11.7
		C180.9,45.8,182.7,46.1,184,45.2z M178.8,32.8c0.3-3,3.5-2.9,4.3-3c0.8-0.2,0.7-1.5,0.7-1.5l-1.8-0.3l3.9-4.8l2.2,6l-1.8-0.3
		c0,0,0,0.2-0.4,1.9c-0.3,1.6-3.2,1.2-4,1.7c-0.8,0.5-0.4,2,1.5,2.2c1.9,0.2,5,0.6,4.8,2.8c-0.2,2.2-1.4,3.4-3.3,3.1
		c-2.5-0.3-2.1,1.8-2.1,1.8l-2.4-0.3c0.3-3,1.2-3.8,3.5-3.4c0.8,0.1,1.4-0.2,1.5-0.7c0.1-0.8-0.7-1.3-3-1.5
		C181.6,36.5,178.4,35.8,178.8,32.8z"/>
	<path d="M168.5,16.2c0.3-3.2-0.8-6.3-2.8-8.6c-2-2.3-4.8-3.9-8-4.1c-6.5-0.5-12.2,4.3-12.7,10.8c-0.3,3.2,0.8,6.3,2.8,8.6
		c2,2.3,4.8,3.9,8,4.1C162.3,27.5,168,22.7,168.5,16.2z M154.5,22.2c0,0-3.6,0.4-3.3-0.3c0,0-0.4-11.5,1.8-11.8
		c2.2-0.3,8.9,0.3,8.9,0.3l0.1-1.3c0.1-0.8,4.2,3.4,4.4,3.7c0,0-4.4,2.2-4.9,2.2c-0.5,0-0.1-1.3-0.1-1.3s-6.2-0.5-6.4,0.2
		C154.9,14.5,154.5,22.2,154.5,22.2z"/>
	<path d="M124.6,11.9c-0.5,0-0.7,0.4-0.6,1l0.5,4.9c0.1,0.6,0.3,0.9,0.8,0.8s0.7-0.4,0.6-1l-0.5-4.9
		C125.4,12.1,125.1,11.8,124.6,11.9z"/>
	<path d="M130.6,13.3l-0.1-1.1c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.1l-0.7,0.1l0.3,2.7l0.7-0.1c0.2,0,0.4-0.1,0.5-0.2
		C130.6,13.9,130.7,13.6,130.6,13.3z"/>
	<path d="M131.4,25.6l4.8-10.4c0.3-0.7,0.3-1.5-0.2-2.2l-6.6-9.3c-0.5-0.7-1.2-1-2-0.9l-11.4,1.1c-0.8,0.1-1.5,0.5-1.8,1.3
		l-4.8,10.4c-0.3,0.7-0.3,1.5,0.2,2.2l6.6,9.3c0.5,0.7,1.2,1,2,0.9l11.4-1.1C130.4,26.8,131,26.3,131.4,25.6z M117.3,20
		c-0.3,0.4-0.8,0.7-1.5,0.7c-0.7,0.1-1.2-0.1-1.6-0.4c-0.4-0.4-0.6-0.9-0.7-1.6l0-0.5l1.3-0.1l0.1,0.6c0.1,0.6,0.3,0.8,0.8,0.8
		c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.1-0.4,0.1-0.7c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.5-0.6-1-0.9c-0.6-0.5-1.1-0.9-1.3-1.3
		s-0.4-0.8-0.5-1.4c-0.1-0.7,0.1-1.3,0.4-1.7c0.3-0.4,0.8-0.7,1.5-0.7c0.7-0.1,1.2,0.1,1.6,0.4c0.4,0.4,0.6,0.9,0.7,1.6l0,0.4
		l-1.3,0.1l0-0.5c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.5-0.2c-0.5,0-0.7,0.4-0.6,0.9c0,0.3,0.1,0.6,0.3,0.9
		c0.2,0.3,0.6,0.6,1.1,0.9c0.6,0.5,1.1,0.9,1.3,1.3c0.3,0.4,0.4,0.9,0.5,1.4C117.8,19,117.7,19.5,117.3,20z M121.2,20.1l-1.4,0.1
		l-0.7-7.7l-1.5,0.1l-0.1-1.3l4.3-0.4l0.1,1.3l-1.5,0.1L121.2,20.1z M126.9,19.1c-0.3,0.4-0.8,0.7-1.5,0.7c-0.7,0.1-1.2-0.1-1.6-0.4
		c-0.4-0.4-0.6-0.9-0.7-1.6l-0.5-4.7c-0.1-0.7,0.1-1.3,0.4-1.7c0.3-0.4,0.8-0.7,1.5-0.7c0.7-0.1,1.2,0.1,1.6,0.4
		c0.4,0.4,0.6,0.9,0.7,1.6l0.5,4.7C127.4,18.1,127.2,18.6,126.9,19.1z M129.8,19.2l-1.4,0.1l-0.9-8.9l2.1-0.2
		c0.7-0.1,1.2,0.1,1.6,0.4c0.4,0.3,0.6,0.9,0.7,1.6l0.1,0.9c0.1,0.7-0.1,1.3-0.4,1.7c-0.3,0.4-0.8,0.6-1.5,0.7l-0.7,0.1L129.8,19.2z
		"/>
	<path d="M170.1,138.8c0.3-0.4,0.6-0.8,0.9-1.3s0.5-0.9,0.7-1.4c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0,0.1-0.1,0.2-0.1
		c-0.2-0.4-0.5-0.8-0.8-1.2c-0.8,0.8-1.4,1.6-1.9,2.6C169.3,138.4,169.4,138.5,170.1,138.8z"/>
	<path d="M175.6,139.9c-0.1,0-0.1,0-0.2,0c0,0.4-0.1,0.7-0.2,1c0.1,0,0.1,0.1,0.2,0.1c0.2-0.2,0.4-0.3,0.6-0.5
		C175.9,140.3,175.7,140.1,175.6,139.9z"/>
	<path d="M171.4,126.7c-0.7-1.5-1.3-2.9-2-4.3c0,0-0.1,0-0.1,0.1c0.2,1.6,1,3,1.7,4.5C171.1,126.8,171.3,126.7,171.4,126.7z"/>
	<path d="M160.1,101.5c-0.4,0.4-0.7,0.7-1,0.9c-0.2-0.4-0.5-0.5-0.8-0.3c0.5,0.6,1,1.1,1.5,1.8c0,0.3,0.1,0.8,0.2,1.3
		c-0.4,0.1-0.8,0.2-1.2,0.2c0.6-0.1,1.3-0.1,2-0.2c0-0.4-0.1-0.8,0-1.1c0.3-0.7,0.1-1.4-0.1-2.2
		C160.6,101.9,160.4,101.7,160.1,101.5z"/>
	<path d="M160.5,100.4c0-0.3,0.1-0.5,0.1-0.7c0.2,0,0.4,0.1,0.7,0.2c-0.5-0.5-0.9-0.8-1.4-1.2c0,0-0.1,0-0.1,0.1
		c0,0.1,0,0.2,0.1,0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.4,0.6-1,1.1-0.9,2.1C159.4,100.8,160,100.6,160.5,100.4z"/>
	<path d="M171.7,122.4c0,0-0.1-0.1-0.1-0.1c-0.3,0.1-0.7,0.1-1.1,0.2c0.2,0.4,0.4,0.8,0.6,1.2C171.4,123.2,171.6,122.8,171.7,122.4z
		"/>
	<path d="M179.4,142.3c-0.2-0.1-0.3-0.3-0.5-0.3c-0.1,0-0.3,0.2-0.3,0.3c0,0.1,0.2,0.3,0.3,0.3C179.1,142.6,179.2,142.4,179.4,142.3
		z"/>
	<path d="M173.1,134.5c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2,0.2-0.4,0.3-0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.4
		C172.7,134.8,172.9,134.6,173.1,134.5z"/>
	<path d="M177.7,128.3c-1.1-0.5-2.2-1-3.3-1.6c-0.1,0.4-0.1,0.7-0.1,1.1c1,0.4,1.9,0.7,2.8,1C177.3,128.7,177.5,128.5,177.7,128.3z"
		/>
	<path d="M175.1,138.8c-0.1-0.1-0.2-0.2-0.4-0.4c-0.2,0.2-0.3,0.3-0.4,0.5C174.7,139.3,174.7,139.3,175.1,138.8z"/>
	<path d="M173.6,135.2c-0.1-0.1-0.3-0.2-0.5-0.4c-0.2,0.2-0.3,0.3-0.4,0.4C173.1,135.7,173.2,135.7,173.6,135.2z"/>
	<path d="M173.4,114c0.2-0.7-0.3-0.6-0.6-0.8C172.7,113.6,173.1,113.8,173.4,114z"/>
	<path d="M148.5,135.9c0.3-0.3,0.2-1-0.2-1.9C148.4,134.6,148.5,135.2,148.5,135.9z"/>
	<path d="M149.3,128.3c-0.3-0.2-0.7-0.3-1-0.5c-0.2,0.2-0.3,0.4-0.4,0.6c0,0.2,0,0.5,0,0.7c0,0,0.1,0,0.1,0.1c0-0.1,0.1-0.2,0.1-0.4
		c0.1,0.2,0.2,0.3,0.3,0.5c0.3,0,0.5-0.1,0.8-0.1C149.3,128.8,149.3,128.6,149.3,128.3z"/>
	<path d="M142.6,131.6c0.1,0.6,0.2,1.1,0.4,1.8c0.5-0.6,0-1,0-1.4c0.1-0.1,0.2-0.2,0.3-0.3C143,131.6,142.8,131.6,142.6,131.6z"/>
	<path d="M149.5,137.9c0,0.3,0.4,0.7,0.8,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7C149.8,137.2,149.4,137.5,149.5,137.9z"/>
	<path d="M147,135.4c0-0.1,0.1-0.1,0.1-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.1,0-0.3,0.1-0.4,0.1c-0.2-0.3-0.4-0.5-0.7-0.8
		c-0.1,0-0.3,0-0.5-0.1c-0.6-0.1-1.2,0-1.6,0.4c-0.2,0.2-0.4,0.6-0.5,0.9c-0.2,0.3-0.3,0.4-0.6,0.2c0.2-0.1,0.3-0.2,0.5-0.4
		c-0.3-0.1-0.5-0.2-0.7-0.3c-0.5,1-0.1,2.2,0.8,2.8c0.2-0.2,0.4-0.4,0.6-0.6c-0.2,0.3-0.3,0.5-0.5,0.8c0,0,0,0,0.1,0
		c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0.5,0.5,0.5,0.9,0.5c0.5,0.1,1,0.1,1.3-0.4c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0.1-0.1
		c-0.1-0.1-0.2-0.3-0.2-0.4c0.2,0.1,0.3,0.2,0.4,0.2c0.8-0.6,1.1-1.2,1.1-2.2c-0.2,0-0.5,0.1-0.7,0.1c0,0,0-0.1,0-0.1
		C146.5,135.6,146.8,135.5,147,135.4z"/>
	<path d="M132.1,142.5c0,0,0-0.2,0.1-0.2c-0.2-0.1-0.3-0.3-0.4-0.3c-0.3,0-0.6,0-0.9,0.1c-0.3,0.1-0.2,0.4,0,0.6c0,0.1,0,0.2,0,0.3
		c0,0.8,0,0.8,0.9,1c0-0.3,0.1-0.5,0.1-0.7C131.8,142.9,131.7,142.6,132.1,142.5z"/>
	<path d="M130.6,141.9c-0.3,0-0.5,0-0.6,0c-0.2,0.4-0.4,0.7-0.6,0.9c0.8,0.6,1.2,1.7,2.2,2.2C130.8,144,129.9,143.2,130.6,141.9z"/>
	<path d="M126.4,135.9c-0.7,0-1.3,0.1-2.1,0.1c0.2,0.4,0.3,0.7,0.4,1.1c0.6-0.3,1.2-0.6,1.9-0.9
		C126.6,136.1,126.5,135.9,126.4,135.9z"/>
	<path d="M124.8,134.7c-0.3,0.1-0.6,0.2-0.9,0.3c0.1,0.2,0.2,0.3,0.2,0.5c0.4,0,0.8-0.1,1.3-0.1C125.2,135.1,125,134.9,124.8,134.7z
		"/>
	<path d="M146,123c1.5-0.9,2.9-1.8,4.4-2.8c0.3-0.2,0.6-0.5,0.5-0.9c-0.1,0-0.1-0.1-0.2-0.1c-2.7-0.5-5.3-1-7.9-1.5
		c-1.7-0.3-3.2,0.5-4.6,1.2c-0.1,0-0.1,0.1-0.2,0.2c0.5-0.3,0.9,0.1,1.3,0.3c0.6,0.3,1.2,0.6,1.9,0.9c0,0,0.1-0.1,0.1-0.1
		c-0.2-0.5-0.4-1-0.6-1.6c-0.1,0-0.2-0.1-0.4-0.1c0.7-0.4,1.3-0.6,2.1-0.5c2.8,0.5,5.5,1,8.3,1.5c0,0.1,0,0.1,0,0.2
		c-1.6,0.9-3.3,1.7-4.9,2.6c-0.3,0.2-0.7,0.2-1.1,0.3c-1,0.1-2,0.2-3,0.4c-1.5,0.3-3,0.6-4.5,1c-0.5,0.1-0.7,0.3-0.6,0.8
		c0.1,0.5-0.5,1.2-1,1.2c-0.4,0-0.9-0.1-1.2-0.1c0.4,0.4,0.7,0.4,1.8,0.4c0.1-0.2,0.3-0.4,0.4-0.6c0.4-0.6,0.9-1,1.7-1.2
		c2.2-0.3,4.4-0.7,6.6-1.1C145.1,123.3,145.6,123.2,146,123z"/>
	<path d="M132,143.6c0.2,0.3,0.4,0.6,0.6,1c0.4,0,0.8,0,1.2,0c0-0.1,0-0.1,0-0.2C133.3,144.1,132.7,143.9,132,143.6
		C132.1,143.6,132.1,143.6,132,143.6z"/>
	<path d="M103.5,134.3c0.4-0.1,0.8-0.1,1.3-0.2C104.2,133.7,103.9,133.8,103.5,134.3z"/>
	<path d="M108.6,130.8c-0.3,0-0.5,0.1-0.8,0.1c0,0,0-0.1-0.1-0.1c0.3-0.2,0.6-0.4,0.8-0.7c0,0-0.1-0.1-0.1-0.1
		c-0.5,0.1-0.7,0.5-1,0.9c0.3,0.2,0.6,0.3,0.8,0.4c0.1-0.1,0.3-0.2,0.4-0.3C108.6,130.9,108.6,130.9,108.6,130.8z"/>
	<path d="M110.6,140.9c-0.1-0.2-0.3-0.5-0.4-0.5c-0.6,0-1.2,0.1-1.9,0.2c0.1,0.8,0.2,1.4,0.3,2.2c0.6-0.1,1.2-0.2,1.8-0.2
		c0.3-0.1,0.5-0.2,0.4-0.6c0-0.2,0.1-0.4,0.1-0.6C110.9,141.2,110.8,141,110.6,140.9z"/>
	<path d="M108.9,128.6c-0.2-0.2-0.4-0.3-0.5-0.4c-0.6,0.4-1.3,0.7-1.3,1.7C108.1,130.1,108.3,129.3,108.9,128.6z"/>
	<path d="M106.3,131.7c-0.2,0-0.4,0-0.5,0.1c0-0.1,0-0.2,0-0.3c0,0-0.1,0-0.1,0c-0.2,0.3-0.4,0.7-0.6,1c0.3,0.2,0.5,0.3,0.7,0.5
		C105.8,132.5,106,132.2,106.3,131.7z"/>
	<path d="M108.8,131.7c0,0,0.1-0.1,0.1-0.1c0,0.1-0.1,0.3-0.1,0.5c0.2,0,0.3,0,0.3,0c0.6,0.5,1.1,1,1.7,1.5c0.2-0.2,0.3-0.3,0.5-0.5
		c-0.4-0.1-0.7-0.3-1-0.4c0.1-0.5-0.3-0.5-0.7-0.4c0-0.2,0-0.3,0.1-0.4c0.2,0,0.3,0.1,0.6,0.1c-0.1-0.2-0.2-0.4-0.3-0.5
		c-0.2,0.1-0.4,0.2-0.6,0.4c0,0,0,0-0.1-0.1c0.2-0.3,0.5-0.6,0.7-1c0,0-0.1-0.1-0.1-0.1c-0.2,0.2-0.5,0.4-0.7,0.6
		c-0.3,0-0.6,0.1-0.9,0.1C108.4,131.4,108.6,131.6,108.8,131.7z"/>
	<path d="M105.7,134c0.3-0.1,0.7-0.1,1.1-0.1c0-0.1,0-0.1,0.1-0.2c-0.5-0.3-1.1-0.6-1.8-0.9c-0.1,0.4-0.2,0.7-0.1,0.9
		C105,134,105.3,134.1,105.7,134z"/>
	<path d="M82.7,172.2c-0.3-0.7-0.4-1.5-0.6-2.5c-1.6,1.3-1.9,2.7-1.2,4.1c0.7,1.3,2.2,2,3.5,1.6c1.5-0.4,2.4-1.8,2.3-3.2
		c-0.1-1.5-1.1-2.5-2.6-2.6c0.1,0.8,0.4,1.7,0.4,2.5c0,0.4-0.5,0.7-0.7,1.1C83.3,172.8,82.8,172.6,82.7,172.2z"/>
	<path d="M177.5,136.5c0.4,0.9,0.9,1.9,1.3,3c0.4-0.3,0.7-0.6,1-0.9c0.3,0.1,0.6,0.3,0.9,0.4c-0.1,0.2-0.2,0.3-0.3,0.5
		c-0.5,0.9,0,2,1,2.2c0.3,0,0.6,0.1,0.9,0.1c0,0.3,0,0.6,0,0.9c-0.8,0.1-1.6-0.2-2.2,0.6c-0.6,0.8,0,1.5,0.2,2.3
		c-0.3,0.1-0.5,0.2-0.8,0.3c-0.5-0.5-0.5-1.3-1.4-1.5c-0.9-0.1-1.3,0.4-1.8,1c-0.3-0.3-0.5-0.6-0.7-0.8c0.8-1,0.6-1.7-0.9-2.5
		c0-0.2,0-0.4,0-0.6c-0.5-0.1-0.9-0.2-1.5-0.4c0.1,0.3,0.2,0.4,0.2,0.5c-0.3,0-0.5-0.1-0.8-0.1c0.2-0.1,0.3-0.2,0.5-0.3
		c0-0.1,0-0.2-0.1-0.3c-0.4,0.2-0.9,0.5-1.4,0.8c0.1,0.1,0.2,0.3,0.3,0.4c-0.4,0.7-0.4,0.9-0.1,1.2c-0.1,0.3-0.1,0.6-0.2,0.9
		c0,0-0.1,0-0.1,0c0-0.4,0.2-0.9-0.3-1.3c-0.6,0.3-1.2,0.5-1.9,0.8c-0.4,0.2-0.5,0.4-0.4,0.8c0.5,1,0.9,2,1.4,2.9
		c0.2,0.4,0.5,0.4,0.8,0.2c0.5-0.3,1-0.5,1.5-0.8c0.1-0.1,0.3-0.1,0.4-0.1c-0.6,0.6-1.5,0.8-2.1,1.4c0.7,0.9,1.6,1.5,2.6,2.1
		c1.4,0.7,1.4,0.7,1.5-0.8c0-0.2,0.1-0.4,0.1-0.6c0.1,0,0.1,0,0.2,0c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.4,0.1,0.6,0.4,0.7
		c1.1,0.3,2.3,0.4,3.4,0.3c0.4-0.1,0.5-0.2,0.5-0.6c0-0.4,0-0.8,0.1-1.2c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.1,0,0.2,0c0,0.6,0,1.2,0,1.9
		c1.6-0.3,3-1,4.3-2.1c-0.5-0.4-0.9-0.8-1.3-1.1c0-0.1,0.1-0.1,0.1-0.2c0.5,0.3,1,0.7,1.4,1c1-0.7,2.1-2.3,2.5-3.6
		c-0.2-0.2-0.5-0.4-0.8-0.5c-0.6-0.4-0.6-0.4-0.5-0.7c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.5,0.3,0.6,0.2,0.7-0.3
		c0.4-1.2,0.5-2.6,0.3-3.8c0-0.1-0.1-0.2-0.1-0.4c-0.6,0.2-1.1,0.5-1.7,0.7c0-0.1-0.1-0.1-0.1-0.2c0.3-0.1,0.5-0.3,0.8-0.4
		c1-0.4,1-0.4,0.7-1.5c0-0.1,0-0.1-0.1-0.2c-0.1-0.3-0.3-0.4-0.6-0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.3,0.1-0.4,0.1-0.5-0.2
		c1.4-0.9,1.4-1.2,0.2-2.3c-0.5,0.3-1.1,0.5-1.7,0.8c0-0.1-0.1-0.1-0.1-0.2c0.5-0.3,1-0.6,1.6-1c-1.1-1.3-2.3-2.1-3.9-2.7
		c-0.1,0.9-0.2,1.6-0.4,2.4c-0.1,0-0.1,0-0.2,0c0-0.2-0.1-0.3-0.1-0.5c0-0.5,0.2-1,0.2-1.5c0-0.2-0.1-0.5-0.3-0.6
		c-0.4-0.2-0.9-0.2-1.4-0.3c0,0.3,0,0.5,0,0.7c0,1,0,2,0,3c0,0.7-0.5,1.1-1,1.5c-0.2-0.4-0.4-0.7-0.5-1
		C177.6,136.5,177.6,136.5,177.5,136.5z"/>
	<path d="M113.9,133.7c-0.2-0.2-0.5-0.4-0.8-0.6c-0.1,0.1-0.1,0.2-0.2,0.2c0.3,0.2,0.5,0.3,0.8,0.5
		C113.8,133.9,113.9,133.8,113.9,133.7z"/>
	<path d="M121,116.9c-0.1-0.2-0.3-0.4-0.5-0.7c-0.3-0.1-0.6-0.1-1-0.2C119.9,116.5,120.6,116.9,121,116.9z"/>
	<path d="M103.8,112.7c-1.4-0.6-2.5-1.8-4.1-2c0,0.1,0,0.2,0.1,0.3c0.4,0.3,0.9,0.5,1.3,0.8C101.8,112.3,102.6,112.8,103.8,112.7z"
		/>
	<path d="M141.6,132.8c0.3,0.3,0.5,0.6,0.8,0.8c0.2-0.1,0.3-0.2,0.4-0.3c-0.2-0.4-0.4-0.7-0.6-1.1
		C142.1,132.5,141.9,132.6,141.6,132.8z"/>
	<path d="M184.7,150.6c0,0,0.1,0.1,0.1,0.1c0.4-0.4,0.9-0.7,1.3-1.1c0,0-0.1-0.1-0.1-0.1C185.5,149.8,185.1,150.2,184.7,150.6z"/>
	<path d="M171.1,141.6c-0.5-0.2-1-0.3-1.1-0.9c-0.1-0.4,0-0.9,0.1-1.4c-0.8-0.6-1-0.6-1.2,0.4c0,0.1-0.1,0.2-0.1,0.4
		c-0.2,1.1-0.3,2.3,0,3.5c0.8-0.1,1.5-0.6,2.2-0.8C171.1,142.3,171.1,142,171.1,141.6z"/>
	<path d="M188.7,145c0.6-2,0.6-4.1,0-6.1c0-0.1-0.1-0.2-0.2-0.3c0,0.1-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.3c0.6,2,0.6,3.9,0,5.9
		c0,0.2-0.2,0.4-0.2,0.5C188.5,145.3,188.7,145.2,188.7,145z"/>
	<path d="M187.8,147.4c0.2-0.5,0.5-1.1,0.7-1.6c0-0.1-0.1-0.3-0.2-0.4c-0.2,0.6-0.4,1.3-0.7,1.9
		C187.7,147.3,187.7,147.3,187.8,147.4z"/>
	<path d="M174.3,140.3c-0.5,0-0.8,0-1.3,0c0,0.2,0,0.4,0,0.6c0.3,0.1,0.7,0.2,1.1,0.3C174.3,140.8,174.3,140.5,174.3,140.3z"/>
	<path d="M116.4,136.1c0.9,0.2,1.2,1.2,2.3,1.4C118.1,136.7,116.8,135.8,116.4,136.1z"/>
	<path d="M99.6,138.8c-0.7,2.3-0.5,4.5,0.5,6.7C99.3,143.3,99.2,141.1,99.6,138.8z"/>
	<path d="M100.5,146.5c0.3,0.4,0.6,0.8,0.9,1.3c0,0,0.1-0.1,0.1-0.1c-0.3-0.4-0.6-0.8-0.8-1.3C100.6,146.5,100.6,146.5,100.5,146.5z
		"/>
	<path d="M114.4,144.4c-0.2-0.2-0.3-0.3-0.5-0.5c0.1-0.2,0.2-0.6,0.4-1c-0.7,0-1.2-0.2-1.6,0.5c-0.4,0.8-1.2,1.2-2.1,1.4
		c-0.2,0-0.4,0-0.7,0c0.5-0.8,1.4-0.5,1.8-1.1c-0.1-0.1-0.3-0.3-0.4-0.5c-0.7,0.5-1.5,0.5-2.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.4
		c0-0.2,0-0.3,0-0.3c-0.2,0-0.4,0-0.6,0c0.4,0.3,0.7,0.5,0.9,0.6c0.3,0,0.5,0.1,0.8,0.1c-0.4,0.1-0.8,0.3-1.2,0.2
		c-1.2-0.2-2.5-2.5-1.8-3.2c0.1,0.2,0.3,0.5,0.4,0.8c-0.1-0.4-0.1-0.6-0.2-0.9c-0.1,0-0.2,0-0.3,0c0-0.2,0-0.5,0-0.7
		c0-0.3,0-0.6,0.1-0.8c0.4-0.9,1.1-1.5,2.1-1.7c0.4-0.1,0.8-0.2,1,0.4c0,0,0.1-0.1,0.1-0.1c0-0.3-0.1-0.5-0.1-0.8
		c0.1-0.2,0.3-0.5,0.4-0.7c-1-1-1.9-2.2-3.6-1.5c0.5,0.3,0.9,1,1.6,1c-0.2,0.5-0.3,0.6-0.7,0.5c-0.1-0.2-0.2-0.4-0.3-0.5
		c-0.3-0.3-0.7-0.6-1.1-0.9c-0.1-0.1-0.3-0.2-0.4-0.2c-1,0-2.1,0-3.2,0c0,0-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.5,0.7
		c-0.4,0.7-0.3,1,0.3,1.5c0.8,0.5,1.6,1.2,2.5,1.8c-0.2-0.1-0.5-0.2-0.7-0.3c-0.7-0.5-1.3-1-2-1.5c-0.4-0.3-0.9-0.2-0.9,0.3
		c-0.2,1-0.4,2.1-0.5,3.2c0,0.5,0.3,0.8,0.8,0.6c1.1-0.4,2.2-0.7,3.2-1.1c0.2-0.1,0.3-0.3,0.4-0.5c-0.1,0.3-0.2,0.5-0.3,0.8
		c-1.2,0.4-2.4,0.7-3.5,1.2c-0.7,0.3-0.6,0.8-0.3,1.7c0.2,0.4,0.5,0.4,1.1,0.1c0.2-0.1,0.4-0.1,0.6-0.1c0,0.1,0,0.2,0.1,0.2
		c-0.2,0.1-0.4,0.2-0.6,0.3c-0.3,0.2-0.8,0.3-0.7,0.9c0.2,1,0.9,1.3,1.8,0.9c0.9-0.4,1.8-0.8,2.7-1.2c0,0-0.1,0-0.1,0
		c0.1,0,0.2,0,0.5,0.1c-0.3,0.2-0.5,0.3-0.6,0.4c-0.8,0.4-1.7,0.7-2.5,1.1c-0.4,0.2-0.5,0.5-0.2,0.9c0.7,0.8,1.6,1.6,2.6,2.1
		c0.4,0.2,0.7,0.1,0.8-0.4c0.1-0.2,0.1-0.4,0.1-0.6c0.2-1,0.4-2.1,0.6-3.1c0.2,0.3,0.4,0.5,0.6,0.7c0,0.1-0.1,0.2-0.1,0.3
		c-0.1,0-0.2,0-0.4,0c-0.2,0.8-0.3,1.5-0.4,2.3c0,0.2-0.1,0.4-0.1,0.6c0,0.5,0.2,0.9,0.6,0.9c0.9,0.2,1.9,0.3,2.8,0.4
		c0.7,0.1,0.9-0.2,1-0.9c0.1-1.1,0.2-2.3,0.3-3.4c0-0.1,0.1-0.3,0.2-0.4c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.2
		c-0.1,1.1-0.3,2.3-0.4,3.4c-0.1,0.7,0.2,1,0.8,0.8c1-0.3,2-0.7,2.9-1.1c0.5-0.2,0.5-0.6,0.1-1c-0.3-0.3-0.5-0.5-0.8-0.7
		c-0.5-0.5-1.1-0.9-1.6-1.4c0,0,0.1-0.1,0.1-0.1c0.3,0.2,0.5,0.3,0.8,0.5c0.6,0.5,1.1,1,1.7,1.5c0.5,0.4,0.8,0.4,1.2,0.1
		c0.8-0.7,1.5-1.6,2-2.6c0.2-0.4-0.1-0.8-0.5-0.7c-0.4,0-0.8,0.1-1.2,0.1c-0.7,0.1-1.3,0.2-2,0.4c-0.2,0-0.5,0.1-0.8,0.2
		c0.1-0.3,0.1-0.5,0.1-0.6c1.2-0.2,2.4-0.4,3.6-0.6c-0.9-0.6-1.7-1.2-2.7-1.9C115.2,143.8,114.9,144.2,114.4,144.4z"/>
	<path d="M117.2,132.6c0.2,0.1,0.4,0.2,0.6,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c0.6,0,0.8-0.2,0.8-0.6c0-0.4-0.4-0.7-0.8-0.7
		s-0.6,0.3-0.5,0.8C117.1,132.6,117.2,132.6,117.2,132.6z"/>
	<path d="M118.3,131.1c0.3,0.1,0.6,0.2,0.9,0.3c-0.1,0.2-0.1,0.4-0.2,0.6c0.6-0.3,0.6-0.8,0.3-1.3
		C118.8,130.5,118.5,130.7,118.3,131.1z"/>
	<path d="M112.5,114.2c0.2,0.2,0.3,0.5,0.5,0.6c0.9,0.6,1.9,1.1,2.8,1.6c0.3,0.2,0.6,0.5,0.9,0.7c0,0.1-0.1,0.1-0.1,0.2
		c-0.7,0.1-1.4,0.1-2.1,0.2c0,0.1,0,0.1-0.1,0.2c0.3,0.3,0.6,0.7,1,0.9c3,1.3,6.1,2.7,9.1,4c-2.6-2.8-5.5-5.1-7.7-8.3
		c0.5,0.2,0.9,0.4,1.4,0.7c-0.1-0.4-0.1-0.7-0.2-1c-4-0.7-7.8-1.5-11.7-2.2c0.2,0.2,0.4,0.3,0.6,0.4c1.6,0.5,3.3,1,4.9,1.6
		C112.3,114,112.4,114.1,112.5,114.2z"/>
	<path d="M130.1,144.9c-0.2-0.3-0.5-0.7-0.7-1c-0.7-1.2-2.2-1.4-3.3-1C127.7,144,129.5,144.9,130.1,144.9z"/>
	<path d="M129.5,137.8c0.2,0.2,0.5,0.3,0.7,0.4c0.7,0.1,0.8,0.3,0.7,1c0,0.2,0,0.6,0.2,0.7c0.4,0.3,0.8,0.6,1.3,0.9
		c0,0-0.1,0-0.1,0.1c0.1,0.3,0.2,0.6,0.3,1c0,0.1-0.1,0.3-0.1,0.6c-0.4,0.1-0.7,0.2-0.3,0.7c0.2-0.3,0.4-0.6,0.6-0.8
		c0.5,0.6,1.2,0.8,1.8,1.1c0.1-0.3,0.3-0.5,0.4-0.9c-0.1-0.3-0.2-0.7-0.4-1.2c0.3,0.1,0.5,0.1,0.7,0.2c0.4,0.1,0.7,0,0.9-0.4
		c0.1-0.4-0.1-0.8-0.5-0.8c-0.3,0-0.5,0-0.8-0.1c-0.3-0.1-0.6-0.2-0.8-0.4c-0.7-0.7-1.3-1.4-2.1-2.2c0.2-0.1,0.4-0.3,0.6-0.4
		c0.4,0,0.9,0.1,1.4,0c0.2,0,0.6-0.1,0.7-0.2c0.1-0.2,0.1-0.6,0-0.7c-0.1-0.2-0.5-0.2-0.7-0.2c-0.7,0.1-1.3,0.2-2,0.3
		c-0.4,0-0.9,0.1-1-0.4c-0.1-0.6-0.6-0.8-1.1-1c-0.7-0.3-1.3-0.5-2-0.8c-0.2-0.1-0.4-0.1-0.6-0.2c0,0.7,0.2,1.3,0.6,1.7
		C128.4,136.6,128.9,137.2,129.5,137.8z"/>
	<path d="M97.3,119.5c0-0.4,0.1-0.8,0.1-1.3c-0.1,0.1-0.3,0.2-0.3,0.2C97.1,118.8,96.8,119.2,97.3,119.5z"/>
	<path d="M107.4,117.8c-0.7-0.4-1.3-0.8-2-0.7c-1.1,0.1-2.2,0.4-3.2,0.6c-0.1,0-0.2,0.1-0.2,0.2
		C102.5,118.5,106.7,118.4,107.4,117.8z"/>
	<path d="M139,117.8c-0.8,0-1.6,0-2.5,0C137.3,118.5,137.8,118.5,139,117.8z"/>
	<path d="M141.1,112.1c0.5-0.2,1.1-0.3,1.7-0.2c1.1,0.2,2.2,0.5,3.2,0.9c2.3,0.8,4.6,1.8,6.9,2.7c-0.2,0.3-0.4,0.7-0.6,1
		c-0.2,0.3-0.4,0.7-0.6,1.1c0.2,0,0.2-0.1,0.3-0.1c0.8-1.2,1.8-2.1,3.4-1.7c0,0,0,0,0.1,0c-0.3,0-0.6,0-0.9,0.1s-0.7,0.1-0.9,0.3
		c-0.7,0.7-1.4,1.4-2.1,2.2c-0.1,0.1-0.3,0.2-0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.3c1.7,0.4,3.4,0.7,5.1,1.1c0.5,0.1,0.9-0.1,1.2-0.5
		c0.4,0.1,0.7,0.2,1,0.3c0.2-0.1,0.3-0.3,0.5-0.4c-0.7-0.2-1.3-0.3-2-0.4c0.1-0.1,0.1-0.2,0.2-0.1c0.7,0.3,1.3,0.3,2.1-0.1
		c0.7-0.4,1.5-0.6,2.4-0.9c-0.1-0.1-0.2-0.2-0.3-0.4c0.5,0.2,0.9,0.3,1.3,0.5c0.3-0.2,0.5-0.5,0.7-0.7c-0.9,0.3-1.6-0.1-2.3-0.4
		c0.1,0,0.3,0,0.4,0c0.6,0.3,1.1,0.1,1.7,0.2c1,0.1,2-0.4,3-0.5c-0.2-0.2-0.5-0.2-0.7-0.2c-0.6-0.1-1.1-0.2-1.7-0.4
		c-0.2,0-0.3-0.1-0.5-0.2c-0.6-0.2-1.2-0.4-1.9-0.6c-1-0.3-2-0.7-3-1c0.1-0.1,0.3-0.2,0.7-0.3c-0.6-0.7-1.2-1.3-1.8-2
		c0-0.1,0-0.2,0-0.3c-0.1-0.8-0.6-1-1.2-1.1c-2.5-0.6-5-1.2-7.5-1.8c-0.9-0.2-1.7-0.2-2.6,0.2c-1,0.4-2.1,0.8-3.1,1.2
		c-0.4,0.2-0.8,0.3-1.2,0.5c0,0.1-0.1,0.1-0.1,0.1c-1,0.7-2.1,1.3-3.1,2c-0.1,0-0.1,0-0.2,0.1c0,0,0.1,0.1,0.1,0.1
		C139.7,112.6,140.4,112.3,141.1,112.1z"/>
	<path d="M76.8,165.8c0,4.8,0,9.2,0,13.2c1.9,1.1,3.5,2.1,5.2,3c0.6,0.3,1.2,0.7,1.8,0.8c2.9,0.3,4.9,2.6,4.7,5.5
		c-0.2,2.8-2.8,4.8-5.6,4.4c-2.8-0.4-4.7-3-4.1-5.7c0.3-1.2,1.1-2.3,1.9-3.8c-1.1-0.5-2.3-1.2-3.8-1.9c0,5.8,0,11.3,0,16.7
		c40.2,0,80.4,0,120.6,0c0-47.7,0-95.3,0-142.9c-5.4,0-10.7,0-16,0l0.6-3.3c0.4,0,0.7,0,0.7-0.1c0.1-0.3,0.5-2.6,0.7-3.7
		c0.2-0.1,0.5-0.2,0.7-0.4l14.1-10.1c1.5-1.1,1.9-3.2,0.8-4.8l-10.1-14.1c-1.1-1.5-3.2-1.9-4.8-0.8L169.9,28
		c-1.5,1.1-1.9,3.2-0.8,4.8l10.1,14.1c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,1.2-0.4,4-0.3,4c0.1,0.1,0.3,0.1,0.6,0.2l-0.6,3.7
		c-8.2,0-16.3,0-24.5,0l1.9-22.4c0.4,0,0.7,0,0.7-0.2c0-0.3,0.2-2,0.3-3.2c6.9-0.4,12.7-5.8,13.3-12.9c0.6-7.8-5.1-14.6-12.9-15.2
		c-7.8-0.6-14.6,5.1-15.2,12.9c-0.6,7.1,4.2,13.3,10.9,14.9c0,1.3-0.1,3.2,0,3.3c0.1,0.1,0.3,0.1,0.6,0.2l-2,22.7
		c-8,0-16.1,0-24.1,0l-2-22c0.4-0.1,0.6-0.1,0.6-0.3c0-0.4-0.3-3.3-0.3-4l4.1-0.4c0.9-0.1,1.7-0.6,2-1.4l5.4-11.8
		c0.4-0.8,0.3-1.7-0.2-2.5l-7.5-10.5c-0.5-0.7-1.4-1.1-2.3-1L115,2.4c-0.9,0.1-1.7,0.6-2,1.4l-5.4,11.8c-0.4,0.8-0.3,1.7,0.2,2.5
		l7.5,10.5c0.5,0.7,1.4,1.1,2.3,1l5.1-0.5c0.1,0.8,0.6,3.9,0.7,4c0.1,0.1,0.3,0,0.6,0l2,21.9c-8.3,0-16.5,0-24.8,0l-1-4.3
		c0.4-0.1,0.6-0.2,0.6-0.4c0-0.3-0.4-1.7-0.6-2.9c1-0.3,1.8-0.6,2.3-0.8c2.6-0.8,2.1-2.5,2.1-2.5s-6.4-30.9-7.3-32.7
		c-0.3-0.6-1.3-0.8-2.6-0.6c-0.4-0.8-1.5-2.5-2.7-2.4c-1.7,0.1-6,1.4-6.5,2.2c-0.3,0.5-0.4,1.8-0.5,2.8c-2.4,1-2,2.2-2,2.2l7.5,32.1
		c0.3,2,3.1,1.7,6,1c0.4,1.2,0.8,2.5,0.9,2.6c0.1,0,0.3,0,0.6,0l0.9,3.9c-7.3,0-14.6,0-21.9,0c-0.4,0.9,0.1,1.1,0.8,1.4
		c1.5,0.6,2.8,1.5,4.2,2.2c0.6,0.3,1.3,0.8,1.9,0.8c2.3,0.3,4.2,1.9,4.6,4.1c0.4,2.4-0.8,4.6-3,5.5c-2.2,0.9-4.6,0.2-6-1.8
		c-1.3-1.9-1.1-4.5,0.5-6.2c0.3-0.3,0.7-0.4,1-0.7c0-0.2-0.1-0.3-0.1-0.5c-1.3-0.6-2.6-1.3-4.1-2.1c0,4.7,0,8.9,0,13.1
		c0,0.3,0.6,0.6,0.9,0.8c1.4,0.8,2.8,1.5,4.2,2.2c0.6,0.3,1.3,0.8,1.9,0.9c2.4,0.3,4.3,1.9,4.6,4.2c0.3,2.4-0.9,4.5-3.1,5.4
		c-2.2,0.9-4.6,0.1-5.9-1.9c-1.3-1.9-1-4.5,0.6-6.2c0.2-0.2,0.6-0.4,0.9-0.6c0-0.2,0-0.3,0-0.5c-1.3-0.6-2.6-1.3-4.1-2.1
		c0,4.7,0,8.9,0,13.1c0,0.3,0.5,0.6,0.9,0.8c1.4,0.8,2.9,1.5,4.3,2.4c0.6,0.5,1.2,0.8,2,0.8c2.7,0.1,4.7,2.4,4.6,5.1
		c-0.1,2.7-2.3,4.7-5.1,4.7c-2.7-0.1-4.7-2.2-4.8-4.8c0-1.8,0.8-3.1,2.4-4.5c-1.5-0.8-2.8-1.4-4.4-2.2c0,4.7,0,8.9,0,13.1
		c0,0.3,0.5,0.7,0.9,0.9c1.4,0.8,2.9,1.4,4.2,2.3c0.7,0.5,1.3,0.9,2.2,0.9c2.6,0.1,4.6,2.4,4.5,5.1c-0.1,2.7-2.3,4.8-5,4.7
		c-2.7-0.1-4.8-2.2-4.8-4.8c0-1.8,0.8-3.2,2.2-4.3c-1.2-1.4-2.5-2.1-4-2c0,4.4,0,8.8,0,12.7c1.9,1.2,3.6,2.1,5.2,3.1
		c0.6,0.3,1.2,0.7,1.8,0.8c2.7,0.2,4.7,2.4,4.7,5.1c-0.1,2.7-2.4,4.9-5.1,4.8c-2.7-0.1-4.8-2.3-4.7-5c0-1.8,0.9-3,2.4-4.3
		c-1.5-0.8-2.8-1.4-4.4-2.2c0,4.8,0,9.1,0,13.4c0,0.2,0.5,0.5,0.9,0.7c1.4,0.8,2.9,1.4,4.2,2.3c0.7,0.5,1.3,0.9,2.2,0.9
		c2.6,0.1,4.5,2.4,4.4,5c-0.1,2.7-2.1,4.8-4.8,4.8c-2.6,0.1-4.8-1.9-5-4.4c-0.2-2,0.6-3.5,2.4-4.9c-1.5-0.8-2.8-1.4-4.3-2.2
		c0,4.9,0,9.3,0,13.2c1.9,1.2,3.6,2.2,5.3,3.1c0.5,0.3,1.1,0.7,1.7,0.7c2.3,0.2,4.2,1.8,4.6,4c0.4,2.4-0.8,4.7-3,5.6
		c-2.2,0.9-4.6,0.1-5.9-1.8c-1.3-1.9-1.1-4.5,0.5-6.2c0.3-0.3,0.5-0.5,1-1c-1.4-0.7-2.7-1.4-4.2-2.1c0,4.8,0,9.3,0,13.3
		c1.8,1,3.5,1.8,5,2.9c0.7,0.5,1.3,0.9,2.2,1c2.3,0.1,4.1,1.9,4.5,4.1c0.3,2.4-0.9,4.5-3.1,5.4c-2.1,0.8-4.5,0.1-5.8-1.8
		c-1.3-1.9-1.1-4.5,0.5-6.2c0.3-0.3,0.7-0.4,1-0.7c0-0.2-0.1-0.3-0.1-0.5C79.6,167.2,78.3,166.6,76.8,165.8z M171.2,32.8
		c-0.9-1.3-0.6-3.2,0.7-4.1l12.2-8.7c1.3-0.9,3.2-0.6,4.1,0.7l8.7,12.2c0.9,1.3,0.6,3.2-0.7,4.1L184,45.6c-1.3,0.9-3.2,0.6-4.1-0.7
		L171.2,32.8z M147.3,23.2c-2.1-2.4-3.2-5.6-2.9-9c0.3-3.4,1.9-6.4,4.3-8.4c2.4-2.1,5.6-3.2,9-2.9c3.4,0.3,6.4,1.9,8.4,4.3
		c2.1,2.4,3.2,5.6,2.9,9c-0.3,3.4-1.9,6.4-4.3,8.4c-2.4,2.1-5.6,3.2-9,2.9C152.3,27.3,149.4,25.7,147.3,23.2z M118,28.5
		c-0.8,0.1-1.6-0.3-2.1-1l-6.9-9.7c-0.5-0.7-0.6-1.5-0.2-2.3l4.9-10.8c0.4-0.8,1-1.3,1.9-1.3l11.9-1.1c0.8-0.1,1.6,0.3,2.1,1
		l6.9,9.7c0.5,0.7,0.6,1.5,0.2,2.3l-4.9,10.8c-0.4,0.8-1,1.3-1.9,1.3L118,28.5z M87,19.6c-0.6-2.4,0.9-4.9,3.4-5.4
		c2.4-0.6,4.9,0.9,5.4,3.4c0.6,2.4-0.9,4.9-3.4,5.4C90.1,23.6,87.6,22.1,87,19.6z M89.6,30.7c-0.6-2.4,0.9-4.9,3.4-5.4
		c2.4-0.6,4.9,0.9,5.4,3.4c0.6,2.4-0.9,4.9-3.4,5.4C92.6,34.6,90.2,33.1,89.6,30.7z M92.2,41.7c-0.6-2.4,0.9-4.9,3.4-5.4
		c2.4-0.6,4.9,0.9,5.4,3.4c0.6,2.4-0.9,4.9-3.4,5.4C95.2,45.7,92.8,44.2,92.2,41.7z M92.4,127.9c1-2,2-3.9,3-5.8
		c0.3-0.6,0.8-0.5,1.3-0.5c-0.1-0.3-0.2-0.6-0.2-1c0-0.3-0.2-0.8,0.3-1.1c-0.3-0.1-0.7-0.2-1.1-0.2c-0.9-0.1-1-0.2-0.7-1.2
		c0.2,0,0.4,0,0.7,0c0.3,0,0.7,0.1,0.9-0.3c0,0,0.1-0.1,0.1-0.1c0.8,0,1.6-0.1,2.4-0.1c0.2,0,0.4,0.1,0.6,0.1c1.7-0.3,3.5-0.6,5.3-1
		c0,0,0-0.1,0-0.1c-0.4,0-0.8-0.1-1.1-0.1c-0.5,0-0.7-0.3-0.7-0.7c0-0.4-0.2-0.7-0.1-1.1c0.1-0.7-0.3-1.1-0.8-1.5
		c-0.6-0.5-1.3-0.9-2-1.3c-0.3-0.2-0.5-0.4-0.5-0.8c-0.2-0.1-0.5-0.3-0.8-0.4c0.2-0.1,0.3-0.2,0.4-0.2c1.4,0.2,2.8,0.3,4.2,0.5
		c0,0.1,0,0.1,0,0.2c-0.7-0.1-1.5-0.2-2.3-0.3c2.1,1.1,4.2,1.8,6.4,2.5c0-0.1,0-0.1,0.1-0.2c-1.2-0.4-2.5-0.8-3.8-1.3
		c0,0,0-0.1,0-0.1c0.7,0.2,1.4,0.3,2.3,0.5c-0.5-0.3-0.9-0.6-1.3-1c0,0,0,0,0.1-0.1c0.2,0,0.4,0.1,0.6,0.1c0,0,0-0.1,0-0.1
		c-0.4-0.2-0.8-0.4-1.1-0.6c0-0.1,0-0.1,0.1-0.2c0.5,0,1,0.1,1.6,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0.7,0.1,1.3,0.1,2,0.2
		c1.1,0.1,2.3,0.2,3.4,0.4c1,0.1,2,0.3,2.9,0.5c0.4,0.1,0.8,0.4,1.1,0.5c0.4,0.1,0.8,0,1.3,0c-0.2-0.5-0.3-0.9-0.4-1.3
		c-0.9,0.1-1.7,0.1-2.6,0.2c1.3-0.2,2.7-0.2,3.8-0.9c0.8,0.7,1.3,2.9,0.9,3.7c0.1,0.3,0.2,0.6,0.2,1c0.1,0.3,0.1,0.6,0.1,0.8
		c0.6,0.2,1.2,0.4,1.8,0.6c1.3,0.4,2.7,0.9,4,1.3c0.9,0.3,1.9,0.2,2.9,0.3c1.2,0,2.4,0.2,3.6,0.2c0.8,0,1.7-0.2,2.6-0.3
		c0.3,0,0.6,0,1,0c0-0.3,0.1-0.7,0.2-1.1c0.2-1.4,1.1-2.3,2.2-3c1.4-1,2.9-1.8,4.3-2.8c0.3-0.2,0.6-0.2,0.8-0.4c0,0,0-0.1,0.1-0.1
		c1.5-0.7,3-1.4,4.6-2c0.6-0.2,1.4-0.1,2.1,0c2.7,0.4,5.2,1.1,7.8,1.9c1.2,0.4,1.9,1.3,2.6,2.3c0.3,0.4,0.7,0.8,1,1.2
		c0.2-0.9,0.8-0.3,1.2-0.5c-0.5-0.5-1.1-1-0.6-1.9c0.2-0.1,0.4-0.2,0.6-0.3c-0.1-0.3-0.1-0.6-0.2-0.8c-0.2-0.4-0.4-0.7-0.9-0.7
		c-0.6,0-1.1,0-1.7-0.2c-0.3,0.1-0.7,0.1-1,0.2c0-0.1,0-0.1,0-0.2c-0.5-0.2-1-0.4-1.6-0.6c0.1-0.2,0.2-0.5,0.3-0.9
		c-0.7,0.1-1.2,0.2-1.8,0.3c-0.4,0-0.7-0.2-0.8-0.6c-0.1-0.4,0-0.6,0.4-0.7c0.3-0.1,0.7-0.1,1-0.1c0.2,0,0.4-0.1,0.6-0.1
		c0.2-0.8,0.6-1.1,1.4-1c0.3,0,0.6-0.1,0.9-0.2c0,0,0,0,0.1,0c0.2-0.2,0.4-0.3,0.7-0.5c0.3-0.5,0.3-1.1-0.1-1.7
		c-0.3-0.5-0.7-0.6-1.2-0.2c-0.5,0.3-0.9,0.5-1.5,0.3c0.4,0.1,0.5-0.2,0.7-0.5c0.4-1,0.7-2.1,0.7-3.3c0,0.4,0.1,0.9,0.1,1.3
		c0.1,0,0.1,0,0.2,0c0.2-1.1,0.5-2.2,0.7-3.3c0,0,0.1,0,0.1,0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.3-0.2,0.6-0.2,0.7,0.1
		c0.5,0.8,0.9,1.6,1.4,2.4c0.1,0.2,0,0.5-0.1,0.7c-0.3,0.4-0.5,0.8-0.2,1.3c0.1,0.3,0.2,0.6,0.2,0.8c0.1,0.5,0.2,1.1,0.3,1.6
		c0,0.2,0,0.5,0,0.6c1.1,0,2.4-0.1,3.7-0.1c0.3,0,0.7,0.3,0.9,0.6c0.6,1,0.8,2.1,0.5,3.2c0,0.1,0,0.3-0.1,0.4
		c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0,0.4-0.1,0.6,0c0.4,0.2,0.6,0.1,0.8-0.2c0.4-0.7,0.9-1.3,1.3-2c0.4,0,0.8,0.1,1.2,0.1
		c0.2,0,0.4,0.2,0.5,0.4c0.6,1.1,1.2,2.3,1.8,3.4c0.2,0.4,0.4,0.8,0.5,1.3c0.1,0.2,0.1,0.4,0.1,0.6c0,0-0.1,0.1-0.1,0.1
		c0.4,0.3,0.7,0.6,1.1,0.9c-0.2,0.2-0.3,0.4-0.5,0.5c-0.2,0-0.3-0.1-0.5-0.1c0-0.1,0.1-0.2,0.1-0.4c-0.1,0.1-0.2,0.1-0.3,0.2
		c0-0.1,0-0.2-0.1-0.4c-0.2,0.1-0.3,0.1-0.6,0.3c0.7,1.3,1.1,2.7,2.2,3.7c-0.4-0.1-0.7-0.2-1.1-0.3c0,0.1-0.1,0.1-0.1,0.2
		c0.1,0.1,0.2,0.2,0.2,0.2c0.4,0.2,0.7,0.4,1.1,0.6c0.1,0.1,0.3,0.2,0.4,0.3c0,0,0-0.1,0.1-0.1c-0.2-0.3-0.4-0.6-0.7-0.8
		c0.6,0.6,1.3,1.2,2,1.8c-0.4,0.5-0.6,0.9-0.9,1.2c-0.1,0.1-0.3,0.1-0.4,0.1c-1,0-2,0.1-3,0.1s-1.8-0.3-2.6-0.9
		c-0.4-0.3-0.8-0.7-1.2-1c-0.1,0-0.1,0.1-0.2,0.1c0.5,0.7,0.9,1.5,1.4,2.3c0.2,0,0.4-0.1,0.6-0.1c0.9,0,1.3,0.5,1.1,1.3
		c-0.2,0.5-0.2,1.2-0.8,1.5c0.2,0.5,0.4,0.9,0.6,1.4c0.3,0.5,0.6,0.7,1.2,0.7c1.2-0.2,2.4-0.4,3.7-0.5c0.5-0.1,1-0.1,1.5-0.1
		c-1,0.2-1.9,0.4-2.9,0.6c0,0.1,0,0.1,0,0.2c0.2,0.1,0.4,0.3,0.6,0.2c2.4-0.5,4.6,0.1,6.8,0.6c0,0,0.1,0,0.1,0
		c0.1-0.1,0.2-0.2,0.3-0.4c0.7,0.4,1.3,0.8,2.1,1.2c-0.5,0.1-0.9,0.2-1.4,0.2c1.1,0.5,4.5,2.4,6.7,6.5c0.6,1.2,2.1,4.5,1.5,8.7
		c-0.1,1-0.7,4.3-3.3,7.3c-2,2.3-4,3.3-4.7,3.7c-0.4,0.2-1.1,0.5-2.1,0.8c-4.6,1.4-8.6,0-9.1-0.2c-1.1-0.4-3.2-1.3-5.2-3.3
		c-0.5-0.5-1.8-1.9-2.9-4.2c-1.6-3.7-1.7-7.4-0.3-11.3c-0.4,0.3-0.7,0.5-1,0.7c0,0-0.1,0-0.1-0.1c0-0.2,0.1-0.4,0.1-0.6
		c0.9-2.7,2.2-5.1,4.2-7.1c0.1-0.1,0.1-0.5,0.2-0.7c0.1-0.4,0.2-0.8,0.3-1.1c0-0.1,0.2-0.1,0.4-0.2c0-0.1,0-0.3,0.1-0.4
		c0.6-0.7,0.4-1.3,0-2c-0.3-0.4-0.5-0.9-0.8-1.4c0,0.7-0.6,0.9-1.1,1.3c-0.4,0.3-0.9,0.5-1.5,0.4c-0.4,0.7-0.7,1.5-1.1,2.2
		c-0.7,1.3-1.3,2.6-2,4c-0.2,0.4-0.5,0.6-0.9,0.6c-0.4,0-0.7,0.2-1.1,0.3c0.1,0.4,0.1,0.4-0.4,1c-0.1-0.1-0.1-0.1-0.3-0.2
		c0,0.1,0,0.2,0,0.3c0.3,0.6,0.4,0.7,0.7,0.6c-0.1-0.1-0.2-0.1-0.3-0.2c0.3-0.2,0.5-0.3,0.8-0.5c0.6,0.8,0.5,1.7,0.3,2.6
		c-0.1,0.3-0.2,0.4-0.8,0.3c0.3,3.3,0,6.4-2.2,9.1c0.3-0.7,0.6-1.3,1-2c0.7-1.2,0.9-2.6,0.8-3.9c-0.1-1.3-0.1-2.7-0.2-4
		c0,0.1,0,0.2,0,0.3c0,1,0,2-0.1,3c-0.1,0.8-0.2,1.6-0.3,2.5c-0.1,0.4-0.2,0.7-0.7,0.7c-0.5,1.5-0.9,3.2-2.5,4.2
		c0.7-1.7,1.4-3.3,2-5c0.2-0.5,0.2-1,0.4-1.6c0.2-0.8,0.3-1.6,0.5-2.5c0.1-0.8,0.3-1.6,0.4-2.5c0-0.3,0-0.6,0-0.7
		c-0.5,2.2-1.1,4.4-1.6,6.6c0,0.1-0.2,0.2-0.2,0.4c0,0-0.1-0.1-0.1-0.1c0-0.2,0-0.5,0-0.7c0,0-0.1,0-0.1,0c-0.1,0.3-0.2,0.5-0.3,0.8
		c-0.4,1.1-0.7,2.1-1.1,3.2c-0.2,0.4-0.5,0.8-0.7,1.2c-0.3,0.8-1,1.3-1.8,1.5c-0.1,0-0.2,0.2-0.4,0.4c0.3,0,0.5,0.1,0.8,0.1
		c0,0.1,0,0.1,0,0.2c-0.8,0.2-1.6,0.4-2.5,0.4c-0.5,0-1,0.1-1.5,0.3c-0.9,0.5-1.8,0.7-2.9,0.6c-1.4-0.1-2.9,0-4.3,0
		c-1.1,0-2.3,0.1-3.4,0c-0.6-0.1-1.1-0.4-1.6-0.7c-0.6-0.4-1.2-0.4-1.9-0.4c-0.6,0.1-1.2,0.1-1.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.1
		c-0.9-0.1-1.8-0.2-2.9-0.3c0.1-0.3,0.2-0.5,0.3-0.7c0.1,0.1,0.1,0.3,0.2,0.4c0.7,0.2,1.4,0.2,2.1,0.1c-0.7-0.7-1.5-0.4-2.3-0.5
		c0.1-0.2,0.2-0.4,0.3-0.6c-0.1-0.1-0.1-0.2-0.2-0.2c-1.9-0.6-3.6-1.7-5.4-2.6c-1.1-0.5-2-1.2-2.9-2c-0.4-0.3-0.9-0.6-1.3-0.9
		c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.7-0.6-1.4-0.8-2.2c-0.1-0.2-0.2-0.3-0.4-0.4c-0.4,0.2-0.7,0.4-1.1,0.5c0,0-0.1-0.1-0.1-0.1
		c0,0.3-0.1,0.6-0.1,0.9c0.2,0.2,0.4,0.3,0.6,0.6c-0.5,0.6-0.9,1.1-1.4,1.7c0-0.1,0-0.2,0.1-0.4c-0.2-0.1-0.4-0.2-0.6-0.4
		c-0.3,0.4-0.5,0.8-0.8,1.2c0.2,0.2,0.4,0.3,0.5,0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.2,0,0.4c0.6,0,1.1,0.1,1.7,0.1
		c-1.2,0.1-2.4-0.2-3.6,0.2c0.8,0.6,1.6,1.2,2.4,1.7c0.1,0.1,0.3,0.1,0.4,0c0.2,0,0.5-0.1,0.8-0.2c0.1-0.4,0.2-1,0.2-1.6
		c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.1-0.3-0.2-0.5-0.4c1,0,1.1-0.8,1.2-1.2c0.5,0.4,1,0.9,1.5,1.4
		c-0.5-0.2-0.9-0.5-1.4-0.8c-0.1,0.3-0.2,0.4-0.3,0.6c1.1,0.8,2.1,1.5,3.2,2.3c0.2,0.1,0.5,0.2,0.8,0.3c-0.3,1.3-1.2,3.8-3.4,6.2
		c-2.3,2.5-4.8,3.7-6.5,4.2c-1.3,0.4-5.4,1.5-10-0.2c-1.1-0.4-5.5-2.2-8-7c-0.5-0.9-2-4.3-1.6-8.8c0.5-6,4.6-10.5,8.9-12.2
		c-0.1,0.4-0.2,0.7-0.4,1.1c0.8,0.1,1.5,0.2,2.3,0.4c0,0,0,0,0-0.1c-0.6-0.2-1.3-0.4-2.1-0.7c0.3-0.6,0.5-1.2,0.8-1.8
		c2.1,1,4.3,1.7,5.9,3.4c-1.1-1.6-2.8-2.4-4.6-3c0,0,0-0.1,0-0.1c0.4-0.1,0.9-0.2,1.3-0.3c3.5-0.6,6.9-0.1,10,1.6
		c0.4,0.2,0.7,0.6,1,0.9c0.4,0.5,0.8,1,1.2,1.5c0,0.2-0.1,0.3-0.2,0.4c0.4,0,0.7,0.1,0.4,0.6c-0.1,0.1,0,0.3,0,0.6
		c0.6-0.3,1.2-0.5,1.8-0.8c0.2,0.3,0.5,0.6,0.7,0.9c0.1,0.1,0.2,0.2,0.4,0.3c1.1,0,2.2,0.1,3.3,0.1c0.2,0,0.4,0,0.6-0.1
		c-0.1-0.8-0.2-1.6-0.4-2.4c0.2,0,0.3-0.1,0.5-0.1c0,0,0-0.1,0-0.1c-0.7-0.3-1.4-0.6-2.2-1c-0.5,0.4-0.9,0.7-1.4,1.1
		c-0.5,0.4-0.9,0.8-1.4,1.1c0.1-0.3,0.2-0.6-0.2-0.8c0.1,0,0.3-0.1,0.4-0.1c0.3,0.3,0.6,0.1,0.8-0.1c0.6-0.5,1.2-1.1,2-1.5
		c0.3-0.2,0.7-0.2,1.1-0.3c0.3-0.1,0.5-0.2,0.8-0.3c0-0.2,0-0.4,0.1-0.5c-0.1-0.4-0.4-0.5-0.8-0.4c-0.1,0-0.3,0.1-0.4,0
		c-0.7-0.4-1.5-0.8-2.2-1.2c-0.2,0.1-0.5,0.4-0.7,0.3c-0.2-0.1-0.4-0.4-0.5-0.7c-0.1-0.3-0.1-0.7-0.1-1c-1.4-0.8-2.8-1.7-4.3-2.4
		c-0.9-0.4-1.7-1-2.5-1.6c-0.4-0.3-0.9-0.6-1.4-0.8c-0.4-0.2-0.8-0.3-1.1-0.5c-1.3-0.9-2.7-0.8-4.1-0.7c-2,0.1-3.9,0.2-5.7,0.3
		c-0.3,0.3-0.5,0.7-0.8,0.9c-1.2,0.6-2.2,1.3-3.1,2.3c-0.2,0.2-0.4,0.3-0.6,0.5c-0.4,0.4-0.9,0.8-1.3,1.2c-0.1,0.1-0.2,0.2-0.3,0.4
		c-1.3,2.1-2.7,4.2-4,6.3c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1-0.1c0.4-1,0.8-2,1.2-3.1c-0.2-0.3-0.4-0.2-0.6,0.1
		c-0.2,0.3-0.4,0.6-0.6,1c0-0.1-0.1-0.1-0.2-0.2C92.3,128.2,92.3,128,92.4,127.9z"/>
	<path d="M130.6,132.1c0,0.9,0,1.7,0,2.5c0.6,0.3,1.4-0.1,2.1,0.5c0.1,0,0.3-0.1,0.4-0.1c0-0.3,0-0.6,0-0.9c0,0,0.1,0,0.1,0.1
		c0.2-0.2,0.3-0.4,0.4-0.5c-0.2-0.3-0.3-0.5-0.4-0.6c0.1-0.1,0.3-0.2,0.4-0.3C132.7,131.9,131.7,132.3,130.6,132.1z"/>
	<path d="M131.6,124.2c0,0,0-0.1,0.1-0.1c0-0.1-0.2-0.2-0.4-0.4c3.8-0.4,7.6-0.5,11.3-1.2c0.1-0.4-0.1-0.6-0.4-0.8
		c-0.8-0.4-1.7-0.7-2.5-1.1c-0.2,0.1-0.4,0.3-0.7,0.4c0-0.1,0-0.2,0-0.4c0,0-0.1-0.1-0.1-0.1c-0.1,0.8-0.7,0.8-1.2,0.9
		c-2.3,0.5-4.6,1-6.9,1.6c-0.1,0-0.2,0.1-0.5,0.1C130.8,123.7,131.2,124,131.6,124.2z"/>
	<path d="M130.7,118.3c-0.7,0-1.4-0.1-2.2-0.1c0,0.1,0,0.1,0,0.2c0.7,0,1.5,0.1,2.2,0.1C130.7,118.5,130.7,118.4,130.7,118.3z"/>
	<path d="M82.7,79.7c-0.3-0.7-0.4-1.5-0.6-2.5c-1.6,1.4-1.9,2.9-1.1,4.2c0.8,1.3,2.3,1.8,3.7,1.3c1.4-0.5,2.2-1.9,2-3.3
		c-0.2-1.5-1.2-2.4-2.6-2.4c0.2,0.8,0.4,1.6,0.4,2.4c0,0.4-0.5,0.8-0.8,1.2C83.3,80.3,82.8,80.1,82.7,79.7z"/>
	<path d="M237.4,68.9c-10.7,10.3-21.4,20.7-32,31c0-0.9,0-1.7,0-2.6c11.3-11,22.6-21.9,33.9-32.8c-0.4-0.4-0.7-0.8-1.1-1.1
		c-0.4,0.3-0.6,0.5-0.9,0.8c-10.7,10.3-21.3,20.6-32,30.9c0-0.9,0-1.7,0-2.6c10.6-10.3,21-20.3,31.5-30.4c-1.3-1.2-2.5-2.3-3.8-3.5
		c0,0-0.1,0.1-0.3,0.2c-9.2,8.9-18.3,17.7-27.5,26.6c0-10.1,0-20.2,0-30.2c-2,0-3.9,0-6,0c0,0.5,0,1,0,1.4c0,47.1,0,94.3,0,141.4
		c0,2-0.1,2.1-2,2.1c-39.6,0-79.1,0-118.7,0c-0.7,0-1.3,0-2,0c0,2.4,0,4.7,0,6.9c42.9,0,85.8,0,128.6,0c0-33.3,0-66.5,0-99.8
		c13.1-12.6,25.9-25,38.9-37.6c-1.3-1.2-2.5-2.4-4.1-3.8C239.3,66.7,238.5,67.9,237.4,68.9z"/>
	<path d="M82.6,64.1c-0.3-0.7-0.4-1.4-0.6-2.2c-1.5,1.1-1.9,2.6-1.2,4c0.7,1.3,2.2,2,3.6,1.6c1.4-0.4,2.4-1.8,2.2-3.3
		c-0.1-1.5-1.1-2.5-2.6-2.6c0.1,0.7,0.4,1.5,0.4,2.2c0,0.5-0.4,0.9-0.7,1.4C83.3,64.9,82.8,64.6,82.6,64.1z"/>
	<path d="M82.7,187.5c-0.3-0.7-0.4-1.5-0.6-2.3c-1.5,1.2-1.9,2.6-1.2,4c0.7,1.3,2.2,2,3.6,1.6c1.4-0.4,2.4-1.8,2.2-3.3
		c-0.1-1.5-1.1-2.5-2.6-2.6c0.1,0.8,0.4,1.6,0.4,2.3c0,0.4-0.4,0.8-0.7,1.2C83.4,188.2,82.8,187.9,82.7,187.5z"/>
	<path d="M244.1,55c-2.9,0.8-5.9,1.6-9,2.4c3.4,3.5,6.6,6.8,10,10.3c1-3.1,1.9-5.9,2.8-8.8C248.3,58.1,245.1,54.8,244.1,55z"/>
	<path d="M246.9,54.3c0.8,0.7,1.4,1.3,2,1.9c0.2-0.8,0.5-1.6,0.8-2.6C248.7,53.8,247.9,54,246.9,54.3z"/>
	<path d="M82.7,141.4c-0.3-0.7-0.4-1.5-0.6-2.3c-1.5,1-1.9,2.4-1.3,3.8c0.6,1.3,2,2.1,3.4,1.8c1.5-0.3,2.5-1.6,2.5-3.1
		c0-1.5-1-2.6-2.6-2.9c0.2,0.8,0.4,1.7,0.4,2.5c0,0.4-0.5,0.8-0.8,1.2C83.3,142,82.8,141.8,82.7,141.4z"/>
	<path d="M82.7,156.7c-0.3-0.7-0.4-1.4-0.6-2.4c-1.5,1.3-1.9,2.8-1.2,4.1c0.7,1.4,2.2,2,3.6,1.5c1.4-0.4,2.3-1.7,2.1-3.2
		c-0.1-1.5-1.1-2.5-2.6-2.6c0.2,0.8,0.4,1.6,0.4,2.3c0,0.4-0.5,0.8-0.7,1.2C83.3,157.4,82.8,157.1,82.7,156.7z"/>
	<path d="M82.7,125.9c-0.3-0.7-0.4-1.5-0.7-2.5c-1.6,1.3-1.9,2.8-1.2,4.1c0.7,1.3,2.3,1.9,3.7,1.5c1.5-0.5,2.3-1.9,2.1-3.5
		c-0.2-1.4-1.2-2.3-2.6-2.3c0.1,0.8,0.4,1.7,0.4,2.5c0,0.4-0.5,0.7-0.7,1.1C83.3,126.5,82.9,126.3,82.7,125.9z"/>
	<path d="M82.7,110.4c-0.3-0.7-0.4-1.4-0.6-2.3c-1.6,1.3-1.9,2.7-1.2,4.1c0.7,1.3,2.2,2,3.6,1.5c1.4-0.4,2.3-1.8,2.1-3.3
		c-0.2-1.5-1.1-2.4-2.6-2.5c0.1,0.8,0.4,1.6,0.4,2.3c0,0.4-0.5,0.8-0.7,1.2C83.3,111.1,82.8,110.8,82.7,110.4z"/>
	<path d="M82.7,95.1c-0.3-0.7-0.4-1.5-0.6-2.4c-1.6,1.4-1.9,2.9-1.1,4.2c0.7,1.3,2.3,1.8,3.7,1.3c1.4-0.5,2.2-1.9,2-3.4
		c-0.2-1.4-1.2-2.3-2.6-2.3c0.1,0.8,0.4,1.6,0.4,2.3c0,0.4-0.5,0.8-0.8,1.2C83.3,95.7,82.8,95.5,82.7,95.1z"/>
	<path d="M164.3,109.6c0,0.1,0.2,0,0.3,0c0-0.1,0.1-0.1,0.1-0.2c-0.3-0.2-0.5-0.5-0.8-0.8C163.7,109.2,164.1,109.3,164.3,109.6z"/>
	<path d="M162.1,108.6c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0.2,0.1,0.4,0.2,0.7c0.3-0.5,0.6-0.9,0.8-1.3c0.8,0.2,1.7,0.5,2.2,1.4
		c0.1,0,0.2-0.1,0.2-0.1c0.1-0.8,0.2-1.7,0.2-2.6c0-0.6-0.2-0.9-0.8-0.9c-0.8,0-1.7,0.2-2.5,0.3c0,0.3,0,0.6,0,0.8c0,0,0,0-0.1,0.1
		c-0.1,0-0.2-0.1-0.4-0.1c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0.2-0.6,0.3-0.9,0.5c0.1,0.2,0.3,0.5,0.4,0.7
		C162,108.3,162,108.4,162.1,108.6z"/>
	<path d="M152.7,138.9c0,0,0.1,0.1,0.1,0.1c0.2-0.1,0.3-0.2,0.5-0.3c-0.1-0.1-0.2-0.2-0.4-0.4C152.8,138.5,152.8,138.7,152.7,138.9z
		"/>
	<path d="M154.6,139.1c0.3-0.7,0.5-1.4,0.8-2.1s0.7-1.4,1.1-2.1c-0.1,0-0.1-0.1-0.2-0.1c-0.5,0.4-1.1,0.8-1.6,1.2
		c0.1,1.1,0.2,2.2-0.3,3.1C154.5,139.1,154.5,139.1,154.6,139.1z"/>
	<path d="M154,139.2c-0.3,0-0.5-0.1-0.9-0.1C153.7,139.7,153.7,139.7,154,139.2z"/>
	<path d="M165.4,114.4c-0.4-1.2-1.1-2.6-1.5-2.7c0.3,0.5,0.5,1,0.8,1.4C164.9,113.5,165.1,113.9,165.4,114.4z"/>
	<path d="M151.4,139.5C151.4,139.5,151.4,139.5,151.4,139.5c0.1,0,0.1,0,0.1,0c0,0,0,0-0.1-0.1C151.4,139.4,151.4,139.4,151.4,139.5
		z"/>
	<path d="M156.3,140.2c0,0.4,0.1,0.7,0,1.1c-0.1,1.7-0.1,1.4-1.5,1.4c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.3-0.1-0.5
		c0,0.2-0.1,0.4-0.1,0.5c-0.6,0.1-1.2,0.1-1.8,0.2c0.4,0.2,0.6,0.3,0.9,0.4c-0.7,0.4-1.3,0.7-2,1c0,0.3,0,0.6,0.1,0.8
		c0.5,0.3,0.9,0.6,1.4,0.8c0.1,0.1,0.4,0.1,0.5,0c1.1-0.3,1.8-1,2.2-2.1c1-2.5,1.8-5,2.3-7.6c0.1-0.4,0.1-0.8,0.2-1.1
		c-1.2,1.3-1.5,3-2.3,4.4C156.1,139.8,156.2,140,156.3,140.2z"/>
	<path d="M140.3,144.9c0.1-0.4,0.2-0.9,0.4-1.2c0.4-0.5,0.4-1,0.3-1.5c-0.3,0-0.5-0.1-0.8-0.1c0.3,0.4,0.6,0.8,0.3,1.2
		c0.1-0.4-0.1-0.7-0.5-0.8c-0.2,0-0.5,0.1-0.6,0.3c-0.3,0.5,0.2,0.7,0.5,1c-0.5,0.2-0.9,0.2-1.3,0.7c0.3-0.1,0.6-0.1,0.8-0.2
		C139.8,144.5,140,144.7,140.3,144.9z"/>
	<path d="M140.3,135.8c-0.1,0.6,0.3,1.8,1,2.4C141,137.5,140.6,136.6,140.3,135.8z"/>
	<path d="M147.8,138c-1.7,1.8-3.5,2.4-5.3,1.4c-1.7-0.8-2.9-2.5-2.4-5.4c-0.5,1.2-0.4,2.4,0.2,3.6c0.1,0.3,0.2,0.6,0.3,0.9
		c0.1,0.2,0.2,0.4,0.4,0.5c0.3,0.2,0.4,0.4,0.3,0.8c0,0.1-0.1,0.2,0,0.3c0.3,0.6,0.6,1.3,0.9,1.8c0.9,0,0.9,0,1.1,0.7
		c0.7,0.2,1.4,0.2,2.1,0.1c0.2,0,0.3,0,0.5,0c0.9,0.1,1.8,0.5,2.3-0.6c0,0,0.1,0,0.1-0.1c0.5-0.5,1-0.9,1.5-1.4
		C148.5,140.1,148.1,139,147.8,138z"/>
	<path d="M150.9,136.9c0.5,0.7,0.6,1.3,0.2,1.6c-0.4,0.2-0.9,0.4-1.3,0.3c-0.8-0.2-0.8-0.9-0.5-1.6c-0.5,0.9-0.6,1.1-0.1,1.6
		c0.4,0.4,1.1,0.6,1.6,0.4c0.6-0.2,1-0.8,0.9-1.4C151.6,137.4,151.5,137,150.9,136.9z"/>
	<path d="M139.3,147.7c0,0.2,0,0.3,0,0.5c0.6-0.3,1.4-0.3,2,0c0.6,0.3,1.3,0.2,1.9-0.1c0.5-0.2,0.7-0.2,0.9,0.2
		c0.6,0.1,1.2,0.2,1.7,0.2s1.1,0,1.5-0.2c0-0.1,0-0.2,0-0.2C144.7,147.9,142.1,147.8,139.3,147.7z"/>
	<path d="M152.4,124.9c-0.2,0-0.4-0.2-0.7-0.4c0.2,0.4,0.5,0.7,0.9,0.6c0.2,0,0.4-0.3,0.6-0.5c0,0-0.1-0.1-0.1-0.1
		C152.9,124.7,152.6,124.9,152.4,124.9z"/>
	<path d="M168.7,111.2c0.4-0.6,0.3-1.1-0.2-1.4c-0.1,0.1-0.1,0.2-0.2,0.3C168.6,110.4,168.6,110.4,168.7,111.2z"/>
	<path d="M168.7,121.1c-0.6-1.3-1.2-2.6-1.8-3.9c0,0-0.1,0-0.1,0.1c0.6,1.3,1.2,2.6,1.8,3.9C168.6,121.1,168.7,121.1,168.7,121.1z"
		/>
	<path d="M166.1,105.4c-0.3-0.2-0.6-0.4-0.9-0.4c-0.6,0-1.2,0-1.8,0.1c-0.3,0-0.5,0.2-0.4,0.6C164,105.6,165,105.5,166.1,105.4z"/>
	<path d="M159.5,126.9c0.2-0.5,0.4-1.1,0.5-1.6C159.6,125.8,159.3,126.3,159.5,126.9z"/>
	<path d="M143.9,131.8c0,0.4-0.1,0.8-0.1,1.2c0.4,0,0.8,0,1.4,0c-0.3-0.2-0.5-0.2-0.6-0.3C144.7,132,144.4,131.7,143.9,131.8z"/>
	<path d="M156.8,130.9c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0,0.1-0.1,0.2-0.1c-0.2-0.3-0.4-0.5-0.5-0.8C157,130.8,156.9,130.8,156.8,130.9z
		"/>
	<path d="M156.4,126.2c0.2,0.5,0.4,0.7,0.8,0.7c0.3,0,0.6-0.2,0.6-0.6C157.2,126.8,157.1,126.8,156.4,126.2z"/>
	<path d="M158.5,131.9c0.1,0,0.1,0.1,0.2,0.1c0.5-1.1,1.1-2.2,1.6-3.2c0,0-0.1,0-0.1-0.1C159.6,129.8,159.1,130.9,158.5,131.9z"/>
	<path d="M155.9,132.7c0.3-0.3,0.5-0.6,0.8-1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3,0.3-0.5,0.7-0.7,1C155.8,132.6,155.8,132.6,155.9,132.7
		z"/>
	<path d="M159,126.4c0.2-0.6,0-1-0.4-1.3c0-0.1-0.1,0-0.2,0c0-0.4-0.2-0.6-0.5-0.6c0,0.2,0,0.4-0.1,0.8c0.2,0,0.3,0,0.5,0
		c0,0.3-0.1,0.6-0.1,0.9C158.6,126.2,158.8,126.3,159,126.4z"/>
</g>



</svg>);
export default iLIVREmotoETM;