import React, { useEffect, useState } from 'react';
import StarRating from './Star';
import { HiUserCircle } from "react-icons/hi2";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const ReviewGoogle = [
    {
        "reviews": "Merci beaucoup, au moniteur, très accueillant nous met en confiance. Et explique super bien. J'ai commencé ma première heure de conduite,le 15/02 je les obtenu le 13/02 merci encore au moniteur",
        "averageRating": 5,
        "name": "Kyslane",
        "totalReviewCount": 4
    },
    {
        "reviews": "Une auto école de qualité, un grand merci à mon moniteur pour son professionnalisme et sa bienveillance",
        "averageRating": 5,
        "name": "Victoria",
        "totalReviewCount": 4
    },
    {
        "reviews": "Une auto école au top du top. Une expérience parfaite un enseignement clair et ludique qui m'a permis d'obtenir mon permis de conduire rapidement. Je vous recommande vivement cette auto école qui sait être à l'écoute de leurs élèves. Vraiment merci beaucoup à vous tous grace à vous je suis titulaire de mon permis.",
        "averageRating": 5,
        "name": "Yann",
        "totalReviewCount": 4
    },
    {
        "reviews": "Excellente auto-école ! J'ai pris une vingtaine d'heures avec eux et j'ai eu mon permis du deuxième coup. Les moniteurs sont compétents et patients. Je recommande !",
        "averageRating": 5,
        "name": "Camille",
        "totalReviewCount": 4
    },
    {
        "reviews": "Super auto-école, un moniteur exceptionnel (Dhya) à la hauteur de mes attentes. Il a su analyser en très peu de temps ma façon de conduire (j'avais quelques heures de conduite) et s'est totalement adapté à mon humeur, mes horaires de libre, ma volonté d'avoir mon permis rapidement.",
        "averageRating": 5,
        "name": "Caroline",
        "totalReviewCount": 2
    },

];

const AvisGoogle = () => {

    const [eleNumber, setEleNumber] = useState(0);
    const [count, setCount] = useState(100);



    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => {
                if (prevCount <= 0) {
                    setEleNumber(prevEleNumber => (prevEleNumber + 1) % ReviewGoogle.length);
                    return 100;
                }
                return prevCount - 1;
            });
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <div className='lay-reviews'>
            <div className='widthMaxcont'>
                <div className='theContAvis'>
                    <button
                    onClick={() => {
                        setEleNumber(prevEleNumber => (prevEleNumber - 1 + ReviewGoogle.length) % ReviewGoogle.length);
                        setCount(100);
                    }} 
                    className='btnGalc'>

                        <IoIosArrowBack color={'gold'} size={26} />
                    </button>

                    <div className='GalAvis'>
                        {ReviewGoogle.map((avis, index) =>
                            <div
                                style={{ transform: index === eleNumber && 'scale(1)' }}
                                className={index === eleNumber ? 'avisOne efGAVIS' : 'avisOne'} 
                                key={index}>
                                <div className='NameAndRate'>
                                    <p className='nameRateur'>
                                        <HiUserCircle size={28} color={'#14689f'} />
                                        {avis.name}
                                    </p>
                                    <StarRating rating={avis.averageRating} />
                                </div>


                                <p className='lavisTTxt'>{avis.reviews}</p>
                                <div className='CONTcharg'>
                                    <div
                                        className={index === eleNumber ? 'theBTNCHARG brIT' : 'theBTNCHARG'}
                                        style={{ width: index === eleNumber ? count + '%' : '0%' }}>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <button 
                    onClick={() => {
                        setEleNumber(prevEleNumber => (prevEleNumber + 1 + ReviewGoogle.length) % ReviewGoogle.length);
                        setCount(100);
                    }}                    
                    className='btnGalc'>
                        <IoIosArrowForward color={'gold'} size={26} />
                    </button>


                </div>
            </div>
        </div>
    );


};

export default AvisGoogle;