import React from 'react';
import Header from '../../components/Header';
import ContactBan from '../../components/ContactBan';
import Footer from '../../components/Footer';

function PolitiqueRGPD() {
    return (
        <React.Fragment>

            <Header />

            <div className='GpageMPORM'>
                <div>
                    <h2>PolitiqueRGPD</h2>
                    <p>The page PolitiqueRGPD</p>
                </div>
            </div>

            <ContactBan />
            <Footer />

        </React.Fragment>
    );
}

export default PolitiqueRGPD;