import React from "react";
const iVOITURETTE = ({size=24, color="#0056b3"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
width={size} height={size} 
viewBox="0 0 300 182" 
fill={color} 
stroke="none"
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round">
<g>
	<path d="M197.1,8.3c-2-0.9-3.9-1.9-6.2-3c2.9,0.3,5.3,1.2,7.6,2.5c6.8,3.6,12.6,8.7,18,14c9.1,8.9,17.7,18.2,26.5,27.4
		c0.2,0.2,0.3,0.5,0.6,1c-0.8,0.1-1.4,0.2-2,0.4c-2.6,0.8-5.2,1.6-7.8,2.3c-0.8,0.2-1.8,0.3-2.6,0.2c-4.8-0.8-9.6-1.8-14.4-2.6
		c-3-0.5-6-0.7-9.1-0.9c-15.8-1.3-31.5-1.2-47.3,0.1c-5.5,0.5-11,0.7-16.4,1.1c-1.3,0.1-2.6,0.3-3.9,0.5c-0.1,0.2-0.1,0.4-0.2,0.6
		c1.2,0.6,2.4,1.1,3.6,1.7c3.1,1.5,6.4,2.4,9.3,4.3c2.6,1.8,5.9,2.5,8.9,3.8c0.8,0.3,1.6,0.8,2.3,1.7c-0.9-0.1-1.8-0.2-2.7-0.4
		c-9.3-2.7-18.4-5.8-27.8-8.2c-8.7-2.3-17.6-4.1-26.5-5.9c-3.8-0.8-7.2,0.9-10.6,2.4c-2.4,1-4.6,2.2-7.3,1.9c-0.3,0-0.6,0-0.9,0.2
		c-5.6,2.9-11.5,1.4-17.4,1.2c-9.1-0.3-18.3-0.3-27.4-0.5c-0.5,0-0.9-0.2-1.1-0.3c1.6-6,3.2-11.8,4.7-17.7c0.5-2,0.5-4.2,0.9-6.2
		c0.3-1.3,0.9-2.4,1.4-3.7c1.2-3.4,2.4-6.9,3.6-10.4c1.6-4.5,5-7.2,9.2-8.6C69.3,5,76.3,3.2,83.3,1.3c2.2-0.6,4.3-0.4,6.5,0.6
		c1.6,0.7,3.5,0.9,5.3,0.8c14.4-1.2,28.8-2.2,43.3-1.4c9.3,0.5,18.6,1.6,27.9,2.8c6.8,0.8,13.6,2.1,20.5,3.3c2.6,0.4,5.2,1,7.8,1.5
		c0.8,0.1,1.6,0.2,2.3,0.2C197,8.8,197.1,8.6,197.1,8.3z M225.4,49.7c0.1-1.1,0.3-1.8,0.2-2.5c-0.4-3.3-1.1-6.2-3.8-8.6
		c-5.5-5-10.6-10.4-16.1-15.3c-5.1-4.5-11.1-7.6-18.3-7c-5.1,0.4-10.1,1.1-15.1,1.8c-2.8,0.4-3.3,1.5-2.4,4.3
		c1.6,4.7,3.2,9.4,4.7,14.1c0.4,1.2,0.8,2.4,1.1,3.6c0.7,0,1,0.1,1.3,0c5.3-2.7,11-1.2,16.5-1.2c7.9,0,14.6,3.2,20.5,8.2
		C217.3,50,221.1,49.8,225.4,49.7z M85.8,42.3c2.9-4.2,2.8-8.1,0.3-12c-5.3-1.4-10.3-2.6-15.2-4c-4.9-1.4-9.8-3-14.8-4.5
		c-3.6,5.5-9.5,25.8-8.5,29.7c7,0,14.2,0,21.7,0c-6.5-1.9-7.8-2.8-8.1-5.4c5.4-0.5,10.7-0.9,16.1-1.4c0-0.3,0.1-0.5,0.1-0.8
		c-0.8-0.2-1.6-0.7-2.3-0.7c-3.7,0-7.3,0.1-11,0.3c-1.4,0-2.8,0.7-3.9-0.6c0.8-2.8,3-4.8,6.1-5.1c5.2-0.6,10.3-0.4,15,2.5
		C82.7,41.2,84.3,41.7,85.8,42.3z M148.9,42c2.9,1.3,5,2.4,7.2,3.3c0.9,0.4,2.1,1,2.7,0.6c0.8-0.5,1.3-1.7,1.6-2.6
		c0.7-2.3,1.2-4.7,1.8-7c0.2-0.7,0.4-1.5,0.1-2c-0.8-1.7-1.8-3.4-2.9-5c-0.5-0.7-1.7-1.4-2.6-1.3c-3.3,0.3-6.5,1-9.6,2.5
		c0.5,2.2,1.1,4.1,1.4,6.1C148.9,38.6,148.8,40.6,148.9,42z M121.5,33.7c-4-2.9-8-2-11.7-1.8c-1.3,0.1-1.4,1.4-1.1,2.4
		c0.7,2.4,1.3,4.9,2.1,7.3c0.5,1.3,1.1,2.6,2.1,3.6c1.8,1.9,3.6,1.4,4.6-1c0.4-1,0.9-2,1.3-3.1C119.8,38.6,120.6,36.2,121.5,33.7z
		 M85.7,51.4c-0.7-2.8-2.5-2.8-4.3-2.4c-2.7,0.7-5.4,1.6-8.1,2.4C77.4,51.8,81.5,53.7,85.7,51.4z"/>
	<path d="M62.8,163.7c10.1-0.4,20.3-0.6,31.1-0.9c-0.8-2.4-1.5-4.3-2.1-6.3c-0.4-1.1-0.8-2.3-0.9-3.4c-0.3-4.9-0.7-9.7-0.7-14.6
		c0-9.1,2.3-17.5,7.9-24.8c0.4-0.5,0.9-1.1,0.8-1.6c-0.5-2.3,0.6-4.2,1.2-6.2c1.6-4.9,6.7-7.2,11.5-5.4c3.5,1.3,5.9,3.8,7.7,7.4
		c-6.4-1.7-11.8,0.1-16.5,3.8c-2.7,2.1-4.9,4.8-6.1,8.2c-0.7,2.1-2.1,3.9-3,5.9c-0.4,1-0.4,2.2-0.6,3.8c2.7-4.2,4.7-8.2,7.4-11.7
		c4.4-5.7,12.7-8.8,19.6-6.5c2.1,0.7,3.6,1.8,4.5,3.8c4.1,8.9,6.4,18.4,7.9,28c0.2,1.1,0,2.2-0.2,3.3c-0.2,1.1-0.7,2.1-1.4,3.8
		c-2-0.9-3.7-1.8-5.8-2.7c-0.1,0.2-1,0.8-0.9,1.2c0.8,3.6,2,7,5.2,9.3c0.1,0.1,0.4,0.1,0.9,0.1c1.1-1.5,2.3-3,3.8-5
		c0.6,1.9,1,3.5,1.5,5.3c1,0.1,2,0.1,3,0.2c7.8,0.8,15.6,1.5,23.4,2.4c1.9,0.2,3.6,1.5,5.5,1.7c6.6,0.9,13.3,1.7,20,2.4
		c7.4,0.8,14.8,1.5,22.1,2.2c7.4,0.7,14.8,1.4,22.2,2.1c5.2,0.4,10.3,0.8,15.5,1.1c1,0.1,2,0,3.1-0.3c-3.9-3.7-8.3-6.9-10.9-12.2
		c1.3-0.2,2.2-0.5,3.2-0.6c5.5-0.4,10.9-0.8,16.4-1c1.2-0.1,2.7,0.3,3.7,0.9c1.4,0.7,2.5,0.8,4,0.5c3.6-0.6,7.3-0.9,10.9-1.5
		c5.2-0.8,10.4-1.7,15.7-2.5c0.4-0.1,0.8-0.1,1.7-0.2c-3.4,8.4-9.3,13.8-17.3,17.5c0.7,0.7,1.2,1.3,1.8,1.9c0,0.1,0,0.4-0.2,0.5
		c-3.5,2.3-7.4,3-11.6,3.2c-8.7,0.4-17.4,1.1-26.2,1.6c-4.5,0.3-8.9,0.5-13.4,0.7c-8,0.3-16.1,0.6-24.1,0.7
		c-21.9,0.2-43.8,0.4-65.7,0.5c-5.9,0-11.8,0-17.7-0.2c-4.2-0.2-8.3-0.8-12.4-1.3c-2.3-0.3-4.3-1.1-5.2-3.1c-4-0.7-7.6-1.5-11.3-2.1
		c-4-0.6-8.1-1.1-12.1-1.7 M52.8,168.3c-3.7-0.5-7.5-0.9-11.2-1c-7.5-0.3-15-0.3-22.5-0.5c-5.6-0.2-9.2-3.1-11.4-9.1
		c1,0.8,1.6,1.4,2.2,1.9c3,2.4,6.9,1.8,8.9-1.5c2.5-4,3.8-8.5,4.7-13.1c1.3-7.5,1.8-15,0.1-22.4c-0.9-4-2-7.9-3.5-11.7
		c-0.7-1.9-2.4-3.5-3.9-5c-2.4-2.3-5.3-2-7.4,0.8c-4,5.2-5.5,11.2-6.1,17.6c-0.3,3-0.4,6.1-0.8,9.1c-1.1-6.1-1.2-12.2-0.3-18.3
		c0.6-4.5,2-8.7,4.8-12.3c2.7-3.4,6.3-4,9.5-1.2c1.8,1.6,3.2,3.6,4.5,5.7c4.2,6.9,5.8,14.7,7.5,22.5c1.1,5,2.1,9.9,3.3,14.9
		c0.8,3.5,3.2,5.6,7.2,6c-0.6,5.4-3.3,9.6-6.5,14.4 M116.3,159.5c1.3-3.6,0.7-7.6-1.2-8.9C111.1,153.1,111.1,155.7,116.3,159.5z"/>
	<path d="M231.2,151.4c-8.1,0-16.3-0.1-24.4,0c-6.3,0.1-12.3-1.3-18.4-2.6c-2.2-0.5-2.7-1.5-1.8-3.6c3.5-8.4,7.1-16.9,10.7-25.3
		c1.3-2.9,3.5-4.1,6.8-4c16.6,0.1,33.2,0,49.8-0.1c5,0,10-0.5,14.9-0.7c3.2-0.1,4.1,0.3,5,3.4c1.2,4.1,2.2,8.3,3,12.4
		c0.7,3.4,1,6.8,1.4,10.3c0.4,3.3-0.3,4.3-3.4,5.6c-4.7,1.9-9.5,2.9-14.5,3.2c-9.7,0.4-19.3,0.7-29,1.1
		C231.2,151.2,231.2,151.3,231.2,151.4z"/>
	<path d="M146.5,70.2c2.9,1,5.8,1.8,8.5,2.9c4.1,1.7,8.1,3.6,12.3,5.4c4.9,2.1,8.7,5.6,11.8,9.8c1.1,1.5,2.2,2.7,4.6,1.8
		c-1.6-3.9-4.8-6.5-7.7-10.1c33.9,1.6,67.3,1.6,101,0.9c-0.3,2.3-0.6,4.1-0.8,5.9c-0.1,1.2-0.5,2.6,0,3.6c1.1,2,0,3.6-0.8,5
		c-0.5,0.9-2.2,1.5-3.4,1.5c-8,0.4-16.1,0.7-24.1,0.8c-15.8,0.1-31.6-0.1-47.3-0.1c-1.2,0-2.4-0.1-3.7-0.2c-2.6,0-4.7-0.8-6.3-3
		c-2-2.7-5.1-2.5-7.9-2.6c-0.3,0.2-0.5,0.3-0.6,0.5c-1.9,3.7-1.9,3.8-6.1,3.2c-10.3-1.4-20.5-3-30.5-6c-1.6-0.5-3.3-1.6-4.5-2.8
		c-1.5-1.5-2.9-2.6-5-3.2c-6.2-1.7-10-6.4-13.2-11.5c-1.7-2.7-3.4-5.4-2.1-8.9c3.7-2.5,7.6-0.8,11.5-0.2c0.3,0.8,0.7,1.7,1,2.5
		C137.6,66.7,142.5,67.4,146.5,70.2c-3.1,0.1-6.3,0.2-8.5,3.6c-1-2.1-2-3.4-4-2.5c0.5,3.4,0.9,6.6,1.5,10.2c1,0.2,2.4,0.4,3.7,0.9
		c6,2.7,12.5,3.5,18.8,4.7c2.8,0.5,3.8-0.4,4-3.2c0.1-2.5-0.4-5-3.1-6c-2.8-1.1-5.8-1.8-8.8-2.6c-0.4,3.3-1.2,6-3.7,8.1
		c-1.1-0.6-2.3-1-3.3-1.7c-1.6-1.1-1.1-1.9,0.5-2.5c0.8,0.6,1.7,1.1,2.5,1.7c3.4-3.9,2.7-6.4-2.3-8.2
		C144.7,71.7,145.6,70.9,146.5,70.2z M172.9,84.8c-1.4-2.4-4.5-4.4-6.8-4.6c-0.1,0.2-0.3,0.5-0.2,0.5c1.6,1.7,3.1,3.4,4.8,5.2
		C171.6,85.5,172.2,85.2,172.9,84.8z"/>
	<path d="M145.7,106.8c11.8,0.6,23.6,1.2,35.3,1.9c1.1,0.1,2.6,0.5,3.2,1.3c2.4,2.8,4.2,5.6,3.1,9.9c-2.4,8.9-5.7,17.6-9,26.2
		c-0.7,2-2.3,2.4-4.2,2.2c-6.1-0.9-12.3-1.8-18.4-2.8c-2.7-0.5-5.4-1.1-8-1.9c-3.3-1-4.9-3.7-5.4-6.8c-1.2-8.4-2.2-16.9-3.3-25.4
		c-0.1-0.6-0.1-1.2,0-1.8c0.1-2.2,0.8-2.9,3-2.9c1.2,0,2.4,0,3.7,0C145.8,106.6,145.8,106.7,145.7,106.8z M151.6,139
		c0.4,0.7,0.6,1.4,0.8,1.4c7.2,0.4,14.4,0.7,21.6,1c0.4,0,0.8-0.7,1.3-1.1C173.3,139.1,155.6,138.1,151.6,139z M161.2,119.4
		c-1.2,0-2.6-0.4-3.5,0.1c-1.3,0.6-2.1,0.8-3.2-0.2c-0.4-0.3-1.6-0.4-1.9-0.1c-0.5,0.5-0.8,1.4-0.7,2.1c0.2,0.9,0.8,1.6,1.4,2.8
		c2.2-3.6,5.4-0.3,7.9-2C161.2,121.3,161.2,120.5,161.2,119.4z"/>
	<path d="M14.9,108.8c-0.5,0.6-0.8,1-1.2,1.4c6.7,4.5,9,11.3,9.1,18.8c0,6-0.4,12.2-1.3,18.1c-0.5,3.2-2.2,6.3-4,9.1
		c-1.9,3-5.2,3-7.7,0.4c-3.2-3.4-4.5-7.7-5.5-12c-1.7-7.5-1.2-15.2,0.1-22.7c0.6-3.7,1.7-7.3,4-10.4
		C10.2,109.1,11.3,108.6,14.9,108.8z"/>
	<path d="M283.6,144.8c-0.3-3.4-0.7-6.3-0.9-9.2c-0.4-5.5-0.7-11-1.2-16.4c-0.4-3.6,1-6.8,2.2-10c0.2-0.6,1-1.3,1.5-1.4
		c3.1-0.4,6.2-0.6,9.8-0.9c-0.7,1.4-1.1,2.2-1.6,3.1c2,1.2,2,1.2,3.4-0.7c0.6,2.4,1.3,4.6,1.8,6.9c0.2,1,0.4,2,0.3,3
		c-0.5,5.1-1.1,10.3-1.7,15.4c-0.3,2.3-1.9,3.6-3.5,5.3c-2.2,2.3-4.7,3.3-7.4,4.4C285.7,144.6,284.8,144.6,283.6,144.8z
		 M294.5,136.1c-0.1-0.3-0.3-0.6-0.4-0.9c-0.9,0-1.7-0.2-2.6-0.1c-2,0.3-4,0.7-5.9,1.2c-0.5,0.1-0.9,0.8-1.4,1.2
		c0.6,0.4,1.2,1.2,1.8,1.1c2.3-0.1,4.6-0.4,6.9-0.8C293.4,137.6,293.9,136.7,294.5,136.1z M295.2,116.7c-2.4,0.5-4.6-0.1-5.8,2.7
		c1.8,0.7,3.4,1.4,5.5,2.3C295,119.7,295.1,118.4,295.2,116.7z"/>
	<path d="M89.4,155.5c-16.1-1.8-31.8-3.6-47.3-5.4c-1.1-1.3-2-2.3-3.4-3.9c1.7-0.1,2.9-0.1,4-0.3c1-0.2,2.4-0.3,2.7-0.9
		c1-1.7,2.4-1.6,3.9-1.6c4.2,0.1,8.4-0.1,11.8,3c0.5,0.5,1.1,0.9,1.6,1.4c2.1,2.4,3.8,2.4,5.7-0.2c0.2-0.2,0.4-0.5,0.9-1
		c1.5,0.3,3.4,0.4,5.1,1c4.1,1.3,8.1,2.8,12.1,4.1C88.2,152.3,89,153.4,89.4,155.5z"/>
	<path d="M288.3,79.1c0,1.1,0,2.1,0,3.4c0.8-0.1,1.4-0.2,2.1-0.3c1.4,2.3-1.6,3-1.7,4.9c0.6,0.1,1.2,0.2,2,0.3
		c0,0.4-0.1,0.9-0.1,1.3c0.6,0.1,1.1,0.2,1.6,0.3c0.3,1.4-1.1,3.4-2.9,4.4c-3.9,2.1-4.9,1.9-9.5-2.5c1-4,4.4-4,7.9-4
		c0-0.4,0-0.8,0-1.2c-0.8-0.4-1.6-0.8-2.5-1.2c0.9-2.2,1.6-4,2.3-5.7C287.8,79,288.1,79.1,288.3,79.1z"/>
	<path d="M129.5,115.5c3.2-0.8,4.6-0.3,5.1,2c1.3,6,2.5,12.1,3.7,18.1c0.1,0.3-0.2,0.7-0.3,1.2c-2.1,0.5-2.4-1-2.7-2.4
		c-1.2-4.5-2.4-9-3.6-13.4C131.1,119.2,130.3,117.5,129.5,115.5z"/>
	<path d="M46.8,66.3c-1,0.2-1.7,0.6-2.1,0.5c-2.1-0.7-4-1-6.2-0.4c-0.4,0.1-1.7-0.9-1.7-1.5c-0.1-0.6,0.9-2,1.3-1.9
		c2.6,0.2,5.2,0.5,7.8,1.1C46.3,64.1,46.4,65.4,46.8,66.3z"/>
	<path d="M265.5,60.4c2.8-0.2,5.6-0.2,8.3,1.3c-1.3,3.2-3.3,3.9-5.6,2c-1-0.8-2-1.7-2.9-2.6C265.3,60.9,265.4,60.7,265.5,60.4z"/>
	<path d="M242.8,47c-1.2-1.5-2.3-2.7-3.4-4c2.7-1.5,4.8-1.1,7,1.4C245.4,45.1,244.3,45.8,242.8,47z"/>
	<path d="M149.3,83.6c1.1-1.2,2.2-2.2,3.5-3.5c1,0.6,2.2,1.3,3.5,2.1C154.1,85.3,153.9,85.4,149.3,83.6z"/>
</g>
</svg>);
export default iVOITURETTE;