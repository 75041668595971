import React, { useState } from 'react';
import { BsGeoAltFill } from "react-icons/bs";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

const ContactBan = () => {

    const [fromContact, setFromContact] = useState({
        nameNP: '',
        email: '',
        mobile: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        const maxLengths = {
            nameNP: 32,
            email: 140,
            mobile: 10,
            message: 420
        };

        const emailRegex = /^[a-zA-Z0-9@._-]*$/;

        if (name === 'email' && !emailRegex.test(value)) {
            return;
        }
        const numericRegex = /^[0-9]*$/;

        if (name === 'mobile' && !numericRegex.test(value)) {
            return;
        }


        if (value.length <= maxLengths[name]) {
            setFromContact({
                ...fromContact,
                [name]: value
            });
        }
    };


    const [isLoading, setIsLoading] = useState(false);
    const [messageALERT, setMessageALERT] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const [captcha, setCaptcha] = useState(null);
    const handleCaptchaChange = (value) => {
        setCaptcha(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!fromContact.nameNP || !fromContact.email || !fromContact.mobile || !fromContact.message) {
            if (!fromContact.nameNP) {
                document.getElementById('nameNP').classList.add('emptyIn');
            } else {
                document.getElementById('nameNP').classList.remove('emptyIn');
            }
            if (!fromContact.email) {
                document.getElementById('email').classList.add('emptyIn');
            } else {
                document.getElementById('email').classList.remove('emptyIn');
            }
            if (!fromContact.mobile) {
                document.getElementById('mobile').classList.add('emptyIn');
            } else {
                document.getElementById('mobile').classList.remove('emptyIn');
            }
            if (!fromContact.message) {
                document.getElementById('message').classList.add('emptyIn');
            } else {
                document.getElementById('message').classList.remove('emptyIn');
            }
            return;
        } else {
            document.getElementById('nameNP').classList.remove('emptyIn');
            document.getElementById('email').classList.remove('emptyIn');
            document.getElementById('mobile').classList.remove('emptyIn');
            document.getElementById('message').classList.remove('emptyIn');
        }


        setIsLoading(true);
        try {
            const response = await axios.post('https://api.zonepermis.fr/api/sendEmail', fromContact, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                setIsLoading(false);
                setMessageALERT("Votre message à bien été envoyé.");
            } else {
                setIsLoading(false);
                setErrorMessage(true);
                setMessageALERT("Merci de nous contacter autrement, un problème technique est survenu.")
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message :', error);
            setErrorMessage(true);
            setMessageALERT('Merci de nous contacter autrement, un problème technique est survenu.');
        }
    };

    return (
        <div id='zbr' className='lay-contact'>
            <div className='widthMaxcont'>
                <div className='theContConD'>



                    <div className='theCdetailsInfosC'>
                        <div className='compINF'>
                            <span>
                                <FaPhone size={36} color={'#f7c615'} />
                            </span>
                            <div>
                                <h4 className='tCinf'>Téléphone</h4>
                                <a href="tel:+33605060700">
                                    <p>06 05 06 07 00</p>
                                </a>
                                <a href="tel:+33493659361">
                                    <p>04 93 65 93 61</p>
                                </a>

                            </div>

                        </div>
                        <div className='compINF'>
                            <span>
                                <MdEmail size={40} color={'#f7c615'} />
                            </span>
                            <div>
                                <h4 className='tCinf'>E-mail</h4>
                                <a href='mailto:zonepermis@gmail.com'>
                                    <p>zonepermis@gmail.com</p>
                                </a>

                            </div>
                        </div>
                        <div className='compINF'>
                            <span>
                                <BsGeoAltFill size={40} color={'#f7c615'} />
                            </span>
                            <div>
                                <h4 className='tCinf'>Adresse</h4>
                                <a href="comgooglemaps://?q=Zone+permis,89+avenue+de+Nice,Antibes">
                                    <p>89 Avenue de Nice</p>
                                    <p>06600 Antibes</p>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='thedFormC'>
                        <form onSubmit={handleSubmit}>
                            <div className='cp'>
                                <label className='lC' htmlFor="nameNP">Nom / Prénom : </label>
                                <input
                                    className='inC'
                                    id="nameNP"
                                    type="text"
                                    name="nameNP"
                                    value={fromContact.nameNP}
                                    maxLength="32"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='cp'>
                                <label className='lC' htmlFor="email">E-mail : </label>
                                <input
                                    className='inC'
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={fromContact.email}
                                    maxLength="140"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='cp'>
                                <label className='lC' htmlFor="mobile">Téléphone mobile : </label>
                                <input
                                    className='inC'
                                    id="mobile"
                                    type="tel"
                                    name="mobile"
                                    value={fromContact.mobile}
                                    maxLength="10"
                                    onChange={handleChange}

                                />
                            </div>
                            <div className='cp'>
                                <label className='lC' htmlFor="message">Message : </label>
                                <textarea
                                    className='inC'
                                    id="message"
                                    type="text"
                                    name="message"
                                    value={fromContact.message}
                                    maxLength="420"
                                    onChange={handleChange}
                                />
                            </div>
                            {/* <ReCAPTCHA
                                sitekey=""
                                onChange={handleCaptchaChange}
                            /> */}
                            {messageALERT ?
                                <p className={`msgAL ${errorMessage ? "errMSG" : ''}`}>
                                    {messageALERT}
                                </p>
                                :
                                <React.Fragment>
                                    <p className='miniRPD'>Repond généralement en moins d'une heure</p>
                                    {isLoading ?
                                        <p>envoi en cours...</p>
                                        :
                                        <button type="submit" className='theBTNsendmes'>
                                            ENVOYER
                                        </button>
                                    }
                                </React.Fragment>
                            }

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );


};

export default ContactBan;