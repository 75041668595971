import React from 'react';
import Header from '../../components/Header';
import ContactBan from '../../components/ContactBan';
import Footer from '../../components/Footer';
import { FaOtter } from 'react-icons/fa6';


function Mentionlegales() {
    return (
        <React.Fragment>

            <Header />

            <div className='GpageMPORM'>
                <div className="widthMaxcont">
                <h1 className='TCDGF'>Mentions légales</h1>

                    <div className='theMPORMw'>
                        <h2>1. Éditeur du Site</h2>
                        <p className='titnJ'><b>Dénomination sociale de l'établissement :</b></p>
                        <p className='titnJ'>F.P.C.S. - Formation Prévention Conduite et Sécurité</p>

                        <p className='titnJ'><b>Forme juridique :</b></p>
                        <p className='titnJ'>Société par Actions Simplifiée (SAS)</p>

                        <p className='titnJ'><b>Adresse du siège social :</b></p>
                        <p className='titnJ'>89 Avenue de Nice, 06600 Antibes</p>

                        <p className='titnJ'><b>Numéro SIRET :</b></p>
                        <p className='titnJ'>824 455 224 00017</p>

                        <p className='titnJ'><b>Capital social :</b></p>
                        <p className='titnJ'>2 000,00 €</p>

                        <p className='titnJ'><b>Directeur générale  :</b></p>
                        <p className='titnJ'>Mohamed-Salem BOUHLEL</p>

                        <p className='titnJ'><b>E-mail :</b></p>
                        <p className='titnJ'>zonepermis@gmail.com</p>

                        <h2>2. Hébergement</h2>
                        <p className='titnJ'><b>Hébergeur :</b></p>
                        <p className='titnJ'>OVH</p>
                        <p className='titnJ'><b>Adresse :</b></p>
                        <p className='titnJ'>2 rue Kellermann, 59100 Roubaix, France</p>

                        <p className='titnJ'><b>Téléphone :</b></p>
                        <p className='titnJ'>1007</p>

                        <p className='titnJ'><b>Site web :</b></p>
                        <p className='titnJ'><a target='_blank' href="https://www.ovh.com">www.ovh.com</a></p>

                        <h2>3. Conception et Réalisation</h2>
                        <p className='titnJ'><b>Développeur du site :</b></p>
                        <p className='titnJ'>Ghassen BOUHLEL</p>

                        <p className='titnJ'><b>E-mail :</b></p>
                        <p className='titnJ'>ghassen.bouhlel@gmail.com</p>

                        <p className='titnJ'><b>Site web :</b></p>
                        <p className='titnJ'><a target='_blank' href="https://www.ghassenb.fr">www.ghassenb.fr</a></p>

                        <h2>4. Propriété Intellectuelle</h2>
                        <p>Le contenu du site <a href="https://www.zonepermis.fr">www.zonepermis.fr</a>, incluant, sans s'y limiter, les textes, images, graphismes, logo, icônes, sons, logiciels, est la propriété de Zone Permis ou de ses partenaires. Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de Zone Permis.</p>

                        <h2>5. Liens Hypertextes</h2>
                        <p>Le site peut contenir des liens hypertextes vers d'autres sites présents sur le réseau Internet. Les liens vers ces autres ressources vous font quitter le site <a>www.zonepermis.fr</a>. Il est possible de créer un lien vers la page de présentation de ce site sans autorisation expresse de Zone Permis. Aucune autorisation ou demande d'information préalable ne peut être exigée par l'éditeur à l'égard d'un site qui souhaite établir un lien vers le site de l'éditeur. Il convient toutefois d'afficher ce site dans une nouvelle fenêtre du navigateur. Cependant, Zone Permis se réserve le droit de demander la suppression d'un lien qu'il estime non conforme à l'objet du site <a>www.zonepermis.fr</a>.</p>

                        <h2>6. Limitation de Responsabilité</h2>
                        <p>Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l'année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui paraît être un dysfonctionnement, merci de bien vouloir le signaler par email, à l'adresse ghassen.bouhlel@gmail.com, en décrivant le problème de la manière la plus précise possible (page posant problème, type d'ordinateur et de navigateur utilisé, etc.).</p>
                        <p>Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En conséquence, Zone Permis ne saurait être tenu responsable d'un quelconque dommage subi par l'ordinateur de l'utilisateur ou d'une quelconque perte de données consécutive au téléchargement. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.</p>

                    </div>

                </div>

            </div>

            <ContactBan />
            <Footer />

        </React.Fragment>
    );
}

export default Mentionlegales;