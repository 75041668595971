import React from "react";
const iAFTWIN = ({size=24, color="#0056b3"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
width={size} height={size} 
viewBox="0 0 300 210"
fill={color} 
stroke="none"
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round">
<g>
	<path className="st0" d="M193.8,1.9c1.9,0,3.9,0,5.8,0c3.7,0.8,5.6,3.5,7.2,6.7c3.2,6.3,6.6,12.5,9.8,18.8c4.7,9.3,9.2,18.6,11.1,29
		c0.5,2.6,0.2,5-1.1,7.4c-4.8-2.4-9.8-4.1-14.7-6.2c-2.4-1-4.5-0.9-6.8,0c-1.3,0.5-1.8,1.2-1.3,2.5c1,2.7,1.7,5.5,3.2,8
		c2.2,3.7,4.9,6.9,9.8,6.5c0.7,0,1.4,0.1,2.1,0.1c2.7,0.3,5.5,0.4,8.3-0.1c-0.4,3.3-0.9,5-2.2,5.4c-3.6,1.2-5.5,4.3-7.5,7.1
		c-2.4,3.3-3.7,6.9-1.3,10.9c0.4,0.7,0.7,1.6,1,2.4c0.5,1.1,0.9,2.4,1.6,3.3c1,1.1,2.3-0.2,3.5,0c0.4,0.1,0.9,0,1.3,0
		c7.4,0.3,14.9,0.5,22.2,2c5,1,9.9,2.2,14.3,4.8c0.5,0.3,1.4,0.4,1.2,1.5c-2.9,0.5-5.9-0.1-9.2,0.6c1.4,0.6,2.4,1.1,3.4,1.6
		c8.3,4,15.3,9.6,20.1,17.5c9.3,15.1,11,31.2,5.1,47.8c-4.2,11.8-12.2,20.4-23.8,25.4c-6.3,2.7-12.9,3-19.6,2.6
		c-11.3-0.7-21-5.2-28.5-13.7c-7.9-8.8-12.7-19.1-13.6-31.1c-0.2-3-0.2-6.1-0.5-9.1c-3.4,0-3.5,0-2.9-3.3c0-0.1,0-0.2,0.1-0.3
		c0.5-3.1,1.6-6.1,1.4-9.3c0-0.6,0.2-1.2,0.5-1.7c2-3.8,2.7-8.3,5.7-11.7c0.7-0.8,0.5-1.4-0.4-1.8c-3.5-1.4-7-2.6-10.8-2.7
		c-1.2,0-1.6,0.6-1,1.7c1,1.9,1.4,3.9,1.5,6.1c0,0.4,0.1,0.9,0.3,1.3c1.7,2.3,1,4.8,0.8,7.3c-0.3,3.2-0.8,6.4,0.2,9.6
		c0.3,1,0.5,2.2-0.4,3c-1.5,1.4-2.7,3.2-4.1,4.7c-3.3,3.5-7.1,6.5-10.8,9.6c-0.9,0.7-1.8,1.5-3,1.6c-6.9,0.8-13.8,2.3-20.8,2.1
		c-6.9-0.2-13.8-0.4-20.7-0.6c-1,0-2,0-2.8-0.6c-0.9-0.7-1.4-0.2-1.8,0.5c-0.3,0.5-0.5,1.1-0.5,1.7c0,0.7-0.4,1.2-1,1.4
		c-0.9,0.4-1.1-0.6-1.6-1c-0.4-0.3-0.5-0.6-0.6-1.1c-0.5-2.3-0.7-2.4-3-2.2c-1.8,0.1-3.7,0.7-5.4-0.5c-1.6-1.1-3.3-1.1-5-0.7
		c-1,0.2-1.9,0.5-2.8-0.1c-1.8-1-3.7-1-5.6-1c-0.9,0-2,0.2-2.6-0.2c-1.5-1.1-1.6,0-1.8,0.9c-0.7,2.5-1.4,5-2.6,7.4
		c-6.1,12.5-15,21.2-29.8,21.9c-10.1,0.5-18.9-2.8-25.9-10.3c-14-15-16.1-37.6-6.6-54.5c6.2-10.9,15.3-17.5,28.1-18.3
		c0.8,0,1.6,0,2.1-0.7c0-0.6-0.4-1.1-0.9-1.2c-2.9-0.7-3.2-3-3.9-5.3c-0.8-3.1-0.3-6.1,0-9.1c0.2-2.6,0.3-2.6-2.1-3.5
		c-0.8-0.3-1.4-0.7-2-1.2c-1.3-1.1-2.5-2.2-3.8-3.3c-2.1-1.9-2.3-1.9-4,0.5c-0.6,0.9-1.7,1.8,0,2.7c0.7,0.4,0.2,0.9,0,1.3
		c-1.6,2.4-3.1,4.9-5.8,6.2c-2,0.9-3.4,2.4-4.6,4.2c-3.4,4.7-6.6,9.6-10.3,14.1c-0.5,0.6-0.9,1.3-1.6,1.8c-1.1,0.7-2.4,0.8-3.4,0.1
		c-0.8-0.6-0.3-1.6,0-2.4c0.4-1.5,0.9-3,1.4-4.5c0.3-1-0.1-1.5-1.1-1.5c-1.2,0-2.4,0.3-3.6-0.3c0-0.3,0-0.7,0-1
		c2-3.7,3.3-7.6,4.7-11.5c1-2.7,2.3-4.8,5.2-5.6c1.2-0.3,1.8-1.1,1.7-2.3c0-0.5,0-1,0.3-1.5c0.9-1.2,0.1-1.7-1-2
		c-0.7-0.2-1.7,0.2-1.9-1c-0.2-1.3,0.3-2,1.6-2.1c2.3-0.3,4.1-1.6,5.8-3.4c-1.1-0.7-2.3-0.4-3.1-1c-0.9-0.7-0.8-1.4,0.1-1.8
		c0.5-0.3,1.2-0.3,1.8-0.3c1.8-0.1,3.6-0.2,5.5-0.3c0-1.7-1.1-2.8-2-3.9c-0.4-0.5-0.8-0.8-0.9-1.5c0-1.1,0.3-2.3-1-3
		c-1.1-0.6-2.1-1.2-3.3-1.6c-0.9-0.3-1.5-0.2-1.7,0.9c-0.2,0.7,0.1,1.9-1.2,2c-0.5-1.4,0.1-2.9-0.6-4.4c-0.4-0.8,1.2-2.4,2.3-2.2
		c3.3,0.5,6.6-0.1,9.9,0.4c4.5,0.6,8.9,1.9,13.4,2.3c10.3,0.8,20.4,2.8,30.3,5.7c1,0.3,2.3,0.4,2.8,1.2c2.3,3,6.7,2.5,8.9,5.7
		c2.6,0.6,4.7,2.1,6.5,3.8c3.7,3.5,8,3.9,12.7,3.3c0.6-0.1,1.2-0.2,1.8-0.2c3.2-0.2,5.8-1.5,8.1-3.7c5.9-5.8,12.2-11.3,16.9-18.3
		c0.8-1.2,1.9-2.3,2.8-3.5c2.4-3.5,5.9-5.4,10-5.9c1.4-0.2,2.8-0.3,4.2-0.5c6.8-0.7,13.4-0.1,19.4,3.6c0.9,0.6,1.5,0.4,2-0.4
		c0.5-0.8-0.3-1.1-0.8-1.4c-3.2-1.4-5.5-4-8.7-5.5c-2.8-1.3-5.4-1.3-8-0.3c-1.6,0.6-3,0.8-4.6,0.3c-1.2-0.4-2.1-0.3-3.1,0.6
		c-1.7,1.5-3.8,0.8-5.8,0.8c-0.5,0-0.8-0.7-0.9-1.2c-0.1-0.7,0-1.3,0-2c-0.7-0.4-1.4-0.2-2-0.1c-3.6,0.2-7.2,0.4-10.7,0.8
		c-2.2,0.2-3.5-0.9-4.6-2.4c-1.5-2.2-0.5-3.9,2.1-4c3.2-0.2,6.5-0.4,9.7-0.4c2.1,0,3.9-0.9,4.9-2.5c1.2-1.9,3.1-2.4,5-2.9
		c1.2-0.3,2.6-0.4,3.9-0.5c3.4-0.3,4-1.3,2.4-4.3c-0.4-0.8-1-1.6-1.7-2.3c-1.8-2.1-4-3.7-6.3-5.1c-1-0.6-1.9-1.1-3-0.2
		c-0.8,0.6-1.8,0.4-2.8,0.1c-2.3-0.5-4.5-1.4-6.7-2.2c-1.3-0.5-2.2-1.5-2.5-2.8c-0.3-1.4-0.6-2.7-0.8-4.1c-0.3-3,0.9-4.2,3.9-4.1
		c1.6,0,3.1,0.4,4.7,0.4c3.8,0,6.4,1.7,7.9,5.2c0.3,0.8,0.8,1.6,0.7,2.5c-0.1,0.8,0.3,1.3,0.7,1.9c2.4,3,3.9,6.6,6.9,9.2
		c0.8,0.7,1.1,1.8,1.2,2.8c0.3,2.3,1.9,3.8,3.1,5.6c1.6,2.3,3.7,2.3,5.9,2.5c0.3,0,0.5,0.1,0.8,0.1c0.9,0.1,1.7,0.4,1.8,1.5
		c0,1.2,0.7,1.6,1.7,1.1c1-0.4,1.9-0.1,2.8,0c3.7,0.5,7.3,0,10.8-1.4c0.7-0.3,1.1-0.6,0.9-1.3c-0.5-1.8,0.6-2.1,2-2.1
		c1.4,0,2.1-0.7,1.7-2.2c-0.3-1.1-0.1-2.3,0.3-3.4c1.5-3.7,0.7-7.6-0.5-11c-1.5-4.4-1.9-8.8-2.4-13.3c-0.2-2.4,1-3.9,2.9-5.1
		C191.5,2.8,192.9,2.8,193.8,1.9z M181.2,75.9c-0.4-0.1-0.5-0.4-0.7-0.7c-0.4-0.5-0.8-1.1-1.2-1.7c0-0.1,0-0.2,0.1-0.3
		c-0.2,0.7-0.9,1.2-0.8,2.1c-1.5-2.5-1.5-2.5-4.2-1.9c1.4,0.3,2.2,1.3,2.8,2.5c0.2,0.4,0.6,0.8,1,0.5c0.2-0.2,0.2-0.8,0.3-1.1
		c0.8,0.2,0.7,1.1,1.2,1.5c0.4,0.3,0.8,1,1.4,0.5C181.6,76.9,181,76.3,181.2,75.9c0.3-0.4,0.9,0.5,1.2-0.2c0.1-0.3-0.1-0.7-0.5-0.7
		C181,74.8,181.6,75.8,181.2,75.9z M169.7,66.3c1,0.3,1.5,1.1,1.9,2c0.3,0.5,0.6,1.3,1.2,0.8c0.6-0.4-0.1-0.8-0.3-1.2
		c-0.2-0.3,0.1-0.4,0.3-0.5c0.5,0.1,1,0.1,1.4,0.6c1.4,1.9,2.3,2.4,4.3,2.9c1,0.2,2.3,1.3,2.2-1c0-0.4,0.6-0.3,0.9-0.2
		c1.1,0.3,2.4,0.9,2.7,1.9c0.3,1.3,1.5,1.7,2.2,2.7c-1.6,0.4-2.8-1-4.5-0.2c0.9,0.4,1.6,0.6,2.3,0.9c0,0.1,0.1,0.3,0.1,0.4
		c-0.8,0.1-1.9,0-2,1.2c0,0.7,0.6,2,1.1,1.9c1.5-0.3,2.3,1.1,3.6,1.1c0.3-1-0.4-1.7-0.1-2.7c1.8,2.6,3,5.9,7,6.3
		c-1-1.8-1.9-3.3-2.8-4.9c0.9-0.6,1.9,0.3,2.5-0.6c0-0.9-0.8-0.9-1.3-1c-1.8-0.7-4.1-0.8-4.3-3.5c0-0.2-0.3-0.4-0.5-0.6
		c-2.1-2.2-4.2-4.5-6.3-6.7c-1.2-1.2-2.7-1.8-4.4-1.4c-0.1,1.1,0.4,2,0.6,3c-0.5,0.4-1-0.1-1.5-0.1c-0.7-1.3-1.7-1.9-3.2-1.4
		c-1.1,0.2-2.1,0-3.1-0.2c-1.1-0.8-2.2-1.2-2.7,0.6c-0.9-1-1.4-2.8-3.3-1.9c1,0.6,1,0.6,0.4,1.6c-0.1,0.1-0.2,0.4-0.1,0.4
		c0.5,0.6,1.3,0.7,2.1,0.8c0.9,0.2,1-0.3,0.9-1.1c0.9,0,0.7,1,1.3,1.3c0.3,0.2,0.6,0.5,0.9,0.3c0.4-0.2,0.2-0.6,0.2-0.9
		C169.1,66.6,169.3,66.4,169.7,66.3z M270.5,161.4c-0.1-10.8-3-19.6-9.5-27.1c-5.3-6.2-12-10.1-20.2-11c-2-0.2-2.5,0.2-2.3,1.7
		c0.7,3.9,1.5,7.7,2.2,11.6c0.2,1.3,0.7,1.7,2.1,1.2c2.6-0.9,5.1-0.4,7.1,1.8c0.6,0.7,1.3,1.1,2.2,1.4c3,0.8,4.8,2.7,5.5,5.7
		c0.2,0.7,0.5,1.3,1,1.8c2.8,2.4,3.6,5.4,2.7,8.9c-0.2,0.7-0.1,1.3,0.1,2c1.1,3.1,0.6,5.8-1.5,8.3c-0.6,0.7-1,1.4-1,2.3
		c-0.1,3.2-1.9,5.1-4.8,6.1c-0.8,0.3-1.9,0.4-2.2,1.3c-0.7,2.5-2.8,2.4-4.6,2.6c-2.2,0.2-4.5-0.1-6.5,1.4c-0.5,0.4-1.3,0.2-2,0.3
		c-2.2,0.2-4.4-1.3-6.5,0.3c-0.1,0.1-0.3-0.1-0.5-0.1c-2-0.6-4.1-0.9-5.5-2.7c-0.3-0.5-0.9-1-1.4-1.1c-3-0.4-4.4-2.6-5.7-4.9
		c-0.7-1.2-1.6-2.2-3.1-2.1c-1.5,0.1-2.1-0.5-2.7-1.9c-0.5-1.2-1.2-2.3-0.9-3.7c0.2-0.9-0.3-1.2-1.1-1.4c-2.2-0.6-3.3,0.3-2.8,2.4
		c2,8.6,6,16,12.7,21.8c9.9,8.7,28.8,11.4,40.7-3.1C267.8,178.2,270.7,170.3,270.5,161.4z M71.8,104.1c4.2-0.5,8-1,11.9-1.4
		c1.1-0.1,2.7,0.4,3.2-0.7c0.5-1.2-0.9-2.1-1.5-3.1c-0.2-0.4-0.6-0.7-0.9-1c-2.6-2.8-5.2-5.7-8.6-7.6c-0.4-0.2-1.7-0.6-0.7-1.4
		c1.4-1.1,0.6-1.3-0.4-1.5c-0.9-0.2-1.7-0.8-2.6-1.2c-3.5-1.6-6.8-3.5-10.9-3.2c-2,0.2-4.1-0.7-5-2.9c-0.3-0.8-1.1-1.1-1.7-1.5
		c-3-1.6-6.2-2.8-9.5-3.6c-1.4-0.4-1.9,0-1.9,1.6c0,1.9,0.5,3.6,1.8,5.1c1.6,2,3.4,3.7,5.3,5.4c1.2,1.1,2.3,1.7,4,1.4
		c1-0.2,1.9-0.1,2.8,0.9c4.2,4.6,9,8.6,12.8,13.5C70.4,103.8,71.1,104.2,71.8,104.1z M190.3,19.7c2.2-1.9,2.8-1.9,5.1-0.2
		c0.7,0.5,1.2,1.3,2.2,1.2c2-0.3,3.6,0.7,5,1.9c2.3,1.9,4.6,3.3,7.8,3c0.2-0.8-0.2-2.2,1-2.6c1.2-0.4,0.9-1,0.5-1.7
		c-2.7-5.1-5.4-10.2-8.1-15.2c-0.6-1.2-1.5-2.3-2.9-2.5c-2.9-0.5-5.8-0.6-8.6,0.3c-2.8,0.8-3.6,2.1-3.6,5
		C189.1,12.4,189.6,16,190.3,19.7z M141.6,155.4c2.1,0.2,4.1-1.3,5.6-3.3c1.1-1.3,2.3-2.3,4-2.7c2.9-0.9,3.3-2.7,0.9-4.6
		c-1.8-1.4-3-3-3.8-5.1c-1.4-3.2-3.9-5.1-7.4-5.5c-3.6-0.4-5.9,1.6-7.7,4.5c-0.4,0.6-0.8,1.2-1,1.8c-0.4,1.6-1.9,3.1-1.4,4.6
		c0.6,2,1.1,4.1,2.3,5.8C135,153.7,137.5,155.4,141.6,155.4z M155.6,93.9c2.1-2.5,3.4-5.3,5.1-7.8c0.8-1.2,1.3-2.4,0.8-3.9
		c-0.4-1.1-0.5-2.4-0.6-3.6c-0.3-2.9,0.1-3.4,3-3.7c2.4-0.2,4.7-0.4,7.1-0.5c2.4-0.2,2.7-0.8,1.5-2.8c-0.5-0.8-1.2-1-1.9-1.3
		c-3.6-1.2-7.1-2.3-10.7-3.5c-1.1-0.4-2.3-0.6-3.3-0.3c-3.1,1-6,2.4-8.6,4.4c-0.8,0.6-1.2,1.5-0.8,2.5c0.3,0.8,0.5,1.7,0.8,2.5
		c1.8,5.3,4.7,10.1,6.2,15.5C154.2,92.2,154.6,93,155.6,93.9z M46.5,168.4c-0.5,0.5-0.4,0.9-0.2,1.2c2.5,4.1,5.2,7.9,9.5,10.2
		c2.8,1.5,5.8,2,8.8,0.9c4.8-1.7,8.4-4.8,10.9-9.2c0.8-1.5,0.2-2.4-0.9-3.3c-1.1-0.9-2.2-1.5-3.4-0.3c-2.3,2.1-4.9,2-7.6,1
		c-0.5-0.2-1-0.4-1.5-0.5c-1.1-0.2-2.2-0.1-3.2,0.5c-3.1,1.9-6.3,2.8-9.8,0.6C48.4,169,47.4,168.8,46.5,168.4z M150.5,98.4
		c-0.7-2.3-1.6-4.6-4.1-5.8c-1.9-0.9-3.9-1.7-5.6-2.8c-2.3-1.5-4.2-1.1-6.2,0.6c-0.5,0.4-1,0.7-1.5,1.1c-4,2.9-8.1,5.8-12.1,8.8
		c-0.9,0.6-1.7,1.1-2.9,0.7c-3.2-1-3.2-1-5.1,1.8c-0.5,0.7-1,1.4-1.5,2.2c-0.7,1-0.4,1.8,0.7,2.3c2.5,1.1,4.9,1.4,7.4-0.4
		c5.2-3.9,10.5-7.5,15.9-11c1.8-1.2,3.2-1.3,5-0.2c2.5,1.5,5.2,2.8,7.9,4.1C149.7,100.2,150.5,99.8,150.5,98.4z M159.9,131.3
		c2.1,0,3.7-0.9,3.3-2.2c-0.6-2.3-1.4-4.8-2.5-6.8c-1-1.8-1.6-4.1-3.5-5.3c-0.2-0.1-0.3-0.7-0.2-1c0.4-2.5-1.4-4.2-2.3-6.2
		c-0.2-0.4-0.4-0.9-0.9-0.8c-0.6,0-0.7,0.6-0.9,1.1c-0.1,0.2-0.2,0.5-0.2,0.7c-1.4,4.5-1.8,9.3-3.4,13.8c-0.5,1.5,0.3,2.3,1.8,1.6
		c1-0.5,1.8-0.3,2.7,0.1c1,0.5,2,1,3.1,1.4c0.9,0.3,1.5,0.8,1.3,1.9C157.9,131.1,158.8,131.4,159.9,131.3z M174.9,77.4
		c-3.4-2-7-1.5-10.7-0.8c-1.6,0.3-2.1,1.1-1.9,2.7c1.6,10,3.2,20,4.8,29.9c0.2,1.4,0.6,2.8,2.1,3.7c0.4-0.8,0.2-1.5,0.1-2.1
		c-0.8-9.6-1.6-19.1-2.3-28.7c-0.2-2.4,0.4-3.3,2.7-4C171.4,77.6,173.1,77.7,174.9,77.4z M161.4,139.5c0-1.7-0.7-3.4-2.4-4.6
		c-3.6-2.7-7.4-5-11.2-7.2c-0.5-0.3-1.1-0.5-1.7-0.6c-1.2-0.2-1.5,0.4-1,1.3c0.8,1.6,1.3,3.2,2.1,4.7c1.9,3.3,5.1,5.7,6.3,9.5
		c0.4,1.2,1.6,1.8,3,0.8c0.7-0.5,1.4-0.5,2.2-0.5C160.9,142.7,161.4,142.1,161.4,139.5z M205.9,109.7c0.1-1.1-0.3-1.8-0.6-2.5
		c-0.9-2.2-2-4.4-3-6.6c-1.3-2.8-2.1-6-5.7-6.9c-0.2-0.1-0.3-0.4-0.5-0.6c-0.3-0.4-0.7-0.9-1.3-0.6c-0.7,0.3-0.5,1-0.4,1.6
		c0.1,0.4,0.3,0.8,0.5,1.2c2.8,6.4,5.6,12.7,8.4,19.1c0.2,0.6,0.4,1.2,1,1.5c0.6,0.4,1.4,0.3,1.2-0.5
		C205.1,113.3,205.3,111.4,205.9,109.7z M69.9,133.3c0.1-0.2,0.1-0.5,0.2-0.7c-2.8-3.1-6.5-4.7-10.7-4.7c-4.7,0-8.3,2.5-11.2,5.9
		c0.1,0.2,0.1,0.4,0.2,0.7C55.6,134.1,62.8,133.7,69.9,133.3z M217,104c-0.1-0.3-0.1-0.6-0.2-0.8c-1.4-3.8-2.7-7.5-4.4-11.1
		c-0.2-0.5-0.4-1-1.1-1c-0.9,0-0.9,0.7-0.9,1.3c0,0.6-0.4,0.9-0.8,1.3c-0.9,0.9-1,1.9-0.4,3.1c1.4,2.7,2.6,5.5,4,8.2
		c0.4,0.7,0.7,1.8,1.9,1.3C216.2,105.9,216.9,105.1,217,104z M194.6,29.4c-2.4,2-3,4.4-2.9,7c0.2,3,1.6,3.9,4.4,2.9
		c0.2-0.1,0.3-0.1,0.5-0.1c0.6-0.2,0.7-0.6,0.6-1.2C196.4,35.2,195.9,32.4,194.6,29.4z M215.5,152.7c-0.8-1.7-3.2-1.7-3.9-3.6
		c-0.2-0.5-0.6-0.9-1.2-0.7c-0.6,0.2-0.7,0.7-0.9,1.2c-0.8,3.6,0.1,6.9,1.9,10c0.1,0.2,0.5,0.2,0.9,0.3c-0.3-1.5-0.5-3-0.9-4.3
		c-0.4-1.3,0.1-1.8,1.3-1.9C213.6,153.5,214.7,153.8,215.5,152.7z M256.9,155c-0.6-2.8-1.9-5.6-4.1-7.9c-0.5-0.6-1.2-0.7-1.9-0.3
		c-0.7,0.5-0.6,1.2-0.4,1.9c0.1,0.5,0.5,1,0.9,1.2c1.3,0.8,1.6,2.2,2,3.5c0.3,0.8,0.5,1.7,0.8,2.5c0.3,0.9,1.1,1,2,0.8
		C257,156.7,257,156,256.9,155z M210.8,105c-1.2,1.3-1.3,3-2,4.5c-0.6,1.5-0.1,2.7,0.5,3.9c0.2,0.4,0.4,0.7,0.9,0.7
		c1.4-0.1,3.8-3.5,3.2-4.7C212.8,107.9,212.3,106.2,210.8,105z M155.8,162.9c2.6-1.8,5.1-3.6,7.2-5.9c-1.4-1.3-2.9-1.2-4.3-1.6
		c-0.7-0.2-1.3,0.1-1.5,0.7C156.5,158.3,155.4,160.4,155.8,162.9z M257.2,162.6c0.1-1-0.3-1.7-1.3-1.8c-1.1-0.1-1.5,0.6-1.7,1.6
		c-0.3,2.4-1.2,4.7-2.4,6.9c-0.4,0.6-1,1.4,0,2c0.9,0.6,1.5-0.2,2-0.7C255.6,168.1,256.7,165.5,257.2,162.6z M214.7,34.4
		c-2-5.6-3.8-7.2-7.4-6.6C209.2,30.6,209.9,34.5,214.7,34.4z M206.3,31.9c-0.7-2.2-2.1-3.9-3.8-5.4c-0.9-0.8-1.9-1.1-3.1-0.7
		c-0.5,0.2-1.1,0.4-1.1,0.9c-0.1,0.4,0.3,0.8,0.7,1c1.8,1.1,3.3,2.4,4.9,3.8C204.5,32.2,205.3,32.5,206.3,31.9z M169.7,45.9
		c1.6,1.5,2.7,2.4,4.5,1.7c2.2-0.9,4.4-1.3,6.4,0.7c0-2.3,0-2.2-1.8-2.4C175.9,45.6,173.1,46.5,169.7,45.9z M102.1,160.7
		c-1.6-2.5-3.1-3-4.8-1.9c-0.6,0.4-0.7,1-0.6,1.6c0,0.7,0.7,0.7,1.2,0.7C99.2,161.2,100.6,161.2,102.1,160.7z M151.9,158.9
		c0.1-1.5-0.4-1.8-1.7-1.2c-0.5,0.2-0.9,0.6-1.2,0.9c-0.5,0.5-1.3,1.1-0.7,2c0.5,0.7,1.2,1.4,2.1,1.4c1.1,0.1,0.9-1.1,1.2-1.7
		C151.8,159.8,151.8,159.3,151.9,158.9z M43.7,152.3c-1.9,2.9-1.2,6.8,1.5,7.9C43.9,157.8,44.5,155.1,43.7,152.3z M161.4,66.2
		c0.4,0.1,0.9,0.2,1.2-0.2c0.4-0.6-0.2-0.9-0.4-1.3c-0.2-0.3-0.8-0.2-0.7-0.8c0.1-1.2-0.9-1.2-1.6-1.2c-0.9-0.1-0.8,0.7-0.9,1.3
		C158.7,65.5,159.2,66,161.4,66.2z M50.1,144.2c-4.5,1.4-5.9,3.1-4.6,5.2c1-0.6,1.4-1.8,2.1-2.6C48.3,146,49.5,145.6,50.1,144.2z
		 M147.2,164.5c-1.2-2.7-2.3-3-3.9-1.4c-0.6,0.6-0.8,1.3,0.3,1.4C144.8,164.7,146,164.9,147.2,164.5z M247.4,153.1
		c0-1-1.2-2.8-2-2.8c-0.6,0-0.7,0.4-0.7,0.9c0,1.2,1.2,3.3,1.8,3.2C247.1,154.2,247.4,153.7,247.4,153.1z M223.3,169.5
		c0,1,0.8,1.5,1.4,2.1c0.5,0.5,1.1,0.6,1.6,0.1c0.5-0.4,0.7-1,0.6-1.7c-0.1-0.9-0.8-0.8-1.4-0.8C224.8,169.2,224,169,223.3,169.5z
		 M47.6,163c-0.1,2.4,0.9,3.1,4.3,2.7C50.3,164.8,49,163.9,47.6,163z"/>
	<path className="st1" d="M226.5,69.1c0,3.3-1.8,5-5,4.5c-0.6-0.1-1.2-0.2-1.8-0.3c-4.7-0.5-8.4-3.7-9.4-8.4c-0.1-0.7-0.2-1.4-0.2-2.1
		c0.1-2.2,1-3.1,3.1-2.8c3.9,0.7,7.6,2,11,3.9C226.2,65,226.5,67.1,226.5,69.1z M215.4,62.6c-2.8-0.1-3.3,0.7-2.1,3.3
		c0.4,0.9,0.9,1.7,1.5,2.4c0.4,0.5,0.9,0.8,1.6,0.6c1.1-0.4,1.8-2.2,1.5-3.9C217.7,63.3,216.9,62.6,215.4,62.6z"/>
	<path className="st2" d="M228.3,73.5c-0.1-3.9-1.3-7.9,0.8-11.8C230.7,63.7,230.3,69.8,228.3,73.5z"/>
	<path className="st3" d="M65.7,84.5c1.6,0.7,3.3,1.3,4.9,2.1c0.4,0.2,0.3,0.6,0.1,0.9c-0.6,1.2-2.7,2.2-4,1.7c-0.8-0.3-1.6-0.7-2.4-1.1
		c-0.6-0.3-1.6-0.6-1.3-1.4C63.6,85.7,64.4,84.8,65.7,84.5z"/>
	<path className="st4" d="M54.3,80c-1.8,2.1-3.4,2.1-6.1,0.3c-0.6-0.4-1.2-0.9-0.6-1.7c0.5-0.6,1-1.3,1.9-0.8c0.3,0.1,0.7,0.2,1,0.4
		C51.7,78.8,53.1,79.1,54.3,80z"/>
	<path className="st3" d="M152.1,78.1c-2.2,0-3.5-1.6-3.5-4c0-2.1,1.6-3.8,3.5-3.8c1.9-0.1,4,2.1,3.9,4.2
		C156.1,76.4,154.3,78.1,152.1,78.1z"/>
	<path className="st2" d="M182.1,68.5c-2.1,0.2-2.6-0.7-2.5-2.6C180.6,66.7,181.3,67.4,182.1,68.5z"/>
</g>

</svg>);
export default iAFTWIN;