const typeCbyCat = [
    {
        category: "PERMIS B",
        options: [
            {
                name: "AUTOMATIQUE",
                typeCoption: "CLASSIQUE",
                codeOption: "SANS-FORFAIT-CODE",
                id: "B-auto"
            },
            {
                name: "MANUELLE",
                typeCoption: "CLASSIQUE",
                codeOption: "SANS-FORFAIT-CODE",
                id: "B-manu"
            }
        ]
    },
    {
        category: "PERMIS A1/A2/A",
        options: [
            {
                name: "MOTO A1",
                typeCoption: '',
                codeOption: "SANS-FORFAIT-CODE",
                id: "A1"
            },
            {
                name: "MOTO A2",
                typeCoption: '',
                codeOption: "SANS-FORFAIT-CODE",
                id: "A2"
            },
            {
                name: "PASSERELLE A",
                typeCoption: '',
                codeOption: '',
                id: "A"
            },
            {
                name: "FORMATION 125",
                typeCoption: '',
                codeOption: '',
                id: "F125"
            }
        ]
    },
    {
        category: "PERMIS AM",
        options: [
            {
                name: "SCOOTER 50",
                typeCoption: '',
                codeOption: '',
                id: "s50"
            },
            {
                name: "Quadricycle",
                typeCoption: '',
                codeOption: '',
                id: "q50"
            }
        ]
    }
];
export default typeCbyCat;