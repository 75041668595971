import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import NotFoundPage from './pages/NotFoundPage';
import Mentionlegales from './pages/ConditionsMPORM/Mentionlegales';
import FormationsPage from './pages/FormationsPage';
import MediationLitigiesConsomation from './pages/ConditionsMPORM/MediationLitigiesConsomation';
import ReglementInterieur from './pages/ConditionsMPORM/ReglementInterieur';
import ObligationsAE from './pages/ConditionsMPORM/ObligationsAE';
import PolitiqueRGPD from './pages/ConditionsMPORM/PolitiqueRGPD';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/nos-formules",
    element: <FormationsPage />,
  },
  {
    path: "/nos-formules/:FormuleS",
    element: <FormationsPage />,
  },
  {
    path: "/mentions-legales",
    element: <Mentionlegales />,
  },
  {
    path: "/politique-de-confidentialite",
    element: <PolitiqueRGPD />,
  },
  {
    path: "/obligations-ecole-de-conduite",
    element: <ObligationsAE />,
  },
  {
    path: "/reglement-interieur",
    element: <ReglementInterieur />,
  },
  {
    path: "/mediations-des-litiges-de-consomation",
    element: <MediationLitigiesConsomation />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
