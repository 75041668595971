import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://192.168.1.199:2000/api',
  headers: {
    'Content-Type': 'application/json',
    // 'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
  // Vous pouvez ajouter d'autres configurations ici (intercepteurs, authentification, etc.)
});

export default apiClient;