import React from 'react';
import Header from '../../components/Header';
import ContactBan from '../../components/ContactBan';
import Footer from '../../components/Footer';

function MediationLitigiesConsomation() {
    return (
        <React.Fragment>

            <Header />

            <div className='GpageMPORM'>
                <div className="widthMaxcont">
                    <h1 className='TCDGF'>Médiation des litiges de consommation</h1>

                    <div className='theMPORMw'>
                        <b>ICI, NOUS AVONS DESIGNÉ LE MÉDIATEUR AUPRÈS DE LA FNA*</b>
                        <p>En cas de litige, et après réclamation écrite auprès de nos services restée infructueuse, vous pouvez saisir gratuitement le Médiateur FNA en vue d'une résolution amiable de votre différend.</p>

                        <p>

                            <b>Adresse postale :</b><br />
                            Le Médiateur FNA<br />
                            Immeuble Axe Nord<br />
                            9 &amp; 11 avenue Michelet<br />
                            93583 Saint Ouen Cedex<br />
                            Site internet : <a target="_blank" href="https://www.mediateur.fna.fr">www.mediateur.fna.fr</a><br /><br />

                            Veuillez consulter le site Internet du Médiateur auprès de la FNA pour toute information relative au processus de médiation, et pour déposer votre dossier en ligne.</p>

                        <div className='theBmed'>
                            <a className='bMED' target="_blank" href="/pdf/LeMediateuraupresdelaFNA.pdf">Voir la note juridique</a>
                        </div>

                        <p className='titR'>
                            * Le Médiateur auprès de la FNA est inscrit sur la liste des médiateurs notifiés à la Commission européenne par décision du 22 juillet 2016. Le service de médiation des litiges deconsommation est réservé aux adhérents de la Fédération Nationale de l'Automobile (FNA) à jour de leur cotisation. Une vérification est effectuée lors du dépôt du dossier de médiation.Notre entreprise certifie être adhérente de la FNA.
                        </p>
                    </div>
                </div>
            </div>

            <ContactBan />
            <Footer />

        </React.Fragment>
    );
}

export default MediationLitigiesConsomation;