import React, { useEffect, useState, useRef } from 'react';
import { IoEarSharp } from "react-icons/io5";
import { MdEventAvailable } from "react-icons/md";
import { MdOutlineTimer } from "react-icons/md";
import { GiBodySwapping } from "react-icons/gi";
import { useInView } from 'react-intersection-observer';


const TypingEffect = ({ text }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [delayCompleted, setDelayCompleted] = useState(false);
  const [height, setHeight] = useState(0);
  const diTe = useRef(null);


  const { ref, inView } = useInView({
    triggerOnce: false, // L'animation s'active et se désactive en fonction de la visibilité
    threshold: 0.1, // Le pourcentage de visibilité avant de déclencher l'animation
  });

  useEffect(() => {
    let timeout;

    if (inView && !delayCompleted) {
      timeout = setTimeout(() => {
        setDelayCompleted(true);
      }, 1);
    }

    if (inView && delayCompleted) {
      if (index < text.length) {
        timeout = setTimeout(() => {
          setDisplayedText(prev => prev + text[index]);
          setIndex(prev => prev + 1);
        }, 4);
      }
    }

    return () => clearTimeout(timeout);
  }, [inView, index, text, delayCompleted]);

  useEffect(() => {
    if (!inView) {
      setDisplayedText('');
      setIndex(0);
      setDelayCompleted(false); // Reset delay when the element goes out of view
    }
  }, [inView]);


  useEffect(() => {
    if (diTe.current) {
      const updateHeight = () => setHeight(diTe.current.offsetHeight);
      const observer = new ResizeObserver(updateHeight);
      observer.observe(diTe.current);

      // Initial height set
      updateHeight();

      return () => observer.disconnect();
    }
  }, [diTe]);

  useEffect(() => {
    const handleResize = () => {
      if (diTe.current) {
        setHeight(diTe.current.offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div style={{height: `${height}px`}} ref={ref} className="thepresInstruc typing-effect">
      <p>{displayedText}</p>
      <p ref={diTe} className='posA'>{text}</p>
      <span className="cursor"></span>
    </div>
  );
};

const CriteresAE = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // L'animation s'active et se désactive en fonction de la visibilité
    threshold: 0.1, // Le pourcentage de visibilité avant de déclencher l'animation
  });
  return (
    <div className='lay-criteres'>
      <div className='widthMaxcont'>
        <div className='theContCritD'>
          <section className='lesdic'>
            <div className={`theDico ${inView ? 'animationClass2' : ''}`}>
              <MdEventAvailable size={130} color={'#14689f'} />
              <span>Disponibles</span>
            </div>
            <div className={`theDico ${inView ? 'animationClass2' : ''}`}>
              <MdOutlineTimer size={130} color={'#14689f'} />
              <span>Patients</span>
            </div>
            <div className={`theDico ${inView ? 'animationClass2' : ''}`}>
              <GiBodySwapping size={130} color={'#14689f'} />
              <span>A l'écoute</span>
            </div>
          </section>
          <div ref={ref}></div>

          <TypingEffect text='Nos instructeurs dévoués sont là pour vous accompagner à chaque étape de votre apprentissage, en vous offrant des conseils personnalisés et une attention constante. Votre réussite et votre satisfaction sont nos priorités.' />

        </div>

      </div>
    </div>
  );


};

export default CriteresAE;