import React, { useState } from 'react';
import useFetch from '../hooks/useFetch';
import { getAllFormules } from '../api/formules';
import ButtonTypeC from './ButtonTypeC';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import typeCbyCat from '../dataJSON/typeCbyCat';


const LesFormules = () => {
    // const { data: dataForm, loading: loadingForm, error: errorForm } = useFetch(getAllFormules);

    const navigate = useNavigate();

    const goTo = (ou) => {
        navigate(`/nos-formules/${ou}`);
    };

    const typeC = '';

    const { ref, inView } = useInView({
        triggerOnce: false, // L'animation s'active et se désactive en fonction de la visibilité
        threshold: 0.1, // Le pourcentage de visibilité avant de déclencher l'animation
    });

    function rplSpa(str) {
        return str.replace(/ /g, '-');
    }

    // if (loadingForm) return <p>Loading...</p>;
    // if (errorForm) return <p>Error: {errorForm.message}</p>;
    return (
        <div className='lay-formules'>
            <div ref={ref} className={`widthMaxcont ${inView ? 'anim animate__pulse' : ''}`}>
                <div className='choiceFilCAT'>
                    <div className='ligneForfait'>
                        {typeCbyCat.map((catD, index) => (
                            <div key={index} className='lapartC'>
                                <p className='CatPermis'>{catD.category}</p>
                                <div id='lisSch' className={catD.category} key={index}>
                                    {catD.options.map((option, index) => (
                                        <ButtonTypeC
                                            mode={option.name}
                                            txtARC={option.name.toUpperCase()}
                                            classn={`chXo ${typeC === '' ? 'animbounce' : 'dx'} ${typeC === option.name ? 'cSE' : ''}`}
                                            size={120}
                                            onClick={() => {
                                                goTo(`${rplSpa(option.name)}${option.typeCoption !== '' ? '&' + rplSpa(option.typeCoption) : ''}${option.codeOption !== '' ? '&' + rplSpa(option.codeOption) : ''}${option.id ? '=' + option.id : ''}`)
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>

                        ))}
                    </div>
                    <div className='ligneForfaitCode'>
                        <p className='CatPermis'>CODE DE LA ROUTE</p>
                        <div id='lisSch' className='forfaitcode'>
                            <ButtonTypeC
                                txtARC={'CODE VOITURE'}
                                mode={'ETG'}
                                classn={`chXo ${typeC === '' ? 'animbounce' : 'dx'} ${typeC === 'CODE ETG' ? 'cSE' : ''}`}
                                size={160}
                                onClick={() => { goTo(`ETG=code`); }}
                            />
                            <ButtonTypeC
                                txtARC={'CODE MOTO'}
                                mode={'ETM'}
                                classn={`chXo ${typeC === '' ? 'animbounce' : 'dx'} ${typeC === 'CODE ETM' ? 'cSE' : ''}`}
                                size={160}
                                onClick={() => { goTo(`ETM=code-moto`); }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div ref={ref}></div>
        </div>
    );


};

export default LesFormules;