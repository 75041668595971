import React, { useState } from 'react';

import SvmiMANU from '../icon/svgiMANU';
import SvmiAUTO from '../icon/iAUTO';
import SvmiAAC from '../icon/iAAC';
import SvmiAFTWIN from '../icon/iAFTWIN';
import SvmiPCX from '../icon/iPCX';
import SvmiCB125F from '../icon/iCB125F';
import SvmiCBH from '../icon/iCBH';
import SvmiVESPA from '../icon/iVESPA';
import SvmiVOITURETTE from '../icon/iVOITURETTE';
import SvmiETG from '../icon/iLIVREvoitureETG';
import SvmiETM from '../icon/iLIVREmotoETM';

import useWindowWidth from '../hooks/useWindowWidth';

const ButtonTypeC = ({
    mode,
    txtARC,
    suppICONoption,
    classn,
    size,
    onClick }) => {

    const tradCatTypeC = {
        "ETG": SvmiETG,
        "ETM": SvmiETM,
        "AAC": SvmiAAC,
        "CS": SvmiAAC,
        "AUTOMATIQUE": SvmiAUTO,
        "MANUELLE": SvmiMANU,
        "MOTO A1": SvmiCB125F,
        "MOTO A2": SvmiCBH,
        "PASSERELLE A": SvmiAFTWIN,
        "FORMATION 125": SvmiPCX,
        "SCOOTER 50": SvmiVESPA,
        "Quadricycle": SvmiVOITURETTE
    };

    const IconComponent = tradCatTypeC[mode] || null;

    const width = useWindowWidth();

    const [thisId, setThisID] = useState(true);

    return (
        <div
            id={thisId ? 'mousOch' : ``}
            onMouseEnter={() => {
                if (width > 1200) {
                    setThisID(true);//a changer si jutilise sp
                }
            }}
            onMouseLeave={() => {
                if (width > 1200) {
                    setThisID(true);
                }
            }}
            className={classn}
            onClick={onClick}>
            <svg viewBox={`0 0 ${thisId ? '135 135' : '100 100'}`} className='circlepath'>
                <path id="arc" d="M 10,50 A 40,40 0 1,1 90,50" fill="transparent" />
                <path id="droit" d="M 10,50 L 135,50" fill="transparent" />
                <text className='theARC'>
                    <textPath href={thisId ? '#droit' : '#arc'} startOffset="50%" textAnchor="middle">
                        {txtARC}
                    </textPath>
                </text>
            </svg>
            {suppICONoption}
            <span
                id='theicSVI'
                style={{ top: (mode === "ETG" || mode === "ETM") ? '-68px' : '' }}>
                {IconComponent && <IconComponent size={size} className='theicSVI' color={"rgb(38 106 167 / 54%)"} />}
            </span>
        </div>
    );
};

export default ButtonTypeC;