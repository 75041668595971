import React from "react";
const svgiMANU = ({ size = 24, color = "#0056b3" }) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size} height={size}
		viewBox="0 0 300 210"
		fill={color}
		stroke="none"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round">
		<path className="st0" d="M149.9,1.4c43.6,0,87.3,0,130.9,0c1.3,0,2.5,0,3.8,0c3,0.1,4.6,2.4,3.7,5.2c-0.5,1.6-1.5,2.5-3.3,2.5
		c-2.3-0.1-2.6,1.1-2.6,2.8c-0.3,11.1-0.6,22.3-0.9,33.4c-0.3,10.4-0.5,20.9-0.8,31.3c-0.1,3.2-0.2,6.4-0.3,9.6
		c-0.1,2.4,0,2.5,2.4,2.3c2.5-0.1,5.1-0.1,7.5,0.8c4.5,1.6,7.9,5.9,8,10.7c0.1,6.2,0.1,12.3,0.1,18.5c0,11.2,0,22.4-0.1,33.6
		c0,14.2-9.9,26.5-23.8,29.5c-2.7,0.6-5.5,0.8-8.2,0.9c-3.9,0.2-7.9,0.1-11.8,0c-15.9-0.5-26.5-11.4-29.4-23.2
		c-0.6-2.3-0.8-4.7-0.9-7.1c-0.1-17,0-34-0.1-51c0-7,4.8-12.8,12.6-12.9c0.9,0,1.9-0.1,2.8-0.1c1.9-0.1,2.5-0.7,2.4-2.5
		c-0.1-4.8-0.1-9.7-0.3-14.5c-0.3-10.4-0.6-20.9-0.8-31.3c-0.2-9.2-0.4-18.4-0.7-27.5c-0.1-2.9-0.6-3.4-3.5-3.4
		c-20.8,0-41.6,0-62.5,0c-3.1,0-3.2,0.1-3.3,3.1c-0.3,10.3-0.6,20.5-0.9,30.8c-0.3,8.9-0.6,17.7-0.8,26.6c-0.1,2.3-0.1,4.6-0.2,6.9
		c0,1.9,0.5,2.4,2.4,2.4c6.6,0,13.2-0.1,19.8,0c10.1,0.1,19.3,8.1,20.7,18.1c0.6,4.2,0.7,8.6,0.9,12.8c0.6,12.6,0.6,25.2,0,37.7
		c-0.2,4.2-0.3,8.4-0.9,12.5c-1.7,11.4-10.3,18.9-21.8,18.9c-26.8,0.1-53.6,0-80.4,0c-5.1,0-10-1.2-14.2-4.4
		c-4.8-3.6-7.5-8.6-8.3-14.4c-0.7-4.7-0.9-9.6-1.2-14.4c-0.5-9.6-0.7-19.1-0.3-28.7c0.3-6.4,0.6-12.8,1.2-19.2
		c0.9-9.4,9.1-17.8,18.5-18.8c4.2-0.5,8.4-0.3,12.6-0.3c3.4,0,6.8,0,10.2,0c2,0,2.4-0.4,2.4-2.4c-0.3-10.9-0.7-21.7-1-32.6
		c-0.3-10.9-0.6-21.8-0.9-32.7c0-1.7-0.5-2.1-2.2-2.1c-3.1,0-6.2,0-9.3,0c-19.8,0-39.6,0-59.4,0c-0.4,0-0.7,0-1.1,0
		c-1.5,0.1-1.9,0.5-2,2c0,0.5,0,0.9,0,1.4c0,10.5,0,21,0,31.5c0,2.9,0.1,3,3,3c2.4,0,5,0,7.3,0.5c6.1,1.3,10.4,6.7,10.4,12.9
		c0,40.7,0,81.4,0,122.1c0,10.1-6.6,19.2-16.5,23.2c-3.7,1.5-7.5,2-11.4,2.3c-6.2,0.5-12.3,0.7-18.5,0.3c-4.9-0.3-9.8-0.9-14.2-3.5
		C7.5,200.3,2.9,193.8,1.7,185c-0.2-1.4-0.2-2.8-0.2-4.2c0-39.5,0-79.1,0-118.6c0-3.9,0.8-7.4,3.4-10.4c2.5-2.9,5.7-4.3,9.4-4.6
		c1.9-0.1,3.7,0,5.6,0c1.8,0,2.3-0.5,2.3-2.3c0-1.2,0-2.4,0-3.6c0-9.9,0-19.8,0-29.7c0-2.1-0.5-2.4-2.5-2.4c-1.6,0.1-3.2,0.1-4.8,0
		c-2.1-0.1-3.5-1.7-3.5-3.8c0-2.3,1.4-3.8,3.6-3.9c0.9-0.1,1.8,0,2.7,0C61.8,1.4,105.9,1.4,149.9,1.4C149.9,1.4,149.9,1.4,149.9,1.4
		z M149.6,87.1C149.6,87.1,149.6,87.1,149.6,87.1c-8.4-0.1-16.8-0.1-25.2,0c-5.2,0-10.5-0.1-15.7,0.1c-7.7,0.3-12.9,5.4-13.5,13
		c-0.7,8.1-1.2,16.3-1.2,24.5c0.1,10.9,0.3,21.7,1.2,32.5c0.7,8.6,6.7,13.8,14.9,13.7c8.9-0.1,17.8,0,26.7,0c17.4,0,34.9,0,52.3,0
		c2.2,0,4.4-0.2,6.4-1c4.9-1.9,8.1-5.4,8.6-10.7c0.6-5.8,0.8-11.6,1-17.4c0.4-10.8,0.3-21.6-0.3-32.5c-0.2-3.7-0.3-7.5-1-11.2
		c-0.9-5.4-4.1-9-9.5-10.4c-1.9-0.5-3.9-0.6-5.8-0.6C175.6,87.1,162.6,87.1,149.6,87.1z M9.6,121.7C9.6,121.7,9.6,121.7,9.6,121.7
		c0,19.3,0,38.6,0,57.9c0,2.6,0.2,5.2,1,7.7c2.5,7.6,9.2,12.6,17.3,12.8c7,0.1,14,0,21-0.3c5.4-0.2,10.1-2.3,13.7-6.3
		c3.5-3.9,5.1-8.6,5.1-13.8c0-12.2-0.2-24.4-0.2-36.6c0-26.6,0-53.3,0.1-79.9c0-0.9,0-1.8-0.1-2.7c-0.3-2.9-1.6-4.5-4.5-5
		C62,55.3,61,55.3,60,55.3c-10.8,0-21.6,0-32.4,0c-4.2,0-8.4,0-12.5,0.1c-3,0.1-4.8,1.8-5.3,4.7C9.6,61,9.6,62,9.6,63
		C9.6,82.5,9.6,102.1,9.6,121.7z M232.4,127.8C232.4,127.8,232.3,127.8,232.4,127.8c-0.1,4.8-0.1,9.6,0,14.4c0.1,4.4,0,8.9,0.5,13.3
		c1.1,9.7,9.9,18,19.6,18.6c5.3,0.4,10.7,0.3,16,0.1c6.6-0.2,12.2-2.9,16.6-8c3.8-4.4,5.4-9.6,5.4-15.3c0-16.2,0-32.4,0-48.6
		c0-0.4,0-0.9-0.1-1.3c-0.3-3-1.5-4.2-4.6-4.4c-0.3,0-0.5,0-0.8,0c-15.7,0-31.3,0-47,0c-4,0-5.5,1.4-5.6,5.4
		C232.3,110.6,232.4,119.2,232.4,127.8z"/>
		<path className="st1" d="M149.9,158.3c-13.1,0-26.2,0-39.2,0c-0.8,0-1.6-0.1-2.4-0.2c-2.1-0.4-3.3-2-3.2-4.1c0-2.1,1.3-3.6,3.5-3.9
		c0.9-0.1,1.8-0.2,2.6-0.2c25.7,0,51.5,0,77.2,0c0.9,0,1.7,0,2.6,0.2c2.3,0.3,3.7,2,3.6,4.3c-0.1,2.1-1.8,3.8-4,3.9
		c-0.5,0-1.1,0-1.6,0C175.9,158.3,162.9,158.3,149.9,158.3z"/>
		<path d="M149.8,106.2c-13.1,0-26.3,0-39.4,0c-0.8,0-1.7-0.1-2.5-0.3c-1.9-0.6-3-2.2-2.9-4.1c0.1-1.7,0.9-3.1,3.3-3.7
		c0.7-0.2,1.5-0.2,2.3-0.2c26.1,0,52.3,0,78.4,0c0.7,0,1.5,0.1,2.2,0.2c1.8,0.3,3.2,1.9,3.3,3.8c0.1,1.8-1.1,3.7-2.9,4.2
		c-0.7,0.2-1.5,0.3-2.3,0.3C176.1,106.2,162.9,106.2,149.8,106.2z"/>
		<path className="st0" d="M149.9,141.1c-13.1,0-26.2,0-39.4,0c-0.7,0-1.4,0-2.1-0.2c-2.2-0.4-3.4-1.9-3.5-3.9c0-2,1.3-3.7,3.4-4.1
		c1.1-0.2,2.2-0.1,3.3-0.1c0.3,0,0.5,0,0.8,0c11.9,0,23.9,0,35.8,0c12.8,0,25.7-0.1,38.5-0.2c1.3,0,2.7,0,4,0.2
		c2.3,0.3,3.7,2,3.6,4.2c-0.1,2.4-1.6,3.9-4,4c-0.8,0-1.6,0-2.3,0C175.4,141.1,162.6,141.1,149.9,141.1z"/>
		<path d="M149.9,115.7c13,0,25.9,0,38.9,0c0.9,0,1.8,0,2.7,0.2c2,0.5,3.3,2.4,3.1,4.3c-0.2,2-1.8,3.6-3.8,3.8c-0.5,0-1,0.1-1.5,0.1
		c-26,0-52,0-78,0c-1,0-2,0-2.9-0.3c-2.2-0.5-3.4-2.2-3.2-4.3c0.2-2,1.6-3.4,3.7-3.7c0.4-0.1,0.9-0.1,1.3-0.1
		C123.3,115.7,136.6,115.7,149.9,115.7C149.9,115.7,149.9,115.7,149.9,115.7z"/>
		<path className="st1" d="M20.8,107.1c-1.6-0.1-3-0.8-3.7-2.4c-0.8-1.7-0.5-3.5,0.9-4.8c1.2-1.2,2.5-2.2,3.8-3.3
		c9.3-7.9,18.7-15.7,28-23.6c1-0.9,2-1.7,3.1-2.5c0.6-0.5,1.3-0.9,2-1.2c1.8-0.7,3.6-0.2,4.7,1.3c1.2,1.6,1.2,3.6-0.2,5.1
		c-1,1.1-2.1,2-3.2,2.9c-10.7,9-21.4,18-32.1,27C23.2,106.4,22.2,107.1,20.8,107.1z"/>
		<path className="st1" d="M20.9,154.7c-2,0-3.2-0.8-3.9-2.5c-0.7-1.8-0.4-3.3,1.1-4.6c3.4-3,6.9-5.9,10.3-8.8
		c7.8-6.6,15.6-13.2,23.5-19.7c1-0.8,2.1-1.6,3.3-2.1c1.6-0.7,3.1-0.1,4.3,1.3c1.1,1.4,1.3,2.9,0.4,4.4c-0.4,0.7-1,1.3-1.6,1.9
		c-8,6.8-16.1,13.6-24.1,20.4c-3.2,2.7-6.5,5.5-9.8,8.2C23.4,154.1,22.3,155,20.9,154.7z"/>
		<path className="st1" d="M20.6,131.3c-2.8,0-4.9-3-3.6-5.6c0.5-1.1,1.5-2,2.4-2.8c5.3-4.5,10.7-9,16-13.5c5.9-5,11.7-9.9,17.6-14.9
		c1.3-1.1,2.7-1.7,4.4-1.1c3.2,1.1,3.9,4.8,1.3,7.2c-1.1,1-2.3,2-3.5,3c-8.2,6.9-16.5,13.9-24.7,20.8c-2.1,1.8-4.2,3.6-6.4,5.4
		C22.8,131,22.1,131.3,20.6,131.3z"/>
		<path className="st1" d="M60.5,144.3c-0.1,1.3-0.6,2.4-1.6,3.3c-5.2,4.4-10.4,8.8-15.6,13.2c-6.2,5.3-12.5,10.5-18.7,15.7
		c-0.7,0.6-1.5,1.2-2.3,1.5c-1.6,0.7-3.1,0.3-4.3-0.9c-1.2-1.2-1.6-2.9-0.8-4.4c0.4-0.9,1.1-1.7,1.8-2.4
		c11.3-9.6,22.7-19.2,34.1-28.8c2.1-1.8,4.1-1.9,5.9-0.4C59.9,142,60.4,143,60.5,144.3z"/>
		<path className="st1" d="M35.6,191.3c-1.8-0.1-3-0.9-3.7-2.5c-0.7-1.7-0.3-3.3,0.9-4.5c1-1.1,2.2-2,3.3-2.9c5.5-4.6,11-9.3,16.4-13.9
		c0.5-0.4,1-0.9,1.6-1.2c1.8-1,4-0.7,5.3,0.8c1.3,1.4,1.4,3.7,0,5.3c-1.1,1.2-2.4,2.2-3.6,3.3c-5.7,4.8-11.4,9.6-17.1,14.4
		C37.8,190.8,36.8,191.3,35.6,191.3z"/>
		<path className="st1" d="M22.8,83c-2.9,0-4.9-3-3.6-5.7c0.4-0.8,1-1.5,1.7-2.1c3-2.6,6-5.1,9-7.6c2.7-2.3,6.2-0.5,6.8,1.8
		c0.4,1.7,0.1,3.3-1.2,4.4c-3.2,2.8-6.5,5.6-9.9,8.3c-0.7,0.6-1.8,0.8-2.7,1.2C22.8,83.2,22.8,83.1,22.8,83z"/>
		<path className="st1" d="M261.5,112.5c-5.3,0-10.7,0-16,0c-0.7,0-1.4,0-2.1-0.2c-2.3-0.5-3.4-1.9-3.4-4.2c0.1-2.1,1.4-3.5,3.7-3.8
		c0.6-0.1,1.2-0.1,1.9-0.1c4.2,0,8.4,0,12.5,0c6.3,0,12.7,0,19,0c0.9,0,1.8,0,2.7,0.2c2.1,0.3,3.3,1.7,3.4,3.8
		c0.1,2.3-1,3.7-3.1,4.2c-0.8,0.2-1.5,0.2-2.3,0.2C272.3,112.5,266.9,112.5,261.5,112.5z"/>
		<path className="st1" d="M261.6,147.5c-5.4,0-10.9,0-16.3,0c-0.7,0-1.3,0-2-0.1c-2.4-0.5-3.5-2-3.4-4.3c0.1-2.2,1.5-3.6,3.8-3.8
		c0.5-0.1,1-0.1,1.5-0.1c10.8,0,21.6,0,32.4,0c0.5,0,1,0,1.5,0.1c2.5,0.2,3.9,1.8,3.9,4.2c0,2.3-1.5,4-4,4
		C273.3,147.5,267.5,147.5,261.6,147.5z"/>
		<path className="st0" d="M261.6,130.4c-5.5,0-10.9,0-16.4,0c-0.5,0-0.9,0-1.4-0.1c-2.5-0.3-4-1.8-3.9-4.2c0.1-2.3,1.6-3.8,4-4
		c0.4,0,0.9,0,1.3,0c11.1,0,22.2,0,33.2,0c1.9,0,3.6,0.6,4.4,2.5c1.2,3-0.7,5.7-4,5.8c-3.5,0.1-6.9,0-10.4,0.1
		C266.2,130.4,263.9,130.4,261.6,130.4C261.6,130.4,261.6,130.4,261.6,130.4z"/>
		<path className="st0" d="M261.6,164.6c-3.7,0-7.3,0-11,0c-2.6,0-4.2-1.7-4.3-4.1c0-2.4,1.6-4.1,4.2-4.1c4.3-0.1,8.5,0,12.8,0
		c3.2,0,6.4,0,9.6,0c3,0.1,4.8,2.8,3.8,5.6c-0.5,1.6-2,2.6-3.8,2.6C269.1,164.6,265.3,164.6,261.6,164.6
		C261.6,164.6,261.6,164.6,261.6,164.6z"/>
	</svg>);
export default svgiMANU;