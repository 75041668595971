import React, { useState } from 'react';
import formations from '../dataJSON/formations';

const tradForAffCode = {
    "MANUELLE": "ETG",
    "AUTOMATIQUE": "ETG",
    "MOTO-A1": "ETM",
    "MOTO-A2": "ETM",
};

const LaFormuleCard = ({
    laFormule,
    code,
    AUTOouMANUouMOTO


}) => {

    const lesFormulesCode = formations.filter(formule =>
        formule.categorie === tradForAffCode[AUTOouMANUouMOTO]
    );

    const [nameFormuleCodeSele, setNameFormuleCodeSele] = useState(lesFormulesCode.length > 0 ? lesFormulesCode[0].nameF : '');

    const laFormuleCode = lesFormulesCode.filter(formule =>
        formule.nameF === nameFormuleCodeSele
    );

    return (
        <div className='LaFormule'>
            <div className="tdFO">
                <h3 className="tFORFAIToneF">{laFormule.nameF}</h3>
                <span>{code && laFormule.description === "SANS FORFAIT CODE" ? "AVEC FORFAIT CODE" : laFormule.description}</span></div>

            {/* ici que ce commence l'ajout a modifier */}

            {code &&
                <ul className='listeF codeUL'>
                    <h3 className='titecodechoiceinfor'>{(lesFormulesCode && lesFormulesCode.length > 1) ? 'Choix du forfait code :' : 'Forfait code :'}</h3>
                    <div className='CatCodeListInForm'>
                        {
                            lesFormulesCode.map((formule, index) => (
                                <React.Fragment>
                                    <span
                                        key={index}
                                        id={`${formule.nameF === nameFormuleCodeSele ? 'selCodefsp' : ''}`}
                                        onClick={() => {
                                            setNameFormuleCodeSele(formule.nameF);
                                        }}>
                                        {formule.nameF}
                                    </span>
                                </React.Fragment>
                            ))}
                    </div>
                    {/* <pre>{JSON.stringify(laFormuleCode, null, 2)}</pre> */}

                    {laFormuleCode && laFormuleCode.length > 0 && (
                        <React.Fragment>
                            {laFormuleCode[0].servicesinF.map((serviceF, index) => (
                                <li key={index} className='liF'>
                                    <div className='contentLiF'>
                                        <p>
                                            <b>{serviceF.quantity > 1 ? serviceF.quantity : ''}</b> {serviceF.quantity > 1 ? serviceF.nomP : serviceF.nom}
                                        </p>
                                        <span><b>{serviceF.totalPrice} €</b></span>
                                    </div>
                                </li>
                            ))}
                        </React.Fragment>
                    )}
                    {/* <pre>{JSON.stringify(serviceCodeFiltre, null, 2)}</pre> */}

                </ul>
            }
            {code &&
                <React.Fragment>
                    {laFormuleCode[0].totalPriceFor &&
                        <p className='priceFORFAITcode'>{laFormuleCode[0].totalPriceFor} €</p>
                    }
                </React.Fragment>
            }



            {/* ici que ce termine l'ajout a modifier */}
            <ul className="listeF">

                {laFormule.servicesinF.map((service, index) => (
                    <li className="liF" key={index}>
                        <div className="contentLiF">
                            <p><b>{service.quantity > 1 ? service.quantity : ''}</b> {service.quantity > 1 ? service.nom : service.nom}</p>
                            <span>{service.totalPrice} €</span>
                        </div>
                    </li>
                ))}

            </ul>

            {code &&
                <p className="priceFORFAITcode">{laFormule.totalPriceFor} €</p>
            }

            {laFormule.remise !== undefined &&
                <ul className='listeF'>
                    <li className='liF'>
                        <div className='contentLiF'>
                            <p>
                                REMISE SPECIALE
                                {/* {dataRemise.nom} */}
                            </p>
                            <span><b>{laFormule.remise.tarifNormal} €</b></span>
                        </div>
                    </li>
                </ul>
            }



            <p className={laFormule.remise ? 'thePricestop' : 'priceFORFAIT pfort'}>
                {code ?
                    laFormule.totalPriceFor + laFormuleCode[0].totalPriceFor
                    :
                    laFormule.totalPriceFor} €
            </p>
            {laFormule.remise &&
                <p className='priceREMISEcntp'>
                    {code ?
                        laFormule.totalPriceFor + laFormuleCode[0].totalPriceFor + laFormule.remise.tarifNormal
                        :
                        laFormule.totalPriceFor + laFormule.remise.tarifNormal} €
                </p>
            }
        </div >
    );
};

export default LaFormuleCard;