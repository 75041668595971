import React, { useEffect, useState } from 'react';
import { FaInstagram, FaSnapchat, FaWhatsapp } from "react-icons/fa6";
import { FaSnapchatGhost, FaFacebookF } from "react-icons/fa";
import { LiaFacebookMessenger } from "react-icons/lia";
import { Link, useLocation  } from 'react-router-dom';
import logoZP from '../assets/logo/LOGO-ZP.png';

const Footer = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className='lay-footer'>
            <footer className='widthMaxcont'>
                <section className='thesecR'>
                    <ul className="media-footer">
                        {/* <li>
                            <a href="">
                                <FaFacebookF size={44} />
                                <span className="hiSo">Facebook</span>
                            </a>
                        </li> */}
                        <li>
                            <a target="_blank" href="https://www.instagram.com/zonepermis/">
                                <FaInstagram size={44} color={'rgba(255, 255, 255, 0.7)'} />
                                <span className="hiSo">Instagram</span>
                            </a>
                        </li>
                        <li>
                            <a href="snapchat://add/zone-permis">
                                <FaSnapchat size={44} color={'rgba(255, 255, 255, 0.7)'} />
                                <span className="hiSo">Snapchat</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://wa.me/33662894627/?text=Bonjour,">
                                <FaWhatsapp size={44} color={'rgba(255, 255, 255, 0.7)'} />
                                <span className="hiSo">WhatsApp</span>
                            </a>
                        </li>

                    </ul>
                </section>
                <section className='LogoCPcs'>
                    <img
                        src={logoZP}
                        alt={'LelogoZonePermis'}
                    />
                    <span>Copyright © 2024 zone permis, Inc.</span>
                </section>
                <section className='ScfFot'>
                    <ul className='rgFooter'>
                        <li>
                            <Link to="/mentions-legales">
                                Mentions légales
                            </Link>
                        </li>
                        <li>
                            <Link to="/obligations-ecole-de-conduite">
                                Obligations de l'école de conduite
                            </Link>
                        </li>
                        <li>
                            <Link to="/reglement-interieur">
                                Réglement intérieur
                            </Link>
                        </li>
                        <li>
                            <Link to="/mediations-des-litiges-de-consomation">
                                Médiation des litiges de consomation
                            </Link>
                        </li>
                    </ul>
                </section>
            </footer>
        </div>
    );


};

export default Footer;