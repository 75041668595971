import React from "react";
const iAUTO = ({size=24, color="#0056b3"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
width={size} height={size} 
viewBox="0 0 300 210" 
fill={color} 
stroke="none"
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round">
    <path className="st0" d="M144.8,1.4c45.2,0,90.4,0,135.6,0c1.3,0,2.6,0,3.9,0c3.4,0.2,5.1,3.3,3.4,6.3c-0.7,1.2-1.8,1.7-3.1,1.7
		c-2.1,0.1-2.6,0.6-2.6,2.6c-0.3,13.4-0.6,26.8-1,40.2c-0.3,12.2-0.7,24.4-1,36.6c-0.1,2.8-0.1,2.8,2.8,2.7
		c3.6-0.2,7.1,0.1,10.2,2.2c3.6,2.6,5.6,6.1,5.6,10.6c0,11.5,0.1,23,0.1,34.4c0,6.3-0.1,12.5-0.1,18.8c-0.1,14.9-11,28-25.7,30.6
		c-6,1.1-12,1-18,0.8c-4.7-0.1-9.5-0.6-13.8-2.7c-12.1-5.8-19-15.3-19.1-29c-0.2-17.5-0.1-35.1-0.1-52.6c0-6.7,4-11.6,10.5-12.9
		c1.8-0.3,3.6-0.3,5.4-0.4c2-0.1,2.6-0.7,2.6-2.7c-0.2-9.5-0.4-18.9-0.7-28.4c-0.2-9.6-0.5-19.2-0.7-28.8
		c-0.2-6.3-0.3-12.5-0.5-18.8c-0.1-2.7-0.7-3.3-3.4-3.3c-21.7,0-43.3,0-65,0c-0.3,0-0.6,0-1,0c-1.7,0-2.2,0.5-2.2,2.3
		c-0.3,9.2-0.6,18.5-0.9,27.7c-0.4,12.4-0.7,24.9-1.1,37.3c0,0.8-0.1,1.7-0.1,2.5c0,1.8,0.5,2.3,2.3,2.3c2.5,0,4.9,0,7.4,0
		c4.4,0,8.8,0,13.2,0c10.5,0.2,20,8.3,21.5,18.7c0.7,4.5,0.8,9.1,1,13.6c0.6,12.8,0.6,25.6,0,38.4c-0.2,4.8-0.3,9.7-1.2,14.5
		c-2.1,11.2-10.9,18.3-22.3,18.3c-27.7,0.1-55.4,0-83.1,0c-6.4,0-12.3-1.8-17-6.5c-4-4-6.1-9-6.6-14.5c-1.5-15.7-1.8-31.5-1.1-47.2
		c0.3-5.1,0.5-10.2,1-15.4c1-11,10.8-19.9,21.8-20c6.9-0.1,13.7,0,20.6,0c2.5,0,2.8-0.4,2.7-2.8c-0.5-16-1-32.1-1.5-48.1
		c-0.2-6.2-0.4-12.4-0.5-18.7c-0.1-2.3-0.4-2.6-2.7-2.7c-25,0-50,0-74.9,0c-13,0-25.9,0-38.9,0c-0.4,0-0.8,0-1.2,0
		C3,9.3,1.5,7.7,1.5,5.6c0-2.5,1.4-4.1,3.7-4.2c1-0.1,1.9,0,2.9,0C53.7,1.4,99.2,1.4,144.8,1.4C144.8,1.4,144.8,1.4,144.8,1.4z
		 M158.9,176.8C158.9,176.8,158.9,176.8,158.9,176.8c8.9,0,17.7,0,26.6,0c2.5,0,5-0.4,7.3-1.4c5.6-2.6,8.2-7.1,8.4-13.1
		c0.3-11.5,0.9-22.9,0.9-34.4c0-8.3-0.5-16.6-1.1-24.9c-0.5-6.3-4.2-10.7-10.1-12.2c-2-0.5-4.1-0.7-6.1-0.7
		C162.9,90,141.2,90,119.5,90c-5.9,0-11.9-0.1-17.8,0.1c-7.1,0.3-12.5,5.2-13.3,12.2c-0.6,5-1.1,10.1-1.2,15.2
		c-0.2,9.9-0.1,19.9,0.1,29.8c0.1,5,0.5,9.9,0.8,14.9c0.2,2.7,0.8,5.4,2.2,7.7c2.8,4.5,7,6.6,12.3,6.7c9.4,0.1,18.7,0.1,28.1,0.1
		C140.1,176.8,149.5,176.8,158.9,176.8z M230.1,132.1C230.1,132.1,230,132.1,230.1,132.1c-0.1,5-0.1,9.9,0,14.9
		c0.1,4.6-0.1,9.3,0.5,13.9c1.2,9.9,10.2,18.5,20.2,19.2c5.5,0.4,11,0.4,16.5,0.1c9.9-0.6,17.1-5.3,21.1-14.6c1.3-3,1.8-6.2,1.8-9.5
		c0-16.8,0-33.5,0-50.3c0-0.2,0-0.4,0-0.6c-0.1-3.8-1.6-5.3-5.4-5.3c-0.2,0-0.4,0-0.6,0c-16,0-31.9,0-47.9,0c-0.7,0-1.4,0-2.1,0.1
		c-2.6,0.4-3.7,1.6-4,4.2c0,0.4-0.1,0.8-0.1,1.2C230.1,114.3,230.1,123.2,230.1,132.1z"/>
	<path className="st1" d="M144.7,163.7c-13.5,0-27.1,0-40.6,0c-0.8,0-1.6-0.2-2.4-0.2c-2.5-0.3-3.5-2.5-3.4-4.4c0.1-2.2,1.6-3.7,3.9-3.9
		c0.9-0.1,1.8-0.2,2.7-0.2c26.5,0,52.9,0,79.4,0c1,0,1.9,0,2.9,0.2c2.3,0.3,3.7,2,3.7,4.4c0,2.1-1.8,3.9-4,4.1c-0.6,0-1.2,0-1.8,0
		C171.6,163.7,158.1,163.7,144.7,163.7z"/>
	<path d="M144.7,109.8c-13.6,0-27.2,0-40.7,0c-0.9,0-1.8-0.1-2.6-0.3c-2-0.6-3.1-2.3-3-4.3c0.1-1.9,1.4-3.4,3.4-3.8
		c0.8-0.2,1.6-0.2,2.4-0.2c27,0,54,0,81,0c0.7,0,1.4,0,2.1,0.1c2,0.3,3.6,2,3.7,4c0.1,2.1-1.3,3.9-3.4,4.4c-0.7,0.1-1.4,0.2-2,0.2
		C171.8,109.8,158.3,109.8,144.7,109.8z"/>
	<path className="st0" d="M144.6,145.9c-13.5,0-27.1,0-40.6,0c-0.7,0-1.5,0-2.2-0.2c-2.2-0.4-3.5-2-3.5-4.1c0-2.1,1.4-3.8,3.5-4.2
		c1.1-0.2,2.3-0.1,3.5-0.1c0.4,0,0.7,0,1.1,0c5.8,0,11.6,0,17.5,0c19.7-0.1,39.4-0.1,59.1-0.2c1.4,0,2.8,0,4.2,0.2
		c2.5,0.3,3.9,2.1,3.8,4.4c-0.1,2.5-1.7,4.1-4.3,4.1c-3.4,0-6.8,0-10.2,0C165.8,145.9,155.2,145.9,144.6,145.9z"/>
	<path d="M144.7,119.7c13.4,0,26.7,0,40.1,0c0.7,0,1.5,0,2.2,0.1c2.3,0.3,4,2.2,3.9,4.4c-0.1,2.1-1.8,4-4,4.1
		c-1,0.1-2.1,0.1-3.1,0.1c-26.3,0-52.7,0-79,0c-1,0-2.1,0-3.1-0.3c-2.2-0.5-3.4-2.2-3.3-4.3c0.1-2.1,1.5-3.6,3.8-3.9
		c0.5-0.1,1-0.1,1.5-0.1C117.3,119.7,131,119.7,144.7,119.7C144.7,119.7,144.7,119.7,144.7,119.7z"/>
	<path className="st1" d="M260.3,116.3c-5.6,0-11.1,0-16.7,0c-0.8,0-1.5-0.1-2.3-0.2c-2.3-0.5-3.4-2-3.3-4.3c0.1-2.2,1.4-3.6,3.7-3.9
		c0.7-0.1,1.4-0.1,2-0.2c3.8,0,7.7,0,11.5,0c7,0,14.1,0,21.1,0c1,0,2.1,0,3.1,0.2c2.1,0.4,3.2,1.9,3.3,4c0,2.2-1.1,3.7-3.1,4.1
		c-0.8,0.2-1.6,0.2-2.4,0.2C271.5,116.4,265.9,116.3,260.3,116.3z"/>
	<path className="st1" d="M260.4,152.5c-5.7,0-11.3,0-17,0c-0.8,0-1.5,0-2.3-0.2c-2.2-0.5-3.4-2-3.3-4.3c0.1-2.2,1.4-3.7,3.6-4
		c0.6-0.1,1.2-0.1,1.8-0.1c11.3,0,22.5,0,33.8,0c0.6,0,1.1,0,1.7,0.1c2.3,0.3,3.7,1.9,3.8,4.3c0,2.3-1.4,3.9-3.7,4.2
		c-0.4,0.1-0.7,0.1-1.1,0.1C272.1,152.5,266.2,152.5,260.4,152.5C260.4,152.5,260.4,152.5,260.4,152.5z"/>
	<path className="st0" d="M260.4,134.9c-5.7,0-11.3,0-17,0c-0.5,0-1.1,0-1.6-0.1c-2.5-0.3-4-1.9-4-4.2c0-2.4,1.4-4,3.9-4.3
		c0.5-0.1,1-0.1,1.6-0.1c11.5,0,23,0,34.5,0c3.2,0,4.9,1.8,4.7,4.8c-0.1,2.3-1.9,3.7-4.5,3.8c-2.8,0-5.6,0-8.5,0
		C266.6,134.9,263.5,134.9,260.4,134.9C260.4,134.9,260.4,134.9,260.4,134.9z"/>
	<path className="st0" d="M260.2,170.2c-3.8,0-7.6,0-11.4,0c-2.4,0-4.1-1.6-4.3-3.8c-0.2-2.7,1.5-4.7,4.3-4.7c4.4-0.1,8.9,0,13.3,0
		c3.2,0,6.4,0,9.6,0c2.7,0,4.4,1.7,4.4,4.2c0,2.6-1.7,4.2-4.4,4.2C267.8,170.2,264,170.2,260.2,170.2
		C260.2,170.2,260.2,170.2,260.2,170.2z"/>
</svg>);
export default iAUTO;