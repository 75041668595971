const PJ = [
    {
      "namePJ": "E-photo d'identité ANTS",
      "description": "Si vous souhaitez envoyer une photo d'identité au format papier : téléchargez et envoyez le formulaire de dépôt photo signature",
      "conditionAgeMini": "13",
      "conditionAgeMax": "99",
      "acceptdocuments": [
        "format numérique",
        "papier"
      ],
      "categories": [
        "B AAC",
        "B MANU",
        "B MANU CS",
        "B MANU AAC",
        "B AUTO",
        "B AUTO CS",
        "B AUTO AAC",
        "A1 A2",
        "A1",
        "A2",
        "AM cyclo",
        "AM quadri",
        "ETG",
        "ETM"
      ],
      "__v": 0
    },
    {
      "namePJ": "Justificatif d'identité",
      "description": "Description de justificatif d'identité",
      "conditionAgeMini": "13",
      "conditionAgeMax": "99",
      "acceptdocuments": [
        "carte nationale d'identité",
        "passeport",
        "titre de séjour"
      ],
      "categories": [
        "B AAC",
        "B MANU",
        "B MANU CS",
        "B MANU AAC",
        "B AUTO",
        "B AUTO CS",
        "B AUTO AAC",
        "A1 A2",
        "A1",
        "A2",
        "AM cyclo",
        "AM quadri",
        "F125",
        "A",
        "ETG",
        "ETM"
      ],
      "__v": 0
    },
    {
      "namePJ": "Justificatif de domicile",
      "description": "Description de justificatif domicile",
      "conditionAgeMini": "13",
      "conditionAgeMax": "99",
      "acceptdocuments": [
        "facture à votre nom de moins de 3 mois (d'eau, d'électricité, de gaz ou de téléphone)",
        "avis d'imposition ou de non imposition",
        "quittance de loyer non manuscrite"
      ],
      "categories": [
        "B AAC",
        "B MANU",
        "B MANU CS",
        "B MANU AAC",
        "B AUTO",
        "B AUTO CS",
        "B AUTO AAC",
        "A1 A2",
        "A1",
        "A2",
        "AM cyclo",
        "AM quadri",
        "ETG",
        "ETM"
      ],
      "__v": 0
    },
    {
      "_id": "661d8ebf2b87bcf012cd3fee",
      "namePJ": "Permis de conduire",
      "description": "Le permis de conduire recto-verso",
      "conditionAgeMini": "13",
      "conditionAgeMax": "99",
      "acceptdocuments": [
        "permis de conduire"
      ],
      "categories": [
        "F125",
        "A"
      ],
      "__v": 0
    },
    {
      "namePJ": "Attestation de suivi de formation",
      "description": "Attestation de suivi de formation pour (A, A1, B96, levée 78)",
      "conditionAgeMini": "13",
      "conditionAgeMax": "99",
      "acceptdocuments": [
        "attestation de suivi de formation"
      ],
      "categories": [
        // "F125",
        // "A"
      ]
    },
    {
      "namePJ": "2x photos d'identité",
      "description": "Photo format passeport",
      "conditionAgeMini": "13",
      "conditionAgeMax": "99",
      "acceptdocuments": [
        "photo photomaton ou autres"
      ],
      "categories": [
        "F125",
        "A"
      ]
    },
    {
      "namePJ": "Attestation d'hébergement",
      "description": "Attestation d'hébergement (Modèle de lettre)",
      "conditionAgeMini": "14",
      "conditionAgeMax": "18",
      "acceptdocuments": [
        "document manuscrit",
        "modèle lettre pdf"
      ],
      "categories": [
        "B AAC",
        "B MANU",
        "B MANU CS",
        "B MANU AAC",
        "B AUTO",
        "B AUTO CS",
        "B AUTO AAC",
        "A1 A2",
        "A1",
        "A2",
        "AM cyclo",
        "AM quadri",
        "ETG",
        "ETM"
      ]
    },
    {
      "namePJ": "Justificatif d'identité du représentant légal",
      "description": "justificatif d'identité au nom du responsable légal",
      "conditionAgeMini": "14",
      "conditionAgeMax": "18",
      "acceptdocuments": [
        "carte nationale d'identité",
        "passeport",
        "carte de séjour"
      ],
      "categories": [
        "B AAC",
        "B MANU",
        "B MANU CS",
        "B MANU AAC",
        "B AUTO",
        "B AUTO CS",
        "B AUTO AAC",
        "A1 A2",
        "A1",
        "A2",
        "AM cyclo",
        "AM quadri",
        "ETG",
        "ETM"
      ]
    },
    {
      "namePJ": "Certificat de participation JDC",
      "description": "Certificat individuel de participation à la journée défense et citoyenneté (JDC)",
      "conditionAgeMini": "16",
      "conditionAgeMax": "25",
      "acceptdocuments": [
        "certificat JDC"
      ],
      "categories": [
        "B AAC",
        "B MANU",
        "B MANU CS",
        "B MANU AAC",
        "B AUTO",
        "B AUTO CS",
        "B AUTO AAC",
        "A1 A2",
        "A1",
        "A2",
        "AM cyclo",
        "AM quadri",
        "ETG",
        "ETM"
      ]
    },
    {
      "namePJ": "ASSR",
      "description": "Pour l'usager âgé de moins de 21 ans, l'ASSR2 ou l'ASR ou, à défaut, une déclaration sur l'honneur en vue de la délivrance d'un premier titre de conduite conforme à l'annexe VII de l'arrêté du 20 avril 2012 modifié fixant les conditions d'établissement, de délivrance et de validité du permis de conduire",
      "conditionAgeMini": "13",
      "conditionAgeMax": "21",
      "acceptdocuments": [
        "ASSR2",
        "ASR",
        "une déclaration sur l'honneur en vue de la délivrance d'un premier titre de conduite conforme à l'annexe VII de l'arrêté du 20 avril 2012"
      ],
      "categories": [
        "AM quadri",
        "AM cyclo"
      ],
      "__v": 0
    },
    {
      "namePJ": "ASSR",
      "description": "Pour l'usager âgé de moins de 21 ans, l'ASSR2 ou l'ASR ou, à défaut, une déclaration sur l'honneur en vue de la délivrance d'un premier titre de conduite conforme à l'annexe VII de l'arrêté du 20 avril 2012 modifié fixant les conditions d'établissement, de délivrance et de validité du permis de conduire",
      "conditionAgeMini": "14",
      "conditionAgeMax": "21",
      "acceptdocuments": [
        "ASSR2",
        "ASR",
        "une déclaration sur l'honneur en vue de la délivrance d'un premier titre de conduite conforme à l'annexe VII de l'arrêté du 20 avril 2012"
      ],
      "categories": [
        "B AAC",
        "B MANU",
        "B MANU CS",
        "B MANU AAC",
        "B AUTO",
        "B AUTO CS",
        "B AUTO AAC",
        "A1 A2",
        "A1",
        "A2",
        "ETG",
        "ETM"
      ],
      "__v": 0
    }
  ];

  export default PJ;