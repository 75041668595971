import React, { useState } from 'react';

const ConditionsAge = ({
    categorie,
    age,
    setAge,
    ageMini,
    trancheAgepossible
}) => {

    
    function getFirstTwoDigits(number) {
        // Convertir le nombre en chaîne de caractères
        const str = number.toString();
        
        // Trouver la position du point décimal
        const decimalIndex = str.indexOf('.');
      
        // Si le point décimal est trouvé, retourner les deux premiers chiffres avant le point
        if (decimalIndex !== -1) {
          return str.slice(0, decimalIndex).slice(0, 2);
        }
      
        // Sinon, retourner les deux premiers chiffres du nombre entier
        return str.slice(0, 2);
      }

    return (
        <div className='CompAge'>
            {trancheAgepossible.length !== 1 &&
                <React.Fragment>
                    <ul>
                        {trancheAgepossible.map((agep, index) => (
                            <li className={`liAGE ${age === agep ? 'selAGE' : ''}`} key={index}>
                                <button onClick={() => setAge(agep)}>{`+${getFirstTwoDigits(agep)}`}</button>
                            </li>
                        ))}

                    </ul>
                    <p>Séléctionnez votre tranche d'âge</p>
                </React.Fragment>
            }

        </div >
    );
};

export default ConditionsAge;