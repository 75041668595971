import React from "react";
const iCB125F = ({ size = 24, color = "#0056b3" }) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size} height={size}
		viewBox="0 0 300 178"
		fill={color}
		stroke="none"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round">
		<g>
			<path d="M60.6,171.7c-4.4-1.3-12-4.4-18.7-11.3c-1.6-1.7-4.5-5-7-9.7c-5.6-10.7-6.2-21.8-2.3-33.1c3.4-9.7,9.9-17,18.9-22
		c9-5,18.7-6.1,28.9-4.6c-8.4-4.3-16.7-8.6-24.8-12.7c-0.4,0.5-0.7,1.1-1.2,1.2c-2.6,0.8-5.2,1.4-7.8,2.1c-2.6,0.7-4.8,2.2-6.3,4.4
		c-5.4,7.6-10.7,15.3-16.4,22.6c-1.8,2.3-5,3.7-7.5,5.4c-0.3,0.2-0.8,0.1-1.5-0.1c5.3-3.2,6.8-8.6,8.7-13.7
		c-0.4-0.2-0.3-0.1-0.7-0.4c-1.3,2-2.6,4-3.8,6c-0.2-0.1-0.4-0.2-0.6-0.4c0.1-0.4,0.2-0.9,0.4-1.3c3.1-6.1,6.4-12.2,9.4-18.5
		c0.9-1.8,2.4-0.7,3.4-1.2v-6.1c-1-0.3-2.6-0.7-4.1-1.2c0.5-2.2,2-3.3,5-3.5c1.4-0.1,2.7,0,4.8,0c-0.5-1.2-0.7-2.2-1.2-2.8
		c-1.7-1.9-3.5-3.7-5.3-5.6c1.1,0.5,2.3,1,3.4,1.5c0.2-0.4,0.1-0.2,0.3-0.6c-1-0.6-2.1-1.2-3.1-1.8c-0.2,0.3-0.3,0.7-0.5,1
		c-1.3-1.4-2.5-2.8-3.8-4.1c0.2-0.3,0.2-0.2,0.4-0.5c0.6,0.2,1.2,0.7,1.8,0.7c0.5,0,1-0.4,1.5-0.6c-0.4-0.4-0.6-1-1.1-1.2
		c-0.9-0.4-2-0.4-2.7-1c-0.9-0.6-1.5-1.5-2.2-2.3c0.2-0.3,0.2-0.3,0.3-0.6c3.8,0.3,7.6,0.6,11.4,1c-1.5-3.7-6.9-4-8.2-8.2
		c0.2-0.2,0.3-0.3,0.5-0.5c2,0.5,4.1,0.7,5.8,1.6c2,1,3.7,2.8,5.6,4c2.7,1.6,5.5,2.9,8.3,4.3c0.2-0.3,0.2-0.2,0.4-0.6
		c-0.8-0.7-1.6-1.4-2.4-2.1c0.1-0.1,0.1-0.1,0.1-0.3c1.4,0.5,2.8,1.1,4.3,1.4c4.8,1,9.7,1.8,14.6,2.8c4.6,0.9,9.3,1.7,13.7,3.1
		c3.2,1,6.3,2.6,9,4.5c4.9,3.4,10,6.2,16.1,7.1c2.2,0.3,4.4,0.7,6.5,1.3c6,1.8,11.1-0.7,16.1-3.5c2.4-1.3,4.2-2.9,4.6-5.8
		c-3.7,0.7-7.4,1.8-11.1,2.2c-7.8,0.8-15.6,1.3-23.4,0.3c-3.4-0.5-6.6-1.5-9.4-3.5c-2-1.5-4.1-3-6.4-4c-2.2-1-4.7-1.3-7-2.4
		c3.3,0.9,6.7,1.4,9.8,2.8c12.2,5.4,24.9,6.2,37.9,4.9c2.1-0.2,4.1-1.1,6.2-1.8c3.1-1.1,3.3-1.2,6.3-0.5c1.5-3.4,4.3-5.5,7.4-7.1
		c4.7-2.4,9.5-4.6,14.3-6.8c4.5-2,9.2-1.6,13.9-1.2c2.5,0.2,4.9,0.8,7.4,0.8c1.8,0,3.4,0.1,5.2,0.6c3.5,1,5.8,3.4,8.1,5.8
		c0.5,0.6,1.1,1.1,1.6,1.7c0.5-0.2,0.9-0.3,1.3-0.5c0.2,0.6,0.4,1.2,0.6,2.1c0.4,0.1,1.1,0.4,1.7,0.3c0.4-0.1,1-0.9,0.9-1.2
		c-0.6-2-0.8-2.7-0.6-4.2l-1.5,0c-0.1-0.2-0.1-0.2-0.2-0.4c0.8-0.7,1.7-1.4,2.3-1.9c-0.1-0.9-0.3-1.8-0.5-2.9c1.1,0.6,1.7,1,2.5,1.2
		c0.4,0.1,0.9,0,1.4,0c-0.1-0.5,0-1.4-0.3-1.6c-1.2-0.7-2.4-2.2-4.2-0.9c-0.2-0.6-0.3-1.1-0.5-1.6c-0.4,0.2-0.7,0.4-1.1,0.6
		c0.5-1.5-0.5-2.9-2.1-3.2c-1.3-0.2-2.6-0.5-3.9-0.6c-0.4,0-1.1,0.2-1.4,0.5c-1.2,1.1-2.4,1.2-3.9,0.3c-0.8-0.5-1.9-0.7-2.8-1
		c-0.8-0.3-1.6-0.9-2.5-1c-1.2-0.2-1.3-0.1-1.7,1.6c-0.5-0.7-1.3-1.3-1.6-2c-0.5-1.4,0.8-1.2,1.5-1.6c0.8-0.4,1.7-0.7,2.7-1.1
		c0.2,0.5,0.4,0.9,0.6,1.4c2.5-1.4-0.8-3.4,1-5.2c-1.2,0.2-2,0.6-2.7,0.5c-0.9-0.1-2.2-0.3-2.6-0.9c-0.4-0.7-0.3-2.2,0.2-2.8
		c0.5-0.5,2-0.7,2.7-0.4c1.7,0.7,3.1,0.8,4.6-0.4c-0.8,0.1-1.7,0.2-2.5,0.3c0-0.1-0.1-0.2-0.1-0.3c0.3-0.2,0.6-0.5,1-0.7
		c1.8-0.7,3.5-1.4,5.3-2.1c1.8-0.7,2.4-2.5,1.8-4c-1.3-3-3.1-5.7-4.7-8.5c-0.1-0.2-0.6-0.1-1-0.2c0,0.4-0.1,0.8,0,1
		c1.7,3.5,0.3,7.6,2.6,10.9c-0.2,0.1-0.4,0.1-0.6,0.2c-0.4-0.9-0.9-1.8-1.3-2.7c-0.4-1.1-1-1.4-2.2-0.9c-1.5,0.6-3.2,0.9-4.8,1.2
		c1.9-0.4,2.9-1.7,3.5-3.3c0.6-1.5,1.7-3,0.8-4.5c-1.9-0.3-3.7-0.6-5.7-0.9c-0.2,0.2-0.6,0.9-1,1.6l-0.5-0.3c2-2.9-0.2-5.9,0.2-8.9
		c0.1-1.1-0.1-2.1,1.5-2.3c0.5-0.1,1-0.7,1.5-1.2c2-2,5-1.3,5.7,1.5c0.4,1.7,0.1,3.7,1.8,4.9c0.1,0.1,0.2,0.4,0.1,0.5
		c-0.8,2.2,0.9,3.6,1.7,5.3c1.2,2.4,2.3,4.9,3.5,7.3c0.5,1,1,2.1,2.6,2.2c3.9,0.5,6.8,2.7,8.9,6c0.2,0.4,0.4,0.9,0.6,1.3
		c-0.1,0.1-0.3,0.2-0.4,0.3c-0.9-1.1-1.6-2.4-2.7-3.3c-1-0.9-2.2-1.5-3.6-2c0.2,1.9-1,3.9,0.7,5.3c1.5,1.2,3.1,2.2,4.7,3.2
		c0.1,0.1,0.9-0.5,1-0.9c0.1-0.5-0.1-1.1-0.2-1.9c0.8,0.3,1.4,0.4,1.9,0.6c5.1,2.5,10.2,4.9,15.3,7.4c0.6,0.3,1.4,0.4,2.1,0.6
		l0.3-0.8c-5.5-2.8-11-5.6-16.4-8.3c1.3-0.9,1.3-0.7,2.8,0.1c1.6,0.9,3.4,1.4,5,2.2c2.5,1.2,5,2.4,7.7,3.2c-0.8-0.5-1.7-1-2.5-1.5
		c-3-1.5-6.1-3-9.1-4.5c-1.9-0.9-3.4-5.9-1.9-8c0.6,1.6,1.2,2.9,1.7,4.2c0.2,0.5,0.4,1.2,0.8,1.5c3.8,2.3,7.6,4.6,11.4,6.9
		c0-1.6,0-3.2,0-4.8c0.4,1.3,0.8,2.5,1.4,3.8c0.2,0.5,0.7,1,1.2,1.3c0.2,0.1,1-0.3,1-0.5c0.1-0.6,0.2-1.6-0.2-1.9
		c-1-1-2.3-1.8-3.5-2.6c-0.4-0.6-0.8-1.4-1.3-1.9c-2.8-2.2-5.7-4.4-8.5-6.5c-0.5-0.4-1.1-0.8-1.6-1.3c2.7,0.3,5.3,0.7,7.5,2.3
		c5.2,3.9,10.1,8.3,14.1,13.4c0.7,0.9,1.5,1.8,2.3,2.7c-1.5,1.2,0.2,1.7,0.7,2.4c0.6,0.8,1.5,1.5,2.2,2.3c-0.4,0.4-0.2,0.3-0.6,0.7
		c-1.4-1-2.7-2-4.2-3.1c-1.6,2.2-2.5-1-3.8-0.7c-0.2-0.7-0.3-1.6-0.7-1.8c-0.3-0.2-1.5,0.1-1.6,0.4c-0.1,0.6,0,1.5,0.3,2.1
		c0.8,1.5,1.7,2.9,2.8,4.2c0.5,0.7,1.4,1.2,2.1,1.7c1.3,1,2.6,2,4.1,3.1c-1,0.8-1.5,1.3-2.5,2.1c2.5,3.8,5,7.7,7.5,11.5
		c-1.5,1-3.1,2.1-4.8,3.1c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.3,0.1-0.4,0.1c-1.6-1.3-3.5-0.3-5.2-0.4c-1.4-0.1-2.7-0.5-4-0.9
		c-1.8-0.6-2.2,0.6-2.6,1.6c-0.3,0.6-0.3,1.4-0.3,2.1c-0.1,2.3-0.8,3-3.7,2.6c2.9,6.3,5.7,12.3,9,18.2c1.6-1.3,2.8-2.9,4.3-1.8
		c1.3-0.7,2.3-1.6,3.5-1.9c11-2.6,21.4-1,31.1,4.6c0.3,0.2,0.5,0.4,0.9,0.7c-0.9,0.4-1.6,0.6-2.7,1.1c15.3,10,23.5,23.6,20.9,41.8
		c-2.6,18.1-11.2,28.7-28.4,34.1c-1.5,0.5-0.1,0-2.2,0.8c-0.1,0,0,0-0.4,0.1c-0.1,0-0.3,0.1-0.8,0.1c-8.5,1.1-16.8,0-17.4-0.2
		c0,0.1-1.7-0.3-2.1-0.5c-1.2-0.4-2.8-1-1.3-0.5c-18.3-6.1-26.4-17.7-28.7-37.2c-1.9,1.6-3.5,3.1-5.2,4.5c-0.2-0.1-0.2-0.1-0.4-0.3
		c0.2-0.4,0.4-0.9,0.6-1.3c1.6-3.3,2.8-6.7,3.3-10.5c1.2-8.5,4-16.6,9.5-23.4c0.3-0.4,0.6-1.3,0.4-1.7c-2.2-4.1-2.1-8.4-1.5-12.8
		c0-0.2,0-0.5,0-1.2c-1.1,0.6-2,1-2.8,1.5c-1.5,0.8-3,1.5-4.4,2.4c-0.9,0.5-2,1.1-2.5,2c-2.9,5.1-5.6,10.3-8.4,15.4
		c-0.2,0.3-0.4,0.9-0.6,0.9c-2.2,0.3-2.4,2.2-3,3.7c-3.3,9.1-9.2,16.6-14.3,24.6c-4.6,3-8.3,7.7-14.7,6.8c-3.2-0.4-6.4-0.4-9.6-0.5
		c-2.7-0.1-5.4,0.1-8.2-0.1c-3.3-0.2-6.5-0.7-9.8-1c-0.4,0-0.8-0.1-1.2-0.1c-2.7,0-3,0.3-2.2,2.9c-5.9,0.2-11.8,0.4-17.7,0.6
		c-1.2,0-2,0.2-2.6,1.5c-2.7,5.6-6.3,10.3-10.8,14c-4,3.3-8.7,5.4-13.1,7.1c-0.9,0.4-1.9,0.7-2.6,1
		C78.6,172.5,71.9,174.1,60.6,171.7z M196.7,70.3c0,0.1,0.1,0.2,0.2,0.2c-0.1,0.2-0.1,0.4-0.2,0.7l0-0.1c-0.3,0.3-0.9,0.8-0.8,0.9
		c0.6,1.2,1.3,2.3,2.1,3.5c-1.6,1.3-3.4-0.2-5.2-0.3c-0.1,0.5-0.2,0.9-0.4,1.5c3.9,1,7.7,2,11.4,2.9c0.1-0.2,0.2-0.3,0.3-0.5
		c-0.6-0.5-1.2-1-1.7-1.5c0.5-0.3,1.2-0.6,1.7-1.2c0.3-0.4,0.5-1.5,0.3-1.7c-2.4-1.7-4.9-3.3-7.4-4.9c-0.1-0.1-0.4,0.1-0.7,0.2
		C196.5,70,196.5,70.1,196.7,70.3L196.7,70.3L196.7,70.3z M107.1,86.4c-1.6-0.1-3.3-0.2-4.9-0.3c0,0.2,0,0.4,0,0.6
		C103.9,88.1,105.4,86.2,107.1,86.4c-0.3,0.3-0.6,0.7-1.1,1.3c1.7,0.1,3.1,1.2,4.6-0.5C109.3,86.9,108.2,86.6,107.1,86.4z
		 M197.8,39.5c-0.9-0.3-1.7-0.7-2.6-1c1.3,2.3,3.2,3.6,5.5,4.4c0.1-0.2,0.3-0.3,0.4-0.5c-0.8-0.7-1.7-1.5-2.5-2.2
		c1.3,0,1.1-0.7,0.4-1.3c-1.5-1.2-3-2.3-4.4-3.4c-0.5,0.4-1,0.8-1.4,1.2C194.7,37.6,196.3,38.5,197.8,39.5z M223.3,93.3
		c0.6,0.4,1.2,0.8,1.9,1.3c0.3-1.8-4-10.4-5.4-11.3c0.6,2,1.2,3.9,1.8,5.9c-0.9-1.3-1.8-2.6-2.8-3.9c-0.2-0.3-0.5-0.7-0.8-0.7
		c-0.7-0.2-4.1,2.3-4.1,3.1c-0.2,3.4-0.3,6.9-0.3,10.3c0,0.8,0.4,1.7,0.6,2.6c3.1-2.2,5.9-4.2,8.8-6.2
		C223.2,94.1,223.2,93.6,223.3,93.3z M243.3,107.5c-0.6,1.5-0.9,3-1.7,3.9c-0.8,0.9-2.2,1.1-3.6,1.8c1.8,3.7,3.8,7.8,5.9,11.9
		c0.2,0.3,0.8,0.6,1.3,0.7c3.2,0.4,5.3,1.8,6.6,5c1.8,4.4-0.5,9.2-5.2,10.3c-3,0.6-5.5-0.3-7.4-2.5c-1.9-2.1-2.6-4.6-1.3-7.4
		c0.2-0.4-0.2-1.2-0.5-1.7c-0.1-0.2-0.9-0.2-1-0.1c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.4,0.3,0.8,0.5,1.2c-0.6,0.4-1,1-1.5,1
		c-1.4,0.1-1.7,0.7-1.6,2c0.1,1.3-1.5,2.3-3,1.8c-1.6-0.5-3.3-1.1-5-1.8c1.9-3.5-1.4-6.5-0.9-10c-0.6,0-0.9,0-1.2,0.1
		c-1.5,0.4-3.1,0.6-4.5,1.2c-1,0.4-1.9,1.2-2.5,2c-1.5,2.2-0.5,3.9,2.1,3.9c0.3,0,0.6,0.2,0.9,0.3c-0.4,0.4-0.7,0.5-1.1,0.5
		c-2.4,0.2-2.7,0.5-2.5,2.9c0.5,4.9,2.2,9.4,5,13.4c1.2,1.7,2.3,1.8,3.6,0.3c1-1.2,1.9-2.5,2.8-3.8c1.2-1.6,2.3-3.2,3.6-4.9
		c0.9,1.4,1.7,2.4,2.1,3.1c-0.8,1.8-1.7,3.3-2,4.9c-0.4,1.9-0.4,3.9-0.5,5.8c-0.1,2.6,0.8,4,3.2,5c6.3,2.5,12.8,2.9,19.4,1
		c2-0.6,2.4-1.5,1.3-3.3c-1.2-1.8-2.5-3.5-3.7-5.2c-0.7-0.9-1.4-1.8-2.2-2.9c1.5-0.5,2.7-0.9,3.9-1.3c-0.1,0.2-0.2,0.4-0.2,0.6
		c2,1.2,4,2.4,6.1,3.5c0.9,0.5,1.8,0.8,2.7,1.1c2.8,1.1,4.3,0.8,6.2-1.5c4.2-5,6.5-10.8,6.9-17.3c0.2-2.9-0.8-3.7-3.6-2.8
		c-2.8,0.9-5.7,1.8-9.1,2.9c0.3-1.7,0.2-3.7,0.8-4c3.5-1.4,5.2-4.5,7.4-7.1c1.5-1.7,1.5-3.6,0.3-5.6c-3.4-5.6-8.3-9.5-14.3-12
		c-3-1.3-3.9-0.7-4,2.6c0,3.1,0,6.2,0,9.5c-1.5-0.4-2.7-0.7-4.2-1.1C247.9,113.1,245.2,110.6,243.3,107.5z M122.4,136.4
		c0-0.7,0.3-1.4,0.1-1.8c-0.6-1.2-1.6-2.1-2.9-1.4c-2.4,1.2-4.1,0.2-5.9-1.4c1.7-0.9,3.2-1.7,5.1-2.6c-0.7-0.3-1.1-0.6-1.5-0.6
		c-2.1,0.4-4.1,0.9-6.2,1.2c-0.8,0.1-1.7,0.2-2.4-0.1c-6.8-2.1-13.5-4.3-20.3-6.5c-2.3-0.7-4.5-1.6-6.8-2.3
		c-2.1-0.6-4.2-1.1-6.5-1.7c0.6-0.4,0.9-0.5,1.2-0.8c-0.8-0.3-1.4-0.6-2-0.8c0.4-0.5,0.7-0.9,0.9-1.3c-0.3-0.2-0.8-0.6-0.9-0.5
		c-0.8,0.6-1.6,1.3-2.7,2.2c-1.4-0.4-3.2-0.9-5.2-1.5c0.9-1.1,3.1,0.3,2.8-1.8c-0.2-1.2-0.8-2.5-1.7-3.4c-1.8-1.8-3.8-3.5-5.9-4.8
		c-1-0.6-3-0.8-4-0.3c-6.2,3.2-10.7,8.2-13.2,14.8c-0.2,0.6,0.3,2.1,0.7,2.2c1.8,0.3,3.7,0.5,5.6,0.3c0.5,0,0.9-1.3,1.4-2
		c0.1-0.2,0.2-0.5,0.3-0.5c1.8,0,1.8-1.5,2.3-2.6c1-2.1,3.6-2.3,5.1-0.5c1.1,1.3,2.5,2.4,4,3.2c2.2,1.2,4.7,2,7,3
		c6.3,2.7,12.8,5.2,17.5,10.4c2.9,3.2,6.3,4.8,10.4,5.6c0.6,0.1,1.1,0.3,1.7,0.4c3.3,0.5,6.5,1.1,9.8,1.3c3.4,0.2,6.7-0.2,10.1-0.2
		c2.4,0,4.4-1,5.5-3.4C124.6,137.5,123.4,136.9,122.4,136.4z M64.8,141.4c-0.6,1.5-1.5,3-2,4.7c-0.4,1.5-0.6,3.2-0.4,4.7
		c0.3,2,0.9,4,1.5,6c0.6,1.9,1.8,3.1,3.9,3.3c6.5,0.8,12.4-0.6,18-3.8c2.2-1.3,2.3-2.4,0.4-3.9c-3.9-2.9-7.9-5.7-12-8.6
		c-0.3,0.6-0.5,1.1-0.8,1.6c-2.5-1.5-4.8-3-7.7-4.8c4.5-1.1,8.4-2,12.3-2.9c0-0.2-0.1-0.4-0.1-0.6c-4.9,0.9-9.9,1.8-15.1,2.8
		c0.4-1.6,0.8-3,1.2-4.6c-0.9-0.1-1.6-0.1-2.4-0.2c0.2-3-1.9-3.7-4.1-4.2c-0.9-0.2-1.9-0.5-2.8-0.8c-0.6-0.3-1.4-0.6-1.8-1.1
		c-0.7-1-1.5-0.9-2.3-0.3c-1.9,1.2-3.7,2.4-5.6,3.6c-2.2,1.4-2.7,2.8-2.1,5.3c1.4,6,4.3,11.1,8.9,15.3c2.2,2,3.3,1.8,4.2-1
		c1-2.8,1.7-5.6,2.6-8.4c0.2-0.6,0.7-1.3,1.2-1.5C61.5,141.7,63,141.6,64.8,141.4z M100.1,82.9c0,0.1,0,0.3-0.1,0.4
		c0.6,0.2,1.2,0.3,1.9,0.5c7.8,1.5,15.6,2.9,23.4,4.4c5.8,1,11.7,2,17.5,2.9c1.3,0.2,2.7,0,4.1,0c0.1-0.3,0.1-0.5,0.2-0.8
		c-0.8-0.5-1.6-1.1-2.5-1.4c-4.1-1.2-8.3-2.2-12.4-3.3c-1.5-0.4-3-0.6-3.1-2.9c-0.7,0.9-1.5,1.8-2.2,2.7c1,0.6,1.9,1.2,2.9,1.9
		c0,0.2,0,0.3-0.1,0.5C119.8,86.2,110,84.6,100.1,82.9z M77.6,107.4c0.9-0.4,1.7-0.7,2.6-1c0-0.2,0-0.4-0.1-0.6
		c-0.9-0.3-1.7-0.5-2.5-0.7c1-0.2,2.1-0.5,3.3-0.8c0-0.2,0-0.4,0-0.6c-0.6-0.2-1.3-0.5-1.9-0.6c-1.9-0.2-3.8-0.2-5.7-0.3
		c-1.9-0.1-2.7,0.8-2.2,2.7c0.5,2.1,1.3,4.1,2,6.1c0.8,2.2,3,2.7,5,0.9c-1.4-0.3-2.5-0.6-3.7-0.9c1.5-0.2,2.9-0.4,4.3-0.6
		c0-0.3,0-0.5,0-0.8c-0.9-0.3-1.9-0.5-2.6-0.8c1.1-0.2,2.3-0.5,3.5-0.8c0-0.2-0.1-0.4-0.1-0.6C78.8,107.9,78.2,107.7,77.6,107.4z
		 M177.7,106.7c-0.2,0.4-0.3,0.9-0.6,1.4c-1.2,2.1-2.4,4.3-3.8,6.3c-0.9,1.3-0.4,1.9,0.7,2.6c1.3,0.9,2.1-0.2,2.8-0.7
		c2.2-1.5,4.4-2.9,6.3-4.7c1.3-1.2,0.5-3-1.2-3.5C180.5,107.7,179.1,107.2,177.7,106.7z M161.6,111.2c0.3-1.9,0.5-3.7,0.8-5.5
		c0.3-1.8-0.6-2.9-2.4-3.1c-0.4,0-0.8-0.1-1.1-0.2c-2.4-0.7-4.1,0.8-4,3.2c0.1,1.1-0.2,2.2-0.4,3.2c-0.4,1.6,1.1,3.6,2.3,2.8
		C158.4,110.6,159.9,111.3,161.6,111.2z M229,114.6c0.1-0.1,0.3-0.2,0.4-0.3c-0.7-1.1-1.2-2.3-2.1-3.2c-0.4-0.4-1.8-0.1-2.4,0.4
		c-1.5,1.3-2.8,2.9-4.1,4.4c-0.8,1-0.5,1.9,0.5,2.7c4.2,3.1,6.8,2,7.5-3.1C229,115.2,229,114.9,229,114.6z M83.1,106.6
		c0.4,0.6,0.8,1.1,1.1,1.5c-0.6,0.3-1.1,0.5-1.8,0.8c0.5,0.6,0.8,1,1.2,1.5c-0.6,0.2-1.2,0.4-1.9,0.6c0.4,0.6,0.7,1.1,1.1,1.7
		c-0.6,0.2-1.1,0.3-1.7,0.5c1.3,1.3,7.6,1.3,10.8,0.1C91.8,110.6,86.2,106,83.1,106.6z M260.5,94c0-0.2,0-0.3,0.1-0.5
		c-6.6-4.1-21.5-4.9-25.8-1.2c1,0.7,2.6,1.3,2.8,2.2c0.6,2.3,2.3,1.3,3.6,1.7c-0.4-0.6-1-0.9-1.5-1.4c-0.5-0.5-1-1.1-1.4-1.7
		c0.7-0.3,1.3-0.8,2-0.9c5.8-1,11.5-0.6,17.2,1.1C258.4,93.7,259.5,93.8,260.5,94z M82.7,143.7c-0.1,0.2-0.1,0.5-0.2,0.7
		c1.4,0.5,2.7,1.3,4.1,1.5c2.4,0.3,4.8,0.3,7.2,0.1c0.7,0,1.8-0.7,2-1.3c0.3-1-0.2-1.9-1.5-2.5C90,140,86.5,142.6,82.7,143.7z
		 M148.2,141.2c3.7,1.1,7.3,0.8,10.8-0.7c1.6-0.7,3.2-1.5,5.1-2.4c-1-0.6-1.5-0.9-2-1.1c-1.8,1.3-7,1.3-9.6,0
		c-0.9,1.9-2.1,2.1-3.7,0.5C148.6,138.7,148.4,139.9,148.2,141.2z M40,62.4c0,0.1-0.1,0.2-0.1,0.3c0.3,0.1,0.7,0.3,1,0.4
		c6.5,1.6,12.6,4.2,19.3,5.4c8.1,1.5,16.1,3.9,23.6,7.7c-2.2-1.5-4.4-3.3-6.9-4c-8.7-2.5-17.5-4.7-26.2-7
		C47.1,64.2,43.5,63.3,40,62.4z M173.7,52.3c-4.3,0.9-8.3,1.7-11.8,2.4c4,0.8,8.4,1.6,12.8,2.5c0.1-0.3,0.2-0.7,0.3-1
		c-1.7-0.7-3.4-1.4-5.6-2.3c3.1-0.5,5.8-0.9,8.7-1.3c-1.4-1.9-7.1-2.8-9.1-1.2C170.7,51.7,172.1,52,173.7,52.3z M227.9,109.3
		c-0.2,0.2-0.3,0.4-0.5,0.6c2.6,2.4,3.2,5.1,1.5,8.2c-0.3,0.5-0.7,1.2-0.6,1.6c0.2,0.6,0.8,1.6,1.3,1.6c1.1,0.1,2.2-0.3,3.2-0.7
		c0.3-0.1,0.5-1.1,0.4-1.6c-0.1-0.5-0.7-0.8-1.1-1.2c2.4-3.1-0.7-4.9-1.6-7.1C230.3,110,228.9,109.7,227.9,109.3z M169,131.4
		c-0.5,1-1,1.7-1.3,2.4c-0.5,1.1-1.2,2.3,0.6,3.3c-2.5,2-4.8,3.9-7,5.7c4,0.5,6.9-1.8,7.3-5.8c0-0.6,0.4-1.2,0.7-1.7
		c1.2-1.9,2.4-3.8,3.6-5.6c-0.1-0.2-0.3-0.3-0.4-0.5C171.1,129.7,170.7,132.6,169,131.4z M99.8,101.6c0.4-1.4-1-5.1-2.1-5.5
		c-3-0.9-6-1.8-9.1-2.7c0.2,0.5,0.3,0.9,0.6,1C92.7,96.8,96.2,99.1,99.8,101.6z M170.5,86.8c0.6,0.7,1,1.5,1.7,1.8
		c1.8,0.8,3.7,1.5,5.7,2c0.5,0.1,1.5-0.5,1.7-1c0.3-0.5-0.1-1.3-0.3-2.2C176.4,87.3,173.8,84.6,170.5,86.8z M41.8,72.5
		c-0.6,0.1-0.9,0.2-1.2,0.2c-1.4,0.3-2.8,0.6-2.2,2.7c0.2,0.6-0.6,1.3-0.9,2.1c-0.2,0.5-0.4,1.3-0.2,1.7c0.2,0.4,1,0.4,1.5,0.6
		c0.2-0.6,0.8-1.5,0.6-1.8c-0.8-1.1-0.3-1.8,0.6-2.1c0.2-0.1,0.9,1.1,1.3,1.7c0.3,0.6,0.5,1.2,0.7,1.8c0.2-0.1,0.3-0.1,0.5-0.2
		C42.3,77.1,42.1,74.9,41.8,72.5z M218.5,54.2c1.2-1,2.1-1.9,3.1-2.8c0.1-0.1,0.2-0.2,0.3-0.3c0.9-1.1,0.8-2.4-0.5-3.1
		c-0.8-0.5-1.8-0.7-2.9-1.1C218.5,49.5,218.5,51.8,218.5,54.2z M272.2,127.3c-0.4-1-0.8-1.8-1.2-2.7c-0.7,0.4-1.5,0.7-2.2,1.2
		c-0.8,0.7-1.6,1.5-2.3,2.4c-0.2,0.3-0.4,0.9-0.2,1.2c0.2,0.3,0.8,0.5,1.1,0.4c1.2-0.3,2.4-0.6,3.5-1.1
		C271.5,128.4,271.7,127.8,272.2,127.3z M248.4,107.9c0-0.1,0.2-0.8-0.1-1.4c-0.3-0.6-1-1.2-1.6-1.4c-0.7-0.2-1.6,0.2-2.3,0.3
		c0.2,0.7,0.3,1.3,0.5,2c0.3,0.7,0.6,1.3,1,1.9c0.5,0.7,1.1,1.2,1.7,1.9c0.3-0.8,0.6-1.7,0.9-2.5
		C248.4,108.6,248.4,108.5,248.4,107.9z M218.8,125.1c1.9-0.4,3.4-0.6,4.8-0.9c0.3-0.1,0.8-0.4,0.8-0.7c0.1-0.3-0.2-0.8-0.5-0.9
		c-1.3-0.5-2.5-1.1-3.8-1.3c-1.8-0.2-2,1.4-2.2,2.6C217.8,124.3,218.5,124.8,218.8,125.1z M46.2,126.8c-0.3,0-0.4,0-0.5,0
		c-0.8,0.4-1.9,0.7-2.2,1.3c-0.4,0.7,0,1.8,0,2.8c0.9-0.2,1.8-0.4,2.6-0.8c0.7-0.3,1.3-0.6,1.8-1.1c0.3-0.3,0.7-0.9,0.6-1.3
		c-0.1-0.4-0.7-0.7-1.1-0.9C46.9,126.7,46.4,126.8,46.2,126.8z M229.4,150.2c-0.1-0.2-0.2-0.7-0.5-1c-0.1-0.1-0.8,0-1,0.2
		c-0.9,1.1-1.7,2.1-2.4,3.3c-0.5,0.8,0.9,2.8,2,2.8c0.5,0,1.1-1,1.3-1.7C229.1,152.8,229.2,151.6,229.4,150.2z M62.5,156.9
		c-0.4-1.6-0.5-2.9-1-4.1c-0.2-0.4-1-0.5-1.6-0.8c-0.5,1.3-1.4,2.6-1.3,3.9c0,0.7,1.7,1.3,2.6,1.8C61.5,157.8,62.1,157.2,62.5,156.9
		z M260.8,155.7c-0.2-0.4-0.2-0.7-0.3-0.7c-1.7-0.8-3.4-1.7-5.1-2.4c0,0-0.7,0.9-0.6,1.2c0.7,1.2,1.4,2.3,2.4,3.3
		c0.3,0.3,1.3,0.2,1.9,0C259.7,156.8,260.3,156.2,260.8,155.7z M65.9,103.7c-1.1,0.6-1.9,1-2.7,1.4c0.4,0.7,0.7,1.4,1.3,2
		c0.6,0.7,1.3,1.3,2,1.8c0.4,0.2,1,0,1.5-0.1c-0.1-1.2-0.2-2.4-0.5-3.5C67.2,104.7,66.5,104.3,65.9,103.7z M93.4,148.4
		c-1.4-0.5-2.2-0.9-3.1-1c-1.1-0.1-2.7-1-3.1,0.6c-0.2,0.7,1.2,1.9,2.1,2.6c0.4,0.4,1.4,0.5,1.9,0.2
		C91.9,150.4,92.4,149.6,93.4,148.4z M123.8,90.4c0-0.3,0.1-0.5,0.1-0.8c-3.8-0.3-7.5-2.2-11.5-1.6c0,0.3-0.1,0.6-0.1,0.9
		C116.2,89.4,120,89.9,123.8,90.4z M126.9,113.7c-0.1,0.3-0.2,0.6-0.2,0.8c0.4,0.3,0.8,0.7,1.3,0.8c1.8,0.2,3.6,0.3,5.4,0.2
		c0.4,0,0.8-0.6,1.3-0.9c-0.4-0.3-0.8-0.9-1.2-0.9C131.3,113.7,129.1,113.7,126.9,113.7z M160.1,96.7c-0.3,1.4-0.9,2.3-0.6,3.1
		c0.5,1.6,2.7,2.3,4.8,1.8C163,100.1,161.7,98.7,160.1,96.7z M84.7,139.6c-4.3-1.9-4.7-1.9-6.6,0.7
		C80.5,141.2,82.6,139.8,84.7,139.6z M216.4,73.4c0.4-0.2,0.7-0.4,1.1-0.6c-1.5-2.7-3.1-5.4-4.6-8.1c-0.2,0.1-0.4,0.2-0.7,0.4
		C213.6,67.8,215,70.6,216.4,73.4z M151.1,109.9c1.8-0.4,2.7-1.9,2.3-4.1C150.4,106.4,150.4,106.4,151.1,109.9z M245.4,131.2
		c-0.9,1.1-1.8,1.7-1.8,2.4c0,0.6,0.9,1.5,1.5,1.7c0.4,0.1,1.5-0.8,1.6-1.4C246.8,133.2,246.1,132.4,245.4,131.2z M45.3,103
		c-0.7,0.9-1,1.8-1.5,1.9c-2.9,0.6-2.4,3.3-3.5,5.1C41.8,107.8,44.7,106.5,45.3,103z M206.4,60.7c-0.2,0.1-0.4,0.2-0.6,0.3
		c0.2,2.6,2.1,5.6,4.1,6C208.7,64.7,207.5,62.7,206.4,60.7z M185,53.6c-0.5-2.4-3.8-3.5-6.9-2.6C180.4,51.9,182.6,52.7,185,53.6z
		 M103.9,124.6c-1.7-3.1-1.8-3.2-3.4-2C101.9,126,102.2,126.3,103.9,124.6z M39.6,111.3c-2.4,0.8-3.5,2.9-3,5.3
		C37.6,114.9,38.5,113.3,39.6,111.3z M208,59.2c0.2-0.1,0.3-0.2,0.5-0.3c-1.4-2.9-2.8-5.8-4.2-8.7c-0.2,0.1-0.5,0.2-0.7,0.4
		C205,53.4,206.5,56.3,208,59.2z M103.6,106.3c0.2-0.1,0.3-0.2,0.5-0.2c-0.3-1.4-0.6-2.9-1.1-4.8c-0.9,1.1-1.4,1.6-1.8,2.1
		C102.1,104.5,102.9,105.4,103.6,106.3z M206.3,59.7c-0.5-1.1-0.9-2.1-1.4-3.1c-0.1-0.3-0.6-0.4-0.8-0.6c-0.2,0.4-0.6,0.8-0.5,1.1
		c0.4,1,0.9,2,1.4,3c0.1,0.2,0.6,0.3,0.8,0.2C206,60.2,206.1,59.9,206.3,59.7z M151.7,103.7c0.1,0.1,0.1,0.2,0.2,0.3
		c0.6-0.5,1.1-1.1,1.8-1.3c1-0.4,2.2-0.5,3.6-0.8c-0.7-1-1.1-1.5-1.5-2C154.3,101.2,153,102.5,151.7,103.7z M103.2,116.3
		c0.2,0.1,0.4,0.1,0.6,0.2c0.9-3.2,1.7-6.4,2.6-9.6c-0.1,0-0.3-0.1-0.4-0.1C105.1,109.9,104.2,113.1,103.2,116.3z M222.3,43.2
		c0.2-0.1,0.5-0.1,0.7-0.2c0.1-0.6,0.5-1.5,0.3-1.7c-0.6-0.6-1.4-0.8-2.2-1.2c-0.2,0.2-0.3,0.4-0.5,0.6
		C221.2,41.6,221.8,42.4,222.3,43.2z M201.4,49.6c0.5-1.6,0.8-2.9,1.2-4.3C200.7,45.4,200,47.3,201.4,49.6z M211.7,91.5
		c-0.5,2.8-0.9,5.6,0.5,8.7C212,97.1,211.9,94.3,211.7,91.5z M136.5,124.1c0.1-0.2,0.3-0.3,0.4-0.5c-0.2-0.7-0.4-1.4-0.6-2.2
		c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0.6-0.7,1.3-1.1,1.9C135.3,123.5,135.9,123.8,136.5,124.1z M46.1,103c0.1,0.1,0.2,0.3,0.3,0.4
		c1.6-1.1,3.2-2.3,4.8-3.4c-0.1-0.2-0.3-0.4-0.4-0.5C49.3,100.7,47.7,101.9,46.1,103z M111.4,120.7c-0.2,0.2-0.4,0.3-0.5,0.5
		c0.4,0.7,0.5,1.6,1.1,2c0.4,0.3,1.3-0.3,2-0.4c0-0.2,0-0.3,0-0.5C113.1,121.7,112.2,121.2,111.4,120.7z M226.4,124.9
		c-1.7-0.1-1.8,0.2-0.3,4.5C226.2,128,226.3,126.5,226.4,124.9z M27.4,92.7c-2.1,1.3-2.8,3.1-2.2,4.5C26,95.5,26.7,94.1,27.4,92.7z
		 M117.7,125.1c0-0.1,0-0.1,0-0.2c-2.9,0-5.9,0-8.8,0c0,0.1,0,0.1,0,0.2C111.8,125.1,114.8,125.1,117.7,125.1z M136,83.4
		c0-0.1,0-0.2,0-0.3c-2.2-0.4-4.5-0.8-6.7-1.3c0,0.2-0.1,0.4-0.1,0.6C131.5,82.8,133.7,83.1,136,83.4z"/>
			<path d="M197,70.5c-0.1,0.2-0.2,0.4-0.3,0.6c0,0,0,0.1,0,0.1C196.8,71,196.9,70.7,197,70.5z" />
			<path d="M243.7,142.5c1.3,0.6,2.7,1.2,4.3,1.9c-2.4,1.1-3.4-0.5-4.6-1.3C243.6,142.9,243.7,142.7,243.7,142.5z" />
			<path d="M235.3,139.4c-1.7-1.6-0.7-2.9-0.1-4.2c0.2,0,0.4,0,0.6,0.1C235.6,136.7,235.4,138.1,235.3,139.4z" />
			<path d="M248.9,123c2.1,0.1,2.4,1.4,2.1,3.5C250.1,125,249.5,124,248.9,123z" />
			<path d="M253.4,137.1c1-1.1,1.8-2.1,2.8-3.2C256.5,135.8,255.9,136.6,253.4,137.1z" />
			<path d="M94.3,133.8c-0.3-0.6-0.6-1.2-1.1-1.7c-1.4-1.4-3-2.7-4.4-4.2c-0.5-0.5-0.7-1.2-0.7-1.9c2,1.7,4,3.4,6,5.1
		c1,0.8,2.2,1.6,3.4,2c5.5,1.6,11.2,3,16.7,4.5c0.7,0.2,1.3,0.7,2,1c-0.1,0.2-0.2,0.4-0.3,0.7c-1.5-0.4-3.1-0.8-4.6-1.2
		c-1.4-0.3-2.8-0.4-4.2-0.7c-2.3-0.5-4.6-1-6.8-1.5c-1-0.2-2.2,0.1-3.1-0.3C96.3,135.3,95.4,134.4,94.3,133.8L94.3,133.8z"/>
			<path d="M94.4,133.8c-3.2-0.9-6.6-1.5-9.1-4.5C89,129.8,91.9,131.2,94.4,133.8C94.3,133.8,94.4,133.8,94.4,133.8z" />
			<path d="M115.3,136.3c-2.7-0.8-4.9-1.5-7-2.1C109.7,133.7,113,134.5,115.3,136.3z" />
			<path d="M123.4,139.8c-1.8,1.6-3.4,0-5.2,0.2c0-0.3,0-0.6,0.1-0.9C120,139.3,121.7,139.5,123.4,139.8L123.4,139.8z" />
			<path d="M123.4,139.8c-1.2-0.6-2.4-1.3-3.6-1.9c0-0.1,0.1-0.2,0.1-0.3c1.4,0.4,2.8,0.9,4.4,1.4
		C123.9,139.3,123.6,139.5,123.4,139.8C123.4,139.8,123.4,139.8,123.4,139.8z"/>
		</g>
	</svg>);
export default iCB125F;